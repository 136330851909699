import React, {useEffect, useState} from 'react';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { dataFormat } from '../../../../functions/dataFormat.js';
import { zl } from "../../../../functions/utilities.js";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPencil, faArrowLeft, faFloppyDisk } from '@fortawesome/free-solid-svg-icons'
import { fetchPrzelewy } from '../../../../Redux/Actions.js'; 

const HistoriaTransakcjiTBody = (props) => {

    const {wybranyMieszkaniecId, wybranaWspolnotaId, przelewy, mieszkancy, udzialyKontaAkt, setTrashId, setOpen} = props;

    const dispatch = useDispatch();

    const [listaF, setListaF] = useState([]);
    const [przelewy_a, setPrzelewy_a] = useState([]);

    const [editedRow, setEditedRow] = useState(0);
    const [editedUser, setEditedUser] = useState(undefined);
    const [editedGrupa, setEditedGrupa] = useState(undefined);

    useEffect( ( ) => {
        if (wybranyMieszkaniecId == 0)
            setPrzelewy_a( przelewy?.filter( p => p.id_wspolnoty == wybranaWspolnotaId && p?.operacja !== "prowizja") )
        else if (wybranyMieszkaniecId  > 0)
            setPrzelewy_a( przelewy?.filter( p => p?.id_user == wybranyMieszkaniecId && p?.operacja !== "prowizja") )
    },[wybranaWspolnotaId, wybranyMieszkaniecId, przelewy]);

    useEffect( () => {
        setListaF( przelewy_a?.filter( p => p?.id_wspolnoty == wybranaWspolnotaId && p.operacja !== "prowizja") )
    },[wybranaWspolnotaId, przelewy_a]);

    const handleClick = id => {
        setTrashId(id)
        setOpen(true);  
    }

    return (
        <tbody>
            {listaF?.map((row, index) => {
                const mieszkaniecTxt = () => {
                    if (row.nadawca_konto === "Konto główne wspólnoty" && row.grupa_numer === 0) {
                        return "Konto główne wspólnoty";
                    } else if (row.odbiorca_konto === "Konto główne wspólnoty") {
                        return row.nadawca_nazwa;
                    } else if ( row.operacja === "przelew wychodzący" ) {
                        return row.odbiorca_nazwa;
                    } else {
                        return mieszkancy?.find( m => m.id_user == row?.id_user)?.imie_nazwisko;
                    }
                }

                const handleChangeUser = e => setEditedUser(e.target.value);
                const handleChangeGrupa = e => setEditedGrupa(e.target.value);
                const handleSaveRow = async () => {
                    try {
                        const response = await axios.post('/api/przelewy/edytuj', { id: editRow, id_user: editedUser, grupa_numer: editedGrupa });
                        if (response.data.success === true)  {
                          dispatch(fetchPrzelewy(wybranaWspolnotaId)); 
                        }
              
                        console.log('Response from backend:', response.data);
                    } catch (error) {
                        console.error('Error sending ID to backend:', error);
                    }
                }
                const handleCancel = e => {
                    setEditedRow(0);
                    setEditedUser(undefined);
                    setEditedGrupa(undefined);
                }

                const editRow = id => setEditedRow(id);
 
                const save = editedRow > 0 && editedRow == row.id ? 
                    (<span
                        className='pointer'
                        onClick={handleSaveRow}
                    >
                        <FontAwesomeIcon icon={faFloppyDisk} />
                    </span>) 
                    : (<></>)

                const cancel = editedRow > 0 && editedRow == row.id ?
                    (<span
                        className='pointer'
                        onClick={handleCancel}
                    >
                        <FontAwesomeIcon icon={faArrowLeft} />
                    </span>)  
                    : (<></>)

                const pencil = editedRow === 0 && ( row.grupa_numer === null || row.id_user === null )  ? 
                    (<span 
                        className='pointer' 
                        onClick={()=>editRow(row.id)}
                    >
                        <FontAwesomeIcon icon={faPencil} />
                    </span>) 
                    : <span></span>;

                const user = editedRow == row.id ? (
                    <select 
                        value={editedUser}
                        onChange={handleChangeUser}
                    >
                        { mieszkancy?.map( (m, i) => (
                            <option
                                key={i}
                                value={m?.id_user}
                            >
                                {m.imie_nazwisko}
                            </option>
                        ) ) }
                    </select>
                ) : ( <span>{mieszkancy?.find( m => m?.id_user == row.id_user)?.imie_nazwisko }</span> )

                const grupa = editedRow == row.id ? (
                    <select
                        value={editedGrupa}
                        onChange={handleChangeGrupa}
                    >
                        { udzialyKontaAkt?.filter( u => u?.id_user == editedUser)?.map( (g,i) => (
                            <option>
                                {g.grupa_numer}
                            </option>
                        )) }
                    </select> 
                ) : (row.grupa_numer)
                
                const trash = editedRow === 0 ? (
                    <span 
                        className='pointer' 
                        onClick={()=>handleClick(row.id)}
                    >
                        <FontAwesomeIcon icon={faTrash} />
                    </span>
                ) : (<></>)

                return (
                    <tr key={index} className={ index % 2 ? "" : "g" }>
                        <td>{ row.operacja === "przeksięgowanie z" || row.operacja === "przeksięgowanie na" ? "" : row.nazwa_pliku}</td>
                        <td>{ row.operacja === "przeksięgowanie z" || row.operacja === "przeksięgowanie na" ? row.id : row.id_transkacji}</td>
                        <td>{row.operacja}</td>
                        <td>{dataFormat(row.data_operacji)}</td>
                        <td>
                            {mieszkaniecTxt() == undefined && row.operacja !== "prowizja" ? <span className='red'>Numer konta nieprzypisany do żadnej grupy czynszowej</span> : mieszkaniecTxt()}
                        </td>
                        <td style={{textAlign: "right"}} >
                            {zl(row.kwota)}
                        </td>
                        <td>{row.nazwa_transakcji}</td>
                        <td>{user}</td>
                        <td style={{ textAlign: "center" }} >
                            {grupa}
                        </td>
                        <td className='ost'>
                            {save}
                            {cancel}
                            {pencil}
                            {trash}
                        </td>
                    </tr>
                )
            })}
        </tbody>
    )
}

export default HistoriaTransakcjiTBody;