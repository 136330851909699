import React, { useEffect, useState } from "react";
import { Formik, Form } from 'formik';
import axios from "axios";
import * as Yup from 'yup';
import { useNavigate  } from "react-router-dom";
import FormikControl from "../Formik/FormikControl";

const WspolnotyEditForm = (props) => {

    const {wspolnota, banki} = props;

    const navigate = useNavigate();

    const [form, setForm] = useState({
        name: '',
        nip: '',
        ulica: '',
        nr_domu: '',
        nr_lokalu: '',
        kod_pocztowy: '',
        miejscowosc: '',
        nr_konta: '',
        id_bank: ''
    })

    console.log(wspolnota)

    useEffect( () => {
        setForm({
            name: wspolnota?.name,
            nip: wspolnota?.nip,
            ulica: wspolnota?.ulica,
            nr_domu: wspolnota?.nr_domu,
            nr_lokalu: wspolnota?.nr_lokalu,
            kod_pocztowy: wspolnota?.kod_pocztowy,
            miejscowosc: wspolnota?.miejscowosc,
            nr_konta: wspolnota?.nr_konta,
            id_bank: wspolnota?.id_bank
        })
    },[wspolnota, banki]);

    const handleCancel = () => {
        setForm({
            name: wspolnota?.name,
            nip: wspolnota?.nip,
            ulica: wspolnota?.ulica,
            nr_domu: wspolnota?.nr_domu,
            nr_lokalu: wspolnota?.nr_lokalu,
            kod_pocztowy: wspolnota?.kod_pocztowy,
            miejscowosc: wspolnota?.miejscowosc,
            nr_konta: wspolnota?.nr_konta,
            id_bank: wspolnota?.id_bank
        })
    }

    const editData = async () => {
          await axios.post('/api/edytuj_wspolnote', {
            id: wspolnota?.id,
            name: form.name,
            nip: form.nip,
            ulica: form.ulica,
            nr_domu: form.nr_domu,
            nr_lokalu: form.nr_lokalu,
            kod_pocztowy: form.kod_pocztowy,
            miejscowosc: form.miejscowosc,
            id_bank: form.id_bank,
            nr_konta: form.nr_konta,
          }).then( (res) => {
            navigate("/wspolnoty");
          }).catch( err => {
            console.log(err);
          })

    };

    const handleEdit = ( ) => editData(form);

    const bankiLista = banki?.map( (b, i) => (
        <option 
            key={i}
            value={b.id}
        >
            {b.nazwa}
        </option>
    ))

    return (
        <div className="wspolnotyEditForm ramka">
            <div className="naglowek">
                <h3>
                    Edycja danych
                </h3>
            </div>
            <div className="wspolnotyEdytuj">
                <div className="wiersz">
                    <label htmlFor="name">
                        Nazwa:
                    </label>
                    <input
                        id="name"
                        type="text"
                        value={form.name}
                        onChange={(e)=>setForm( prevState => ({ ...prevState, name: e.target.value }) )}
                    />
                </div>
                <div className="wiersz">
                    <label htmlFor="nip">
                        NIP:
                    </label>
                    <input
                        id="nip"
                        type="text"
                        value={form.nip}
                        onChange={(e)=>setForm( prevState => ({ ...prevState, nip: e.target.value }) )}
                    />
                </div>
                <div className="wiersz">
                    <label htmlFor="ulica">
                        Ulica:
                    </label>
                    <input
                        id="ulica"
                        type="text"
                        value={form.ulica}
                        onChange={(e)=>setForm( prevState => ({ ...prevState, ulica: e.target.value }) )}
                    />
                </div>
                <div className="wiersz">
                    <label htmlFor="nr_domu">
                        Nr budynku:
                    </label>
                    <input
                        id="nr_domu"
                        type="text"
                        value={form.nr_domu}
                        onChange={(e)=>setForm( prevState => ({ ...prevState, nr_domu: e.target.value }) )}
                    />
                </div>
                <div className="wiersz">
                    <label htmlFor="nr_lokalu">
                        Nr lokalu:
                    </label>
                    <input
                        id="nr_lokalu"
                        type="text"
                        value={form.nr_lokalu}
                        onChange={(e)=>setForm( prevState => ({ ...prevState, nr_lokalu: e.target.value }) )}
                    />
                </div>
                <div className="wiersz">
                    <label htmlFor="kod_pocztowy">
                        Kod pocztowy:
                    </label>
                    <input
                        id="kod_pocztowy"
                        type="text"
                        value={form.kod_pocztowy}
                        onChange={(e)=>setForm( prevState => ({ ...prevState, kod_pocztowy: e.target.value }) )}
                    />
                </div>
                <div className="wiersz">
                    <label htmlFor="miejscowosc">
                        Kod pocztowy:
                    </label>
                    <input
                        id="miejscowosc"
                        type="text"
                        value={form.miejscowosc}
                        onChange={(e)=>setForm( prevState => ({ ...prevState, miejscowosc: e.target.value }) )}
                    />
                </div>
                <div className="wiersz">
                    <label htmlFor="id_bank">
                        Bank:
                    </label>
                    <select
                        onChange={(e)=>setForm( prevState => ({ ...prevState, id_bank: e.target.value }) )}
                        id="id_bank"
                        value={form.id_bank}
                    >
                        {bankiLista}
                    </select>  
                </div>
                <div className="wiersz">
                    <label htmlFor="nr_konta">
                        Numer konta:
                    </label>
                    <input
                        id="nr_konta"
                        type="text"
                        value={form.nr_konta}
                        onChange={(e)=>setForm( prevState => ({ ...prevState, nr_konta: e.target.value }) )}
                    />
                </div>
                <div className="wiersz button">
                    <button onClick={handleEdit}>
                        Zapisz
                    </button>
                    <button onClick={handleCancel}>
                        Anuluj
                    </button>
                </div>
            </div>
        </div>               
    )
}

export default WspolnotyEditForm;