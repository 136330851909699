import React from 'react';
import MieszkaniecDaneInformacjeWsad from './MieszkaniecDaneInformacjeWsad';
import MieszkaniecDaneHistoriaZmian from './MieszkaniecDaneHistoriaZmian';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouseChimneyMedical } from '@fortawesome/free-solid-svg-icons';
import Tooltip from '@mui/material/Tooltip';

const MieszkaniecDaneInformacje = props => {

    const { mieszkaniec, user, mieszkaniecAll, zmianyMieszkancaPokaz, setZmianyMieszkancaPokaz, setAddGrupe, imie, setImie, telefon, setTelefon, email, setEmail, nota, setNota, zarzad, setZarzad, notaDane, setNotaDane} = props;

    return (
        <div className="ramka">
            <div className='naglowek'>
                <h2>
                    Informacje podstawowe 
                </h2>
                    <Tooltip
                        title="Dodaj nową grupę"
                        placement='top'
                    >
                        <FontAwesomeIcon 
                            icon={faHouseChimneyMedical} 
                            onClick={()=>setAddGrupe(true)}
                            className="pointer"
                        />
                    </Tooltip>
            </div>
            <MieszkaniecDaneInformacjeWsad 
                mieszkaniec={mieszkaniec}
                user={user}
                imie={imie}
                setImie={setImie}
                telefon={telefon}
                setTelefon={setTelefon}
                email={email}
                setEmail={setEmail}
                nota={nota}
                setNota={setNota}
                zarzad={zarzad}
                setZarzad={setZarzad}
                notaDane={notaDane}
                setNotaDane={setNotaDane}
            />
            <MieszkaniecDaneHistoriaZmian
                idw={mieszkaniec?.id_wspolnoty}
                mieszkaniecAll={mieszkaniecAll}
                zmianyMieszkancaPokaz={zmianyMieszkancaPokaz}
                setZmianyMieszkancaPokaz={setZmianyMieszkancaPokaz}
            />
        </div>
    )
}

export default MieszkaniecDaneInformacje;