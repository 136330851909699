import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector, useDispatch } from 'react-redux';
import MieszkaniecDaneGrupyLokale from './MieszkaniecDaneGrupyLokale';
import MieszkaniecDaneGrupyHistoriaZmian from "./MieszkaniecDaneGrupyHistoriaZmian.js";
import MieszkaniecDaneGrupyDodajLokal from "./MieszkaniecDaneGrupyDodajLokal.js";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil, faFileInvoiceDollar, faChevronLeft, faCheck, faPlus } from '@fortawesome/free-solid-svg-icons';
import { format } from 'date-fns';
import { findMieszkanieId } from "../../functions/ulice.js";
import { 
    fetchUdzialyKonta,
    fetchUdzialyKontaAll,
    fetchUdzialyKontaAkt
  } from '../../Redux/Actions.js'; 
import { hasKey } from "../../functions/tablice.js";
import { formatKonta } from "../../functions/utilities.js";
import NotaModal from "../NotyWydruk/NotaModal.js";
import Tooltip from '@mui/material/Tooltip';

const MieszkaniecDaneGrupy = props => {

    const { mieszkaniec, grupy_g, grupaUdzial, wspolnota, udzialyKontaAkt_u, editDataOd, setEditDataOd, editDataDo, setEditDataDo, tablicaCzynsze,  taGrupaUdzial } = props;

    const ulice = useSelector( state => state?.ulice?.data);
    const lokale = useSelector( state => state?.lokale?.data);
    const dispatch = useDispatch();

    const [czyWydruk, setCzyWydruk] = useState(false);
    const [zmianyGrupyPokaz, setZmianyGrupyPokaz] = useState( {id: 0, show: false} );
    const [udzialGrupaNota, setUdzialGrupaNota] = useState([]);

    const [nota, setNota] = useState(0);

    const [addLokal, setAddLokal] = useState(false);

    const [editMieszkancy, setEditMieszkancy] = useState({visible: false, value: null});
    const [editKonto, setEditKonto] = useState({visible: false, konto: ""});
    
    const [editPoczatek, setEditPoczatek] = useState("");

    useEffect( () => {
        setAddLokal(false);
    },[grupaUdzial]);

    useEffect( () => {
        setEditMieszkancy( {visible: false, value: grupaUdzial?.liczba_domownikow, data: grupaUdzial?.data_od });
        setEditKonto( {visible: false, konto: grupaUdzial?.konto_bankowe} );
    },[grupaUdzial])

    useEffect( () => setUdzialGrupaNota( udzialyKontaAkt_u?.find( u => u?.id == nota) ), [nota, udzialyKontaAkt_u]);

    const showPrint = () => setCzyWydruk( prevState => !prevState );

    const lokaleArr = grupy_g?.map( g => {
        if (g.data_od !== null) {
            const mieszaknie_w_grupie = udzialyKontaAkt_u?.find( udzial => udzial.grupa_numer === g.grupa_numer);
            return ({
                czy_mieszkanie_w_grupie: hasKey(mieszaknie_w_grupie,"czy_mieszkanie_w_grupie") ? mieszaknie_w_grupie["czy_mieszkanie_w_grupie"] : "",
                grupa_numer: g.grupa_numer,
                id: g.id,
                id_lokal: g.id_lokal,
                rodzaj: lokale?.find( lokal => lokal.id == g.id_lokal)?.rodzaj,
                id_user: g.id_user,
                imie_nazwisko: mieszkaniec?.imie_nazwisko,
                id_wspolnoty: g.id_wspolnoty,
                data_od: g.data_od,
                data_do: g.data_do,
                wersja: g.wersja,
                udzial: lokale?.find( lokal => lokal.id == g.id_lokal)?.udzial
            })
        }   
    })

    const lokalDane = id => lokale?.find( lokal => lokal.id == id);
    const ulicaDane = id => ulice?.find( ulica => ulica.id == id);

    const grupaSzczegoly = () => {

        const mieszkanieId = findMieszkanieId(grupaUdzial, grupy_g, lokale);
        const mieszkanie = lokaleArr?.find( lokal => lokal?.id_lokal == mieszkanieId );
        const lokal = lokalDane(mieszkanieId);
        const ulica = ulicaDane(lokal?.ulica);
        const klatka = lokal?.klatka !== null && lokal?.klatka !== undefined && lokal?.klatka?.length > 0 ?  ` (klatka ${lokal?.klatka})` : "";
        const metraz = lokalDane(mieszkanie?.id_lokal)?.metraz; 
        const udzial = lokalDane(mieszkanie?.id_lokal)?.udzial;

        const mieszkanieLista = [{
            ulica,
            lokal: lokal?.numer,
            id_lokal: lokal?.id,
            klatka,
            metraz,
            udzial,
            grupa_numer: mieszkanie?.grupa_numer,
            grupa_id: mieszkanie?.id,
            liczba_domownikow: grupaUdzial?.liczba_domownikow,
            wersja: mieszkanie?.wersja,
            data_od: mieszkanie?.data_od,
            data_do: mieszkanie?.data_do
        }];

        const garaze =  lokaleArr?.filter( lokal => lokal?.rodzaj === "garaż"   && lokal.grupa_numer === grupaUdzial.grupa_numer);
        const komorki = lokaleArr?.filter( lokal => lokal?.rodzaj === "komórka" && lokal.grupa_numer === grupaUdzial.grupa_numer);
        const strychy = lokaleArr?.filter( lokal => lokal?.rodzaj === "strych"  && lokal.grupa_numer === grupaUdzial.grupa_numer);

        const listaTemp = obj => {
            return obj?.map( g => {
                const lokal = lokalDane(g.id_lokal);
                const ulica = ulicaDane(lokal.ulica);
                const klatka = lokal.klatka !== null ?  ` (klatka ${lokal.klatka})` : "";
                if (g.data_do === null) {
                    return (    
                        {
                            id_lokal: g.id_lokal,
                            ulica,
                            lokal: lokal.numer,
                            klatka,
                            metraz: lokalDane(g.id_lokal).metraz,
                            udzial: lokal.udzial,
                            grupa_numer: g.grupa_numer,
                            grupa_id: g.id,
                            wersja: g.wersja,
                            data_do: g.data_do,
                            data_od: g.data_od
                        }
                    )
                }
            })
        }

        const garazeLista  = listaTemp(garaze)?.filter( element => element !== undefined); 
        const komorkiLista = listaTemp(komorki)?.filter( element => element !== undefined); 
        const strychyLista = listaTemp(strychy)?.filter( element => element !== undefined);

        const czyPusta = ( mieszkanieLista?.length || garazeLista?.length || komorkiLista?.length || strychyLista?.length) ? false : true;

        const handleSaveKonto = async () => {
            try {
                await axios.post('/api/grupy/edytuj_konto', {
                    konto: editKonto.konto.replace(/\s+/g, ''), 
                    id: grupaUdzial.id
                }).then( response => {
                    dispatch(fetchUdzialyKonta(wspolnota.id));
                    dispatch(fetchUdzialyKontaAkt(wspolnota.id));
                    dispatch(fetchUdzialyKontaAll(wspolnota.id));
                    setEditKonto(prevState =>({...prevState, visible: false} ));
                })
            } catch (error) {
                console.log(error);
            }
        }
    
        return (
            <div>
                <div className="naglowek">
                    <h2>
                        {`Grupa numer ${grupaUdzial?.grupa_numer} (id: ${grupaUdzial?.id}) ${ czyPusta ? " (bez lokali)" : ""}`}
                    </h2>
                    <div className="buttons">
                        {
                            mieszkaniec?.czy_nota == 1 ? (
                                <FontAwesomeIcon 
                                    icon={faFileInvoiceDollar} 
                                    size="lg"
                                    className="pointer" 
                                    onClick={()=>{
                                        showPrint()
                                        setNota(grupaUdzial.id)
                                    }}
                                />
                            ) : ""
                        }
                        <Tooltip
                            title="Dodaj nowy lokal"
                            placement='top'
                        >
                            <FontAwesomeIcon 
                                icon={faPlus} 
                                onClick={()=>setAddLokal(true)}
                                className="pointer"
                            />
                        </Tooltip>
                    </div>
                </div>

                <ul className="daneGrupy">
                    <li>
                        <div className="w150">
                            Początek:
                        </div>
                        <div>
                            {`${grupaUdzial?.czynsz_od === null ? `brak / błąd` : format(grupaUdzial?.czynsz_od, 'yyyy-MM-dd')}`}
                        </div>
                    </li>
                    {
                        editKonto.visible  ? (
                            <li>
                                <div className="w150">
                                    Numer konta bankowego:
                                </div>
                                <input
                                    type="text"
                                    value={editKonto?.konto}
                                    onChange={(e)=>setEditKonto( prevState =>({...prevState, konto: e.target.value} ))}
                                />
                                <FontAwesomeIcon 
                                    icon={faChevronLeft} 
                                    onClick={(e)=>setEditKonto( prevState =>({...prevState, visible: false} ))}
                                    className="pointer"
                                />
                                <FontAwesomeIcon 
                                    icon={faCheck} 
                                    onClick={handleSaveKonto}
                                    className="pointer"
                                />
                            </li>
                        ) : (
                            <li>
                                <div className="w150">
                                    Numer konta bankowego:
                                </div>
                                <div>
                                    {formatKonta(editKonto?.konto)}
                                </div>
                                <FontAwesomeIcon 
                                    icon={faPencil} 
                                    onClick={()=>setEditKonto( prevState =>({...prevState, visible: true} ))}    
                                    className="pointer"
                                />

                            </li>
                        )
                    }
                </ul>
                {
                    taGrupaUdzial?.length > 1 ? (
                        <MieszkaniecDaneGrupyHistoriaZmian 
                            zmianyGrupyPokaz={zmianyGrupyPokaz}
                            setZmianyGrupyPokaz={setZmianyGrupyPokaz}
                            taGrupaUdzial={taGrupaUdzial}
                        />
                    ) : ""
                }
                {mieszkanieId && (<MieszkaniecDaneGrupyLokale 
                    tytul="Mieszkanie" 
                    obj={mieszkanieLista} 
                    editDataOd={editDataOd} 
                    setEditDataOd={setEditDataOd}
                    editDataDo={editDataDo}
                    setEditDataDo={setEditDataDo}
                    editMieszkancy={editMieszkancy}
                    setEditMieszkancy={setEditMieszkancy}
                    grupaUdzial={grupaUdzial}
                    editKonto={editKonto}
                />)}
                { garazeLista && garazeLista.length > 0 ? (
                    <MieszkaniecDaneGrupyLokale 
                        tytul="Garaże" 
                        obj={garazeLista} 
                        editDataOd={editDataOd}
                        setEditDataOd={setEditDataOd}
                        editDataDo={editDataDo}
                        setEditDataDo={setEditDataDo}
                    />
                ) : ""}
                { komorkiLista && komorkiLista.length > 0 ? (
                    <MieszkaniecDaneGrupyLokale 
                        tytul="Komórki" 
                        obj={komorkiLista} 
                        editDataOd={editDataOd}
                        setEditDataOd={setEditDataOd}
                        editDataDo={editDataDo}
                        setEditDataDo={setEditDataDo}
                    />
                ) : ""}
                { strychyLista && strychyLista.length > 0 ? (
                    <MieszkaniecDaneGrupyLokale 
                        tytul="Strychy" 
                        obj={strychyLista} 
                        editDataOd={editDataOd}
                        setEditDataOd={setEditDataOd}
                        editDataDo={editDataDo}
                        setEditDataDo={setEditDataDo}
                    />
                ) : ""}
                {
                    addLokal ? (
                        <MieszkaniecDaneGrupyDodajLokal
                            lokale={lokale}
                            ulice={ulice}
                            setAddLokal={setAddLokal}
                            grupaUdzial={grupaUdzial}
                            wspolnota={wspolnota}
                        />
                    ) : (
                        <>
                        </>
                    )
                }
            </div>
        )
    }

    return (
        <div className="ramka grupaUdzial">
            {grupaSzczegoly()}
            {
                czyWydruk ? (
                    <NotaModal
                        showPrint={showPrint}
                        mieszkaniec={mieszkaniec}
                        wspolnota={wspolnota}
                        lokale={lokale}
                        udzialGrupaNota={udzialGrupaNota}
                        nota={nota}
                        tablicaCzynsze={tablicaCzynsze}
                    />
                ) : ""
            }

        </div>
    )
}

export default MieszkaniecDaneGrupy;