const initialState = {
    data: [],
  };
  
const lokaleAllReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_LOKALE_ALL':
      return { ...state, data: action.payload };
    default:
      return state;
  }
};

export default lokaleAllReducer;