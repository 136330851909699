import React from "react";
import WspolnotaRozliczenieTHead from "./WspolnotaRozliczenieTHead.js";
import WspolnotaRozliczenieTBody from "./WspolnotaRozliczenieTBody.js";
import WspolnotyRozliczenieTFooter from "./WspolnotaRozliczenieTFooter.js";

const WspolnotaRozliczenieTable = ( props ) => {

  const { stawki, stawkiJednorazowe, czynszeMsc, ind, udzialyKontaAkt,   
    idw, wspolnota, lokale, ulice, grupy, przelewyWspolnota, czynszeWspolnota,  
    mieszkancy, zestaw } = props;

  return (
    <div className="table-container">
      <table className="lista tabela naleznosci">
        <WspolnotaRozliczenieTHead 
          stawki={stawki}
          stawkiJednorazowe={stawkiJednorazowe}
          czynszeMsc={czynszeMsc}
          ind={ind}
        />
        <WspolnotaRozliczenieTBody
          idw={idw}
          wspolnota={wspolnota}
          lokale={lokale}
          ulice={ulice}
          grupy={grupy}
          udzialyKontaAkt={udzialyKontaAkt}
          stawki={stawki}
          stawkiJednorazowe={stawkiJednorazowe}
          przelewyWspolnota={przelewyWspolnota}
          czynszeWspolnota={czynszeWspolnota}
          czynszeMsc={czynszeMsc}
          mieszkancy={mieszkancy}
          ind={ind}
          zestaw={zestaw}
        />
        <WspolnotyRozliczenieTFooter
          udzialyKontaAkt={udzialyKontaAkt}
          stawki={stawki}
          stawkiJednorazowe={stawkiJednorazowe}
          czynszeMsc={czynszeMsc}
          ind={ind}
        />
      </table>
    </div>
  )
}

export default WspolnotaRozliczenieTable;