import React, { useEffect, useState } from "react";

import { zaokr, zl } from "../../functions/utilities";
import { mm_rrrr_to_date } from '../../functions/dataFormat';


const MieszkaniecDaneSaldo = props => {

    const { tablicaPrzelewy_g, tablicaCzynsze_g, grupaUdzial } = props;

    const [przelewySuma, setPrzelewySuma] = useState(0);
    const [czynszeSuma, setCzynszeSuma] = useState(0);
    const [saldo, setSaldo] = useState("ładuje...");

    const [czyDwukrotnosc, setCzyDwukrotnosc] = useState(false);

    useEffect( () => {
        setPrzelewySuma(zaokr(tablicaPrzelewy_g?.przelewy?.reduce( (suma, obiekt) => suma + obiekt.kwota, 0),4));
    },[tablicaPrzelewy_g] );


    useEffect( () => {
        const len = tablicaCzynsze_g?.length;
        
        if (tablicaCzynsze_g?.length > 1 && new Date(mm_rrrr_to_date(tablicaCzynsze_g[len-1].msc)) > new Date() ) {
            const temp = tablicaCzynsze_g.slice(0, -1);
            
            setCzynszeSuma(zaokr(temp?.reduce( (suma, obiekt) => suma + obiekt.czynsz_na_msc, 0),4))
        } else {
            setCzynszeSuma(zaokr(tablicaCzynsze_g?.reduce( (suma, obiekt) => suma + obiekt.czynsz_na_msc, 0),4))
        }
    },[tablicaCzynsze_g] );

    useEffect( () => {
        if (przelewySuma - czynszeSuma > 0) 
            setSaldo(`nadpłata w wysokości ${zl(przelewySuma - czynszeSuma)}.`)
        else if (przelewySuma - czynszeSuma < 0) 
            setSaldo(`niedopłata w wysokości ${zl(przelewySuma - czynszeSuma)}.`)
        else if (przelewySuma - czynszeSuma == 0)
            setSaldo(`saldo 0 zł.`) 
    },[ przelewySuma, czynszeSuma]);

    useEffect( () => {
        if (tablicaCzynsze_g?.length > 0)
            setCzyDwukrotnosc( (przelewySuma - czynszeSuma) < 0 && zaokr(przelewySuma, 4) - zaokr(czynszeSuma, 4) < tablicaCzynsze_g[tablicaCzynsze_g?.length-1]?.czynsz_na_msc * -2  ? true : false )
    },[przelewySuma, czynszeSuma, tablicaCzynsze_g])

    return (
        <div className="saldo ramka">
            <h2>
                Saldo 
            </h2>
            <p
                style={ przelewySuma - czynszeSuma < 0 ? {fontWeight: "600"} : {} }
                className={ czyDwukrotnosc ? "red" : ""}
            >
                {`Na dziś w tej grupie jest widnieje ${saldo}`}
            </p>
        </div>
    )
}

export default MieszkaniecDaneSaldo;