import React, {useEffect, useState} from "react";
import { useSelector, useDispatch } from 'react-redux';
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import GoBackBtn from "../GoBackBtn";
import { 
    fetchBanki,
    fetchWspolnoty
  } from '../../Redux/Actions.js'; 

const WspolnotyAddForm = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    
    useEffect( ()=> {
        dispatch(fetchBanki());
    },[dispatch]);
    
    const banki = useSelector( state => state?.banki?.data );

    const [form, setForm] = useState({
        name: '',
        nip: '',
        ulica: '',
        nr_domu: '',
        nr_lokalu: '',
        kod_pocztowy: '',
        miejscowosc: '',
        nr_konta: '',
        id_bank: 1
    })

    const addData = async (form) => {
        try {
          // Wysyłamy dane na serwer
          await axios.post('/api/dodaj_wspolnote', {
            name: form.name,
            nip: form.nip,
            ulica: form.ulica,
            nr_domu: form.nr_domu,
            nr_lokalu: form.nr_lokalu,
            kod_pocztowy: form.kod_pocztowy,
            miejscowosc: form.miejscowosc,
            id_bank: form.id_bank,
            nr_konta: form.nr_konta
          }).then(response => {
            dispatch(fetchWspolnoty());
            navigate(`/wspolnoty`);
          });
          // Po udanym dodaniu danych możesz wykonać odpowiednie akcje, np. wyczyść formularz
        } catch (error) {
          console.error('Błąd podczas dodawania danych:', error);
        }
      };
    
    const handleSave = () => addData(form);

    const bankiLista = banki?.map( (b, i) => (
        <option 
            key={i}
            value={b.id}
        >
            {b.nazwa}
        </option>
    ))

    return (
        <div className="content">
            <h1 className="nazwa">
                Dodaj wspólnotę
            </h1>
            <GoBackBtn />
            <div className="wspolnotyDodaj ramka">
                <div className="wiersz">
                    <label htmlFor="name">
                        Nazwa:
                    </label>
                    <input
                        id="name"
                        type="text"
                        value={form.name}
                        onChange={(e)=>setForm( prevState => ({ ...prevState, name: e.target.value }) )}
                    />
                </div>
                <div className="wiersz">
                    <label htmlFor="nip">
                        NIP:
                    </label>
                    <input
                        id="nip"
                        type="text"
                        value={form.nip}
                        onChange={(e)=>setForm( prevState => ({ ...prevState, nip: e.target.value }) )}
                    />
                </div>
                <div className="wiersz">
                    <label htmlFor="ulica">
                        Ulica:
                    </label>
                    <input
                        id="ulica"
                        type="text"
                        value={form.ulica}
                        onChange={(e)=>setForm( prevState => ({ ...prevState, ulica: e.target.value }) )}
                    />
                </div>
                <div className="wiersz">
                    <label htmlFor="nr_domu">
                        Nr budynku:
                    </label>
                    <input
                        id="nr_domu"
                        type="text"
                        value={form.nr_domu}
                        onChange={(e)=>setForm( prevState => ({ ...prevState, nr_domu: e.target.value }) )}
                    />
                </div>
                <div className="wiersz">
                    <label htmlFor="nr_lokalu">
                        Nr lokalu:
                    </label>
                    <input
                        id="nr_lokalu"
                        type="text"
                        value={form.nr_lokalu}
                        onChange={(e)=>setForm( prevState => ({ ...prevState, nr_lokalu: e.target.value }) )}
                    />
                </div>
                <div className="wiersz">
                    <label htmlFor="kod_pocztowy">
                        Kod pocztowy:
                    </label>
                    <input
                        id="kod_pocztowy"
                        type="text"
                        value={form.kod_pocztowy}
                        onChange={(e)=>setForm( prevState => ({ ...prevState, kod_pocztowy: e.target.value }) )}
                    />
                </div>
                <div className="wiersz">
                    <label htmlFor="miejscowosc">
                        Kod pocztowy:
                    </label>
                    <input
                        id="miejscowosc"
                        type="text"
                        value={form.miejscowosc}
                        onChange={(e)=>setForm( prevState => ({ ...prevState, miejscowosc: e.target.value }) )}
                    />
                </div>
                <div className="wiersz">
                    <label htmlFor="id_bank">
                        Bank:
                    </label>
                    <select
                        onChange={(e)=>setForm( prevState => ({ ...prevState, id_bank: e.target.value }) )}
                        id="id_bank"
                    >
                        {bankiLista}
                    </select>  
                </div>
                <div className="wiersz">
                    <label htmlFor="nr_konta">
                        Numer konta:
                    </label>
                    <input
                        id="nr_konta"
                        type="text"
                        value={form.nr_konta}
                        onChange={(e)=>setForm( prevState => ({ ...prevState, nr_konta: e.target.value }) )}
                    />
                </div>
                <div className="wiersz button">
                    <button onClick={handleSave}>
                        Zapisz
                    </button>
                </div>
            </div>
    
        </div>
        
    )
}

export default WspolnotyAddForm;

