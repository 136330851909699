import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { copyToClipboard } from '../../functions/utilities.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane, faPencil, faChevronLeft, faCheck, faFileInvoiceDollar } from '@fortawesome/free-solid-svg-icons';
import { format } from 'date-fns';
import DialogYesNo from '../DialogYesNo.js';
import emailjs from '@emailjs/browser';
import axios from "axios";
import { 
    fetchMieszkancy,
    fetchUsers,
    fetchUserPasswordSend
  } from '../../Redux/Actions.js'; 

const MieszkaniecDaneInformacjeWsad = props => {

    const { mieszkaniec, user, imie, setImie, email, setEmail, telefon, setTelefon, nota, setNota, zarzad, setZarzad, notaDane, setNotaDane } = props;
    
    const dispatch = useDispatch();

    const [open, setOpen] = useState(false);
    const [history, setHistory] = useState({});

    const handleSendPwd = () => setOpen(true);

    const userPasswordSend = useSelector( state => state?.userPasswordSend?.data);

    useEffect( () => {
        dispatch(fetchUserPasswordSend());
    },[dispatch]);

    useEffect( () => {
        setHistory( userPasswordSend?.find( u => u.id_user == user?.id) )
    },[userPasswordSend, mieszkaniec, user]);

    const templateParams = {
        client: mieszkaniec?.email,
        login: mieszkaniec?.telefon,
        password: user?.password,
    }

    const sendMail = () => {
        emailjs.send('service_d6ugz9r', 'template_rgzgc5s', templateParams, 'Sf5FCcGAApPYZnnXi').then(
            (response) => {
                console.log('SUCCESS!', response.status, response.text);
                sendLog();                
            },
            (error) => {
              console.log('FAILED...', error);
            },
        );
    }  

    const sendLog = async () => {
        try {
            await axios.post('/api/password/send', {
                id_user: mieszkaniec?.id_user, 
                email: mieszkaniec?.email
            }).then(response => {
                if (response.status === 200) {
                    dispatch(fetchUserPasswordSend()); 
                }
            });
        } catch (error) {
            console.error('Błąd podczas dodawania danych:', error);
        }
    }

    const handleSaveEmail = async () => {
        try {
            await axios.post('/api/mieszkancy/edytuj_email', {
                email: email.value, 
                id: mieszkaniec.id
            }).then( response => {
                dispatch(fetchMieszkancy(mieszkaniec.id_wspolnoty));
                setEmail(prevState =>({...prevState, visible: false} ));

            })
        } catch (error) {
            console.log(error);
        }
    }

    const handleSaveTelefon = async () => {
        try {
            await axios.post('/api/mieszkancy/edytuj_telefon', {
                telefon: telefon.value, 
                id: mieszkaniec.id
            }).then( response => {
                dispatch(fetchMieszkancy(mieszkaniec.id_wspolnoty));
                setTelefon(prevState =>({...prevState, visible: false} ));
                handleSaveLogin();
            })
        } catch (error) {
            console.log(error);
        }
    }

    const handleSaveLogin = async () => {
        try {
            await axios.post('/api/mieszkancy/edytuj_login', {
                telefon: telefon.value, 
                id: mieszkaniec.id_user
            }).then( response => {
                dispatch(fetchUsers(mieszkaniec.id_wspolnoty));
            })
        } catch (error) {
            console.log(error);
        }
    }

    const handleSaveImie = async () => {
        try {
            await axios.post('/api/mieszkancy/edytuj_imie', {
                imie: imie.value, 
                id: mieszkaniec.id
            }).then( response => {
                dispatch(fetchMieszkancy(mieszkaniec.id_wspolnoty));
                setImie(prevState =>({...prevState, visible: false} ));
            })
        } catch (error) {
            console.log(error);
        }
    }

    const handleSaveZarzad = async (zarzad) => {
        const tempZarzad = zarzad.value === "tak" ? 1 : null;
        try {
            axios.post('/api/mieszkaniec/dodaj', {
                id_wspolnoty: mieszkaniec?.id_wspolnoty,
                id_user: mieszkaniec?.id_user,
                imie_nazwisko: mieszkaniec?.imie_nazwisko,
                telefon: mieszkaniec.telefon,
                email: mieszkaniec.email,
                data_od: zarzad.data,
                czy_zarzad: tempZarzad,
                status: "",
                czy_nota: mieszkaniec.czy_nota,
                nip: mieszkaniec.nip,
                notaFirma: mieszkaniec.notaFirma,
                notaAdres: mieszkaniec.notaAdres,
                notaKodMiasto: mieszkaniec.notaKodMiasto
            }).then( response => {
                console.log(response)
                dispatch(fetchMieszkancy(mieszkaniec?.id_wspolnoty)); 
                dispatch(fetchUsers(mieszkaniec?.id_wspolnoty))
                setZarzad( prevState => ({...prevState, visible: false}));
            })
        } catch (error) {
            console.log(error)
        }
    }

    const handleSaveNota = async (nota) => {
        const tempNota = nota.value === "tak" ? 1 : null;
        try {
            axios.post('/api/mieszkaniec/dodaj', {
                id_wspolnoty: mieszkaniec?.id_wspolnoty,
                id_user: mieszkaniec?.id_user,
                imie_nazwisko: mieszkaniec?.imie_nazwisko,
                telefon: mieszkaniec.telefon,
                email: mieszkaniec.email,
                data_od: nota.data,
                czy_zarzad: mieszkaniec.czy_zarzad,
                status: "",
                czy_nota: tempNota,
                nip: mieszkaniec.nip,
                notaFirma: mieszkaniec.notaFirma,
                notaAdres: mieszkaniec.notaAdres,
                notaKodMiasto: mieszkaniec.notaKodMiasto
            }).then( response => {
                console.log(response)
                dispatch(fetchMieszkancy(mieszkaniec?.id_wspolnoty)); 
                dispatch(fetchUsers(mieszkaniec?.id_wspolnoty))
                setNota( prevState => ({...prevState, visible: false}));
            })
        } catch (error) {
            console.log(error)
        }
    }

    const listaRozwijana = (state, setState) => {

        const handleChange = e => setState( prevState => ({...prevState, value: e.target.value})  )

        return (
            <select
                value={state.value}
                onChange={handleChange}
            >   
                <option value="tak"> 
                    Tak
                </option>
                <option value="nie">
                    Nie
                </option>
            </select>
        )   
    }

    const data = (state, setState) => {

        const handleChange = e => setState( prevState => ({...prevState, data: e.target.value}))

        return (
            <input
                type="date"
                value={state.data}
                onChange={handleChange}
            />
        )
    }
    
    const handleSaveNotaDane = async () => {
        try {
            await axios.post('/api/mieszkancy/edytuj_nota_dane', {
                id: mieszkaniec.id,
                nip: notaDane.notaNip, 
                notaFirma: notaDane.notaNazwa, 
                notaAdres: notaDane.notaAdres, 
                notaKodMiasto: notaDane.notaKodMiasto
            }).then( response => {
                dispatch(fetchMieszkancy(mieszkaniec?.id_wspolnoty));
                setImie(prevState =>({...prevState, visible: false} ));
            })
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <ul className="lista mieszkancyDane">
            <DialogYesNo
                open={open}
                setOpen={setOpen}
                message="Czy na pewno chcesz wysłać e-mail z loginem i hasłem?"
                f={sendMail}
                id={templateParams}
            />
            { imie.visible ? (
                    <li>
                        <div className="first">
                            Imię i nazwisko
                        </div>
                        <div>
                            <input
                                type="text"
                                value={imie?.value}
                                onChange={(e)=>setImie( prevState =>({...prevState, value: e.target.value} ))}
                            />
                            <FontAwesomeIcon 
                                icon={faChevronLeft} 
                                onClick={()=>setImie( prevState =>({...prevState, visible: false} ))}
                                className="pointer"
                            />
                            <FontAwesomeIcon 
                                icon={faCheck} 
                                onClick={handleSaveImie}
                                className="pointer"
                            />
                        </div>
                    </li>
                ) : (
                    <li>
                        <div className='first'>Imię i nazwisko</div>
                        <div>{mieszkaniec?.imie_nazwisko}</div>
                        <FontAwesomeIcon 
                            icon={faPencil} 
                            onClick={()=>setImie( prevState =>({...prevState, visible: true} ))}    
                            className="pointer"
                        />
                    </li>
            ) }
            {  telefon.visible ? (
                    <li>
                        <div className="first">
                            Telefon (login)
                        </div>
                        <div>
                            <input
                                type="text"
                                className='w50'
                                value={telefon?.value}
                                onChange={(e)=>setTelefon( prevState =>({...prevState, value: e.target.value} ))}
                            />
                            <FontAwesomeIcon 
                                icon={faChevronLeft} 
                                onClick={()=>setTelefon( prevState =>({...prevState, visible: false} ))}
                                className="pointer"
                            />
                            <FontAwesomeIcon 
                                icon={faCheck} 
                                onClick={handleSaveTelefon}
                                className="pointer"
                            />
                        </div>
                    </li>
                ) : (
                    <li>
                        <div className='first'>Telefon (login)</div>
                        <div>{mieszkaniec?.telefon}</div>
                        <FontAwesomeIcon 
                            icon={faPencil} 
                            onClick={()=>setTelefon( prevState =>({...prevState, visible: true} ))}    
                            className="pointer"
                        />
                    </li>
            ) }
            { email.visible ? (
                <li>
                    <div className="first">
                        E-mail:
                    </div>
                    <div>
                        <input
                            type="text"
                            value={email?.value}
                            onChange={(e)=>setEmail( prevState =>({...prevState, value: e.target.value} ))}
                        />
                        <FontAwesomeIcon 
                            icon={faChevronLeft} 
                            onClick={()=>setEmail( prevState =>({...prevState, visible: false} ))}
                            className="pointer"
                        />
                        <FontAwesomeIcon 
                            icon={faCheck} 
                            onClick={handleSaveEmail}
                            className="pointer"
                        />
                    </div>
                </li>
            ) : (
                <li>
                    <div className='first'>E-mail</div>
                    <div>{mieszkaniec?.email}</div>
                    <FontAwesomeIcon 
                        icon={faPencil} 
                        onClick={()=>setEmail( prevState =>({...prevState, visible: true} ))}    
                        className="pointer"
                    />
                </li>
            )}

            <li>
                <div className='first'>Hasło</div>
                <div 
                    className='pwd'
                >   
                    <div
                        className='pointer'
                        onClick={copyToClipboard}
                    >
                        <span>
                            {user?.password}
                        </span>
                    </div>
                    {
                        mieszkaniec?.email ? (
                            <>
                                <div >
                                    <FontAwesomeIcon 
                                        icon={faPaperPlane} 
                                        className='pointer'
                                        onClick={handleSendPwd}
                                    />
                                </div>   
                                <div>
                                    { 
                                        history?.data_utworzenia ?(
                                            format(history?.data_utworzenia, 'yyyy-MM-dd') 
                                        ) : (
                                            `brak wysyłki`
                                        )
                                    }
                                </div>  
                            </>
                        ) : ""
                    }           
                </div>
            </li>
            { zarzad.visible ? (
                <li>
                    <div className='first'>Czy zarząd?</div>
                    <div>
                        { listaRozwijana(zarzad, setZarzad) }
                        { data(zarzad, setZarzad)}
                        <FontAwesomeIcon 
                            icon={faChevronLeft} 
                            onClick={()=>setZarzad( prevState =>({...prevState, visible: false} ))}
                            className="pointer"
                        />
                        <FontAwesomeIcon 
                            icon={faCheck} 
                            onClick={()=>handleSaveZarzad(zarzad)}
                            className="pointer"
                        />
                    </div>
                </li>
            ) : (
                <li>
                    <div className='first'>Czy zarząd?</div>
                    <div>{mieszkaniec?.czy_zarzad == 1 ? "Tak" : "Nie"}</div>
                    <FontAwesomeIcon 
                        icon={faPencil} 
                        onClick={()=>setZarzad( prevState =>({...prevState, visible: true} ))}    
                        className="pointer"
                    />
                </li>
            )
            }
            { nota.visible ? (
                <li>
                    <div className='first'>Czy nota?</div>
                    <div>
                        { listaRozwijana(nota, setNota) }
                        { data(nota, setNota)}
                        <FontAwesomeIcon 
                            icon={faChevronLeft} 
                            onClick={()=>setNota( prevState =>({...prevState, visible: false} ))}
                            className="pointer"
                        />
                        <FontAwesomeIcon 
                            icon={faCheck} 
                            onClick={()=>handleSaveNota(nota)}
                            className="pointer"
                        />
                    </div>
                </li>
            ) : (
                <li>
                    <div className='first'>Czy nota?</div>
                    <div>{mieszkaniec?.czy_nota == 1 ? "Tak" : "Nie"}</div>
                    <FontAwesomeIcon 
                        icon={faPencil} 
                        onClick={()=>setNota( prevState =>({...prevState, visible: true} ))}    
                        className="pointer"
                    />
                    <FontAwesomeIcon 
                        icon={faFileInvoiceDollar} 
                        onClick={()=>setNotaDane( prevState=>({...prevState, visible: !notaDane.visible, edit: false }))} 
                        className="pointer"
                    />
                </li>
            ) }
            { notaDane?.visible ? (
                <>
                    { notaDane.edit ? (
                        <>
                            <li>
                                <div className='first'>NIP</div>
                                <div>
                                    <input
                                        type="text"
                                        className='w50'
                                        value={notaDane?.notaNip}
                                        onChange={(e)=>setNotaDane( prevState =>({...prevState, notaNip: e.target.value} ))}
                                    />  
                                </div>
                            </li>
                            <li>
                                <div className='first'>Nota - nazwa firmy</div>
                                <div>
                                    <input
                                        type="text"
                                        className='w50'
                                        value={notaDane?.notaNazwa}
                                        onChange={(e)=>setNotaDane( prevState =>({...prevState, notaNazwa: e.target.value} ))}
                                    />  
                                </div>
                            </li>
                            <li>
                                <div className='first'>Nota - adres</div>
                                <div>
                                    <input
                                        type="text"
                                        className='w50'
                                        value={notaDane?.notaAdres}
                                        onChange={(e)=>setNotaDane( prevState =>({...prevState, notaAdres: e.target.value} ))}
                                    />  
                                </div>
                            </li>
                            <li>
                                <div className='first'>Kod pocztowy i miejscowość</div>
                                <div>
                                    <input
                                        type="text"
                                        className='w50'
                                        value={notaDane?.notaKodMiasto}
                                        onChange={(e)=>setNotaDane( prevState =>({...prevState, notaKodMiasto: e.target.value} ))}
                                    />  
                                </div>
                            </li>
                            <li style={{display: "flex", justifyContent: "center"}}>
                                <div style={{width: "initial"}}>
                                    <FontAwesomeIcon 
                                        icon={faChevronLeft} 
                                        onClick={()=>setNotaDane( prevState =>({...prevState, edit: false} ))}
                                        className="pointer"
                                    />
                                    <FontAwesomeIcon  
                                        icon={faCheck} 
                                        onClick={handleSaveNotaDane}
                                        className="pointer"
                                    />
                                </div>
                            </li>
                        </>
                    ) : (
                        <>
                            <li>
                                <div className='first'>NIP</div>
                                <div>{mieszkaniec?.nip}</div>
                            </li>
                            <li>
                                <div className='first'>Nota - nazwa firmy</div>
                                <div>{mieszkaniec?.notaFirma}</div>
                            </li>
                            <li>
                                <div className='first'>Nota - adres</div>
                                <div>{mieszkaniec?.notaAdres}</div>
                            </li>
                            <li>
                                <div className='first'>Kod pocztowy i miejscowość</div>
                                <div>{mieszkaniec?.notaKodMiasto}</div>
                            </li>
                            <li style={{display: "flex", justifyContent: "center"}}>
                                <div style={{width: "initial"}}>
                                    <FontAwesomeIcon 
                                        icon={faPencil} 
                                        onClick={()=>setNotaDane( prevState =>({...prevState, visible: true, edit: true} ))}    
                                        className="pointer"
                                    />
                                </div>
                            </li>
                        </>
                    ) }
                </>
            ) : ""  }
            <li>
                <div className='first'>Ostatnia aktualizacja</div>
                <div>{format(mieszkaniec?.data_od, 'yyyy-MM-dd')}</div>
            </li>
        </ul>
    )
}

export default MieszkaniecDaneInformacjeWsad;