import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { motion } from 'framer-motion' 
import { wstepy } from '../../functions/motions';
import MieszkancyDaneGrupyLokale from '../Mieszkancy/MieszkancyDaneGrupyLokale';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileInvoice, faCopy } from '@fortawesome/free-solid-svg-icons';
import '../../scss/style.css';
import '../../scss/listy.css';
import '../../scss/mieszkancy.css'
import { copyToClipboard, copyToClipboardText, formatKonta } from '../../functions/utilities.js';
import { mieszkanie_wersja_max, lokale_wersja_max } from "../../functions/grupy";
import {  
    fetchWersje, 
    fetchUdzialyKontaAll,
} from "../../Redux/Actions";


const MieszkaniecDaneGrupy = props => {

    const { mieszkaniec, activeGroup, setActiveGroup, ulice, lokale, udzialyKontaAktU, grupyU, setUdzialyKonta_a } = props;
    
    const dispatch = useDispatch();


    useEffect( ()=> {
        dispatch(fetchUdzialyKontaAll());
    },[dispatch]);

    useEffect( () => {
        dispatch(fetchWersje(grupyU));
    },[dispatch, grupyU]);

    const lokaleArr = grupyU?.map( g => 
        {
            if (g.data_od !== null) 
            return ({
                czy_mieszkanie_w_grupie: udzialyKontaAktU?.filter( udzial => udzial.grupa_numer === g.grupa_numer)[0]?.czy_mieszkanie_w_grupie,
                grupa_numer: g.grupa_numer,
                id: g.id,
                id_lokal: g.id_lokal,
                rodzaj: lokale?.filter( lokal => lokal.id == g.id_lokal)[0]?.rodzaj,
                id_user: g.id_user,
                imie_nazwisko: mieszkaniec?.imie_nazwisko,
                id_wspolnoty: g.id_wspolnoty,
                data_od: g.data_od,
                data_do: g.data_do,
                wersja: g.wersja
            })
    })

    const lokalDane = id => lokale?.filter( lokal => lokal.id == id)[0];

    const ulicaDane = id => ulice?.filter( ulica => ulica.id == id)[0];

    const listaGrup = udzialyKontaAktU?.map( (udzialGrupa, i) => {
       
        const czyMieszkanie = udzialGrupa?.czy_mieszkanie_w_grupie;
        const mieszkanie = mieszkanie_wersja_max(lokaleArr?.filter( lokal => lokal?.rodzaj === "mieszkanie" && lokal.grupa_numer === udzialGrupa.grupa_numer));
        const mieszkanieLista = [];

        if (czyMieszkanie) {
            const lokal = lokalDane(mieszkanie?.id_lokal);
            const ulica = ulicaDane(lokal?.ulica);
            const klatka = lokal?.klatka !== null && lokal?.klatka !== undefined && lokal?.klatka?.length > 0 ?  ` (klatka ${lokal?.klatka})` : "";
            const metraz = lokalDane(mieszkanie?.id_lokal)?.metraz;
            const udzial = lokalDane(mieszkanie?.id_lokal)?.udzial;
            
            mieszkanieLista.push({
                ulica,
                lokal: lokal?.numer,
                klatka,
                metraz,
                udzial,
                grupa_numer: mieszkanie?.grupa_numer,
                grupa_id: mieszkanie?.id,
                liczba_domownikow: udzialGrupa?.liczba_domownikow,
                wersja: mieszkanie?.wersja
            });
        }

        const garaze =  lokale_wersja_max(lokaleArr?.filter( lokal => lokal?.rodzaj === "garaż"   && lokal.grupa_numer === udzialGrupa.grupa_numer));
        const komorki = lokale_wersja_max(lokaleArr?.filter( lokal => lokal?.rodzaj === "komórka" && lokal.grupa_numer === udzialGrupa.grupa_numer));
        const strychy = lokale_wersja_max(lokaleArr?.filter( lokal => lokal?.rodzaj === "strych"  && lokal.grupa_numer === udzialGrupa.grupa_numer));

        const listaTemp = obj => {
            return obj?.map( g => {
                const lokal = lokalDane(g.id_lokal);
                const ulica = ulicaDane(lokal.ulica);
                const klatka = lokal.klatka !== null ?  ` (klatka ${lokal.klatka})` : "";
                if (g.data_do === null) {
                    return (    
                        {
                            ulica,
                            lokal: lokal.numer,
                            udzial: lokal.udzial,
                            klatka,
                            metraz: lokalDane(g.id_lokal).metraz,
                            grupa_numer: g.grupa_numer,
                            grupa_id: g.id,
                            wersja: g.wersja,
                            data_do: g.data_do
                        }
                    )
                }
            })
        }

        const garazeLista = listaTemp(garaze)?.filter( element => element !== undefined); 
        const komorkiLista = listaTemp(komorki)?.filter( element => element !== undefined); 
        const strychyLista = listaTemp(strychy)?.filter( element => element !== undefined);
     
        const handleRozliczenia = (idw, grupa_numer) => {
            setActiveGroup({ idw: idw, grupa_numer: grupa_numer});
            setUdzialyKonta_a(udzialyKontaAktU?.find( u=> u?.grupa_numer == grupa_numer));
        }

        const handleCopyClick = (e) => {
            e.stopPropagation();
            const text = e.currentTarget.previousSibling.innerText;
            copyToClipboardText(text);
        };
        return (
            <motion.div 
                key={i} 
                className={ udzialGrupa.id == activeGroup?.idw ? "grupa ramka-br2 ramka-border-shadow" : "grupa ramka-br ramka-border-shadow" }
                initial={{x:100, opacity: 0}}
                animate={{x:0, opacity: 1, transition: {delay: i * 0.2}}}
                onClick={()=>handleRozliczenia(udzialGrupa.id, udzialGrupa.grupa_numer)}
            >
                <ul className="lista udzialKonto">
                    {/* <li>
                        <div>
                            Data utworzenia:
                        </div>
                        <div>
                            {`${dataFormat(udzialGrupa.czynsz_od)}`}
                        </div>
                    </li> */}
                    <li>
                        <div>
                            Numer konta bankowego:
                        </div>
                        <div className="numer">
                            <span onClick={copyToClipboard} 
                                className="pointer"
                            >
                                {`${formatKonta(udzialGrupa.konto_bankowe)}   `}    
                            </span>
                            <FontAwesomeIcon 
                                icon={faCopy} 
                                className="pointer"
                                onClick={handleCopyClick}
                            />
                        </div>
                    </li>
                </ul>                

                { czyMieszkanie && (
                    <MieszkancyDaneGrupyLokale tytul="Mieszkanie" obj={mieszkanieLista} />
                ) }
                { garazeLista.length > 0 ? (
                    <MieszkancyDaneGrupyLokale tytul="Garaże" obj={garazeLista}/>
                ) : ""}
                { komorkiLista.length > 0 ? (
                    <MieszkancyDaneGrupyLokale tytul="Komórki" obj={komorkiLista}/>
                ) : ""}
                { strychyLista.length > 0 ? (
                    <MieszkancyDaneGrupyLokale tytul="Strychy" obj={strychyLista}/>
                ) : ""}
            </motion.div>   
        )
    })

    return (
        <div className="grupyLista">
            {
                listaGrup?.length === 0 ? (
                    <motion.div 
                        className="grupa ramka-border-shadow"
                        variants={wstepy}
                        initial="initial"
                        animate="animate"
                    >
                        <div className="naglowek">
                            <h4>
                                {`Brak przypisanych nieruchomości`}
                            </h4>
                        </div>
                    </motion.div>   
                ) : listaGrup
            }
        </div>
    )
}

export default MieszkaniecDaneGrupy;