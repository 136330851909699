import React, { useState, useEffect } from "react";



const WspolnotaRozliczenieRozliczeniaMieszkancow = (props) => {

  const { stawki, miesiac } = props;

  const [stawkiOst, setStawkiOst] = useState([]);

  

  return (
    <div 

    >
      
    </div>
  )
}

export default WspolnotaRozliczenieRozliczeniaMieszkancow;