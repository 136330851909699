import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import WspolnotyUliceLista from "./WspolnotyUliceLista.js";
import WspolnotyUliceDodaj from "./WspolnotyUliceDodaj.js";


const WspolnotyUlice = props => {

    const { wspolnota, wybranaUlica, setWybranaUlica, ulice } = props;

    const [activeWindow, setActiveWindow] = useState("lista");

    const page = () => {
        switch(activeWindow) {
            case "lista":
                return (   
                    <WspolnotyUliceLista 
                        ulice={ulice}
                        wybranaUlica={wybranaUlica}
                        setWybranaUlica={setWybranaUlica}
                        setActiveWindow={setActiveWindow}
                        idw={wspolnota?.id}
                    /> );
            case "dodaj":
                return (
                    <WspolnotyUliceDodaj
                        idw={wspolnota?.id}
                        setActiveWindow={setActiveWindow}
                    />
                );
            default: return (<></>);
        }
    }

    return (
        <div className="wspolnotyUlice ramka">
            {page()}
        </div>
    )
}

export default WspolnotyUlice;