import React from "react";
import GoBackBtn from "../../GoBackBtn.js";

// import WspolnotaRozliczenieSMS from "./WspolnotaRozliczenieSMS.js";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPrint, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'

const WspolnotaRozliczenieButtons = props => {

  const { czyRozliczenie, setCzyRozliczenie, miesiac, setMiesiac, listaMsc, setShowPrint, rozliczeniaMieszkancow, setRozliczeniaMieszkancow } = props;

  const select = listaMsc?.map( (e,i) => (
    <option key={i} value={e}>
      {e} 
    </option>
  ));

  const onChangeHandle = (e) => setMiesiac(e.target.value);
    
  const handleShowPrint = () => setShowPrint( prevState => !prevState);

  const handleRozliczeniaMieszkancow = () => setRozliczeniaMieszkancow(prevState => !prevState);

  const eye = rozliczeniaMieszkancow ? (
    <FontAwesomeIcon 
      icon={faEyeSlash} 
    />
  ) : (
    <FontAwesomeIcon 
      icon={faEye} 
    />
  );

  return (
    <div className="buttons"> 
      <GoBackBtn />
        {
          czyRozliczenie ? (
            <>
              <button onClick={()=>{setCzyRozliczenie(false)}}>
                Zaległości
              </button>
              <select onChange={onChangeHandle} value={miesiac}>
                <option value="0">
                  Wybierz okres:
                </option>
                {select}
              </select>
              <FontAwesomeIcon 
                icon={faPrint} 
                className='pointer'
                onClick={handleShowPrint}
                style={{margin: "0 50px"}}
              />
              <button
                onClick={handleRozliczeniaMieszkancow}
                style={{display: "none"}}
              >
                {`Rozliczenia mieszkańców `}
                {eye}
              </button>
            </>
        ) : (
          <>
            <button
              onClick={()=>{setCzyRozliczenie(true)}}
            >
              Rozliczenie
            </button>
          </>
        )
      }
    </div>
  )
}

export default WspolnotaRozliczenieButtons;