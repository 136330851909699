import React from "react";
import { zl, zaokr } from "../../../functions/utilities.js";

const MieszkaniecDaneWodaRozliczenieKoszt = props => {
    
    const {  zuzycie, stawkaRozliczenie } = props;

    return (
        <div className="koszt">
            <div className="naglowek">Koszt pobranej wody</div>
            
            <div className="kalkulacje">
                <p>
                    {`Zimna woda: (${zaokr(zuzycie.zimna,4)} m³ + ${zaokr(zuzycie.ciepla,4)} m³) x ${zl(stawkaRozliczenie?.zimna_woda)} = ${zl(zuzycie.zimnaKoszt)} `}
                </p>
                <p>
                    {`Podgrzanie wody: (${zaokr(zuzycie.ciepla,2)} m³) x ${zl(stawkaRozliczenie?.ciepla_woda)} = ${zl(zuzycie.cieplaKoszt)} `}
                </p>
                <p>
                    {`Razem koszt zużycia wody: ${ zl( zuzycie.zimnaKoszt + zuzycie.cieplaKoszt ) }`}
                </p>
            </div>
        </div>
    )
}

export default MieszkaniecDaneWodaRozliczenieKoszt;