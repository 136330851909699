import React, {useEffect, useState } from 'react';

const MieszkancyRozliczenieListTHead = ( props ) => {

    const { grupa_numer, czynszeMieszkaniec, stawki } = props;
    
    const [czyInd, setCzyInd] = useState(false);
    console.log(stawki)
    useEffect( () => {
        if (czynszeMieszkaniec?.grupy_msc?.length > 0) {
            const grupySprawdzane = czynszeMieszkaniec?.grupy_msc?.filter( g => g?.grupa_numer == grupa_numer);
            grupySprawdzane?.forEach( grupa => {
                grupa?.temp_msc?.forEach( g1 => {
                    if (g1?.jednorazoweIndTab?.length > 0)
                        setCzyInd(true) 
                })

            })
        }
    },[czynszeMieszkaniec]);

    return (
        <thead>
            <tr>
                <th>Miesiąc</th>
                { stawki?.czy_eksploatacja == "1" ? (<th>eks.</th>) : "" }
                { stawki?.czy_garaz == "1" ? (<th>gar.</th>) : ""}
                { stawki?.czy_strych == "1" ? (<th>str.</th>) : "" }
                { stawki?.czy_komorka == "1" ? (<th>kom.</th>) : "" }
                { stawki?.czy_utrzymanie_czystosci == "1" ? (<th>utr.</th>) : "" }
                { stawki?.czy_wywoz_smieci == "1" ? (<th>wyw.</th>) : "" }
                { stawki?.czy_fundusz_remontowy == "1" ? (<th>rem.</th>) : "" }
                { stawki?.czy_konserw_domofonu == "1" ? (<th>dom.</th>) : "" }
                { stawki?.czy_konserw_windy == "1" ? (<th>win.</th>) : "" }
                { stawki?.czy_opl_za_adm == "1" ? (<th>adm.</th>) : "" }
                { stawki?.czy_abonament_cw == "1" ? (<th>abo.</th>) : "" }
                { stawki?.czy_co == "1" ? (<th>co</th>) : "" }
                { stawki?.czy_energia_el_lokalu == "1" ? (<th>ene.</th>) : "" }
                { stawki?.czy_ryczalt_gaz == "1" ? (<th>gaz</th>) : "" }
                { stawki?.czy_zagosp_terenu == "1" ? (<th>zag.</th>) : "" }
                { stawki?.czy_antena == "1" ? (<th>ant.</th>) : "" }
                { stawki?.czy_ciepla_woda == "1" ? (<th>w. ciepła</th>) : "" }
                { stawki?.czy_zimna_woda == "1" ? (<th>w. zimna</th>) : "" }
                { stawki?.czy_dod_1 == "1" ? (<th>dod. 1</th>) : "" }
                { stawki?.czy_dod_2 == "1" ? (<th>dod. 2</th>) : "" }
                { stawki?.czy_dod_3 == "1" ? (<th>dod. 3</th>) : "" } 
                { czyInd ? (
                    <th>Ind.</th>   
                ) : (
                    ""
                )}
                <th>Suma</th>
                    
                <th>Drukuj</th>
            </tr>
        </thead>
    )
}

export default MieszkancyRozliczenieListTHead;