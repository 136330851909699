import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { sortByNum } from "../../../functions/utilities.js";

import { 
  fetchWspolnoty,
  fetchMieszkancyAll_w, 
  fetchUsers,
  fetchSerwis,
  fetchLokaleAll,
  fetchUliceAll,
  fetchUdzialyKontaAllAkt,
  fetchGrupyW
} from '../../../Redux/Actions.js'; 
import { motion } from 'framer-motion';
import { wstepy } from '../../../functions/motions.js';
import { AdresPro2 } from "../../../functions/ulice.js";


const WspolnotyListaWyszukaj = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  const wspolnoty = useSelector( state  => state?.wspolnoty?.data);
  const mieszkancy = useSelector( state => state?.mieszkancyAll_w?.data);
  const users = useSelector( state => state?.users?.data);
  const lokale = useSelector( state => state?.lokaleAll?.data);
  const ulice = useSelector( state => state?.uliceAll?.data);
  const udzialyKontaAllAkt = useSelector( state => state?.udzialyKontaAllAkt?.data);
  const grupyW = useSelector( state => state?.grupyW?.data);
  
  useEffect( ()=> {
    dispatch(fetchWspolnoty()); 
    dispatch(fetchMieszkancyAll_w()); 
    dispatch(fetchUsers());
    dispatch(fetchSerwis());
    dispatch(fetchLokaleAll());
    dispatch(fetchUliceAll());
    dispatch(fetchUdzialyKontaAllAkt());
    dispatch(fetchGrupyW());
  },[dispatch]);

  const [inputValue, setInputValue] = useState("");
  const [uniqueUsers, setUniqueUsers] = useState([]);
  const [isFocus, setFocus] = useState(false);
  
  const openHint = ( (link_wspolnota, link_user, link_grupa) => {
    setFocus(false);
    navigate(`/mieszkancy?idw=${link_wspolnota}`, { state: { link_user, link_grupa } });
  });

  useEffect( () => {
    const temp = mieszkancy?.map( (m, i) => {
      
      return ({
        id_wspolnoty: m.id_wspolnoty,
        id_user: m.id_user,
        imie_nazwisko: m.imie_nazwisko
      })
    })
    
    setUniqueUsers(sortByNum(temp, "id_wspolnoty"))
  },[mieszkancy, users])

  const lista = uniqueUsers?.map((mieszkaniec, i) => {
    const input = mieszkaniec?.imie_nazwisko?.toLowerCase();
    const isMatch = input.indexOf(inputValue) > -1;
    const wspolnota = wspolnoty?.find( w => w?.id == mieszkaniec.id_wspolnoty)?.name;
    const udzialy = udzialyKontaAllAkt?.filter( u => u?.id_user == mieszkaniec?.id_user );

    let udzialyLista;
    
    if (udzialy && udzialy?.length > 0) {
      udzialyLista = udzialy?.map( (u, i) => {
        const adres = AdresPro2(u, lokale, ulice, grupyW);
        return (
          isMatch && u.id_wspolnoty != 9 && (
            <div 
              key={i} 
              className="hint" 
              onClick={()=>openHint(mieszkaniec?.id_wspolnoty, mieszkaniec?.id_user, u.grupa_numer)}
            >
              {wspolnota} <span className="imie">{mieszkaniec?.imie_nazwisko}</span>{`, ${adres} (grupa: ${u.grupa_numer})`}
            </div>
          )
        )
    })
    } else {
      udzialyLista = isMatch && mieszkaniec.id_wspolnoty != 9 && (
        <div 
          key={i} 
          className="hint" 
          onClick={()=>openHint(mieszkaniec?.id_wspolnoty, mieszkaniec?.id_user, -1)}
        >
          {wspolnota} <span className="imie">{mieszkaniec?.imie_nazwisko}</span>{` (brak grupy)`}
        </div>
      )
    }
    return udzialyLista;
  });

  return (
    <div className="wyszukaj">
      <FontAwesomeIcon 
        icon={faMagnifyingGlass} 
        onClick={()=>{setFocus(!isFocus)}}
        className="pointer"
        style={{padding: '5px 10px'}}
        type="text"
      />  
      <motion.input 
        className="searchMieszkaniec" 
        placeholder="Znajdź mieszkańca"
        onClick={()=>{setFocus(!isFocus)}}
        value={inputValue.toLowerCase()}
        onChange={(e)=>setInputValue(e.target.value.toLowerCase())}
        variants={wstepy}
        initial="initial"
        animate="animate"
      />
      {
        isFocus && (
          <motion.div 
            className="hintList"
            variants={wstepy}
            initial="initial"
            animate="animate"
          >
            { lista }
          </motion.div>
        ) 
      }
    </div>
  )
}

export default WspolnotyListaWyszukaj;

