import React from "react";
import { zl } from "../../../functions/utilities.js";

const MieszkaniecDaneWodaStanLicznikowAdd = props => {

    const { wz, setWz, wc, setWc, pzd, setPzd, stawkiAkt } = props;

    const handlePzd = e => setPzd(e.target.value);
    const handleWc = e => setWc(e.target.value);
    const handleWz = e => setWz(e.target.value);

    return (
        <div className="form ramka-inset-shadow" >    
            <div className="left"> 
                <div className="">
                    <label htmlFor="pzd">
                        Początek:
                    </label>
                    <input 
                        type="date"    
                        id="pzd"
                        value={pzd}
                        onChange={handlePzd}
                    />
                </div>
            </div>
            <div className="right">
                <div className="row">
                    <div>
                        <label htmlFor="wz">
                            Stan wody zimnej:
                        </label>
                        <input 
                            type="number" 
                            id="wz" 
                            className="w100"
                            value={wz}
                            onChange={handleWz}
                        />
                    </div>
                    <div>
                        <label htmlFor="swz">
                            Stawka wody zimnej:
                        </label>
                        <input 
                            type="text" 
                            id="swz" 
                            className="w100"
                            value={zl(stawkiAkt?.zimna_woda)}
                            disabled
                        />
                    </div>
                </div>
                <div className="row">
                    <div>
                        <label htmlFor="wc">
                            Stan wody ciepłej:
                        </label>
                        <input 
                            type="number" 
                            id="wc" 
                            className="w100"
                            value={wc}
                            onChange={handleWc}
                        />
                    </div>
                    <div>
                        <label htmlFor="swc">
                            Stawka wody ciepłej:
                        </label>
                        <input 
                            type="text" 
                            id="swc" 
                            className="w50"
                            value={zl(stawkiAkt?.ciepla_woda)}
                            disabled
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MieszkaniecDaneWodaStanLicznikowAdd;