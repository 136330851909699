import React, { useState } from "react";
import axios from "axios";
import { useDispatch } from 'react-redux';
import { fetchStawkiJednorazowe } from "../../../Redux/Actions";
import { dataFormat2, dataFormatMsc } from "../../../functions/dataFormat";
import DialogOk from "../../DialogOk";
import { dropdownOptionsJednorazowe } from "./WspolnotyStawkiAddFormListy";

const WspolnotyStawkiJednorazoweAddForm = props => {

    const { wspolnota } = props;

    const dispatch = useDispatch();

    const [msc, setMsc] = useState(dataFormat2(new Date(new Date().setDate(1))));
    const [koszt1, setKoszt1] = useState({ val: 0, name: "", rodzaj: 0, czy: false });
    const [koszt2, setKoszt2] = useState({ val: 0, name: "", rodzaj: 0, czy: false });
    const [koszt3, setKoszt3] = useState({ val: 0, name: "", rodzaj: 0, czy: false });
    const [open, setOpen] = useState(false);
    const message = `Nie wszystkie pola są uzupełnione.`;
    
    const check = (state, set, opis) => {
        const handle = () => { 
            if (!state.czy == false) {
                set((prevState) => ({ ...prevState, val: 0})); 
                set((prevState) => ({ ...prevState, rodzaj:  ""})); 
                set((prevState) => ({ ...prevState, name:  ""})); 
            }
            set((prevState) => ({ ...prevState, czy: !state.czy})); 
        }

        return (
            <div className="check">
                <input type='checkbox' checked={state.czy} onChange={handle} />
                <span onClick={handle} className="pointer">{opis}</span>
            </div>    
        )
    }
    const lista = (lista, state, set) => {
        const opcje = lista?.map( (r, i) => {
            return (
                <option key={i} value={r.value} >
                    {r.key}
                </option>
            )
        })
        const handle = e => {set((prevState) => ({...prevState, rodzaj: e.target.value}))}
        return (
            <div className="lista">
                <select value={state.rodzaj} onChange={handle} disabled={!state.czy}>
                    {opcje}
                </select>
            </div>
        )
    }
    const inputName = (state, set) => {
        const handle = e => set((prevState) => ({...prevState, name: e.target.value}) );
        return (
            <div className="input">
                <input 
                    type="text"
                    value={state.name}
                    onChange={handle}
                    disabled={!state.czy}
                />
            </div>
        )
    }
    const inputVal = (state, set) => {
        const handle = e => set((prevState) => ({...prevState, val: e.target.value}) );
        return (
            <div className="input">
                <input 
                    type="number"
                    value={state.val}
                    onChange={handle}
                    disabled={!state.czy}
                />
            </div>
        )
    }

    const handleMsc = e => setMsc(e.target.value);

    const onSubmit = () => {
        const obj = {
            id_wspolnoty: wspolnota?.id,
            miesiac: msc,
            msc: dataFormatMsc(msc),
            dod_1_opis: koszt1.name,
            dod_1_kwota: koszt1.val,
            dod_1_czy: koszt1.czy,
            dod_1_rodzaj: koszt1.rodzaj,
            dod_2_opis: koszt2.name,
            dod_2_kwota: koszt2.val,
            dod_2_czy: koszt2.czy,
            dod_2_rodzaj: koszt2.rodzaj,
            dod_3_opis: koszt3.name,
            dod_3_kwota: koszt3.val,
            dod_3_czy: koszt3.czy,
            dod_3_rodzaj: koszt3.rodzaj,
        }
        valid(obj) ? send(obj) : setOpen(true);
        setKoszt1({ val: 0, name: "", rodzaj: 0, czy: false });
        setKoszt2({ val: 0, name: "", rodzaj: 0, czy: false });
        setKoszt3({ val: 0, name: "", rodzaj: 0, czy: false });
    }

    const valid = (obj) => {
        return true;
    }

    const send = async (obj) => {
        try {
          await axios.post('/api/wspolnoty_stawki_jednorazowe/dodaj', {
            obj
          }).then( response => {
            dispatch(fetchStawkiJednorazowe(wspolnota?.id));
            setMsc(dataFormat2(new Date(new Date().setDate(1))));

          });
        } catch (error) {
          console.error('Błąd podczas dodawania danych:', error);
        }
    };

    return (
        <div className="stawkiContainer">
            <DialogOk
                open={open}
                setOpen={setOpen}
                message={message}
            />
            <h3>Nowe stawki jednorazowe</h3>
            <div className="formularz">
                <div className="wiersz">
                    { check(koszt1, setKoszt1, "Koszt 1:") }
                    { inputName(koszt1, setKoszt1) }
                    { inputVal(koszt1, setKoszt1) }
                    { lista(dropdownOptionsJednorazowe, koszt1, setKoszt1) }
                </div>
                <div className="wiersz">
                    { check(koszt2, setKoszt2, "Koszt 2:") }
                    { inputName(koszt2, setKoszt2) }
                    { inputVal(koszt2, setKoszt2) }
                    { lista(dropdownOptionsJednorazowe, koszt2, setKoszt2) }
                </div>
                <div className="wiersz">
                    { check(koszt3, setKoszt3, "Koszt 3:") }
                    { inputName(koszt3, setKoszt3) }
                    { inputVal(koszt3, setKoszt3) }
                    { lista(dropdownOptionsJednorazowe, koszt3, setKoszt3) }
                </div>
                <div className="wiersz">
                    <input
                        type="date"
                        value={msc}
                        onChange={handleMsc}
                    />
                </div>
                <div className="button">
                    <button onClick={onSubmit}>Zapisz</button>
                </div>
            </div>

        </div>
    )
}

export default WspolnotyStawkiJednorazoweAddForm;