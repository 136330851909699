import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHouseLock, faL, faUserLock } from '@fortawesome/free-solid-svg-icons'
import { adresyPro } from "../../functions/ulice.js";
import { copyToClipboard } from '../../functions/utilities.js';

const MieszkancyListaLista = props => {

    const { mieszkancy, setMieszkaniec, setEditDataOd, setEditDataDo, udzialyKontaAkt, 
        wybraneGrupyUdzial, ulice, lokale, grupy, grupaUdzial, setGrupaUdzial, setUser, ulicaId, bezdomni, mieszkaniec, setAddGrupe,
        rodzajLokalu, setWybranyOdczyt, users_w, state } = props;

    const [showPwd, setShowPwd] = useState(false);
    const handlePwd = () => setShowPwd( prevState => !prevState);

    const [adresy, setAdresy] = useState([]); 
    const [lista, setLista] = useState([]);

    useEffect( () => {
        setAdresy(adresyPro(wybraneGrupyUdzial, lokale, ulice, grupy, udzialyKontaAkt, rodzajLokalu))
    },[wybraneGrupyUdzial, lokale, ulice, grupy, udzialyKontaAkt, rodzajLokalu]);
    
    useEffect( () => {
        const temp = adresy?.map( (a, i) => {
            const m = mieszkancy?.find( m => m?.id_user == a?.id_user);
            const user = users_w?.find( u => u?.id == a?.id_user);
            const ikona = (
                <FontAwesomeIcon 
                    icon={faHouseLock} 
                    className="pointer"
                    style={{"margin": "0 10px 0 0"}}
                />
            )
            return (
                <li 
                    key={i} 
                    className={ m?.id_user ==  grupaUdzial?.id_user && a?.grupa_numer == grupaUdzial?.grupa_numer ? "pointer active" : "pointer"  } 
                    onClick={()=>handleClick(a?.udzialGrupa, m, user)} 
                >
                    {
                         !showPwd ? (
                            <>
                                <div>
                                    { a?.czyZamkniety ? ikona : "" }
                                    {`${a.adres}, ${m?.imie_nazwisko} (${m?.id_user}) `}
                                </div>
                                {
                                    m?.czy_nota == ""
                                }
                            </>

                        ) : (
                            <>
                                <div>
                                    { a?.czyZamkniety ? ikona : "" }
                                    { `${a.adres}, ${m?.imie_nazwisko} (${m?.id_user})`}
                                </div>
                                <div
                                    style={
                                        {"textAlign": "right", "maxWidth": "70px"}
                                    }
                                >
                                    <span 
                                        onClick={copyToClipboard}
                                    >
                                        {user?.user}
                                    </span>
                                </div>
                                <div
                                    style={
                                        {"textAlign": "right", "maxWidth": "70px"}
                                    }
                                >
                                    <span 
                                        onClick={copyToClipboard}
                                    >
                                        {user?.password}
                                    </span>
                                </div>
                            </>
                        )
                    }
                </li>
            )
        });
        setLista( temp );
    },[adresy,mieszkancy, users_w, grupaUdzial]);

    const listaBezdomnych = bezdomni?.map( (b, i) => {
        const user = users_w?.find( u => u?.id == b.id_user);
        return (
            <li 
                key={i} 
                className={ mieszkaniec?.id_user == b?.id_user ? "active pointer" : "pointer" }
                onClick={()=>handleClick([], b, user)}    
            >
                {
                    !showPwd ? (
                        <>
                            <div>
                                {`${b?.imie_nazwisko} (${b?.id_user}) `}
                            </div>
                            {
                                b?.czy_nota == ""
                            }
                        </>

                    ) : (
                        <>
                            <div>
                                {`${b?.imie_nazwisko} (${b?.id_user})`}
                            </div>
                            <div
                                style={
                                    {"textAlign": "right", "maxWidth": "70px"}
                                }
                            >
                                <span 
                                    onClick={copyToClipboard}
                                >
                                    {user?.user}
                                </span>
                            </div>
                            <div
                                style={
                                    {"textAlign": "right", "maxWidth": "70px"}
                                }
                            >
                                <span 
                                    onClick={copyToClipboard}
                                >
                                    {user?.password}
                                </span>
                            </div>
                        </>
                    )
                }
            </li>
        )
    })

    const handleClick = (u, m, usr) => {
        setEditDataOd({id: 0, show: false, dataOd: ""})
        setEditDataDo({id: 0, show: false, dataOd: ""})
        setGrupaUdzial(u)
        setMieszkaniec(m)
        setUser(usr);
        setAddGrupe(false);
        setWybranyOdczyt(undefined);
        if (state) {
            state.link_user = 0;
        }
    }

    return (
        <div className="ramka" style={{backgroundColor: "var(--wnetrzeRamki)"}}>
            <ul className="lista" tabIndex={0} >
                <li>
                    <div>
                        <span>Imię i nazwisko </span>
                        <FontAwesomeIcon 
                            icon={faUserLock} 
                            className="pointer"
                            style={{"margin": "0 0 0 30px"}}
                            onClick={handlePwd}
                        />
                    </div>
                </li>
                { ulicaId === -1 ? ( 
                    listaBezdomnych
                 ) : (
                    lista
                 )
                }
            </ul>

        </div>

    )
}

export default MieszkancyListaLista;