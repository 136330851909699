import React, { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import ZbiorowyWydrukA4 from './ZbiorowyWydrukA4';
import ZbiorowyWydrukButton from './ZbiorowyWydrukButton';

const ZbiorowyWydrukModal = props => {

    const { setShowPrint, listaMsc, wspolnota, idw, visible, czynszeWspolnota, przelewyWspolnota } = props;

    const componentRef = useRef();
    
    const handleShowPrint = () => setShowPrint(prevState => !prevState);

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: 'Visitor Pass',
        onAfterPrint: () => console.log('Printed PDF successfully!'),
    });

    return (
        <div 
            className='rozliczenieWydruk'
            onClick={handleShowPrint}
        >
            <div className='modal'>
                <div>
                    <button
                        onClick={handlePrint}
                    >
                        Drukuj
                    </button>
                </div>
                <ZbiorowyWydrukA4
                    componentRef={componentRef} 
                    wspolnota={wspolnota}
                    listaMsc={listaMsc}
                    idw={idw}
                    visible={visible}
                    czynszeWspolnota={czynszeWspolnota}
                    przelewyWspolnota={przelewyWspolnota}
                />
            </div>
        </div>
    )
}

export default ZbiorowyWydrukModal;