import React, { useEffect, useState } from "react";
import WynikiGlosowaniaA4THead from "./WynikiGlosowaniaA4THead";
import WynikiGlosowaniaA4TBody from "./WynikiGlosowaniaA4TBody";
import WynikiGlosowaniaA4Podsumowanie from "./WynikiGlosowaniaA4Podsumowanie";
 
const WynikiGlosowaniaA4 = props => {

    const { componentRef, tablicaMieszkancyUdzialy, activeCard, glosowaniePytania, glosowanieOdpowiedzi, setPytanieWydruk, pytanieWydruk, wspolnota, tablica } = props;

    const [pytanie, setPytanie] = useState(null);
    const [wspolnotaUlica, setWspolnotaUlica] = useState(""); 

    useState( () => setPytanie( glosowaniePytania?.find( g => g?.id == pytanieWydruk) ), [glosowaniePytania, pytanieWydruk]);

    useState( () => {
        setWspolnotaUlica(wspolnota?.nr_lokalu !== undefined && wspolnota?.nr_lokalu !== null ? wspolnota?.ulica + " " + wspolnota?.nr_domu + "/" + wspolnota?.nr_lokalu : wspolnota?.ulica + " " + wspolnota?.nr_domu);
    },[wspolnota]);

    return (
        <div    
            style={{
                width: '21cm',
                height: '29.7cm',
                backgroundColor: 'white',
                // boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
                padding: '15mm 15mm',
                boxSizing: 'border-box',
                maxWidth: '100%',
                maxHeight: '100%',
                fontFamily: "Roboto, sans-serif", 
                marginBottom: "10px",
                fontSize: "13px"
            }} 
            ref={componentRef ? componentRef : null}
        >
            <section
                style={{
                    display: "flex",
                    justifyContent: "space-between"
                }}
            > 
                <div>
                    <span style={{display: "block"}}>{wspolnota?.name}</span>
                    <span style={{display: "block"}}>{wspolnotaUlica}</span>
                    <span style={{display: "block"}}>{wspolnota?.kod_pocztowy} {wspolnota?.miejscowosc}</span>
                </div>
                <div>
                    {`${wspolnota?.miejscowosc}, `}
                    <input 
                        style={{borderLeft: "none", borderTop: "none", borderRight: "none", borderBottom: "1px solid", width: "150px", fontSize: "13px"}}
                    />
                </div>
            </section>
            <section style={{marginTop: "30px"}}>
                <p style={{textAlign: "center", fontSize: "15px"}}>
                    {activeCard?.numer}
                </p>
            </section>
            <section style={{margin: "30px 0"}}>
                <p >
                    {`Pytanie: ${pytanie?.tresc}`}
                </p>
            </section>
            <WynikiGlosowaniaA4Podsumowanie
                pytanie={pytanie} 
                glosowanieOdpowiedzi={glosowanieOdpowiedzi}
                tablicaMieszkancyUdzialy={tablicaMieszkancyUdzialy}
            />
            <section
                style={{display: "flex", justifyContent: "center"}}
            >
                <table
                    style={{
                        fontSize: "11px",
                        width: "95%"
                    }}
                >
                    <WynikiGlosowaniaA4THead />
                    <WynikiGlosowaniaA4TBody
                        tablicaMieszkancyUdzialy={tablicaMieszkancyUdzialy}
                        glosowanieOdpowiedzi={glosowanieOdpowiedzi}
                        tablica={tablica}
                        pytanie={pytanie}
                    />
                </table>
            </section>


        </div>
    )
}

export default WynikiGlosowaniaA4;