import React, { useState, useEffect } from 'react';
import { zl, podstawa } from '../../../functions/utilities';
import { translate, rodzaj_rozliczenia } from '../../../functions/dictionary';

const MieszkaniecStawkiStawki = props => {

    const { stawkiOst, tablicaCzynsze } = props;

    const [tablicaCzynszeAkt, setTablicaCzynszeAkt] = useState([]);

    useEffect( () => {
        let len = tablicaCzynsze?.temp_msc?.length;
        if (len && len > 1)
            setTablicaCzynszeAkt( tablicaCzynsze?.temp_msc[len - 2] );
        else if (len && len === 1)
        setTablicaCzynszeAkt( tablicaCzynsze?.temp_msc[0] );
    },[tablicaCzynsze])

    const generateListItems = (stawki) => {
        const elements = [];
        if (stawki) {
            const hasOwn = Object.prototype.hasOwnProperty;
            for (const key in stawki) {
                if (hasOwn.call(stawki, key) && !key.startsWith('czy_') && !key.endsWith('_rodzaj')) {
                    const rodzajKey = `${key}_rodzaj`;
                    const czyKey = `czy_${key}`;
                    const cena = stawki[key];
                    const rodzaj = stawki[rodzajKey];
                    const czyUwzględniany = stawki[czyKey] && !isNaN(tablicaCzynszeAkt[key]);
                    const czyStala = rodzaj_rozliczenia(rodzaj, key) === "opłata stała" ? "1" : podstawa(tablicaCzynszeAkt, key)

                    if (czyUwzględniany && cena > 0 && tablicaCzynszeAkt[key] > 0) {
                        elements.push(
                            <li key={key}>
                                <div>
                                    {translate(key)}
                                </div>
                                <div>
                                    {rodzaj_rozliczenia(rodzaj, key)}
                                </div>
                                <div>
                                    { isNaN(czyStala) ? 0 : czyStala}
                                </div>
                                <div>
                                    { isNaN(cena) ? 0 : zl(cena)}
                                </div>
                                <div>
                                    {zl(tablicaCzynszeAkt[key])}
                                </div>
                            </li>
                        );
                    }
                }
            }
            return elements;
        } else return ""
    };

    
    return (
        <>
            {generateListItems(stawkiOst)}
        </>
    )

}

export default MieszkaniecStawkiStawki;