import React, { useEffect, useState } from "react";

import { zl } from "../../functions/utilities";
import { translate } from "../../functions/dictionary";

const MieszkaniecDaneNaleznosci = props => {

    const { tablicaCzynsze_g, grupaUdzial } = props;

    const [tablicaMsc, setTablicaMsc] = useState({});
    const [msc, setMsc] = useState("");

    useEffect( () => {
        if (tablicaCzynsze_g?.length === 1) {
            setMsc(tablicaCzynsze_g[0].msc)
        }
        else if (tablicaCzynsze_g?.length > 1)
            setMsc(tablicaCzynsze_g[ tablicaCzynsze_g?.length - 1 ].msc)
    },[tablicaCzynsze_g, grupaUdzial])

    useEffect( () => setTablicaMsc( tablicaCzynsze_g?.find( t => t?.msc === msc) ),[msc, tablicaCzynsze_g, grupaUdzial])

    const handleMsc = e => setMsc(e.target.value)

    const miesiace = tablicaCzynsze_g?.map( (t, i) => (
        <option
            key={i}
            value={t.msc}
        >
            {t.msc}
        </option>
    ))

    const RentTable = () => {
        if (!tablicaMsc || typeof tablicaMsc !== "object") {
          return <p>Brak danych</p>;
        }
      
        const filteredEntries = Object.entries(tablicaMsc).filter(
          ([key, value]) => typeof value === "number" && !isNaN(value) && !key.includes("_podstawa") && value !== 0 && key !== "czynsz_na_msc"
        );
      
        return (
              filteredEntries.map(([key, value]) => (
                <tr key={key} >
                    <td>{translate(key)}</td>
                    <td>{zl(value)}</td>
                </tr>
              )
            ))
        
      };

    return (
        <div className="naleznosci ramka">
            <div className="naglowek">
                <h2>
                    Należności 
                </h2>
                <div>
                    <select 
                        value={msc}
                        onChange={handleMsc}
                    >
                        {miesiace}
                    </select>
                </div>
            </div>
            <div className="naleznosci ramka-inset-shadow">
                <table>
                    <thead>
                        <tr>
                            <th>
                                Opis
                            </th>
                            <th>
                                Kwota
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {RentTable(tablicaMsc)}
                    </tbody>
                    <tfoot>
                        <tr>
                            <td>
                                Razem:
                            </td>
                            <td>
                                {zl(tablicaMsc?.czynsz_na_msc)}
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </div>
            
        </div>
    )
}

export default MieszkaniecDaneNaleznosci;