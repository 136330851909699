import React, { useState, useEffect } from "react";
import { adresPro, findMieszkanieId } from '../../../functions/ulice';
import { useSelector } from 'react-redux';
import { dataFormat2 } from "../../../functions/dataFormat";
import WspolnotyStawkiJednorazoweGrupyForm from "./WspolnotyStawkiJednorazoweGrupyForm";
import WspolnotyStawkiJednorazoweGrupyLista from "./WspolnotyStawkiJednorazoweGrupyLista";
import WspolnotyStawkiJednorazoweGrupyPodsumowanie from "./WspolnotyStawkiJednorazoweGrupyPodsumowanie";

const WspolnotyStawkiJednorazoweGrupy = props => {

    const { wspolnota, mieszkancy, udzialyKontaAkt, stawkiJednorazoweInd, grupy } = props;

    const lokale = useSelector( state => state?.lokale?.data);
    const ulice = useSelector( state => state?.ulice?.data);
    
    const [wybranyMieszkaniec, setWybranyMieszkaniec] = useState("Wybierz mieszkańca:");
    const [wybranaGrupa, setWybranaGrupa] = useState([]);
    const [udzialyKontaMieszkaniec, setUdzialyKontaMieszkaniec] = useState([]);
    const [grupy_m, setGrupy_m] = useState([]);
    const [stawka, setStawka] = useState({msc: dataFormat2(new Date(new Date().setDate(1))), opis: "", kwota: 0}); 
    const [podsumowanie, setPodsumowanie] = useState(undefined);
    

    const __WYBIERZ_MIESZKANCA__ = "Wybierz mieszkańca:";
    const __WYBIERZ_GRUPE__ = "Wybierz grupę:";

    const mieszkancyLista = mieszkancy?.filter(m => m?.id_wspolnoty == wspolnota?.id)?.map( (mieszkaniec, i) => {
        return (
            <option 
                key={i} 
                value={mieszkaniec?.id_user}
            >
                {mieszkaniec?.imie_nazwisko}
            </option>
        )
    })

    useEffect( () => {
        const sumaStawek = Object.values(
            stawkiJednorazoweInd.reduce((acc, { msc, opis, kwota }) => {
              acc[msc] = acc[msc] || { msc, opis, kwota: 0 };
              acc[msc].kwota += kwota;
              return acc;
            }, {})
          );
          setPodsumowanie(sumaStawek)
    },[stawkiJednorazoweInd]);
        
    useEffect( () => setGrupy_m( grupy?.filter( g => g?.id_user == wybranyMieszkaniec )), [wybranyMieszkaniec, grupy] );
    useEffect( () => setUdzialyKontaMieszkaniec( udzialyKontaAkt?.filter( m => m?.id_user == wybranyMieszkaniec )), [udzialyKontaAkt, wybranyMieszkaniec]);
    useEffect( () => setStawka( {msc: dataFormat2(new Date(new Date().setDate(1))), opis: "", kwota: 0} ), [wybranaGrupa, wybranyMieszkaniec]);
    

    const grupyLista = udzialyKontaMieszkaniec && udzialyKontaMieszkaniec?.map( (grupa, i) => {

        const mieszkanieId = findMieszkanieId(grupa, grupy_m, lokale );
        const mieszkanie = adresPro(mieszkanieId, lokale, ulice)

        return (
            <option
                key={i}
                value={grupa?.grupa_numer}
            >
                {`Grupa numer ${grupa?.grupa_numer}, ${mieszkanie}`}
            </option>
        )
    })

    const handleMieszkaniec = e => setWybranyMieszkaniec( e.target.value );   
    const handleGrupa = e => setWybranaGrupa( e.target.value );

    return (
        <div className="stawkiJednorazoweGrupy">
            <h3>
                Stawki jednorazowe indywidualne
            </h3>
            <div className="top">
                <WspolnotyStawkiJednorazoweGrupyPodsumowanie
                    podsumowanie={podsumowanie}
                />
                <div
                    className="wybor"
                >
                    <div className="mieszkancy">
                        <select
                            onChange={handleMieszkaniec}
                            value={wybranyMieszkaniec}
                        >
                            <option key={0}>
                                Wybierz mieszkańca:
                            </option>
                            {mieszkancyLista}
                        </select>
                    </div>
                    <div className="grupy">
                        <select
                            onChange={handleGrupa}
                            value={wybranaGrupa}
                        >
                            <option key={0}>
                                Wybierz grupę:
                            </option>
                            {grupyLista}
                        </select>
                    </div>
                    {wybranaGrupa !== __WYBIERZ_GRUPE__ && wybranyMieszkaniec !== __WYBIERZ_MIESZKANCA__ ? (
                        <WspolnotyStawkiJednorazoweGrupyForm 
                            stawka={stawka}
                            setStawka={setStawka}
                            wspolnota={wspolnota}
                            wybranyMieszkaniec={wybranyMieszkaniec}
                            wybranaGrupa={wybranaGrupa}
                        />
                    ) : ""}
                </div>
            </div>

            <WspolnotyStawkiJednorazoweGrupyLista
                mieszkancy={mieszkancy}
                lokale={lokale}
                ulice={ulice}
                wybranyMieszkaniec={wybranyMieszkaniec}
                stawkiJednorazoweInd={stawkiJednorazoweInd}
                __WYBIERZ_MIESZKANCA__={__WYBIERZ_MIESZKANCA__}
                udzialyKontaAkt={udzialyKontaAkt}
            />
        </div>
    )

}

export default WspolnotyStawkiJednorazoweGrupy;
