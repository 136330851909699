import React from "react";
import { zl } from "../../../functions/utilities.js";
import { zaokr } from "../../../functions/utilities.js";

const MieszkaniecDaneWodaRozliczeniePobrana = props => {
    
    const { wybranyOdczyt } = props;

    return (
        <div className="lewa">
            <div className="naglowek">Pobrana woda</div>
            <table>
                <thead>
                    <tr>
                        <th></th>
                        <th>
                            Początek
                        </th>
                        <th>
                            Koniec
                        </th>
                        <th>
                            Zużycie
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            zimna woda
                        </td>
                        <td>
                            {zaokr(wybranyOdczyt?.zimna_stan_poczatek,2)}
                        </td>                    
                        <td>
                            {zaokr(wybranyOdczyt?.zimna_stan_koniec,2)}
                        </td>
                        <td>
                            {zaokr(wybranyOdczyt?.zimna_stan_koniec - wybranyOdczyt?.zimna_stan_poczatek, 2)}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            ciepła woda
                        </td>
                        <td>
                            {zaokr(wybranyOdczyt?.ciepla_stan_poczatek,2)}
                        </td>                    
                        <td>
                            {zaokr(wybranyOdczyt?.ciepla_stan_koniec,2)}
                        </td>
                        <td>
                            {zaokr(wybranyOdczyt?.ciepla_stan_koniec - wybranyOdczyt?.ciepla_stan_poczatek, 2)}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default MieszkaniecDaneWodaRozliczeniePobrana;