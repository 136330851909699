import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { useSelector, useDispatch } from 'react-redux';
import emailjs from '@emailjs/browser';
import { mm_rrrr_to_date } from '../../../functions/dataFormat.js';
import { zl, zaokr } from "../../../functions/utilities.js";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons'
import { AdresPro2 } from "../../../functions/ulice.js";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AuthContext } from "../../../Context/AuthProvider.js";
import { fetchZaleglosciPowiadomienia } from "../../../Redux/Actions.js";
import WspolnotaRozliczenieSMS from "./WspolnotaRozliczenieSMS.js";

const WspolnotaRozliczenieZaleglosci = (props) => {

  const { czynszeMsc, idw, udzialyKontaAkt, przelewyWspolnota, czynszeWspolnota, mieszkancy, ulice, lokale, grupy } = props;

  const [przelewySuma, setPrzelewySuma] = useState(0);
  const [czynszeSuma, setCzynszeSuma] = useState(0);
  const [saldo, setSaldo] = useState("ładuje...");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const zaleglosciPowiadomienia = useSelector( state => state?.zaleglosciPowiadomienia?.data);

  const { userName } = useContext(AuthContext);
  
  useEffect( () => {
    const temp = przelewyWspolnota.map(({ id_user, nr_grupyUdzial, przelewy }) => ({
      id_user,
      grupa_numer: nr_grupyUdzial,
      suma: Math.round( przelewy.reduce((acc, { kwota }) => acc + kwota, 0) * 100 ) / 100 , // Sumowanie kwot
    }));
    setPrzelewySuma(temp);
  },[przelewyWspolnota])

  useEffect( () => {  
    const currentDate = new Date();
    const temp = czynszeWspolnota.flatMap(({ grupy_udzial_msc }) => 
      grupy_udzial_msc.map(({ grupa_numer, id_user, temp_msc }, i) => {
        const mieszkaniec = mieszkancy?.find( m => m?.id_user == id_user);
        const validTempMsc = temp_msc.filter(({ msc }) => new Date(mm_rrrr_to_date(msc)) <= currentDate);
        const suma = Math.round( validTempMsc.reduce((acc, { czynsz_na_msc }) => acc + czynsz_na_msc, 0) * 100 )/ 100;
        const ostatniCzynszowy = [...validTempMsc]
          .filter(({ czynsz_na_msc }) => czynsz_na_msc > 0) // Pomijamy wpisy z czynszem 0
          .sort((a, b) => new Date(mm_rrrr_to_date(b.msc)) - new Date(mm_rrrr_to_date(a.msc))) // Sortujemy malejąco po dacie
          .shift() || null; // Pobieramy pierwszy element lub null jeśli brak

        const dwukrotnoscOstatniegoCzynszu = ostatniCzynszowy?.czynsz_na_msc * 2;

        return { id: i, grupa_numer, id_user,suma, ostatni_czynszowy: ostatniCzynszowy, dwukrotnoscOstatniegoCzynszu, mieszkaniec  /* suma, /*, ostatniObiekt*/ };
      })
    );
    setCzynszeSuma(temp)
  },[czynszeWspolnota])

  const lista = czynszeSuma && czynszeSuma?.map( (czynsz, i) => {

    const przelew = przelewySuma?.find( p => p?.id_user == czynsz?.id_user && p?.grupa_numer == czynsz?.grupa_numer);
    const przelewySuma_g = przelew ? przelew.suma : 0;
    const saldo = przelewySuma_g - czynsz?.suma;
    const saldoPowiadomienie = saldo < 0 &&  saldo < (czynsz?.dwukrotnoscOstatniegoCzynszu * -1) ? true : false;
    const udzial = udzialyKontaAkt?.find( u => u?.id_user == czynsz?.id_user && u?.grupa_numer == czynsz?.grupa_numer)
    const adres = AdresPro2(udzial, lokale, ulice, grupy);

    const handleLink = (link_wspolnota, link_user, link_grupa) => navigate(`/mieszkancy?idw=${link_wspolnota}`, { state: { link_user, link_grupa } });
    
    const sendMail = async (id_user, grupa_numer, zaleglosc, zalegloscTxt, adres) => {
      const mieszkaniec = mieszkancy?.find( m => m?.id_user == id_user);
      const email = mieszkaniec?.email;
      const templateParams = {
        email: email,
        adres: adres,
        kwota: zalegloscTxt,
      }
      emailjs.send('service_d6ugz9r', 'template_tci0f9b', templateParams, 'Sf5FCcGAApPYZnnXi').then(
          (response) => {
              console.log('SUCCESS!', response.status, response.text);
              sendLog(id_user, grupa_numer, zaleglosc, email, userName);                
          },
          (error) => {
            console.log('FAILED...', error);
          },
      );
    }  

    const sendLog = async (id_user, grupa_numer, zaleglosc, email, userName) => {
      try {
        await axios.post('/api/zaleglosci/send-mail-log', {
          id_user, grupa_numer, zaleglosc, email, userName
        }).then(response => {
            if (response.status === 200) {
                dispatch(fetchZaleglosciPowiadomienia()); 
            }
        });
      } catch (error) {
          console.error('Błąd podczas dodawania danych:', error);
      }
    }

    const zaleglosc = zaokr(przelewySuma_g - czynsz?.suma, 2);
    const zalegloscTxT = zl( przelewySuma_g - czynsz?.suma );
    return (
      saldoPowiadomienie ? (
        <tr>
          <td>
            {`${czynsz?.mieszkaniec?.imie_nazwisko} (${czynsz?.mieszkaniec?.id_user})`}
          </td>
          <td 
            className="u pointer"
            onClick={()=>handleLink(czynsz?.mieszkaniec?.id_wspolnoty, czynsz?.mieszkaniec?.id_user, czynsz?.grupa_numer)}
          >
            {`${adres} (grupa numer: ${czynsz?.grupa_numer})`}
          </td>
          <td>
            {zl(czynsz?.suma)}
          </td>
          <td>
            {zl(przelewySuma_g)}
          </td>
          <td>
            <span style={saldo < 0 ? {color: "red"} : {}}>
              { zalegloscTxT }
            </span>
          </td>
          <td>
            { `${zl(czynsz?.ostatni_czynszowy?.czynsz_na_msc)} / ${zl(czynsz?.dwukrotnoscOstatniegoCzynszu)}`}
          </td>
          <td style={{textAlign: "center"}}>
            <FontAwesomeIcon 
              icon={faPaperPlane} 
              style={{color: "#307bfd", cursor: "not-allowed"}} 
              // onClick={()=>sendMail(czynsz?.mieszkaniec?.id_user, czynsz?.grupa_numer, zaleglosc, zalegloscTxt, adres)}
            />
          </td>
          <td style={{textAlign: "center"}}>
            <FontAwesomeIcon 
              icon={faPaperPlane} 
              style={{color: "#307bfd", cursor: "not-allowed"}} 
            />
          </td>
          <td>

          </td>
        </tr>
      ) : <></>
    )
  })

  return (
    <>
      <WspolnotaRozliczenieSMS
      
      />
      <table className="tabela" style={{width: "fit-content"}}> 
        <thead>
          <tr>
            <th>
              Mieszkaniec
            </th>
            <th>
              Adres (grupa)
            </th>
            <th>
              Czynsze - suma
            </th>
            <th>
              Wpłaty - suma
            </th>
            <th>
              Saldo
            </th>
            <th>
              Ostatni czynsz / 2x ostatniego czynszu
            </th>
            <th>
              Powiadom - e-mail
            </th>
            <th>
              Powiadom - sms
            </th>
            <th>
              Ostatnie powiadomienie: data/kwota/kanał
            </th>
          </tr>
        </thead>
        <tbody>
          {lista}
        </tbody>
      </table>
    </>

  )
}

export default WspolnotaRozliczenieZaleglosci;