import React, { useEffect, useState, useRef } from 'react';
import Papa from "papaparse";
import { getTextAfterColon } from '../../../../functions/utilities';

const ImportPKOBP_csv = (props) => {

  const { setWczytane,setPelneDane, przelewy, setCzyDubel, setWybranaWspolnota } = props;
  const fileInputRef = useRef(null);
  const arr = [];

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    Papa.parse(file, {
      complete: (result) => {
        const rawData = result.data;
        
        rawData[0][7] = "nazwa";
        rawData[0][8] = "adres_tytul";
        rawData[0][9] = "tytul";
        
        if (rawData.length === 0) return;

        // Sprawdzanie nagłówków
        let headers = rawData[0];
        headers = headers.map((header, index) => ( header));

        // Konwersja danych do obiektów
        const formattedData = rawData.slice(1).map((row) => {
          let rowData = {};
          headers.forEach((header, index) => {
            rowData[header] = row[index] || "";
          });
          return rowData;
        });
        
        

        if (formattedData?.length > 0) {
          formattedData?.forEach( (row, i) => {
            console.log(row)
            if (row["Typ transakcji"] === "Opłata") {
              const obj = {
                przelewId: "",
                dataOperacji: row["Data operacji"],
                dataWczytania: new Date(),
                rodzajOperacji: "prowizja",
                tytul: row["Opis transakcji"],
                kwota: row["Kwota"],
                nadawcaNazwa: "",
                nadawcaKonto: "",
                odbiorcaNazwa: "",
                odbiorcaKonto: "",
                plik: file.name
              }
              arr.push(obj);
            } else if (row["Typ transakcji"] === "Przelew z rachunku") {
              const obj = {
                przelewId: "",
                dataOperacji: row["Data operacji"],
                dataWczytania: new Date(),
                rodzajOperacji: "przelew wychodzący",
                tytul: getTextAfterColon(row["tytul"]),
                kwota: row["Kwota"],
                nadawcaNazwa: "Wspólnota",
                nadawcaKonto: "",
                odbiorcaNazwa: getTextAfterColon(row["nazwa"]),
                odbiorcaKonto:  getTextAfterColon(row["Opis transakcji"]),
                plik: file.name
              }
              arr.push(obj);
            } else if (row["Typ transakcji"] === "Przelew na konto") {
              const obj = {
                przelewId: "",
                dataOperacji: row["Data operacji"],
                dataWczytania: new Date(),
                rodzajOperacji: "przelew przychodzący",
                tytul: extractTextAfterCL(row["adres_tytul"]),
                kwota: row["Kwota"],
                nadawcaNazwa: getTextAfterColon(row["nazwa"]),
                nadawcaKonto: getTextAfterColon(row["Opis transakcji"]), 
                odbiorcaNazwa: row["tytul"].includes("ID=") ? "Wspólnota  (konto grupy)" : "Wspólnota (konto główne)",
                odbiorcaKonto: row["tytul"].includes("ID=") ? getIdAfterEqual(row["tytul"]) : "Konto główne wspólnoty",
                plik: file.name
              }
              arr.push(obj);
            }

            
          })

        }

        setWczytane(arr)
      },
      header: false,
      skipEmptyLines: true,
      encoding: "windows-1250", // Ustawione na podstawie wykrytego kodowania
    });
  };

  
  const getIdAfterEqual = (text) => {
    const match = text.match(/ID=(.{12})/);
    return match ? match[1] : undefined;
  };

  const extractTextAfterCL = (text) => {
    const match = text.match(/CL=(.{6})(.*)/);
    if (match)
      return match[2].trim();
    else 
      return getTextAfterColon(text);
  };

  const handleClearFile = () => {
    fileInputRef.current.value = ''; 
    setWczytane([]);
    setPelneDane([]);
    setCzyDubel(false);
    // setWybranaWspolnota(null);
  }

  return (
    <div className='reader'>
      <input 
        type="file" 
        id="file-input" 
        accept=".csv" 
        onChange={handleFileUpload} 
        ref={fileInputRef}
      />
      <button
        onClick={handleClearFile}
      >
        Wyczyść schowek
      </button>
    </div>
  );
};

export default ImportPKOBP_csv;