import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';

import GoBackBtn from "../../GoBackBtn";
import { wstepy } from '../../../functions/motions';
import { sortByNum, sortByTxt } from "../../../functions/utilities";
import { dataFormat } from "../../../functions/dataFormat";

import Loader from "../../Loader";
import GrupyInstrukcja from "./GrupyInstrukcja";
import { grupy_all_max_akt } from "../../../functions/grupy";
import { adresPro, findMieszkanieId } from "../../../functions/ulice";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { fetchUdzialyKonta, fetchGrupy, fetchUlice, fetchLokale, fetchWspolnota, fetchMieszkancy } from "../../../Redux/Actions";


const Grupy = () => {


    const queryParams = new URLSearchParams(window.location.search)
    const idw = queryParams.get("id");
    const dispatch = useDispatch();
    const [loaderOn, setLoaderOn] = useState(false);

    const udzialyKonta = useSelector( state => state?.udzialyKonta?.data);
    const grupy = useSelector( state => state?.grupy?.data);
    const ulice = useSelector( state => state?.ulice?.data);
    const lokale = useSelector( state => state?.lokale?.data);
    const wspolnotaArr = useSelector( state => state?.wspolnoty?.data);
    const mieszkancy = useSelector( state => state?.mieszkancy?.data);

    const [wspolnota, setWspolnota] = useState([]);
    const [duble, setDuble] = useState([]);
    const [showHelp, setShowHelp] = useState(false);

    useEffect( () => setWspolnota( wspolnotaArr?.find( w => w?.id == idw)), [idw, wspolnotaArr]);
    
    useEffect( ()=> {
        dispatch(fetchWspolnota(idw)); 
        dispatch(fetchMieszkancy(idw)); 
        dispatch(fetchLokale(idw)); 
        dispatch(fetchUlice(idw)); 
        dispatch(fetchGrupy(idw)); 
        dispatch(fetchUdzialyKonta(idw));
    },[dispatch]);
    
    const znajdzDuplikaty = (grupy) => {
        const map = new Map();
        const duplikaty = [];
      
        grupy?.forEach((obiekt) => {
          const klucz = `${obiekt?.id_user}-${obiekt?.grupa_numer}-${obiekt?.data_od}`;
      
          if (map.has(klucz)) {
            if (map.get(klucz) === 1) {
              // Dodaj pierwszy znaleziony duplikat
              duplikaty.push(obiekt);
            }
            // Zwiększ licznik wystąpień
            map.set(klucz, map.get(klucz) + 1);
          } else {
            // Dodaj unikalny klucz do mapy
            map.set(klucz, 1);
          }
        });
      
        // Dodaj kolejne wystąpienia do tablicy duplikatów
        grupy?.forEach((obiekt) => {
          const klucz = `${obiekt?.id_user}-${obiekt?.grupa_numer}-${obiekt?.data_od}`;
          if (map.get(klucz) > 1) {
            duplikaty.push(obiekt);
          }
        });
      
        return duplikaty;
    };

    useEffect( () =>  setDuble(znajdzDuplikaty(udzialyKonta) ), [udzialyKonta]);
    

    const lista = sortByTxt(mieszkancy,"imie_nazwisko")?.map( (mieszkaniec, i) =>{

        const udzialy = sortByNum(udzialyKonta?.filter( u => u?.id_user == mieszkaniec.id_user),"grupa_numer");

        const udzialyLista = udzialy?.map( (udzial, j) => {

            const grupy_m = grupy?.filter( g => g?.id_user == mieszkaniec?.id_user && g?.grupa_numer == udzial?.grupa_numer);

            const czyDubel = duble?.find( d => d?.id == udzial.id) ? true : false;

            const czyOstatni = j < udzialy?.length - 1 ? true : false;

            let styl = "";

            if (czyDubel && czyOstatni)
                styl = "red udzialyRow border"
            else if (czyDubel && !czyOstatni)
                styl = "red udzialyRow"
            else if (!czyDubel && czyOstatni)
                styl = "udzialyRow border"
            else 
                styl = "udzialyRow"

            const mieszkanieId = findMieszkanieId(udzial, grupy_m, lokale);
            
            const lokaleGrupy = grupy_m?.map( (grupa, i) => {

                const mieszkanieAdres = adresPro(mieszkanieId, lokale, ulice)

                return (
                    <div className="grupaRow" >
                        <div>
                            {mieszkanieAdres} 
                        </div>
                        <div>
                            {`data grupy od: ${dataFormat(grupa?.czynsz_od)}`}
                        </div>
                    </div>
                )
            })

            return (
                <div 
                    key={j}
                    className={ styl } 
                >
                    <div key={j} className="udzialRow">
                        <div>
                            {`grupa numer: ${udzial?.grupa_numer} (id: ${udzial?.id})`}
                        </div>
                        <div>
                            {`data lokalu od: ${dataFormat(udzial?.data_od)}`}
                        </div>
                        <div>
                            {`liczba mieszkańców: ${udzial?.liczba_domownikow}`}
                        </div>

                    </div>
                    <div className="grupyRow">
                        { lokaleGrupy }
                    </div>
                </div>
            )
        })


        return (
            <li 
                key={i}
            >
                <div className="mieszkaniec">
                    {`${mieszkaniec?.imie_nazwisko} (id ${mieszkaniec?.id_user})`}
                </div>
                <div className="udzialyGrupy">
                    {udzialyLista}
                </div>
            </li>
        )
    });

    const handleHelp = () => setShowHelp( prevState => !prevState);

    return (
        <div 
            className="content grupyLista"
            variants={wstepy}
            initial="initial"
            animate="animate"
        >
            <Loader stan={loaderOn} />
            <h1 className="nazwa">
                Grupy we wspólnocie
            </h1>
            <h3>
                { wspolnota?.name }
            </h3>
            <div className="buttonsBar">
                <div className="buttonsZbiorowka">
                    <GoBackBtn />
                </div>
                <div>
                    <FontAwesomeIcon 
                        icon={faCircleInfo} 
                        size="2xl"
                        className="pointer"
                        onClick={handleHelp}
                    />
                </div>
            </div>

            { showHelp && <GrupyInstrukcja /> }
            <div className="lista">
                <ul>
                    {lista}
                </ul>
            </div>
        </div>
    )
}

export default Grupy;