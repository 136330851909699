import React from 'react';


const NotyTHead = () => {
    return (
        <thead>
            <tr>
                <th>
                    Mieszkaniec
                </th>
                <th>
                    Mieszkanie
                </th>
                <th>
                    Podgląd
                </th>
                <th>
                    Status
                </th>
                <th style={{minWidth: "70px"}}> E-mail</th>
            </tr>
        </thead>
    )
}

export default NotyTHead;