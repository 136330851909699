import React, { useContext, useState, useEffect } from "react";
import { NavLink } from 'react-router-dom';
import { adres } from "../../../functions/ulice";
import { ikona } from '../../../functions/ikony';
import { usun_duplikaty } from '../../../functions/grupy';
import { zl } from "../../../functions/utilities";
import { getSaldo_g, saldoWykrzyknik } from "../../../functions/tablice";
import { AuthContext } from "../../../Context/AuthProvider";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPrint } from '@fortawesome/free-solid-svg-icons'

const WspolnotaRozliczenie = (props) => {

  const { idw, wspolnota, lokale, ulice, grupy, udzialyKontaAkt, stawki, stawkiJednorazowe, przelewyWspolnota, czynszeWspolnota, czynszeMsc, mieszkancy, ind, zestaw } = props;

  const { role } = useContext(AuthContext);

  const drukarka = (id_user, grupa_numer) => (                        
    <FontAwesomeIcon 
        icon={faPrint} 
        className='pointer'
        onClick={()=> {zestaw(id_user, grupa_numer)}}
    />
  )

  const tab = udzialyKontaAkt?.map( (udzial, i) => {

    const mieszkaniec = mieszkancy.find( mieszkaniec => mieszkaniec.id_user == udzial.id_user);
    const czynsz_temp = czynszeMsc?.find( czynsz => czynsz.id_user == udzial.id_user && czynsz.grupa_numer == udzial.grupa_numer);
    
    const indy = ind?.map( (n,i) => {
      const indPoj = czynsz_temp?.ind?.find( c => c?.opis == n)?.kwota
      return (
        <td key={i}> {indPoj === undefined ? zl(0) : zl(indPoj) } </td> 
      )
    })

    const czynsz = czynsz_temp ? czynsz_temp.czynsz : null
    const saldo = parseFloat(getSaldo_g(przelewyWspolnota, czynszeWspolnota, udzial).toFixed(2));

    if (czynsz?.length == 0)
      return {}

    const grupy_usera = grupy?.filter( g => g.id_user == mieszkaniec?.id_user && g.grupa_numer == udzial?.grupa_numer);

    const lokale_usera_temp = grupy_usera?.map( g => {
      return {
        id_lokal: g.id_lokal,
        id_user: g.id_user,
        grupa_numer: g.grupa_numer
      }
    });

    const lokale_usera = usun_duplikaty(lokale_usera_temp);
    
    const lokale_td = lokale_usera?.map( (lokal, i) => {
      return (
        <li key={i}>
          {
            role === "admin" ? (
              <NavLink 
                to={`/mieszkancy/rozliczenia?id=${lokal?.id_user}&idw=${idw}&g=${lokal?.grupa_numer}`}
                state={
                  {
                    from: {
                      czynszeMieszkaniec: czynszeWspolnota?.find( t => t.id_user == lokal.id_user),
                      przelewyMieszkaniec: przelewyWspolnota?.find( t => t.id_user == lokal?.id_user && t.nr_grupyUdzial == lokal.grupa_numer)?.przelewy,
                      wspolnota: wspolnota,
                    }
                  }
                }
              > 
                {ikona(lokale?.filter( l => l.id === lokal.id_lokal )[0]?.rodzaj)} &nbsp; {`${adres(lokale?.filter( l => l.id === lokal.id_lokal )[0]?.ulica, ulice, lokal.id_lokal, lokale) } (grupa ${lokal?.grupa_numer})`} 
              </NavLink>
            ) : (
              <>
                {ikona(lokale?.filter( l => l.id === lokal.id_lokal )[0]?.rodzaj)} &nbsp; {`${adres(lokale?.filter( l => l.id === lokal.id_lokal )[0]?.ulica, ulice, lokal.id_lokal, lokale)}`} 
              </>
            )
          }
        </li>
      )
    })

    const id = role === "admin" ? ` (${mieszkaniec?.id_user})` : ``;

    return (
        czynsz && <tr key={i}>
          <td className="left">
            {`${mieszkaniec?.imie_nazwisko}${id}`} {saldo < 0.00 ? saldoWykrzyknik() : "" } 
          </td>
          <td>
            <ul style={{"listStyle": "none"}}>
              {lokale_td}
            </ul>
          </td>
            { stawki?.czy_eksploatacja == 1 ? (<td>{zl(czynsz.eksploatacja)}</td>) : ""   }
            { stawki?.czy_garaz == 1 ? (<td>{zl(czynsz.garaz)}</td>) : ""}
            { stawki?.czy_strych == 1 ? (<td>{zl(czynsz.strych)}</td>) : "" }
            { stawki?.czy_komorka ==  1 ? (<td>{zl(czynsz.komorka)}</td>) : "" }
            { stawki?.czy_utrzymanie_czystosci == 1 ? (<td>{zl(czynsz.utrzymanie_czystosci)}</td>) : ""} 
            { stawki?.czy_wywoz_smieci == 1 ? (<td>{zl(czynsz.wywoz_smieci)}</td>) : ""} 
            { stawki?.czy_fundusz_remontowy == 1 ? (<td>{zl(czynsz.fundusz_remontowy)}</td>) : ""} 
            { stawki?.czy_konserw_domofonu == 1 ? (<td>{zl(czynsz.konserw_domofonu)}</td>) : ""} 
            { stawki?.czy_konserw_windy == 1 ? (<td>{zl(czynsz.konserw_windy)}</td>) : ""} 
            { stawki?.czy_opl_za_adm == 1 ? (<td>{zl(czynsz.opl_za_adm)}</td>) : ""} 
            { stawki?.czy_abonament_cw == 1 ? (<td>{zl(czynsz.abonament_cw)}</td>) : ""} 
            { stawki?.czy_co == 1 ? (<td>{zl(czynsz.co)}</td>) : ""} 
            { stawki?.czy_energia_el_lokalu == 1 ? (<td>{zl(czynsz.energia_el_lokal)}</td>) : ""} 
            { stawki?.czy_ryczalt_gaz == 1 ? (<td>{zl(czynsz.ryczalt_ga)}</td>) : ""} 
            { stawki?.czy_zagosp_terenu == 1 ? (<td>{zl(czynsz.zagosp_teren)}</td>) : ""} 
            { stawki?.czy_antena == 1 ? (<td>{zl(czynsz.antena)}</td>) : ""} 
            { stawki?.czy_ciepla_woda == 1 ? (<td>{zl(czynsz.ciepla_woda)}</td>) : ""} 
            { stawki?.czy_zimna_woda == 1 ? (<td>{zl(czynsz.zimna_woda)}</td>) : ""} 
            { stawkiJednorazowe?.dod_1_czy == 1 ? (<td>{zl(czynsz.dod_1)}</td>) : ""} 
            { stawkiJednorazowe?.dod_2_czy == 1 ? (<td>{zl(czynsz.dod_2)}</td>) : ""} 
            { stawkiJednorazowe?.dod_3_czy == 1 ? (<td>{zl(czynsz.dod_3)}</td>) : ""} 
            {
              <>
                {indy}
              </>
            }
          <td>{zl(czynsz.czynsz_na_msc)}</td>
          <td>{drukarka(mieszkaniec?.id_user, udzial.grupa_numer)}</td>
        </tr>
    )
  })

  return (
    <tbody>
      {tab}
    </tbody>
  )
}

export default WspolnotaRozliczenie;