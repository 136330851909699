import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { wstepy } from "../../functions/motions";
import { strefa } from "../../functions/dataFormat";
import PrzelgadLista from "./PrzegladLista";
import PrzelgadSzczegoly from "./PrzelgadSzczegoly";
import PrzegladGlosy from "./PrzegladGlosy";
import PrzegladWydrukModal from "./PrzegladWydrukModal"; 
import { useSelector } from 'react-redux';
import { adresPro, findMieszkanieId } from '../../functions/ulice';
import { sortByTxt } from "../../functions/utilities";

const Przelgad = props => {

    const { glosowanieKarty, glosowaniePytania, glosowanieOdpowiedzi, glosowanieUchwaly, wspolnota, activeCard, setActiveCard } = props;

    const [udzialyKonta_w, setUdzialyKonta_w] = useState([]);
    const [grupy_w, setGrupy_w] = useState([]);

    const [grupyKarty, setGrupyKarty] = useState([]);
    const [grupyKartyUniq, setGrupyKartyUniq] = useState([]);
    const [udzialyKarty, setUdzialyKarty] = useState([]);
    const [udzialyKartyUniq, setUdzialyKartyUniq] = useState([]);
    const [odpowiedziActiveCard, setOdpowiedziActiveCard] = useState([]);
    const [tablica, setTablica] = useState([]);
    const [tablicaMieszkancyUdzialy, setTablicaMieszkancyUdzialy] = useState([]);
    const [showPrint, setShowPrint] = useState(false);
    const [pytanieWydruk, setPytanieWydruk] = useState(0);

    const handlePrint = () => setShowPrint( prevState => !prevState);

    const mieszkancy = useSelector( state => state?.mieszkancy?.data);
    const lokale = useSelector(state => state?.lokale?.data);
    const ulice = useSelector(state => state?.ulice?.data);
    const udzialyKonta = useSelector( state => state?.udzialyKonta?.data);
    const grupy = useSelector( state => state?.grupy?.data);

    useEffect( () => setUdzialyKonta_w( udzialyKonta?.filter( u => u?.id_wspolnoty == wspolnota?.id) ), [udzialyKonta, wspolnota]);
    useEffect( () => setGrupy_w( grupy?.filter( g => g?.id_wspolnoty == wspolnota?.id)), [grupy, wspolnota]);
    useEffect( () => setOdpowiedziActiveCard( glosowanieOdpowiedzi?.filter( g => g?.id_karty == activeCard?.id)), [activeCard, glosowanieOdpowiedzi]);
    useEffect( () => setGrupyKarty(grupy_w?.filter( g => strefa(g.data_od) <= strefa(activeCard?.data_do) && ( g.data_do === null || strefa(g.data_do) >= strefa(activeCard?.data_od))  )),[activeCard, grupy_w]);
    useEffect( () => setUdzialyKarty(udzialyKonta_w?.filter( u => grupyKarty?.filter( g => g?.id_user == u?.id_user && g?.grupa_numer == u?.grupa_numer)?.length > 0 )),[grupyKarty, udzialyKonta_w]);
    
    useEffect( () => {
        const uniqueUdzialy = udzialyKarty
            .map(({ id_user, grupa_numer }) => ({ id_user, grupa_numer }))
            .filter((item, index, self) => 
                index === self.findIndex((t) => t.id_user === item.id_user && t.grupa_numer === item.grupa_numer )
            );
        setUdzialyKartyUniq(uniqueUdzialy);
    },[udzialyKarty])

    useEffect( () => {
        const uniqueGrupy = grupyKarty
            .map(({ id_user, grupa_numer, id_lokal }) => ({ id_user, grupa_numer, id_lokal }))
            .filter((item, index, self) => 
                index === self.findIndex((t) => t.id_user === item.id_user && t.grupa_numer === item.grupa_numer && t.id_lokal == item.id_lokal)
            );
        setGrupyKartyUniq(uniqueGrupy);
    },[grupyKarty])
    
    useEffect( ( ) => {
        const temp = udzialyKartyUniq?.map( (udzial, i) => {
        
            const mieszkaniec = mieszkancy?.find( m => m?.id_user == udzial.id_user);
            const lokalId = findMieszkanieId(udzial, grupyKartyUniq, lokale);
            const adres = adresPro( lokalId, lokale, ulice);
            const lokal = lokale?.find( l => l?.id == lokalId);//mieszkanie
            const ulicaId = lokal?.ulica;
            const ulica = ulice?.find( u => u?.id == ulicaId);
            const ulicaNazwa = ulica?.nazwa;
            const ulicaNumer = ulica?.numer_budynku;
            const lokalNumerTxt = lokal?.numer;
            const lokalNumer = lokalNumerTxt === "" ? 0 : parseInt(lokalNumerTxt);
            const grupyUdzialu = grupyKartyUniq?.filter( g => g?.id_user == udzial.id_user && g?.grupa_numer == udzial.grupa_numer);
    
            const odpowiedziUser = odpowiedziActiveCard?.filter( o => o?.id_user == udzial?.id_user);
    
            const czyOdp = odpowiedziUser?.length > 0 ? 1 : 0;
    
            let udzialProc = 0;
    
            grupyUdzialu?.forEach( (grupa, i) => {
                const lokalP = lokale.find( l => l?.id == grupa.id_lokal);
                udzialProc += lokalP.udzial;
            })
            
            return ({
                id_user: udzial.id_user,
                imie_nazwisko: mieszkaniec.imie_nazwisko,
                adres: adres,
                ulicaNazwa: ulicaNazwa,
                ulicaBudynek: ulicaNumer,
                lokalNumer: lokalNumer,
                udzialProc,
                odpowiedziUser,
                czyOdp
    
            })
        }).sort((a, b) => {
            if (a.ulicaNazwa < b.ulicaNazwa) return -1;
            if (a.ulicaNazwa > b.ulicaNazwa) return 1;
            if (a.ulicaBudynek < b.ulicaBudynek) return -1;
            if (a.ulicaBudynek > b.ulicaBudynek) return 1;
            return a.lokalNumer - b.lokalNumer;
        });
        setTablica(temp);
    },[udzialyKartyUniq, mieszkancy, grupyKartyUniq, lokale, ulice])
    
    useEffect( () => {
        const wynik = [];
        tablica.forEach(({ id_user, udzialProc, imie_nazwisko, czyOdp }) => {
          const istniejący = wynik.find((obj) => obj.id_user === id_user);
          if (istniejący) {
            istniejący.udzialProc += udzialProc;
          } else {
            wynik.push({ id_user, udzialProc, imie_nazwisko, czyOdp });
          }
        });
        setTablicaMieszkancyUdzialy(sortByTxt(wynik,"imie_nazwisko")); 
    },[tablica]);

    return (
        // <motion.div
        //     variants={wstepy}
        //     initial="initial"
        //     animate="animate"
        //     className="przeglad"
        // >
        <div className="przeglad">
            <PrzelgadLista
                glosowanieKarty={glosowanieKarty}
                activeCard={activeCard}
                setActiveCard={setActiveCard}
            />
            <PrzelgadSzczegoly
                glosowaniePytania={glosowaniePytania}
                glosowanieOdpowiedzi={glosowanieOdpowiedzi}
                glosowanieUchwaly={glosowanieUchwaly?.find( u => u?.id_karty == activeCard?.id)}
                activeCard={activeCard}
                tablicaMieszkancyUdzialy={tablicaMieszkancyUdzialy}
                setShowPrint={setShowPrint}
                handlePrint={handlePrint}
                setPytanieWydruk={setPytanieWydruk}
            />
            {/* <PrzegladGlosy
                activeCard={activeCard}
                tablicaMieszkancyUdzialy={tablicaMieszkancyUdzialy}
            /> */}
            {
                showPrint ? (
                    <PrzegladWydrukModal
                        handlePrint={handlePrint}
                        wspolnota={wspolnota}
                        tablicaMieszkancyUdzialy={tablicaMieszkancyUdzialy}
                        activeCard={activeCard}
                        glosowaniePytania={glosowaniePytania}
                        glosowanieOdpowiedzi={glosowanieOdpowiedzi}
                        pytanieWydruk={pytanieWydruk}
                        setPytanieWydruk={setPytanieWydruk}
                        tablica={tablica}
                    />
                ) : ""
            }
            
        </div>
    )
}

export default Przelgad;