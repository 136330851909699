import React, { useEffect, useState } from 'react';
import { obliczDatyNoty } from '../../functions/dataFormat.js';

const NotaTytul = (props) => {

    const { tablicaCzynsze, udzialGrupaNota } = props;

    const datyNoty = obliczDatyNoty(); 
    const [numerNoty, setNumerNoty] = useState(0);

    const [tytul, setTytul] = useState("");

    useEffect( () => {
        setTytul( `Nota ksiegowa ${numerNoty}/${datyNoty?.miesiacTytul}` )
    }    
    , [numerNoty, datyNoty]);

    useEffect( () => {
        if (tablicaCzynsze && tablicaCzynsze?.length > 0) {
            const nr = tablicaCzynsze[0]?.grupyNoty?.find( t => t.id_user == udzialGrupaNota?.id_user && t.grupa_numer == udzialGrupaNota?.grupa_numer)?.notaNr;
            setNumerNoty(nr)
        }
        
    },[udzialGrupaNota, tablicaCzynsze])
    
    const handleChangeTytul = e => setTytul(e.target.value)
    
    return (
        <section
            style={{
                display: 'flex',
                justifyContent: 'center',
                fontSize: '16px',
                margin: '10mm 0 0 0'
            }}
        >
            <input
                style={{
                    display: "block",
                    width:"100%",
                    type:"text",
                    fontWeight:"500",
                    border:"none",
                    textAlign: "center",
                    fontSize: "16px",
                    backgroundColor: "transparent"
                }}
                value={tytul}
                onChange={handleChangeTytul}
            />
        </section>
    )
}

export default NotaTytul;