import React, {useEffect, useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ImportTransakcjiWgranaLista from './ImportTransakcjiWgranaLista'
import ImportTransakcjiSend from './ImportTranskacjiSend';
import ImportBNP from '../FormBanki/ImportBNP';
import ImportPKOBP_csv from '../FormBanki/ImportPKOBP_csv';

import ImportTransakcjiListaWspolnot from './ImportTransakcjiListaWspolnot';

import { 
    fetchMieszkancy, 
    fetchPrzelewy, 
    fetchUdzialyKontaAkt,
    fetchBanki
  } from '../../../../Redux/Actions';

const ImportTransakcji = ( props ) => {

    const {wspolnoty} = props;

    const dispatch = useDispatch();

    const [ pelneDane, setPelneDane ] = useState(undefined);
    const [fileName, setFileName] = useState("");
    const [wczytane, setWczytane] = useState([]);
    const [czyDubel, setCzyDubel] = useState(false);
    const [wybranaWspolnota, setWybranaWspolnota] = useState({id: 0});
    const [czyWplataNaKontoGlowne, setCzyWplataNaKontoGlowne] = useState(false);

    useEffect( ()=> {
        if (wybranaWspolnota && wybranaWspolnota?.id > 0) {
            dispatch(fetchMieszkancy(wybranaWspolnota.id)); 
            dispatch(fetchPrzelewy(wybranaWspolnota.id)); 
            dispatch(fetchUdzialyKontaAkt(wybranaWspolnota.id));
        }
        dispatch(fetchBanki());
    },[dispatch]);

    const udzialyKontaAkt = useSelector( state => state?.udzialyKontaAkt?.data);
    const mieszkancy = useSelector( state => state?.mieszkancy?.data);
    const przelewy = useSelector( state => state?.przelewy?.data);
    const banki = useSelector( state => state?.banki?.data);

    useEffect( () => {
        if (wspolnoty && wspolnoty?.length > 0)  
            setWybranaWspolnota( wspolnoty[0]);
    },[wspolnoty]);

 
    const clearData = () => {
        setWczytane([]);
        setPelneDane(undefined);
        setFileName("");
        setCzyDubel(false);
        setCzyWplataNaKontoGlowne(undefined);   
    }

    useEffect( () => {
        if (wybranaWspolnota && wybranaWspolnota?.id > 0) {
            dispatch(fetchMieszkancy(wybranaWspolnota.id)); 
            dispatch(fetchPrzelewy(wybranaWspolnota.id)); 
            dispatch(fetchUdzialyKontaAkt(wybranaWspolnota.id));
        }
    },[wybranaWspolnota])

    useEffect( () => {
        if (wczytane && wczytane?.length > 0) {
            const temp = [];
            wczytane?.forEach( w => {
                let udzialKontoAkt;
                if (wybranaWspolnota?.id_bank == 1) {
                    udzialKontoAkt = udzialyKontaAkt?.find( u => w.rodzajOperacji !== "prowizja" && u.konto_bankowe.substr(14, u.konto_bankowe.length) == w.odbiorcaKonto && u.id_wspolnoty == wybranaWspolnota.id) 
                } else {
                    udzialKontoAkt = udzialyKontaAkt?.find( u => (u.konto_bankowe === w.nadawcaKonto && w.nadawcaKonto?.length > 0) || (u.konto_bankowe === w.odbiorcaKonto && w.odbiorcaKonto?.length > 0 ))
                }
                
                temp.push({
                    id_transkacji: w?.przelewId,
                    data_operacji: w?.dataOperacji,
                    data_wczytania: w?.dataWczytania,
                    kwota: w?.kwota,
                    id_user: udzialKontoAkt?.id_user,
                    grupa_numer: udzialKontoAkt?.grupa_numer,
                    id_wspolnoty: wybranaWspolnota !== null ? wybranaWspolnota.id : udzialKontoAkt?.id_wspolnoty,
                    nadawca_nazwa: w?.nadawcaNazwa,
                    nadawca_konto: w?.nadawcaKonto,
                    nazwa_transakcji: w?.tytul,
                    nazwa_pliku: w?.plik,
                    operacja: w?.rodzajOperacji,
                    odbiorca_nazwa: w?.odbiorcaNazwa,
                    odbiorca_konto: w?.odbiorcaKonto,                    
                });
            })
            setPelneDane(temp);
        }
    },[wczytane, udzialyKontaAkt, fileName, wybranaWspolnota]);

    const formularzBanku = () => {
        
        switch(wybranaWspolnota?.id_bank) {
            case "0":
                return (
                    <></>
                )
            case "6": 
                return (   
                    <ImportBNP
                        setWczytane={setWczytane}
                        setPelneDane={setPelneDane}
                        przelewy={przelewy}
                        setCzyDubel={setCzyDubel}
                    /> );
            case "1":
                return (
                    <ImportPKOBP_csv
                        setWczytane={setWczytane}
                        setPelneDane={setPelneDane}
                        przelewy={przelewy}
                        setCzyDubel={setCzyDubel}
                        setWybranaWspolnota={setWybranaWspolnota}
                        wspolnoty={wspolnoty}
                    />
                );
            default: return (<>{`import nieprzewidziany dla banku ${banki?.find( b => b?.id == wybranaWspolnota?.id_bank)?.nazwa}`}</>);
            
        }
    }

    return (
        <div className="przelewy">
            <h4 className="nazwa">
                Import przelewów
            </h4>

            <div className='import'>
                <div className='form'>
                    <ImportTransakcjiListaWspolnot
                        wspolnoty={wspolnoty}
                        wybranaWspolnota={wybranaWspolnota}
                        setWybranaWspolnota={setWybranaWspolnota}
                        clearData={clearData}
                    />
                    {formularzBanku()}
                </div>
                {
                    czyDubel ? (
                        <span className='red'>Plik o tej nazwie został już kiedyś wczytany.</span>
                    ) : (
                        <div className='tabela'>
                            <ImportTransakcjiWgranaLista 
                                fileName={fileName}
                                pelneDane={pelneDane}
                                wspolnoty={wspolnoty}
                                mieszkancy={mieszkancy}
                                wybranaWspolnota={wybranaWspolnota}
                                setWybranaWspolnota={setWybranaWspolnota}
                                czyWplataNaKontoGlowne={czyWplataNaKontoGlowne}
                                setCzyWplataNaKontoGlowne={setCzyWplataNaKontoGlowne}
                            />
                            <ImportTransakcjiSend
                                pelneDane={pelneDane}
                                setFileName={setFileName}
                                setPelneDane={setPelneDane}
                                setCzyDubel={setCzyDubel}
                                setWczytane={setWczytane}
                                setCzyWplataNaKontoGlowne={setCzyWplataNaKontoGlowne}
                                wybranaWspolnota={wybranaWspolnota}
                            />
                        </div>
                    )
                }
            </div>
        </div>
    )
}

export default ImportTransakcji;