import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { findMieszkanieId, adresPro } from '../../../functions/ulice';
import { dataCzas } from '../../../functions/dataFormat';

const NotyHistoriaTBody = ( props ) => {

    const { grupy, udzialyKontaAkt, lokale, ulice, notyMsc, mieszkancy } = props;

    const [notyUniq, setNotyUniq] = useState([]);

    useEffect( ( ) => {

      const znajdzNajnowszeNoty = (notyMsc) => {
        // Tworzymy mapę, aby przechowywać najnowsze noty dla par id_user-grupa_numer
        const najnowszeNotyMap = new Map();
      
        notyMsc.forEach((nota) => {
          const key = `${nota.id_user}-${nota.grupa_numer}`;
          const existingNota = najnowszeNotyMap.get(key);
      
          // Jeśli klucz już istnieje, porównujemy daty
          if (!existingNota || new Date(nota.data_utworzenia) > new Date(existingNota.data_utworzenia)) {
            najnowszeNotyMap.set(key, nota);
          }
        });
      
        // Konwertujemy mapę na tablicę wartości
        return Array.from(najnowszeNotyMap.values());
      };

      setNotyUniq(znajdzNajnowszeNoty(notyMsc));

    },[notyMsc])

    // console.log(notyMsc?.filter( n => n?.grupa_numer == 13));
    // console.log(notyUniq?.filter( n => n?.grupa_numer == 13));

    const pobierz = async fileName => {
        console.log(fileName)
        try {
            const response = await axios.post( 
                '/api/noty/pobierz',
                { fileName: fileName }, 
                { responseType: 'blob' })
            
            // if (response.statusText !== "OK") {
            //   throw new Error('Błąd podczas pobierania pliku PDF');
            // }
            // console.log(response)
      
            const url = window.URL.createObjectURL(new Blob([response.data]));
      
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName); // Nazwa pliku do pobrania
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
          } catch (error) {
            alert(`Plik "${fileName}" nie istnieje.`)
          }
    }   
    
    const lista = notyUniq?.map( (element, i) => {

      const udzial = udzialyKontaAkt?.find( u => u?.id_user == element?.id_user && u.grupa_numer == element?.grupa_numer);

      const mieszkanieId = findMieszkanieId(udzial,grupy,lokale);      

      const mieszkanie = adresPro(mieszkanieId, lokale, ulice)
      const msc = element?.msc;
      const imieNazwisko = mieszkancy?.find( m => m?.id_user == element?.id_user)?.imie_nazwisko;
      
      if (element?.mail_dataczas !== null) {

        return (
          <tr key={i}>
            <td>
              {element.id}
            </td>
            <td>
              {`${imieNazwisko}` }
            </td>
            <td>
                {`${mieszkanie} (g. ${element?.grupa_numer})`}
            </td>
            <td>
              {msc}
            </td>
            <td
              id={element?.nota}
              className='central'
            >
              {
                <FontAwesomeIcon 
                    icon={faEye} 
                    size='xs'
                    id={element?.nota}
                    onClick={()=>pobierz(element?.nazwa_pliku)}
                    className='pointer'
                />
              }
            </td>
            <td>
              {element?.nazwa_pliku}
            </td>
            <td className='central'>
              { `wysłany ${dataCzas(element?.mail_dataczas)}` }
            </td>
          </tr>
        )

      }


    });

    return (
        <tbody>
            {lista}
        </tbody>
    )
}

export default NotyHistoriaTBody;