import React, { useState, useEffect } from "react";
import '../scss/logi.css';
import '../scss/style.css';
import { useSelector, useDispatch } from 'react-redux';
import { 
    fetchWspolnoty, 
    fetchMieszkancyAll_w, 
    fetchUsersAll,
    fetchLogi
  } from '../Redux/Actions.js'; 

  import { format } from 'date-fns';
import { usun_duplikaty } from "../functions/grupy.js";


const Logi = () => {

    const dispatch = useDispatch();
    const _MIESZKANIEC_ = "mieszkaniec";
    const _WSZYSTKIE_ = "Wszystkie";

    useEffect( () => {
        dispatch(fetchLogi());
        dispatch(fetchMieszkancyAll_w());
        dispatch(fetchUsersAll());
        dispatch(fetchWspolnoty());
    },[dispatch])

    const [filtr, setFiltr] = useState("Zalogowanie");
    const [listaLogi, setListaLogi] = useState([]);

    const wszystkieWspolnoty = {id: -1, name: "Wszystkie"};

    const [wybranaStrona, setWybranaStrona] = useState("Zalogowanie");
    const [wybranaWspolnota, setWybranaWspolnota] = useState(wszystkieWspolnoty);

    const wspolnoty = useSelector( state  => state?.wspolnoty?.data);
    const mieszkancy = useSelector( state => state?.mieszkancyAll_w?.data);
    const users = useSelector( state => state?.usersAll?.data);
    const logi = useSelector( state => state?.logi?.data);

    const [logiBezDubli, setLogiBezDubli] = useState([]);

    useEffect( () => setLogiBezDubli( usun_duplikaty(logi) ), [logi]);

    const strony = ["Strona Główna", "O Nas", "Kontakt", "Komunikaty", "Zalogowanie", "Polityka prywatności", "Wszystkie"];

    const filtrStrony = strony?.map( (strona, i) => ( 
        <div 
            key={i}
            className={ wybranaStrona === strona ? "filtrBtn pointer active" : "filtrBtn pointer" }
            onClick={()=>{setWybranaStrona(strona)}}
        >
            {strona}
        </div>
    ));

    const filtrWspolnoty = wspolnoty?.map( (wspolnota, i) => {
        return (
            <div
                key={i}
                className={ wspolnota.id == wybranaWspolnota?.id ? "filtrBtn pointer active" : "filtrBtn pointer" }
                onClick={()=>{setWybranaWspolnota(wspolnota)}}
            >
                {wspolnota.name}
            </div>
        )

    })

    useEffect( ( ) => {
        let filtrPoStronach = [];

        const logiUser = logiBezDubli?.map( log => {
            
            const userTemp = users?.find( u => u?.id == log.id_user);
            if (userTemp?.id) {
                return (
                    {...log,
                    id_wspolnoty: userTemp.id_wspolnoty}
                )
            } 
        }
    )?.filter( l => l !== undefined);

        if (wybranaStrona !== _WSZYSTKIE_)
            filtrPoStronach = logiUser.filter( l => l.akcja == wybranaStrona);
        else 
            filtrPoStronach = logiUser;

        let filtrPoWspolnotach = [];

        if (wybranaWspolnota.id !== -1)
            filtrPoWspolnotach = filtrPoStronach.filter( w => w?.id_wspolnoty == wybranaWspolnota.id);
        else 
            filtrPoWspolnotach = filtrPoStronach;

        setListaLogi(filtrPoWspolnotach);
    },[logiBezDubli, wybranaStrona, wybranaWspolnota, users]);

    const lista = listaLogi?.map( (l, i) => {

        const user = users?.find( u => u.id == l?.id_user && u.role === _MIESZKANIEC_)
        const mieszkaniec = mieszkancy?.find( m => m.id_user == l?.id_user);
        const wspolnota = wspolnoty?.find( w => w?.id == user?.id_wspolnoty);
        
        if (user?.role === "mieszkaniec" || l.id_user == 0) {
            return (
                <tr key={i}>
                    <td>
                        {wspolnota?.name}
                    </td>
                    <td style={{width: "130px"}}>
                        {l.id_user == 0 ? "gość" : user?.user}
                    </td>
                    <td>
                        {mieszkaniec?.imie_nazwisko}
                    </td>
                    <td style={{width: "180px"}}>
                        {format(l.data_utworzenia,'yyyy-MM-dd HH:mm:ss')}
                    </td>
                    <td style={{width: "130px"}}>
                        {l.akcja}
                    </td>
                </tr>
            )
        } else return (<></>)
    })

    return (
        <div className="content logi">
            <h3>
                Historia logowań
            </h3>
            <div className="filtrBox">
                <h5>
                    Wybierz stronę:
                </h5>
                <div className="box">
                    {filtrStrony}
                </div>
            </div>
            <div className="filtrBox">
                <h5>
                    Wybierz WM:
                </h5>
                <div className="box">
                    {filtrWspolnoty}
                    <div
                        key={-1}
                        className={ wybranaWspolnota?.id == -1 ? "filtrBtn pointer active" : "filtrBtn pointer" }
                        onClick={()=>setWybranaWspolnota(wszystkieWspolnoty)}
                    >
                        Wszystkie
                    </div>
                </div>
            </div>
            <div className="table">
                <table
                    className="logi"
                >
                    <thead>
                        <tr>
                            <th>
                                Wspólnota
                            </th>
                            <th style={{width: "130px"}}>
                                Login
                            </th>
                            <th>
                                Mieszkaniec
                            </th>
                            <th style={{width: "180px"}}>
                                Data
                            </th>
                            <th style={{width: "130px"}}>
                                Strona
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {lista}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default Logi;