import React, { useEffect, useState } from "react";
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPlus, faChevronLeft, faCheck } from '@fortawesome/free-solid-svg-icons'
import axios from "axios";
import { dataFormat2 } from "../../../functions/dataFormat.js";
import { 
    fetchOdczyty
} from '../../../Redux/Actions.js';
import MieszkaniecDaneWodaStanLicznikowAdd from "./MieszkaniecDaneWodaStanLicznikowAdd.js";
import MieszkaniecDaneWodaStanLicznikowLista from "./MieszkaniecDaneWodaStanLicznikowLista.js";

const MieszkaniecDaneWodaStanLicznikow = props => {
    
    const dispatch = useDispatch();

    const { grupaUdzial, odczyty, stawkiAkt, wybraneRozliczenie, setWybraneRozliczenie, wybranyOdczyt, setWybranyOdczyt } = props;

    const [odczyty_g, setOdczyty_g] = useState([]);
    const [addNew, setAddNew] = useState(false);
    const [edit, setEdit] = useState({id: null, visible: false});
    const [wc, setWc] = useState(0);
    const [wz, setWz] = useState(0);
    const [pzd, setPzd] = useState("");
    
    const [editStan, setEditStan] = useState({
        cieplaPoczatek: undefined,
        cieplaKoniec: undefined,
        zimnaPoczatek: undefined,
        zimnaKoniec: undefined,
        okresPoczatek: undefined,
        okresKoniec: undefined
    })

    useEffect( () => setOdczyty_g( odczyty?.filter( p => p?.id_user == grupaUdzial?.id_user && p?.grupa_numer == grupaUdzial?.grupa_numer ) ), [grupaUdzial, odczyty])

    useEffect( () => {
        if (edit.visible) {
            const edytowanyOdczyt = odczyty_g?.find( o => o?.id == edit.id);

            setEditStan( prevState => (
                {...prevState, 
                    okresPoczatek: edytowanyOdczyt?.okres_poczatek ? dataFormat2(edytowanyOdczyt?.okres_poczatek) : null,
                    okresKoniec: edytowanyOdczyt?.okres_koniec ? dataFormat2(edytowanyOdczyt?.okres_koniec) : null,
                    cieplaPoczatek: edytowanyOdczyt?.ciepla_stan_poczatek,
                    cieplaKoniec: edytowanyOdczyt?.ciepla_stan_koniec,
                    zimnaPoczatek: edytowanyOdczyt?.zimna_stan_poczatek,
                    zimnaKoniec: edytowanyOdczyt?.zimna_stan_koniec
                } 
            ));   
        }
    },[odczyty_g, edit])

    const handleAnuluj = () => {
        setWc(0);
        setWz(0);
        setPzd("");
        setAddNew(false);
    }

    const handleSave = async () => {
        try {
            axios.post("/api/odczyt/dodaj", { 
                id_wspolnoty: grupaUdzial?.id_wspolnoty,
                id_user: grupaUdzial?.id_user,
                grupa_numer: grupaUdzial?.grupa_numer,
                okres_poczatek: pzd,
                okres_koniec: null,
                ciepla_stan_poczatek: wc,
                ciepla_stan_koniec: null,
                ciepla_zuzycie: null,
                zimna_stan_poczatek: wz,
                zimna_stan_koniec: null,
                zimna_zuzycie: null
            }).then(response => {
              dispatch(fetchOdczyty(grupaUdzial?.id_wspolnoty)); 
              setWc(0);
              setWz(0);
              setPzd("");
              setAddNew(false);
            })
          } catch (error) {
            console.error('Błąd podczas dodawania danych:', error);
          }
    }

    const handlePlus = () => {
        console.log('handlePlus');
        setAddNew(true);
        setEdit({id: null, visible: false})
        setWybranyOdczyt(undefined);
    }

    return (
        <div className="planpowaneZuzycie">
            <div className="naglowek">
                <h5>
                    Stan liczników (odczyty)
                </h5>
                { !addNew ? (
                        <div>
                            <FontAwesomeIcon 
                                icon={faPlus} 
                                className="pointer" 
                                onClick={handlePlus}
                            />
                        </div>
                    ) : (
                        <div>
                            <FontAwesomeIcon 
                                icon={faChevronLeft} 
                                onClick={handleAnuluj}
                                className="pointer"
                            />
                            <FontAwesomeIcon 
                                icon={faCheck} 
                                onClick={handleSave}
                                className="pointer"
                            />
                        </div>
                    )}
            </div>
            
            { addNew ? 
                <MieszkaniecDaneWodaStanLicznikowAdd
                    pzd={pzd}
                    setPzd={setPzd}
                    wz={wz}
                    setWz={setWz}
                    wc={wc}
                    setWc={setWc}
                    stawkiAkt={stawkiAkt}
                />
                 : 
                <></>
            }
            <MieszkaniecDaneWodaStanLicznikowLista
                odczyty_g={odczyty_g}
                setOdczyty_g={setOdczyty_g}
                edit={edit}
                setEdit={setEdit}
                editStan={editStan}
                setEditStan={setEditStan}
                wybraneRozliczenie={wybraneRozliczenie}
                setWybraneRozliczenie={setWybraneRozliczenie}
                wybranyOdczyt={wybranyOdczyt}
                setWybranyOdczyt={setWybranyOdczyt}
                idw={grupaUdzial?.id_wspolnoty}
            />
        </div>
    )
}

export default MieszkaniecDaneWodaStanLicznikow;