import React, { useState, useEffect } from "react";
import { zl, isNumber } from "../../../functions/utilities";

const WspolnotaRozliczenieTFooter = (props) => {

    const { udzialyKontaAkt, stawki, stawkiJednorazowe, czynszeMsc, ind } = props;

    const [skladniki, setSkladniki] = useState({});

    function containsJednorazowaKwota(czynszeMsc) {
      return czynszeMsc?.some(czynszObj => 
        czynszObj.czynsz && 'jednorazowaInd_kwota' in czynszObj.czynsz
      );
    }

    const indCols = ind?.map( (e,i) => {
      let temp_suma = 0;
      
      czynszeMsc?.forEach( c => {
        if (c.ind) {
          const temp_kwota = c?.ind?.find( o => o.opis == e)?.kwota;
          temp_suma += temp_kwota ? temp_kwota : 0;
        }
      })
      return (<td key={i}>{zl(temp_suma)}</td>)
    })
    
    useEffect( () => {
      setSkladniki({})
      udzialyKontaAkt?.forEach( (udzial, i) => {
        const czynsz_temp = czynszeMsc?.filter( czynsz => czynsz.id_user == udzial.id_user && czynsz.grupa_numer == udzial.grupa_numer);
        const czynsz = czynsz_temp && czynsz_temp.length > 0 ? czynsz_temp[0].czynsz : null
        if (czynsz && stawki?.czy_eksploatacja == 1 ) {
          setSkladniki( prevState => ({
            ...prevState,
            eksploatacja: (prevState.eksploatacja || 0) + czynsz?.eksploatacja,
            garaz: (prevState?.garaz || 0 ) + czynsz?.garaz, 
            strych: (prevState?.strych || 0 ) + czynsz?.strych, 
            komorka: (prevState?.komorka || 0 ) + czynsz?.komorka, 
            utrzymanie_czystosci: (prevState?.utrzymanie_czystosci || 0 ) + czynsz?.utrzymanie_czystosci, 
            wywoz_smieci: (prevState?.wywoz_smieci || 0 ) + czynsz?.wywoz_smieci, 
            fundusz_remontowy: (prevState?.fundusz_remontowy || 0 ) + czynsz?.fundusz_remontowy, 
            konserw_domofonu: (prevState?.konserw_domofonu || 0 ) + czynsz?.konserw_domofonu, 
            konserw_windy: (prevState?.konserw_windy || 0 ) + czynsz?.konserw_windy, 
            opl_za_adm: (prevState?.opl_za_adm || 0 ) + czynsz?.opl_za_adm, 
            abonament_cw: (prevState?.abonament_cw || 0 ) + czynsz?.abonament_cw, 
            co: (prevState?.co || 0 ) + czynsz?.co, 
            energia_el_lokalu: (prevState?.energia_el_lokalu || 0 ) + czynsz?.energia_el_lokalu,  
            ryczalt_gaz: (prevState?.ryczalt_gaz || 0 ) + czynsz?.ryczalt_gaz, 
            zagosp_terenu: (prevState?.zagosp_terenu || 0 ) + czynsz?.zagosp_terenu, 
            antena: (prevState?.antena || 0 ) + czynsz?.antena, 
            ciepla_woda: (prevState?.ciepla_woda || 0 ) + czynsz?.ciepla_woda, 
            zimna_woda: (prevState?.zimna_woda || 0 ) + czynsz?.zimna_woda, 
            dod_1: (prevState?.dod_1 || 0 ) + czynsz?.dod_1, 
            dod_2: (prevState?.dod_1 || 0 ) + czynsz?.dod_2, 
            dod_3: (prevState?.dod_1 || 0 ) + czynsz?.dod_3, 
            czynsz_na_msc: (prevState?.czynsz_na_msc || 0) + czynsz?.czynsz_na_msc,
            jednorazowaInd_kwota: (prevState?.jednorazowaInd_kwota || 0) + (isNumber(czynsz?.jednorazowaInd_kwota) ? czynsz?.jednorazowaInd_kwota : 0)
          }))
        }
      })
    },[udzialyKontaAkt, czynszeMsc, stawki] )

    return (
      <tfoot>
        <tr style={{fontWeight: "700"}}>
          <td colSpan={2}/>
            { stawki?.czy_eksploatacja == 1 ? (<td>{zl(skladniki.eksploatacja)}</td>) : ""   }
            { stawki?.czy_garaz == 1 ? (<td>{zl(skladniki.garaz)}</td>) : ""}
            { stawki?.czy_strych == 1 ? (<td>{zl(skladniki.strych)}</td>) : "" }
            { stawki?.czy_komorka ==  1 ? (<td>{zl(skladniki.komorka)}</td>) : "" }
            { stawki?.czy_utrzymanie_czystosci == 1 ? (<td>{zl(skladniki.utrzymanie_czystosci)}</td>) : ""} 
            { stawki?.czy_wywoz_smieci == 1 ? (<td>{zl(skladniki.wywoz_smieci)}</td>) : ""} 
            { stawki?.czy_fundusz_remontowy == 1 ? (<td>{zl(skladniki.fundusz_remontowy)}</td>) : ""} 
            { stawki?.czy_konserw_domofonu == 1 ? (<td>{zl(skladniki.konserw_domofonu)}</td>) : ""} 
            { stawki?.czy_konserw_windy == 1 ? (<td>{zl(skladniki.konserw_windy)}</td>) : ""} 
            { stawki?.czy_opl_za_adm == 1 ? (<td>{zl(skladniki.opl_za_adm)}</td>) : ""} 
            { stawki?.czy_abonament_cw == 1 ? (<td>{zl(skladniki.abonament_cw)}</td>) : ""} 
            { stawki?.czy_co == 1 ? (<td>{zl(skladniki.co)}</td>) : ""} 
            { stawki?.czy_energia_el_lokalu == 1 ? (<td>{zl(skladniki.energia_el_lokal)}</td>) : ""} 
            { stawki?.czy_ryczalt_gaz == 1 ? (<td>{zl(skladniki.ryczalt_ga)}</td>) : ""} 
            { stawki?.czy_zagosp_terenu == 1 ? (<td>{zl(skladniki.zagosp_teren)}</td>) : ""} 
            { stawki?.czy_antena == 1 ? (<td>{zl(skladniki.antena)}</td>) : ""} 
            { stawki?.czy_ciepla_woda == 1 ? (<td>{zl(skladniki.ciepla_woda)}</td>) : ""} 
            { stawki?.czy_zimna_woda == 1 ? (<td>{zl(skladniki.zimna_woda)}</td>) : ""} 
            { stawkiJednorazowe?.dod_1_czy == 1 ? (<td>{zl(skladniki.dod_1)}</td>) : ""} 
            { stawkiJednorazowe?.dod_2_czy == 1 ? (<td>{zl(skladniki.dod_2)}</td>) : ""} 
            { stawkiJednorazowe?.dod_3_czy == 1 ? (<td>{zl(skladniki.dod_3)}</td>) : ""}
            {
              containsJednorazowaKwota(czynszeMsc) ? (
                <>
                  {indCols}
                </>
              ) : <></>
            } 
            <td>{zl(skladniki.czynsz_na_msc)}</td>
            <td></td>
        </tr>
      </tfoot>
    )
}

export default WspolnotaRozliczenieTFooter;