import React from "react";

import ZbiorowyWydrukTHead from "./ZbiorowyWydrukTHead";
import ZbiorowyWydrukTBody from "./ZbiorowyWydrukTBody";
import ZbiorowyWydrukTFoot from "./ZbiorowyWydrukTFoot";

const ZbiorowyWydrukA4 = props =>  {

    const { componentRef, wspolnota, listaMsc, idw, visible, czynszeWspolnota, przelewyWspolnota } = props;

    return (
        <div    
            style={{
                width: '29.7cm',
                minHeight: '21cm',
                backgroundColor: 'white',
                boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
                padding: '5mm 5mm',
                boxSizing: 'border-box',
                maxWidth: '100%',
                maxHeight: '100%',
                fontSize: '12px'
            }}
            ref={componentRef ? componentRef : null}
        >
            <div style={{marginBottom: "10px"}}>
                {
                    listaMsc?.length > 0 ? (
                        `Raport zbiorowy dla ${wspolnota?.name} w okresie ${listaMsc[0]} - ${listaMsc[listaMsc?.length - 1]}` 
                    ) : (
                        `Raport zbiorowy dla ${wspolnota?.name} w okresie ${listaMsc[0]}` 
                    )
                }
            </div>
            <table
                style={{
                    fontSize: '10px',
                    borderCollapse: 'collapse',
                    // width: '100%'
                }}
            >
                <ZbiorowyWydrukTHead
                    listaMsc={listaMsc}
                    visible={visible}
                />
                <ZbiorowyWydrukTBody
                    listaMsc={listaMsc}
                    idw={idw}
                    visible={visible}
                    czynszeWspolnota={czynszeWspolnota}
                    przelewyWspolnota={przelewyWspolnota}
                />
                <ZbiorowyWydrukTFoot
                    listaMsc={listaMsc}
                    visible={visible}
                    czynszeWspolnota={czynszeWspolnota}
                    przelewyWspolnota={przelewyWspolnota}
                />

            </table>

        </div>
    )
}

export default ZbiorowyWydrukA4;