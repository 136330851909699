import React, { useRef } from "react";
import { addOneDay, data_z_dniem, dataFormat2 } from "../../../../functions/dataFormat";
import { zl, zaokr } from "../../../../functions/utilities";
import { adresPro2 } from "../../../../functions/ulice";

const MieszkaniecDaneWodaRozliczenieWydrukA4 = props => {
    
    const { componentRef, setShowPrint, czynszeOkres, sumaZaliczek, wybranyOdczyt, zuzycie, stawkaRozliczenie, podsumowanie, mieszkaniec, adres } = props;    

    const naliczoneZaliczki = czynszeOkres?.map( (zaliczka, i) => (
        <tr key={i}>
            <td style={{border: "1px solid black", padding: "5px 10px"}}>{zaliczka.msc}</td>
            <td style={{border: "1px solid black", padding: "5px 10px"}}>{ zl(zaliczka.zimna_woda) }</td>
            <td style={{border: "1px solid black", padding: "5px 10px"}}>{ zl(zaliczka.ciepla_woda) }</td>
        </tr>
    ));

    return (
        <div    
            style={{
                width: '29.7cm',
                minHeight: '21cm',
                backgroundColor: 'white',
                // boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
                padding: '15mm 15mm',
                boxSizing: 'border-box',
                maxWidth: '100%',
                maxHeight: '100%',
                fontSize: '14px'
            }}
            ref={componentRef ? componentRef : null}
        >
            <section
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "100px"
                }}
            >
                <div>
                    <p style={{lineHeight: "1.4rem", textDecoration: "underline", fontWeight: "600"}}>Administrator:</p>
                    <p style={{lineHeight: "1.4rem"}}>Przedsiebiorstwo Usługowe "eM3"</p>
                    <p style={{lineHeight: "1.4rem"}}>J. Mojsiewicz i Wspólnicy s.j.</p>
                    <p style={{lineHeight: "1.4rem"}}>ul.Mieszka I 57/6 66-400 Gorzów Wlkp.</p>
                    <p style={{lineHeight: "1.4rem"}}>tel: 95 7 24 19 08, 690 240 779, em3.sj@tlen.pl</p>
                </div>
                <div>
                    <p style={{lineHeight: "1.4rem"}}>
                        {`Gorzów Wlkp. ${data_z_dniem(addOneDay(wybranyOdczyt?.okres_koniec))}`} 
                    </p>
                </div>
            </section>
            <section
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "100px"
                }}
            >
                <div
                    style={{width: "100%"}}
                ></div>
                <div
                    style={{width: "100%", fontWeight: "700"}}
                >
                    <p>Sz. P.</p>
                    <p>{` ${mieszkaniec?.imie_nazwisko}`}</p>
                    <p>{adres}</p>
                    <p>66-400 Gorzów Wlkp.</p>
                </div>
            </section>
            
            <section style={{marginBottom: "50px"}}>
                <p
                    style={{marginLeft: "100px", fontWeight: "600"}}
                >
                    {`Rozliczenie zużycia wody za okres od ${dataFormat2(wybranyOdczyt.okres_poczatek)} do ${dataFormat2(wybranyOdczyt.okres_koniec)}`}   
                </p>
            </section>
            
            <section
                style={{
                    display: "flex",
                    justifyContent: "space-around",
                    margin: "0 0 50px 0"
                }}
            >
                <div>
                    <div className="naglowek">Naliczone zaliczki</div>
                    <table style={{borderCollapse: "collapse", border: "1px solid black"}}>
                        <thead>
                            <tr style={{border: "1px solid black", padding: "5px 10px"}}>
                                <th style={{border: "1px solid black", padding: "5px 10px"}}>
                                    Miesiąc
                                </th>
                                <th style={{border: "1px solid black", padding: "5px 10px"}}>
                                    Zimna woda
                                </th>
                                <th style={{border: "1px solid black", padding: "5px 10px"}}>
                                    Ciepła woda
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            { naliczoneZaliczki }
                        </tbody>
                        <tfoot>
                            <tr>
                                <td style={{border: "1px solid black", padding: "5px 10px"}}>Razem:</td>
                                <td style={{border: "1px solid black", padding: "5px 10px"}}>
                                    { zl(sumaZaliczek?.zimna_woda) }
                                </td>
                                <td style={{border: "1px solid black", padding: "5px 10px"}}>
                                    { zl(sumaZaliczek?.ciepla_woda) }
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
                <div className="lewa">
                    <div className="naglowek">Pobrana woda - stany liczników</div>
                    <table >
                        <thead>
                            <tr >
                                <th></th>
                                <th style={{border: "1px solid black", padding: "5px 10px"}}>
                                    Początek
                                </th>
                                <th style={{border: "1px solid black", padding: "5px 10px"}}>
                                    Koniec
                                </th>
                                <th style={{border: "1px solid black", padding: "5px 10px"}}>
                                    Zużycie
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr style={{border: "1px solid black", padding: "5px 10px"}}>
                                <td style={{border: "1px solid black", padding: "5px 10px"}}>
                                    zimna woda
                                </td>
                                <td style={{border: "1px solid black", padding: "5px 10px", "textAlign": "center"}}>
                                    {zaokr(wybranyOdczyt?.zimna_stan_poczatek,2)}
                                </td>                    
                                <td style={{border: "1px solid black", padding: "5px 10px", "textAlign": "center"}}>
                                    {zaokr(wybranyOdczyt?.zimna_stan_koniec,2)}
                                </td>
                                <td style={{border: "1px solid black", padding: "5px 10px", "textAlign": "center"}}>
                                    {zaokr(wybranyOdczyt?.zimna_stan_koniec - wybranyOdczyt?.zimna_stan_poczatek, 2)}
                                </td>
                            </tr>
                            <tr style={{border: "1px solid black", padding: "5px 10px"}}>
                                <td style={{border: "1px solid black", padding: "5px 10px"}}>
                                    ciepła woda
                                </td>
                                <td style={{border: "1px solid black", padding: "5px 10px", "textAlign": "center"}}>
                                    {zaokr(wybranyOdczyt?.ciepla_stan_poczatek,2)}
                                </td>                    
                                <td style={{border: "1px solid black", padding: "5px 10px", "textAlign": "center"}}>
                                    {zaokr(wybranyOdczyt?.ciepla_stan_koniec,2)}
                                </td>
                                <td style={{border: "1px solid black", padding: "5px 10px", "textAlign": "center"}}>
                                    {zaokr(wybranyOdczyt?.ciepla_stan_koniec - wybranyOdczyt?.ciepla_stan_poczatek, 2)}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div className="koszt">
                        <div className="naglowek">Koszt pobranej wody</div>
                    
                        <div className="kalkulacje">
                            <p style={{textAlign: "right", padding: "0 20px 0 0"}}>
                                {`Zimna woda: (${zuzycie.zimna} m³ + ${zuzycie.ciepla} m³) x ${zl(stawkaRozliczenie?.zimna_woda)} = ${zl(zuzycie.zimnaKoszt)} `}
                            </p>
                            <p style={{textAlign: "right", padding: "0 20px 0 0"}}>
                                {`Podgrzanie wody: (${zuzycie.ciepla} m³) x ${zl(stawkaRozliczenie?.ciepla_woda)} = ${zl(zuzycie.cieplaKoszt)} `}
                            </p>
                            <p style={{textAlign: "right", padding: "0 20px 0 0"}}>
                                {`Razem koszt zużycia wody: (${zuzycie.ciepla} m³) x ${ zl( zuzycie.zimnaKoszt + zuzycie.cieplaKoszt ) } `}
                            </p>
                        </div>
                    </div>
                </div>
            </section>


            <section style={{margin: "0 0 50px 0"}}>
                <div className="koszt">
                    <div className="naglowek">Rozliczenie</div>
                    
                    <div className="podsumowanie">
                        <div>
                            <table>
                                <tbody>
                                    <tr>
                                        <td style={{textDecoration: "underline"}}>
                                            zimna woda:
                                        </td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>
                                            {zl(sumaZaliczek?.zimna_woda)}
                                        </td>
                                        <td>
                                            zaliczki na zimna wodę
                                        </td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>
                                            {zl(zuzycie?.zimnaKoszt)}
                                        </td>
                                        <td>
                                            koszt zimnej wody
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {podsumowanie?.zimnaOpis}
                                        </td>
                                        <td>
                                            {zl(podsumowanie?.zimnaPodsumowanie)}
                                        </td>
                                        <td>
                                            rozliczenie zimnej wody
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div>
                            <table>
                                <tbody>
                                    <tr>
                                        <td style={{textDecoration: "underline"}}>
                                            ciepła woda:
                                        </td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>
                                            {zl(sumaZaliczek?.ciepla_woda)}
                                        </td>
                                        <td>
                                            zaliczki na ciepłą wodę
                                        </td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>
                                            {zl(zuzycie?.cieplaKoszt)}
                                        </td>
                                        <td>
                                            koszt ciepłej wody
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {podsumowanie?.cieplaOpis}
                                        </td>
                                        <td>
                                            {zl(podsumowanie?.cieplaPodsumowanie)}
                                        </td>
                                        <td>
                                            rozliczenie ciepłej wody
                                        </td>
                                    </tr>
                                </tbody>
                        </table>
                        </div>
                        
                    </div>
                </div>
            </section>
            <section style={{display: "flex", justifyContent: "center"}}>
                <div className="suma ramka-inset-shadow" style={{width: "fit-content", padding: "10px 50px"}}>
                    <p>
                        {`Razem: ${zl(podsumowanie?.zimnaPodsumowanie)} + ${zl(podsumowanie.cieplaPodsumowanie)} = ${zl( podsumowanie?.sumaTotal )} ${ podsumowanie?.sumaTotalOpis }`}
                    </p>
                </div>
            </section>

        </div>
    )
}

export default MieszkaniecDaneWodaRozliczenieWydrukA4;