import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import axios from "axios";
import MieszkaniecDaneDodajGrupeLokale from './MieszkaniecDaneDodajGrupeLokale.js';
import MieszkaniecDaneDodajGrupeMieszkanie from './MieszkaniecDaneDodajGrupeMieszkanie.js';
import { motion } from 'framer-motion';
import { wstepy } from '../../functions/motions.js';

import { dataFormat2 } from '../../functions/dataFormat.js';
import {  
    fetchLokale, 
    fetchGrupy, 
    fetchGrupyAkt,
    fetchUdzialyKonta,
    fetchUdzialyKontaAkt,
    fetchMieszkancy,
    fetchWspolnoty
  } from '../../Redux/Actions.js'; 

import DialogOk from '../DialogOk.js'

const MieszkaniecDaneDodajGrupe = (props) => {

    const { mieszkaniec, id_wspolnoty, setActiveWindow, setAddGrupe, ktoraGrupa } = props;

    const dispatch = useDispatch();

    const ulice = useSelector( state => state?.ulice?.data);
    const lokale = useSelector( state => state?.lokale?.data);
    const [wybraneMieszkanie, setWybraneMieszkanie] = useState(0);
    const [wybraneLokale, setWybraneLokale] = useState([]);
   
    const [kontoBankowe, setKontoBankowe] = useState("");
    const [liczbaDomownikow, setLiczbaDomownikow] = useState(0)
    const [domownicy, setDomownicy] = useState(false);
    const [dataOd, setDataOd] = useState(dataFormat2(new Date()));

    const [kontoValid, setKontoValid] = useState(true);

    useEffect( () => {
        if ( kontoBankowe?.length !== 26)
            setKontoValid(false);
        else
            setKontoValid(true)
    },[kontoBankowe])

    useEffect( ()=> {
        dispatch(fetchLokale(id_wspolnoty)); 
        dispatch(fetchGrupy(id_wspolnoty)); 
        dispatch(fetchGrupyAkt(id_wspolnoty)); 
        dispatch(fetchUdzialyKonta(id_wspolnoty));
        dispatch(fetchUdzialyKontaAkt(id_wspolnoty));
    },[dispatch, id_wspolnoty]);
  

    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("");

    const addData = async (obj) => {
        try {
            await axios.post('/api/grupy/dodaj', {
                id_wspolnoty: obj.id_wspolnoty,
                id_user:obj.id_user,
                grupa_numer: obj.grupa_numer,
                id_lokal: obj.id_lokal,    
                data_od: obj.data_od,
                wersja: 1
            }).then( response => {
                axios.post('/api/lokal/zajmij', {
                    id_lokal: obj.id_lokal,    
                }).then( response => {
                    dispatch(fetchLokale(id_wspolnoty)); 
                    dispatch(fetchGrupy(id_wspolnoty)); 
                    dispatch(fetchGrupyAkt(id_wspolnoty)); 
                    dispatch(fetchUdzialyKonta(id_wspolnoty));
                    dispatch(fetchUdzialyKontaAkt(id_wspolnoty));
                    dispatch(fetchMieszkancy(id_wspolnoty));
                    dispatch(fetchWspolnoty(id_wspolnoty)); 
                });
            })
        } catch (error) {
            console.log(error)
        }       
    }

    const addData2 = async (obj) => {
        try { 
            await axios.post('/api/grupy_konta_udzialy/dodaj', {
                id_wspolnoty: obj.id_wspolnoty,
                id_user:obj.id_user,
                grupa_numer: obj.grupa_numer,
                konto_bankowe: obj.konto_bankowe,
                liczba_domownikow: obj.liczba_domownikow,
                data_od: obj.data_od,
                czy_mieszkanie_w_grupie: obj.czy_mieszkanie_w_grupie,
                czynsz_od: obj.data_od
            }).then( response => {
                dispatch(fetchLokale(id_wspolnoty)); 
                dispatch(fetchGrupy(id_wspolnoty)); 
                dispatch(fetchGrupyAkt(id_wspolnoty)); 
                dispatch(fetchUdzialyKonta(id_wspolnoty));
                dispatch(fetchUdzialyKontaAkt(id_wspolnoty));
                dispatch(fetchMieszkancy(id_wspolnoty));
                dispatch(fetchWspolnoty(id_wspolnoty)); 
            })
        } catch (error) {
            console.log(error)
        }   
    }

    const handleAdd = () => {

        let czy_mieszkanie_w_grupie = null;

        if (domownicy) {
            czy_mieszkanie_w_grupie = 1;
            addData({
                id_wspolnoty,
                id_user: mieszkaniec?.id_user,
                grupa_numer: ktoraGrupa,
                id_lokal: wybraneMieszkanie,
                data_od: dataOd,
            })
        }
        wybraneLokale?.forEach( lokal => {
            addData({
                id_wspolnoty,
                id_user: mieszkaniec?.id_user,
                grupa_numer: ktoraGrupa,
                id_lokal: lokal.id,
                data_od: dataOd,
            })
        })
        addData2({
            id_wspolnoty, 
            id_user: mieszkaniec?.id_user,
            grupa_numer: ktoraGrupa,
            konto_bankowe: kontoBankowe, 
            liczba_domownikow: liczbaDomownikow,
            czy_mieszkanie_w_grupie,
            data_od: dataOd
        })

        setWybraneMieszkanie(0);
        setWybraneLokale([]);
        setAddGrupe(false);
        
        setMessage("Dane zostały zapisane.");
        setOpen(true);
    }

    const handleKontoBankowe = e => setKontoBankowe(e.target.value); 
    const handleDataOd = e => setDataOd( e.target.value )

    const handleAnuluj = () => {
        setAddGrupe(false);
    }

    return (
        <motion.div 
            className='ramka grupaUdzial formDodajGrupe'
            variants={wstepy}
            initial="initial"
            animate="animate"
        >
            <DialogOk
                open={open}
                setOpen={setOpen}
                message={message}
            />
            <div className='naglowek'>
                <h2>{`Dodawanie grupy nr ${ ktoraGrupa }`}</h2> 
            </div>
            <ul className='lokale lista'>
                <li>
                    <div style={{flex: "initial", width: "150px"}}>
                        Data od:
                    </div>
                    <input
                        type="date"
                        id="data_od"
                        value={dataOd}
                        onChange={handleDataOd}
                    />
                </li>
                <li>
                    <div style={{flex: "initial", width: "150px"}}>
                        Numer konta (bez spacji):
                    </div>
                    <input 
                        type="text" 
                        style={{"width": "300px"}} 
                        value={kontoBankowe}
                        onChange={handleKontoBankowe}
                        className={ kontoValid ? "" : "red" }
                    />
                </li>
            </ul>


            <MieszkaniecDaneDodajGrupeMieszkanie 
                setWybraneMieszkanie={setWybraneMieszkanie}
                domownicy={domownicy}
                setDomownicy={setDomownicy}
                ulice={ulice}
                lokale={lokale}
                id_wspolnoty={id_wspolnoty}
                liczbaDomownikow={liczbaDomownikow}
                setLiczbaDomownikow={setLiczbaDomownikow}
            />
            <MieszkaniecDaneDodajGrupeLokale 
                lokale={lokale}
                ulice={ulice}
                rodzajLokalu="garaż"
                id_wspolnoty={id_wspolnoty}
                wybraneLokale={wybraneLokale}
                setWybraneLokale={setWybraneLokale}
            />
            <MieszkaniecDaneDodajGrupeLokale 
                lokale={lokale}
                ulice={ulice}
                rodzajLokalu="komórka"
                id_wspolnoty={id_wspolnoty}
                wybraneLokale={wybraneLokale}
                setWybraneLokale={setWybraneLokale}
            /> 
            <MieszkaniecDaneDodajGrupeLokale 
                lokale={lokale}
                ulice={ulice}
                rodzajLokalu="strych"
                id_wspolnoty={id_wspolnoty}
                wybraneLokale={wybraneLokale}
                setWybraneLokale={setWybraneLokale}
            />

            <button onClick={handleAdd}>
                Zapisz
            </button>

            <button onClick={handleAnuluj}>
                Anuluj
            </button>

        </motion.div>
    )
}

export default MieszkaniecDaneDodajGrupe;