export const objIsNotEmpty = obj => {
    return obj && Object.keys(obj).length > 0 ? true : false;
}

export const zera = liczba => {
  return Math.round(liczba * 100000 ) / 100000
}

export const czyAktywnySerwis = serwisy => {
    if (serwisy && serwisy?.length > 0 ) {
      let temp = false;
      serwisy.forEach( s => {
          if (s.data_do === null)
              temp = true;
      });
      return temp;
    } else {
      return false;
    }
}

export const mergeArrays = (a, b) => {
  return [...b, ...a];
}

export const null_brak = l => ( l == null ? "brak" : l )

export const NaNtoZero = liczba => isNaN(liczba) ? 0 : liczba;

export const formatKonta = konto => {
  if (!konto || konto?.length !== 26) {
    return konto;
  }
  return konto.replace(/(\d{2})(\d{4})(\d{4})(\d{4})(\d{4})(\d{4})(\d{3})/, '$1 $2 $3 $4 $5 $6 $7');
}

export function delay(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export function removeDuplicates(array) {
  const uniqueArray = array.filter((item, index, self) =>
    index === self.findIndex((t) => JSON.stringify(t) === JSON.stringify(item))
  );
  return uniqueArray;
}


export const zaokr = (liczba, zera) => {
  switch (zera) {
    case 2:
      return Math.round(liczba * 100) / 100;
    case 3:
      return Math.round(liczba * 1000) / 1000;
    case 4:
      return Math.round(liczba * 10000) / 10000;
    case 5:
      return Math.round(liczba * 100000) / 100000;
    default: return liczba;
  }
}

export const filterZeroValues = (array, fieldsToSkip = []) => {

  if (array !== undefined) {
    const keys = new Set(array.flatMap(Object.keys));
    const keysToRemove = Array.from(keys).filter(key => 
        !fieldsToSkip.includes(key) && 
        array.every(obj => obj[key] === 0 || obj[key] === null || isNaN(obj[key]))
    );
    const filteredArray = array.map(obj => {
        const newObj = { ...obj };
        keysToRemove.forEach(key => delete newObj[key]);
        return newObj;
    });
    
    return filteredArray;
  } else 
    return [];
}

export const copyToClipboard = e => {
  const text = e.target.innerText;

  const textarea = document.createElement('textarea');
  textarea.value = text;
  document.body.appendChild(textarea);

  textarea.select();
  textarea.setSelectionRange(0, 99999); // Dla urządzeń mobilnych

  document.execCommand('copy');

  document.body.removeChild(textarea);

  alert(`Skopiowano: ${text}`);
};

export const copyToClipboardText = (text) => {
  const textarea = document.createElement('textarea');
  textarea.value = text;
  document.body.appendChild(textarea);
  textarea.select();
  textarea.setSelectionRange(0, 99999); // For mobile devices
  document.execCommand('copy');
  document.body.removeChild(textarea);
  alert('Skopiowano: ' + text);
};

export const zl = (amount) => {

  amount = typeof amount === "string" ? parseFloat(amount) : amount;
  const number = !isNumber(amount) ? 0 : parseFloat(amount);

  // Użycie Intl.NumberFormat do formatowania liczby
  const formattedNumber = new Intl.NumberFormat('pl-PL', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    useGrouping: true
  }).format(number);
  return `${formattedNumber} zł`;
}

export const pln = (amount) => {

  amount = typeof amount === "string" ? parseFloat(amount) : amount;
  const number = !isNumber(amount) ? 0 : parseFloat(amount);
  const formattedNumber = new Intl.NumberFormat('pl-PL', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    useGrouping: true
  }).format(number);
  return `${formattedNumber} PLN`;
}

export const z = amount => {
  const number = !isNumber(amount) ? 0 : parseFloat(amount);
  const formattedNumber = new Intl.NumberFormat('pl-PL', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    useGrouping: true
  }).format(number);
  return `${formattedNumber}`;
}

export const isNumber = (value) => {
  return typeof value === 'number' && !isNaN(value);
}

export const wykrzyknik = () => {
  return (<span style={{"fontSize": "16px","fontWeight": "800","color": "red" }}>{"  !" }</span>)
}

function numberToWords(number) {
  const units = ["", "jeden", "dwa", "trzy", "cztery", "pięć", "sześć", "siedem", "osiem", "dziewięć"];
  const teens = ["dziesięć", "jedenaście", "dwanaście", "trzynaście", "czternaście", "piętnaście", "szesnaście", "siedemnaście", "osiemnaście", "dziewiętnaście"];
  const tens = ["", "", "dwadzieścia", "trzydzieści", "czterdzieści", "pięćdziesiąt", "sześćdziesiąt", "siedemdziesiąt", "osiemdziesiąt", "dziewięćdziesiąt"];
  const hundreds = ["", "sto", "dwieście", "trzysta", "czterysta", "pięćset", "sześćset", "siedemset", "osiemset", "dziewięćset"];
  const thousands = ["", "tysiąc", "tysiące", "tysięcy"];

  if (number === 0) return "zero";

  let words = '';

  if (number >= 1000) {
      const thousandsValue = Math.floor(number / 1000);
      if (thousandsValue === 1) {
          words += thousands[1] + ' ';
      } else if (thousandsValue >= 2 && thousandsValue <= 4) {
          words += numberToWords(thousandsValue) + ' ' + thousands[2] + ' ';
      } else {
          words += numberToWords(thousandsValue) + ' ' + thousands[3] + ' ';
      }
      number %= 1000;
  }

  if (number >= 100) {
      words += hundreds[Math.floor(number / 100)] + ' ';
      number %= 100;
  }

  if (number >= 20) {
      words += tens[Math.floor(number / 10)] + ' ';
      number %= 10;
  } else if (number >= 10) {
      words += teens[number - 10] + ' ';
      number = 0;
  }

  words += units[number];

  return words.trim();
}

export const slownie = (number) => {
  const integerPart = Math.floor(number);
  const decimalPart = Math.round((number - integerPart) * 100); // Uzyskaj część dziesiętną

  let result = numberToWords(integerPart);

  if (decimalPart > 0) {
      result += ` ${decimalPart.toString().padStart(2, '0')}/100 PLN`;
  }

  return result;
}

export const sortByTxt = (arr, v) => {
  return arr?.sort((a, b) => {
      const nameA = a[v]?.toUpperCase(); // Ignorowanie wielkości liter
      const nameB = b[v]?.toUpperCase(); // Ignorowanie wielkości liter

      if (nameA < nameB) {
          return -1;
      }
      if (nameA > nameB) {
          return 1;
      }

      return 0; // Jeśli są równe
  });
}

export const sortByNum = (arr, v) => {
  return arr?.sort((a, b) => a[v] - b[v]);
}

export const sortByDate = (arr, key) => {
  return arr.sort((a, b) => new Date(b[key]) - new Date(a[key]));
}

export const podstawa = (tablicaCzynszeAkt, key) => {
  const klucz = key + "_podstawa";
  return tablicaCzynszeAkt[klucz];
}

export const getTextAfterColon = (text) => {
  const index = text.indexOf(": ");
  return index !== -1 ? text.slice(index + 2) : ""; // +2, aby pominąć ": "
};