import React, { useState, useEffect } from "react";
import axios from "axios";

const WspolnotaRozliczenieSMS = () => {

  const handleSend = async () => {
    try {
      await axios.post('/api/sms-test', {
        telefon, sms
      })
    } catch (error) {
      console.error('Błąd podczas dodawania danych:', error);
    }
};

  const [telefon, setTelefon] = useState("");
  const [sms, setSms] = useState("");

  const handleTelefon = e => setTelefon(e.target.value);
  const handleSms = e => setSms( e.target.value);

  // console.log(telefon);
  // console.log(sms);

  return (
    <div className="sms ramka">
      <input 
        value={telefon}
        type="text"
        onChange={handleTelefon}
        placeholder="telefon"
      />
      <input
        value={sms}
        type="text"
        onChange={handleSms}
        placeholder="treść"
      />
      <button
        onClick={handleSend}
      >
        Wyślij
      </button>

    </div>
  )
}

export default WspolnotaRozliczenieSMS;