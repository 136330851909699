import React, { useState, useEffect } from "react";
import { NavLink } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { 
  fetchWspolnoty,
  fetchMieszkancy, 
  fetchUsers,
  fetchSerwis
} from '../../../Redux/Actions.js'; 
import { motion } from 'framer-motion';
import { wstepy } from '../../../functions/motions.js';
import Loader from "../../Loader.js";

import WspolnotyListaTHead from "./WspolnotyListaTHead.js";
import WspolnotyListaTBody from "./WspolnotyListaTBody.js";
import WspolnotyListaWyszukaj from "./WspolnotyListaWyszukaj.js";

const WspolnotyLista = () => {

    const dispatch = useDispatch();

    const wspolnoty = useSelector( state  => state?.wspolnoty?.data);
    
    useEffect( ()=> {
      dispatch(fetchWspolnoty()); 
      dispatch(fetchMieszkancy()); 
      dispatch(fetchUsers());
      dispatch(fetchSerwis());
    },[dispatch]);
    
    const [loaderOn, setLoaderOn] = useState(true);
  
    useEffect( () => {
      const fetchData = async () => {
        if (wspolnoty?.length > 0 ) {
          setLoaderOn(false);
        }
      };
      fetchData();
    },[wspolnoty])
  
    return (
        <motion.div
          className="content"
          variants={wstepy}
          initial="initial"
          animate="animate"
        >
          <Loader stan={loaderOn} />
            <h1 className="nazwa">
                Lista wspólnot
            </h1>
            <div 
              className="buttons"
              style={{width: "100%", justifyContent: "space-between"}}
            >
              <NavLink to="/wspolnoty/dodaj">
                  <button>Dodaj wspólnotę</button>
              </NavLink>
              <WspolnotyListaWyszukaj />
            </div>
            
            <ul 
              className="lista"
              style={{paddingRight: "15px"}}
            >
              <WspolnotyListaTHead />
            </ul>
            <ul className="lista wspolnoty">
              { 
                <WspolnotyListaTBody 
                  wspolnoty={wspolnoty}
                /> 
              }
            </ul>

        </motion.div>
    )
}

export default WspolnotyLista;

