import React, {useEffect, useState} from 'react';

const ImportTransakcjiListaWspolnot = props => {

    const { wspolnoty, wybranaWspolnota, setWybranaWspolnota, clearData } = props;

    const lista = wspolnoty.map( (b,i) => {

        return (
            <option
                key={i}
                value={b.id}
            >
                {b.name}
            </option>
        )
    })

    const handleChange = e  => {
        const id = e.target.value;
        const wspolnota = wspolnoty?.find( w => w?.id == id);
        setWybranaWspolnota( wspolnota );
        clearData();
    }

    return (
        <div 
            className='listaBankow'
        >
            <select
                value={wybranaWspolnota?.id}
                onChange={handleChange}
            >
                <option key={0} value={0}>
                    Wybierz:
                </option>
                { lista }
            </select>
        </div>
    )
}

export default ImportTransakcjiListaWspolnot;