import React, { useState, useEffect } from "react";
import axios from "axios";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faCheck, faXmark, faGear, faFloppyDisk, faCircleLeft } from '@fortawesome/free-solid-svg-icons'
import Tooltip from '@mui/material/Tooltip';
import { useSelector, useDispatch } from 'react-redux';
import {  
    fetchLokale, 
    fetchGrupy, 
    fetchGrupyAkt,
    fetchUdzialyKonta,
    fetchUdzialyKontaAkt
  } from '../../Redux/Actions.js'; 

const MieszkaniecDaneGrupyDodajLokal = props => {

    const { lokale, ulice, setAddLokal, grupaUdzial, wspolnota} = props;

    const wolneGaraze = lokale?.filter( lokal => lokal.rodzaj === "garaż" && lokal.czy_zajety === null );
    const wolneKomorki = lokale?.filter( lokal => lokal?.rodzaj === "komórka" && lokal.czy_zajety === null);
    const wolneStrychy = lokale?.filter( lokal => lokal?.rodzaj === "strych" && lokal.czy_strych === null);

    const dispatch = useDispatch();
    
    const [dodawany, setDodawany] = useState({id: undefined, data: ""});

    const handleData = e => {
        setDodawany( prevState => ({...prevState, data: e.target.value}) )
    }

    const zajmijLokal = async obj => {
        console.log(obj)
        try {
            await axios.post('/api/grupy/dodaj', {
                id_wspolnoty: wspolnota?.id,
                id_user: grupaUdzial.id_user,
                grupa_numer: grupaUdzial.grupa_numer,
                id_lokal: obj.id,    
                data_od: dodawany.data,
                wersja: 1
            }).then( response => {
                axios.post('/api/lokal/zajmij', {
                    id_lokal: obj.id,    
                }).then( response => {
                    dispatch(fetchLokale(wspolnota?.id)); 
                    dispatch(fetchGrupy(wspolnota?.id)); 
                    dispatch(fetchGrupyAkt(wspolnota?.id)); 
                    dispatch(fetchUdzialyKonta(wspolnota?.id));
                    dispatch(fetchUdzialyKontaAkt(wspolnota?.id));
                    setDodawany({id: 0, data: ""});
                });
            })
        } catch (error) {
            console.log(error)
        }  
    }

    const lista = array => {
        return array?.map( (w, i) => {
            const ulicaObj = ulice?.find( u => u?.id == w.ulica);
            const ulicaNazwa = `${ulicaObj.rodzaj} ${ulicaObj.nazwa} ${ulicaObj.numer_budynku}`;

            return (
                <li
                    key={i}
                >
                    <div style={{width: "200px"}}>
                        { ulicaNazwa }
                    </div>
                    <div  style={{width: "55px"}}>
                        <span>{w.numer}</span>
                    </div>
                    <div  style={{width: "35px"}}>
                        {w.metraz > 0 ? `${w.metraz}` : "-"}
                    </div>
                    <div  style={{width: "130px"}}>
                        {
                            dodawany.id === w.id ? (
                                <>
                                    <input
                                        type="date"
                                        value={dodawany.data}
                                        onChange={handleData}
                                    />
                                    <div>
                                        <Tooltip
                                            title="Zapisz"
                                            placement='top'
                                        >
                                            <FontAwesomeIcon 
                                                icon={faFloppyDisk} 
                                                className="pointer"
                                                onClick={()=>zajmijLokal(w)}
                                                style={{margin: "0 1px"}}
                                            />
                                        </Tooltip>
                                        <Tooltip
                                            title="Anuluj"
                                            placement='top'
                                        >
                                            <FontAwesomeIcon 
                                                icon={faCircleLeft} 
                                                className="pointer"
                                                onClick={()=>setDodawany({id: 0, data: ""})}
                                                style={{margin: "0 1px"}}
                                            />
                                        </Tooltip>
                                    </div>
                                </>
                            ) : (
                                <FontAwesomeIcon 
                                    icon={faGear} 
                                    onClick={()=>{setDodawany({id: w.id, data: ""})}}
                                />
                            )
                        }
                    </div>
                </li>
            )
        })
    }


    const ulHead = (
        <ul className="head">
            <li>
                <div style={{width: "200px"}}>Ulica</div>
                <div style={{width: "55px"}}>Nr lokalu</div>
                <div style={{width: "35px"}}>m²</div>
                <div style={{width: "130px"}}></div>
            </li>
        </ul>
    )

    return (
        <div
            className="addLokalBox"
        >
            {
                wolneGaraze?.length > 0 ? (
                    <div className="rodzaj">
                        <h5>
                            Garaże
                        </h5>
                        { ulHead }
                        <ul className="listaLokal">
                            {lista(wolneGaraze)}
                        </ul>
                    </div>
                ) : (<></>)
            }
            {
                wolneKomorki?.length > 0 ? (
                    <div className="rodzaj">
                        <h5>
                            Komórki
                        </h5>
                        { ulHead }
                        <ul className="listaLokal">
                            {lista(wolneKomorki)}
                        </ul>
                    </div>
                ) : (<></>)
            }
            {
                wolneStrychy?.length > 0 ? (
                    <div className="rodzaj">
                        <h5>
                            Strychy
                        </h5>
                        { ulHead }
                        <ul className="listaLokal">
                            {lista(wolneStrychy)}
                        </ul>
                    </div>
                ) : (<></>)
            }
        </div>
    )
}

export default MieszkaniecDaneGrupyDodajLokal;