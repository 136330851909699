import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { formatKonta } from '../../functions/utilities.js';
import { findMieszkanieId } from "../../functions/ulice.js";


const NotaDaty = (props) => {

    const { mieszkaniec, lokale, udzialGrupaNota, datyNoty } = props;

    const ulice = useSelector( state => state?.ulice?.data);
    const grupy = useSelector( state => state?.grupy?.data);
    
    const [grupy_u, setGrupy_u] = useState([]);
    const [mieszkanie, setMieszkanie] = useState([]);

    useState( () => setGrupy_u( grupy?.filter( g => g?.id_user == mieszkaniec?.id_user)), [grupy, mieszkaniec]);

    useEffect( () => {

        const czyMieszkanie = udzialGrupaNota?.czy_mieszkanie_w_grupie;
        const grupa_numer = grupy_u?.filter( g => g?.grupa_numer == udzialGrupaNota?.grupa_numer) ;
        
        const lokaleArr = grupa_numer?.map( g => 
            {

                return ({
                    czy_mieszkanie_w_grupie: czyMieszkanie,
                    grupa_numer: g.grupa_numer,
                    id: g.id,
                    id_lokal: g.id_lokal,
                    rodzaj: lokale?.find( lokal => lokal.id == g.id_lokal)?.rodzaj,
                    id_user: g.id_user,
                    imie_nazwisko: mieszkaniec?.imie_nazwisko,
                    id_wspolnoty: g.id_wspolnoty,
                    data_od: g.data_od,
                    data_do: g.data_do,
                    wersja: g.wersja,
                    udzial: lokale?.find( lokal => lokal.id == g.id_lokal)?.udzial
                })
        })
        
        const mieszkanieLista = [];
        const lokalDane = id => lokale?.find( lokal => lokal.id == id);
        const ulicaDane = id => ulice?.find( ulica => ulica.id == id);
        
        if (czyMieszkanie) {
            const mieszkanieId = findMieszkanieId(udzialGrupaNota, grupy_u, lokale);
            
            const mieszkanie = lokaleArr?.find( lokal => lokal?.id_lokal == mieszkanieId );
            
            const lokal = lokalDane(mieszkanie?.id_lokal);
            const ulica = ulicaDane(lokal?.ulica);
            const klatka = lokal?.klatka !== null && lokal?.klatka !== undefined && lokal?.klatka?.length > 0 ?  ` (klatka ${lokal?.klatka})` : "";
            const metraz = lokalDane(mieszkanie?.id_lokal)?.metraz; 
            const udzial = lokalDane(mieszkanie?.id_lokal)?.udzial;
            
            mieszkanieLista.push({
                ulica,
                lokal: lokal?.numer,
                klatka,
                metraz,
                udzial,
                grupa_numer: mieszkanie?.grupa_numer,
                grupa_id: mieszkanie?.id,
                liczba_domownikow: udzialGrupaNota?.liczba_domownikow,
                wersja: mieszkanie?.wersja
            });
            
            setMieszkanie(mieszkanieLista[0])
        }   
        
    },[udzialGrupaNota])
    
    
    return (
        <>
            <section
                style={{
                    display: 'flex',
                    justifyContent: "left",
                    fontSize: '13px',
                    margin: '10mm 0 0 0'
                }}
            >
                <div
                    style={{
                        marginRight: "30px",
                        width: "110px"
                    }}
                >
                    <span>
                        Sposób zapłaty:<br/>
                        Data sprzedaży:
                    </span>
                </div>
                <div
                    style={{
                        marginRight: "120px"
                    }}
                >
                    <span>
                        przelew<br/>
                        {datyNoty?.dataSprzedazy}<br/>
                    </span>
                </div>
                <div
                    style={{
                        marginRight: "30px",
                        width: "110px"
                    }}
                >
                    <span>
                        Termin zapłaty:<br/>
                        Miesiąc nalicz.:
                    </span>
                </div>
                <div>
                    <span>
                        {datyNoty?.terminZaplaty}<br/>
                        {datyNoty?.miesiacNaliczenia}
                    </span>
                </div>
            </section>
            <section
                style={{
                    display: 'flex',
                    justifyContent: "left",
                    fontSize: '13px',
                    margin: '1mm 0 0 0'
                }}
            >
                <div
                    style={{
                        marginRight: "30px",
                        width: "110px"
                    }}
                >
                    <span>
                        Dotyczy lokalu:
                    </span>
                </div>
                <div
                    style={{
                        marginRight: "120px"
                    }}
                >
                    <span>
                        {`${mieszkanie?.ulica?.rodzaj} ${mieszkanie?.ulica?.nazwa} ${mieszkanie?.ulica?.numer_budynku} / ${mieszkanie?.lokal} ${mieszkanie?.klatka}`}
                    </span>
                </div>

            </section>
            <section
                style={{
                    display: 'flex',
                    justifyContent: "left",
                    fontSize: '13px',
                    margin: '10mm 0 0 0'
                }}
            >
                <div
                    style={{
                        marginRight: "30px"
                    }}
                >
                    <span>
                        Konto bankowe:
                    </span>
                </div>
                <div>
                    <span>
                        {formatKonta(udzialGrupaNota?.konto_bankowe)}
                    </span>
                </div>
            </section>
        </>
                    
    )
}

export default NotaDaty;