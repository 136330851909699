import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';

import '../../../scss/wspolnoty.css';
import '../../../scss/stawki.css';
import GoBackBtn from "../../GoBackBtn.js";

import WspolnotyStawkiLista from './WspolnotyStawkiLista.js'
import Loader from "../../Loader.js";
import { objIsNotEmpty } from "../../../functions/utilities.js";

import { 
  fetchWspolnota, 
  fetchMieszkancy,
  fetchStawki,
  fetchStawkiJednorazowe,
  fetchUdzialyKontaAkt,
  fetchStawkiJednorazoweInd,
  fetchGrupy,
  fetchUlice,
  fetchLokale
} from '../../../Redux/Actions.js'; 

import WspolnotyStawkiAddForm from "./WspolnotyStawkiAddForm.js";
import WspolnotyStawkiJednorazowe from "./WspolnotyStawkiJednorazowe.js";
import WspolnotyStawkiJednorazoweGrupy from './WspolnotyStawkiJednorazoweGrupy.js'

const WspolnotyStawki = () => {

  const queryParams = new URLSearchParams(window.location.search)
  const idw = queryParams.get("id");

  const dispatch = useDispatch();

  const stawkiArch = useSelector( state => state?.stawki?.data);
  const wspolnotaArr = useSelector( state  => state?.wspolnota?.data);
  const mieszkancy = useSelector( state => state?.mieszkancy?.data);
  const udzialyKontaAkt = useSelector( state => state?.udzialyKontaAkt?.data);
  const stawkiJednorazowe = useSelector( state => state?.stawkiJednorazowe?.data );
  const stawkiJednorazoweInd = useSelector( state => state?.stawkiJednorazoweInd?.data);
  const grupy = useSelector( state => state?.grupy?.data);

  const [activeWindow, setActiveWindow] = useState("lista");

  const [wspolnota, setWspolnota] = useState([]);
  const [stawkiAkt, setStawkiAkt] = useState(undefined);
  const [loaderOn, setLoaderOn] = useState(true);

  useEffect( () => setWspolnota(  wspolnotaArr && wspolnotaArr?.length > 0 ? wspolnotaArr[0] : [] ), [wspolnotaArr])

  useEffect( ()=> {
    dispatch(fetchWspolnota(idw)); 
    dispatch(fetchMieszkancy(idw));
    dispatch(fetchStawki(idw));
    dispatch(fetchStawkiJednorazowe(idw));
    dispatch(fetchUdzialyKontaAkt(idw));
    dispatch(fetchStawkiJednorazoweInd(idw));
    dispatch(fetchGrupy(idw));
    dispatch(fetchLokale(idw));
    dispatch(fetchUlice(idw));
  },[dispatch,idw]);

  useEffect( () => {
    if (stawkiArch && stawkiArch?.length > 0)
      setStawkiAkt( stawkiArch[0] );
  } , [stawkiArch]); 

  const [tablica, setTablica] = useState(undefined);

  useEffect(() => {
    if (tablica)
      setLoaderOn(false);
  })

  useEffect( () => {
    const tablica_temp = [];
    if (objIsNotEmpty(stawkiAkt)) {
      const o = stawkiAkt;
      const dodaneKlucze = {};
      for (const klucz in o) {
        if (Object.prototype.hasOwnProperty.call(o, klucz)) {
          const kluczBezRodzaj = klucz.replace('_rodzaj', ''); // Usuń '_rodzaj', jeśli występuje
          const kluczBezCzy = klucz.replace('czy_', '');
          const wartosc = o[klucz];
          const odpowiednikRodzaj = o[`${kluczBezRodzaj}_rodzaj`];
          const odpowiednikRodzaj2 = o[`czy_${kluczBezRodzaj}`];

          if (!dodaneKlucze[kluczBezRodzaj] && !dodaneKlucze[kluczBezCzy]) {
            tablica_temp.push({
              klucz: kluczBezRodzaj,
              wartosc,
              odpowiednikRodzaj,
              odpowiednikRodzaj2
            });
            dodaneKlucze[kluczBezRodzaj] = true;
            dodaneKlucze[odpowiednikRodzaj2] = true;
          }
        }
      }  
    }
    setTablica(tablica_temp)
  },[stawkiAkt]);

  const page = () => {
    switch(activeWindow) {
      case "lista":
        return (
          <WspolnotyStawkiLista
            idw={idw}
            stawkiAkt={stawkiAkt}
            setStawkiAkt={setStawkiAkt}
            tablica={tablica}
            stawkiArch={stawkiArch}
          />
        )
      case "dodaj":
        return (
          <WspolnotyStawkiAddForm 
            idw={idw}
            stawkiAkt={stawkiAkt}
          />
        )
      case "jednorazowe":
        return (
          <WspolnotyStawkiJednorazowe
            wspolnota={wspolnota}
            stawkiJednorazowe={stawkiJednorazowe}
          />
        )
      case "jednorazoweGrupy":
        return (
          <WspolnotyStawkiJednorazoweGrupy
            wspolnota={wspolnota}
            mieszkancy={mieszkancy}
            udzialyKontaAkt={udzialyKontaAkt}
            stawkiJednorazoweInd={stawkiJednorazoweInd}
            grupy={grupy}
          />
        )
      default: return ("");
    }

  }

  const handleLista = () => setActiveWindow("lista");
  const handleDodaj = () => setActiveWindow("dodaj");
  const handleJednorazowe = () => setActiveWindow("jednorazowe");
  const handleJednorazoweGrupy = () => setActiveWindow("jednorazoweGrupy");

  return (
    <div className="content stawki">
        <h1 className="nazwa">
          Stawki wspólnoty { wspolnota?.name }
        </h1>
        <div className="buttons">
          <GoBackBtn />
          <button
            onClick={handleLista}
          >
            Podgląd stawek
          </button>
          <button 
            onClick={handleDodaj}
          >
            Dodaj nowe stawki
          </button>
          <button
            onClick={handleJednorazowe}
          >
            Stawki jednorazowe wspólnoty
          </button>
          <button
            onClick={handleJednorazoweGrupy}
          >
            Stawki jednorazowe grupy
          </button>
        </div>
        {page()}
        <Loader stan={loaderOn}/>
    </div>
  )
}

export default WspolnotyStawki;