import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import { isNumber, zl } from '../../../functions/utilities';
import { setTablicaPrzelewy } from '../../../Redux/Actions';

const MieszkaniecSaldo = props => {

    const { tablicaCzynsze, tablicaPrzelewy_g } = props;

    const [tablicaCzynszeAct, setTablicaCzynszeAct] = useState([]);
    const [tablicaPrzelewyAct, setTablicaPrzelewyAct] = useState([]);

    const [sumaNaleznosci, setSumaNaleznosci] = useState(0);
    const [sumaPrzelewow, setSumaPrzelewow] = useState(0);

    useEffect( () => {
        setTablicaCzynszeAct( tablicaCzynsze?.temp_msc?.length === 1 ? tablicaCzynsze?.temp_msc : tablicaCzynsze?.temp_msc?.slice(0, tablicaCzynsze?.temp_msc?.length-1) )
    },[tablicaCzynsze]);

    useEffect( () => {
        let temp = 0;
        if (tablicaCzynszeAct?.length > 1) {
            for (let i = 0; i < tablicaCzynszeAct.length; i++) {
                temp += tablicaCzynszeAct[i].czynsz_na_msc;

            }
        } else if (tablicaCzynszeAct?.length == 1) {
            temp = tablicaCzynszeAct[0].czynsz_na_msc;
        }
        setSumaNaleznosci(temp)
    },[tablicaCzynszeAct]);
    
    useEffect( ()  => { 
        let temp = 0;
        tablicaPrzelewy_g?.forEach( e => temp += e.kwota);
        setSumaPrzelewow(temp)
    },[tablicaPrzelewy_g]);

    const [saldo, setSaldo] = useState("");

    useEffect( () => {
        setSaldo( sumaPrzelewow - sumaNaleznosci );
    },[sumaNaleznosci, sumaPrzelewow])

    const data = format(new Date(), 'dd-MM-yyyy');

    const opis = () => {
        if (isNumber(saldo)) {
            if (parseFloat(saldo?.toFixed(2)) > 0)
                return `występuje nadpłata w wysokości ${zl(saldo)}.`;
            else if (parseFloat(saldo?.toFixed(2)) == 0)
                return `saldo wynosi 0 zł.`;
            else if (parseFloat(saldo?.toFixed(2)) < 0)
                return `występuje niedopłata w wysokości ${zl(saldo)}.`;
        } else return "";

    }

    return (
        <div className='saldo'>
            <h4>
                Saldo
            </h4>  
            <span>Na dzień {data} na koncie {opis()}</span>
        </div> 
    )
}

export default MieszkaniecSaldo;