import React from "react";
import { zl } from "../../../functions/utilities.js";

const MieszkaniecDaneWodaRozliczenieSuma = props => {
    
    const {  podsumowanie } = props;


    return (
        <div className="suma ramka-inset-shadow">
            <p>
                {`Razem: ${zl(podsumowanie?.zimnaPodsumowanie)} + ${zl(podsumowanie.cieplaPodsumowanie)} = ${zl( podsumowanie?.sumaTotal )} ${ podsumowanie?.sumaTotalOpis }`}
            </p>
        </div>
    )
}

export default MieszkaniecDaneWodaRozliczenieSuma;