import React from "react";



const ZbiorowyWydrukTHead = props => {

    const { listaMsc, visible } = props;

    const kolumnyMiesiace = listaMsc?.map( (e, i) => {
        return (
            <>
                <td style={{border: "1px solid black", padding: "1px 2px"}}>
                    należności
                </td>
                <td style={{border: "1px solid black", padding: "1px 2px"}}>
                    wpłaty
                </td>
                <td style={{border: "1px solid black", padding: "1px 2px"}}>
                    saldo
                </td>
            </>
        )

    })
    
    const thDaty = listaMsc?.map( (e,i) => {
        return (
            <th colSpan={3} style={{textAlign: "center", border: "1px solid black"}}>
                {e}
            </th>
        )
    })

    return (
        <thead>
            <tr key={-1}>
                <th rowSpan={2} style={{border: "1px solid black"}}>
                    Mieszkaniec
                </th>
                <th rowSpan={2} style={{border: "1px solid black"}}>
                    Adres
                </th>
                {
                    <>
                        {
                            visible ? (
                                <>{thDaty}</> ) : (<></>) 

                        }
                        <th colSpan={3} style={{textAlign: "center", border: "1px solid black"}}>
                            Podsumowanie
                        </th>
                    </>
                }
            </tr>
            {
                <tr key={0}>
                    {
                        visible ? (kolumnyMiesiace) : <></>
                    }
                    <td style={{border: "1px solid black"}}>
                        nal. razem
                    </td>
                    <td style={{border: "1px solid black"}}>
                        wpł. razem
                    </td>
                    <td style={{border: "1px solid black"}}>
                        sal. razem
                    </td>
                </tr>
            }
        </thead>
    )
}

export default ZbiorowyWydrukTHead;