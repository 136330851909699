import React, { useRef } from "react";
import WynikiGlosowaniaA4 from "./WynikiGlosowaniaA4";
import WynikiGlosowaniaA4Buttons from "./WynikiGlosowaniaA4Buttons";
                

const PrzegladWydrukModal = props => {

    const { handlePrint, tablicaMieszkancyUdzialy, activeCard, glosowaniePytania, glosowanieOdpowiedzi, setPytanieWydruk, pytanieWydruk, wspolnota, tablica } = props;

    const handleModalClick = (event) => event.stopPropagation();

    const componentRef = useRef();

    const showPrint = () => {
        handlePrint();
        setPytanieWydruk(null);
    }

    return (
        <div
            className="glosowanieWydruk" 
            onClick={showPrint}
        >
            <div className="modal" onClick={handleModalClick}>
                <WynikiGlosowaniaA4
                    componentRef={componentRef}
                    tablicaMieszkancyUdzialy={tablicaMieszkancyUdzialy}
                    activeCard={activeCard}
                    glosowaniePytania={glosowaniePytania}
                    glosowanieOdpowiedzi={glosowanieOdpowiedzi}
                    setPytanieWydruk={setPytanieWydruk}
                    pytanieWydruk={pytanieWydruk}
                    wspolnota={wspolnota}
                    tablica={tablica}
                />
                <WynikiGlosowaniaA4Buttons
                    componentRef={componentRef}
                    showPrint={showPrint}
                />
            </div>
        </div>
    )
}

export default PrzegladWydrukModal;