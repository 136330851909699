import React, { useEffect, useRef, useState } from "react";
import MieszkaniecWydrukNaglowek from "./MieszkaniecWydrukNaglowek.js";
import MieszkaniecWydrukKonto from "./MieszkaniecWydrukKonto.js";
import MieszkaniecWydrukButtons from './MieszkaniecWydrukButtons.js';
import MieszkaniecWydrukStawki from "./MieszkaniecWydrukStawki.js";
import MieszkaniecWydrukTHead from "./MieszkaniecWydrukTHead.js";
import MieszkaniecWydrukTFooter from './MieszkaniecWydrukTFooter.js';
import MieszkaniecWydrukStawkiJednorazowe from "./MieszkaniecWydrukStawkiJednorazowe.js";
import MieszkaniecWydrukStawkiJednorazoweInd from './MieszkaniecWydrukStawkiJednorazoweInd.js';

const MieszkaniecWydruk = props => {

    const { udzialyKonta_a, showPrint, mieszkaniec, tablicaCzynszeAkt, stawkiOst, stawkiJednorazowe, stawkiJednorazoweInd, lokale, ulice, grupy_a, miesiac, grupa_numer } = props;

    const handleModalClick = (event) => event.stopPropagation();

    const componentRef = useRef(null);

    const [licznik, setLicznik] = useState(0)
    const [licznikJednorazowe, setLicznikJednorazowe] = useState(licznik);

    useEffect( () => setLicznikJednorazowe(licznik), [licznik])

    useEffect( () => setLicznik( Object.keys(stawkiOst).filter(key => key.includes("czy_") && stawkiOst[key] == 1)?.length ), [stawkiOst]);

    useEffect( () => setLicznikJednorazowe( Object?.keys(stawkiJednorazowe).filter(key => key.includes("czy") && stawkiJednorazowe[key] == 1).length ) , [stawkiJednorazowe])

    const puste = Array(6).fill(null).map((_, index) => (
        <td
            key={index}
            style={{
                borderLeft: '1px solid black',
                borderRight: '1px solid black',
                padding: '3cm 0 0 0'
            }}
        />
    ));

    return (
        <div className="mieszkaniecWydruk" onClick={showPrint}>
            <div className="modal" onClick={handleModalClick}>
                <div 
                    style={{
                        width: '21cm',
                        height: '29.7cm',
                        backgroundColor: 'white',
                        boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
                        padding: '15mm 15mm',
                        boxSizing: 'border-box',
                        maxWidth: '100%',
                        maxHeight: '100%',
                    }} 
                    ref={componentRef}
                >
                    <MieszkaniecWydrukNaglowek
                        udzialyKonta_a = {udzialyKonta_a}
                        mieszkaniec = {mieszkaniec}
                        lokale = {lokale}
                        ulice = {ulice}
                        grupy_a = {grupy_a}
                        miesiac = {miesiac}
                    />
                    <section
                        style={{
                            margin: '10mm 0 0 0'
                        }}
                    >
                        <table
                            style={{
                                fontSize: '13px',
                                borderCollapse: 'collapse',
                                width: '100%'
                            }}
                        >
                            <MieszkaniecWydrukTHead />
                            <tbody>
                                <MieszkaniecWydrukStawki
                                    tablicaCzynszeAkt={tablicaCzynszeAkt}
                                    stawkiOst={stawkiOst}
                                />
                                <MieszkaniecWydrukStawkiJednorazowe
                                    stawkiJednorazowe={stawkiJednorazowe}
                                    miesiac={miesiac}
                                    licznik={licznik}
                                    setLicznikJednorazowe={setLicznikJednorazowe}
                                />
                                <MieszkaniecWydrukStawkiJednorazoweInd
                                    mieszkaniec={mieszkaniec}
                                    stawkiJednorazoweInd={stawkiJednorazoweInd}
                                    licznikJednorazowe={licznikJednorazowe}
                                    miesiac={miesiac}
                                    grupa_numer={grupa_numer}
                                />
                                <tr>
                                    {puste}
                                </tr>
                                <MieszkaniecWydrukTFooter
                                    tablicaCzynszeAkt={tablicaCzynszeAkt}
                                />
                            </tbody>
                        </table>
                    </section>
                    <MieszkaniecWydrukKonto
                        udzialyKonta_a={udzialyKonta_a}
                    />
                </div>

                <MieszkaniecWydrukButtons
                    showPrint={showPrint}
                    componentRef={componentRef}
                />
            </div>
        </div>
    )
}

export default MieszkaniecWydruk;