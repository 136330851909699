import React from "react";

import { NavLink } from "react-router-dom";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBook, faList, faTableList } from '@fortawesome/free-solid-svg-icons'
import { faUserGroup } from '@fortawesome/free-solid-svg-icons'
import { faHandHoldingDollar } from '@fortawesome/free-solid-svg-icons'
import { faChartPie, faFileInvoiceDollar } from '@fortawesome/free-solid-svg-icons'
import { faAddressCard } from '@fortawesome/free-solid-svg-icons'

import { saldoWykrzyknik } from "../../../functions/tablice.js";

const WspolnotyListaTBody = ( props ) => {

    const { wspolnoty, saldo } = props;

    const wiersz = wspolnoty?.map( (item,i) => {
                  
      let czy_saldo = saldo?.filter( s => s.id_wspolnoty == item.id)[0]?.saldo < 0 ? true : false;

      return (
        <li 
          key={item.id} 
          className={ i % 2 === 0 ? "g": ""} 
          style={{minHeight: "40px", display: "flex", justifyContent: "space-between"}}
        >
          <div style={{flex: "initial", width: "50px"}}>{item.id}</div>
          <div style={{flex: "initial", width: "400px"}}>{`${item.name}`}</div>
          <div style={{padding: "0 0 0 10px", flex: "initial", width: "120px"}}>{item.nip}</div>
          <div style={{flex: "initial", width: "250px"}}>{`${item.ulica} ${item.nr_domu ? item.nr_domu : ""}`}</div>
          <div className="central" style={{flex: "initial", width: "100px"}}>
            <NavLink 
              to={`/wspolnoty/dane?id=${item.id}`} 
              exact="true"
            >
              <FontAwesomeIcon 
                icon={faAddressCard} 
                size="lg"
              />
            </NavLink>
          </div>
          <div className="central" style={{flex: "initial", width: "100px"}}>
            <NavLink 
              to={`/wspolnoty/stawki/?id=${item.id}`} 
              exact="true"
            >
              <FontAwesomeIcon 
                icon={faList} 
                size="lg"
              />
            </NavLink>
          </div>
          <div className="central" style={{flex: "initial", width: "100px"}}>
            <NavLink 
              to={`/wspolnoty/rozliczenia/?id=${item.id}`} 
              exact="true" 
              style={{ "textDecoration": 'none'}} 
            >
              <FontAwesomeIcon 
                icon={faHandHoldingDollar} 
                size="lg"
              />
              { czy_saldo ? saldoWykrzyknik() : ""}
            </NavLink>
          </div>
          <div className="central" style={{flex: "initial", width: "100px"}}>
            <NavLink 
              to={`/wspolnoty/raportZbiorowy/?id=${item.id}`} 
              exact="true" 
              style={{ "textDecoration": 'none'}} 
            >
              <FontAwesomeIcon 
                icon={faBook} 
                size="lg"
              />
            </NavLink>
          </div>
          <div className="central" style={{flex: "initial", width: "100px"}}>
            <NavLink 
              to={`/mieszkancy?idw=${item.id}`} 
              exact="true" 
            >
              <FontAwesomeIcon 
                icon={faUserGroup} 
                size="lg"
              />
            </NavLink>
          </div>
          <div className="central" style={{flex: "initial", width: "100px"}}>
            <NavLink 
              to={`/wspolnoty/grupy/?id=${item.id}`} 
              exact="true" 
              style={{ "textDecoration": 'none'}} 
            >
              <FontAwesomeIcon 
                icon={faTableList} 
                size="lg"
              />
            </NavLink>
          </div>
          <div className="central" style={{flex: "initial", width: "100px"}}>
            <NavLink
              to={`/noty?idw=${item.id}`}
              exact="true"
            >
              <FontAwesomeIcon 
                icon={faFileInvoiceDollar}
                size="lg"
              />
            </NavLink>
          </div>
          <div className='central' style={{flex: "initial", width: "100px"}}>
            {/* <NavLink  
              to={`/wspolnoty/udzialy?id=${item.id}`}  
            > */}
              <FontAwesomeIcon 
                icon={faChartPie} 
                style={{color: "#307bfd", cursor: "not-allowed"}} 
                size="lg"
              />
            {/* </NavLink> */}
          </div>
        </li>
      )
    })

    return (
      wiersz
    )
}

export default WspolnotyListaTBody;