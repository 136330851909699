import React, { useState, useContext, useEffect} from "react";
// import { motion } from 'framer-motion' 
import axios from "axios";
import { useDispatch } from "react-redux";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouse } from '@fortawesome/free-solid-svg-icons';
import { faWarehouse } from '@fortawesome/free-solid-svg-icons';
import { faAccusoft } from '@fortawesome/free-brands-svg-icons';
import { faDoorClosed, faPencil, faChevronLeft, faCheck } from '@fortawesome/free-solid-svg-icons';
import { dataFormat } from "../../functions/dataFormat";
import { AuthContext } from "../../Context/AuthProvider";

import { 
    fetchGrupy, 
    fetchGrupyAkt, 
    fetchGrupyAll,
    fetchUdzialyKonta,
    fetchUdzialyKontaAll,
    fetchUdzialyKontaAkt,
    fetchLokale
  } from '../../Redux/Actions.js'; 

const MieszkaniecDaneGrupyLokale = props => {

    const { role} = useContext(AuthContext);

    const { tytul, obj, editDataOd, setEditDataOd, setEditDataDo, editDataDo, editMieszkancy, setEditMieszkancy, grupaUdzial, editKonto } = props;

    const dispatch = useDispatch();

    const ikona = (tytul) => {
        switch (tytul){
            case "Mieszkanie": 
                return (<FontAwesomeIcon icon={faHouse} />)
            case "Garaże":
                return (<FontAwesomeIcon icon={faWarehouse} />);
            case "Komórki":
                return (<FontAwesomeIcon icon={faDoorClosed} />);
            case "Strychy":
                return (<FontAwesomeIcon icon={faAccusoft} />);
            default:
                return "";
        }
    }

    const handleDataOd = e => setEditDataOd( prevState =>({...prevState, dataOd: e.target.value} ));
    const handleDataDo = e => setEditDataDo( prevState =>({...prevState, dataDo: e.target.value} )); 

    const handlePencilOd = id => {
        setEditDataOd({
            show: true,
            id: id,
            dataOd: ""
        })
    }

    const handlePencilDo = id => {
        setEditDataDo({
            show: true,
            id: id,
            dataDo: ""
        })
    }

    const saveOd = async () => {
        try {
            await axios.post('/api/grupy/edytuj_data_od', {
                data_od: editDataOd.dataOd, 
                id: editDataOd.id
            }).then( response => {
                dispatch(fetchGrupy(grupaUdzial.id_wspolnoty));
                dispatch(fetchGrupyAkt(grupaUdzial.id_wspolnoty));
                dispatch(fetchGrupyAll(grupaUdzial.id_wspolnoty));
                setEditDataOd({id: 0, show: false, dataOd: ""});
            })
        } catch (error) {
            console.log(error);
        }
    }

    const handleSaveDataOd = id => saveOd();

    const handleSaveDataDo = id => saveDo(id);   

    const saveDo = async (id_lokal) => {
        try {
            await axios.post('/api/grupy/edytuj_data_do', {
                data_do: editDataDo.dataDo, 
                id: editDataDo.id
            }).then( response => {
                // if (editDataDo.dataDo) {

                // }
                dispatch(fetchGrupy(grupaUdzial.id_wspolnoty));
                dispatch(fetchGrupyAkt(grupaUdzial.id_wspolnoty));
                dispatch(fetchGrupyAll(grupaUdzial.id_wspolnoty));
                setEditDataDo({id: 0, show: false, dataDo: ""});
                try {
                    axios.post('/api/lokal/zwolnij', {
                        id_lokal: id_lokal
                    }).then(response => {
                        dispatch(fetchLokale(grupaUdzial.id_wspolnoty));
                    })
                } catch (error) {
                    console.log(error)
                }

            })
        } catch (error) {
            console.log(error);
        }
    }

    const handleAnulujOd = id => setEditDataOd({id: 0, show: false, dataOd: ""});
    const handleAnulujDo = id => setEditDataDo({id: 0, show: false, dataDo: ""});

    const handlePencilMieszkancy = () => setEditMieszkancy( prevState =>({...prevState, visible: true} ));
    const handleMieszkancyLiczba = (e) => setEditMieszkancy( prevState => ({...prevState, value: e}));
    const handleMieszkancyData = e => setEditMieszkancy( prevState => ({ ...prevState, data: e }) );

    const handleSaveMieszkaniec = () => {

        axios.post('/api/grupy_konta_udzialy/dodaj', {
            id_wspolnoty: grupaUdzial.id_wspolnoty,
            id_user: grupaUdzial.id_user,
            grupa_numer: grupaUdzial.grupa_numer,
            konto_bankowe: editKonto.konto.replace(/\s+/g, ''),
            liczba_domownikow: editMieszkancy?.value,
            data_od: editMieszkancy?.data,
            czynsz_od: grupaUdzial?.czynsz_od,                
            czy_mieszkanie_w_grupie: grupaUdzial?.czy_mieszkanie_w_grupie
        }).then( response => {
            dispatch(fetchUdzialyKonta(grupaUdzial.id_wspolnoty));
            dispatch(fetchUdzialyKontaAkt(grupaUdzial.id_wspolnoty));
            dispatch(fetchUdzialyKontaAll(grupaUdzial.id_wspolnoty));        
            setEditMieszkancy( prevState =>({...prevState, visible: false} ) )
        })

    }

    const lista = obj.map( (el,i) => {
        return (
            <div key={i} className="lokal">
                <ul className='lokale lista'>
                    <li>
                        <div className="w150">{`Adres:`}</div>
                        <div>{`${el?.ulica?.rodzaj} ${el?.ulica?.nazwa} ${el?.ulica?.numer_budynku} / ${el?.lokal} ${el?.klatka}`}</div>
                    </li>
                    {
                        role === "admin" ? (
                            <li>
                                <div className="w150">Id lokalu:</div>
                                <div>{el.id_lokal}</div>
                            </li>
                        ) : (<></>)
                    }

                    <li>
                        <div className="w150">Powierzchnia:</div>
                        <div>{`${el?.metraz} m²`}</div>
                    </li>
                    <li>
                        <div className="w150">Udział:</div>
                        <div>{`${el?.udzial !== null ? el?.udzial : "brak danych" }`}</div>
                    </li>
                    {
                        tytul === "Mieszkanie" ? (
                            <>
                                {
                                    editMieszkancy?.visible ? (
                                        <li>
                                            <div  className="w150">Liczba mieszkańców:</div>
                                            <input 
                                                type="number"
                                                value={editMieszkancy?.value}
                                                onChange={(e)=>handleMieszkancyLiczba(e.target.value)}
                                                style={{width: "60px", marginRight: "10px"}}
                                            />
                                            <input 
                                                type="date"
                                                value={editMieszkancy?.data}
                                                onChange={(e)=>handleMieszkancyData(e.target.value)}
                                            />
                                            <div>
                                                <FontAwesomeIcon 
                                                    icon={faChevronLeft} 
                                                    onClick={()=>setEditMieszkancy( prevState =>({...prevState, visible: false} ))}
                                                    className="pointer"
                                                />
                                                <FontAwesomeIcon 
                                                    icon={faCheck} 
                                                    onClick={handleSaveMieszkaniec}
                                                    className="pointer"
                                                />
                                            </div>
                                        </li>
                                    ) : (
                                        <li>
                                            <div  className="w150">Liczba mieszkańców:</div>
                                            <div>{el?.liczba_domownikow}</div>
                                            <div>
                                                <FontAwesomeIcon 
                                                    icon={faPencil} 
                                                    onClick={handlePencilMieszkancy}    
                                                    className="pointer"
                                                />
                                            </div>
                                        </li>
                                    )
                                }
                            </>
                        ) : ""
                    }
                    {
                        role === "admin" ? (
                            <>
                                {
                                    editDataOd?.show && editDataOd?.id == el.grupa_id ? (
                                        <li>
                                            <div className="edit-data-od">
                                                <div>Data zasiedlenia:</div>
                                                <input 
                                                    type="date"
                                                    value={editDataOd?.dataOd}
                                                    onChange={handleDataOd}
                                                />
                                                <FontAwesomeIcon 
                                                    icon={faChevronLeft} 
                                                    onClick={()=>handleAnulujOd()}
                                                    className="pointer"
                                                />
                                                <FontAwesomeIcon 
                                                    icon={faCheck} 
                                                    onClick={()=>handleSaveDataOd(el.grupa_id)}
                                                    className="pointer"
                                                />
                                            </div>
                                        </li>            
                                    ) : (
                                        <li>
                                            <div className="w150">Data zasiedlenia:</div>
                                            <div style={{fontWeight: 600}}>{dataFormat(el?.data_od)}</div>
                                            <div>
                                                <FontAwesomeIcon 
                                                    icon={faPencil} 
                                                    onClick={()=>handlePencilOd(el.grupa_id)}    
                                                    className="pointer"
                                                />
            
                                            </div>
                                        </li>
                                    )
                                }
                                {
                                    editDataDo?.show && editDataDo?.id == el.grupa_id ? (
                                        <li>
                                            <div className="edit-data-od">
                                                <div>Data wysiedlenia:</div>
                                                <input 
                                                    type="date"
                                                    value={editDataDo?.dataDo}
                                                    onChange={handleDataDo}
                                                />
                                                <FontAwesomeIcon 
                                                    icon={faChevronLeft} 
                                                    onClick={()=>handleAnulujDo()}
                                                    className="pointer"
                                                />
                                                <FontAwesomeIcon 
                                                    icon={faCheck} 
                                                    onClick={()=>handleSaveDataDo(el.id_lokal)}
                                                    className="pointer"
                                                />
                                            </div>
                                        </li>
                                    ) : (
                                        <li>
                                            <div className="w150">Data wysiedlenia:</div>
                                            <div style={{fontWeight: 600}}>{dataFormat(el?.data_do)}</div>
                                            <div>
                                                <FontAwesomeIcon 
                                                    icon={faPencil} 
                                                    onClick={()=>handlePencilDo(el.grupa_id)}    
                                                    className="pointer"
                                                />
                                            </div>
                                        </li>
                                    )
                                }
                            </>

                        ) : (
                            <></>
                        )
                    }
                </ul>
            </div>
        )
    });

    const liczbaLokali = tytul === "Mieszkanie" ? (` ${tytul}`) : (` ${tytul} (${obj?.length})`);

    return (
        <div className="lokale">
            <h4>{ikona(tytul)} {liczbaLokali} </h4>
            { lista }
        </div>
    )
}

export default MieszkaniecDaneGrupyLokale;