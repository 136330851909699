import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import WspolnotyEditForm from "./WspolnotyEditForm";
import WspolnotyUlice from "./Ulice/WspolnotyUlice";
import WspolnotyLokale from "./Lokale/WspolnotyLokale";
import GoBackBtn from "../GoBackBtn";
import { 
    fetchWspolnota, 
    fetchUlice,
    fetchLokale,
    fetchBanki,
  } from '../../Redux/Actions.js'; 


const WspolnotyDane = () => {

    const queryParams = new URLSearchParams(window.location.search)
    const idw = queryParams.get("id");
    const dispatch = useDispatch();

    const [wspolnota, setWspolnota] = useState(undefined);

    const wspolnotaArr = useSelector( state  => state?.wspolnota?.data);
    const ulice = useSelector( state => state?.ulice?.data);
    const lokale = useSelector( state => state?.lokale?.data);
    const banki = useSelector( state => state?.banki?.data );
        
    useEffect( () => setWspolnota(wspolnotaArr && wspolnotaArr?.length > 0 ? wspolnotaArr[0] : []),[wspolnotaArr]);

    useEffect( ()=> {
        dispatch(fetchWspolnota(idw));  
        dispatch(fetchUlice(idw));
        dispatch(fetchLokale(idw));
        dispatch(fetchBanki());
    },[dispatch, idw]);
    
    const [wybranaUlica, setWybranaUlica] = useState([]);

    useEffect( () => setWybranaUlica( ulice[0]?.id ), [ulice])

    console.log(wspolnotaArr)

    return (
        <div className="content">
            <h1 className="nazwa">
                { wspolnota?.name }
            </h1>
            <GoBackBtn/>
            <div className="wspolnotyDane">
                <WspolnotyEditForm 
                    wspolnota={wspolnota}
                    banki={banki}
                />
                <WspolnotyUlice
                    wspolnota={wspolnota}
                    wybranaUlica={wybranaUlica}
                    setWybranaUlica={setWybranaUlica}
                    ulice={ulice}
                />
                <WspolnotyLokale
                    wspolnota={wspolnota}
                    wybranaUlica={wybranaUlica}
                    lokale={lokale}
                />
            </div>


        </div>
    )
}

export default WspolnotyDane;