import React, { useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { AuthContext } from '../../Context/AuthProvider';
import { fetchSerwis } from '../../Redux/Actions';
import { dataCzas } from '../../functions/dataFormat';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import DialogYesNo from '../DialogYesNo';
import { czyAktywnySerwis } from '../../functions/utilities';

const Tablica = () => {

    const [tresc, setTresc] = useState("");
    const [czyAktywny, setCzyAktywny] = useState(false);
    const [open, setOpen] = useState(false);
    const [removeId, setRemoveId] = useState(0);
    
    const dispatch = useDispatch();

    const { userId } = useContext(AuthContext);

    useEffect( () => {
        dispatch(fetchSerwis())
    },[dispatch]);

    const serwisy = useSelector( state => state?.serwis?.data);

    useEffect( () => {
        const odp = czyAktywnySerwis(serwisy);
        setCzyAktywny( odp )
        if (odp)
            setTresc("");        
    },[serwisy]);

    const handleTresc = e => setTresc(e.target.value);

    const add = async () => {
        try {
            const response = await axios.post('/api/serwis/dodaj', {
                tresc, 
                user: userId
            });
            if (response.status === 200) {
                console.log('Tablica została opublikowana.');
                dispatch(fetchSerwis());
            }
            } catch (error) {
                console.error('Błąd podczas publikacji tablicy:', error);
            }
    }

    const remove = (id) => {
        try {
          axios.post('/api/serwis/usun', {
              id: id
          }).then( response => {
            dispatch(fetchSerwis());  
          })
        } catch (error) {
          console.log(error)
      }      
    }
    
    const historiaLista = serwisy && serwisy?.map( (s, i) => (
        <li key={i}>
            <div>
                {s.id}
            </div>
            <div>
                {s.tresc}
            </div>
            <div>
                {dataCzas(s.data_utworzenia)}
            </div>
            <div>
                {s.data_do ? dataCzas(s.data_do) : <span style={{fontWeight: "600"}}>aktywny</span>}
            </div>
            <div>
                {s.data_do ? <></> : <FontAwesomeIcon icon={faTrash} className='pointer' onClick={()=>handleTrash(s.id)}/>}
            </div>
        </li>
    ));

    const handleTrash = id => {
        setRemoveId(id)
        setOpen(true);
    }

    return (
        <div className='content'>
            <div className="serwis">
                <div className='formularz ramka'>
                    <label
                        htmlFor="tresc"
                    >Treść komunikatu</label>
                    <input 
                        type="text"
                        id="tresc"
                        value={tresc}
                        onChange={handleTresc}
                    />
                    <button 
                        onClick={add}
                        style={ czyAktywny || tresc.length === 0 ? {cursor: "not-allowed"} : {} }
                        disabled={ czyAktywny ? true : false}
                    >
                        Publikuj
                    </button>
                    { czyAktywny ? <span>Nie można dodać nowej tablicy, gdy aktywna jest poprzednia.</span> : <></>}
                </div>

                <div className='historia ramka'>
                    <ul className='lista'>
                        <li className='head'>
                            <div>
                                ID
                            </div>
                            <div>
                                Treść
                            </div>
                            <div>
                                Data publikacji
                            </div>
                            <div>
                                Data wycofania
                            </div>
                            <div>

                            </div>
                        </li>
                        {historiaLista}
                    </ul>
                </div>
            </div>
            <DialogYesNo 
                open={open}
                setOpen={setOpen}
                message={"Czy na pewno chcesz wycofać tę tablicę serwisową?"}
                f={remove}
                id={removeId}
            />
        </div>
    )
}

export default Tablica;