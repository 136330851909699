import React from 'react';



const MieszkancyUliceBox = props => {

    const { ulice, ulicaId, setUlicaId, setMieszkaniec,setUser, setGrupaUdzial, setAddGrupe, setWybranyOdczyt } = props;

    
    
    const uliceLista = ulice?.map( (u, i) => {

        return (
            <div 
                className= {u.id == ulicaId ? 'ulicaBtn pointer active' : 'ulicaBtn pointer' } 
                onClick={()=>{
                    setUlicaId(u.id)
                    setMieszkaniec(undefined);
                    setGrupaUdzial(undefined);
                    setUser(undefined);
                    setAddGrupe(false);
                    setWybranyOdczyt(undefined)
                }}
                key={i}
            >
                {`${u.nazwa} ${u.numer_budynku}`}
            </div>
        )
    })

    return (
        <div className='mieszkancyUlice'>
            <h5>
                Wybierz ulicę
            </h5>
            <div className='box'>
                {uliceLista}
                <div 
                    className= {ulicaId === -1 ? 'ulicaBtn pointer active' : 'ulicaBtn pointer' }
                    onClick={()=>{
                        setUlicaId(-1)
                        setMieszkaniec(undefined);
                        setGrupaUdzial(undefined);
                        setUser(undefined);
                        setAddGrupe(false);
                    }}
                >
                    {`Mieszkańcy bez grupy`}
                </div>
            </div>
        </div>
    )
}

export default MieszkancyUliceBox;