import React from "react";
import { zl } from "../../../functions/utilities";

const WspolnotyStawkiJednorazoweGrupyPodsumowanie = props => {

    const { podsumowanie } = props;

    const lista = podsumowanie?.map( (p, i) => (
        <li>
            <div>
                {p.msc}
            </div>
            <div>
                {p.opis}
            </div>
            <div>
                {zl(p.kwota)}
            </div>
        </li>
    ) )

    return (
        <ul 
            className="lista ramka-inset-shadow"
        >
            <li>
                <div>
                    Miesiąc / dzień
                </div>
                <div>Opis</div>
                <div>
                    Suma
                </div>
            </li>
            {lista}
        </ul>
    )

}

export default WspolnotyStawkiJednorazoweGrupyPodsumowanie;
