import React, { useEffect, useState } from "react";
import axios from "axios";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faGear, faCircleLeft, faFloppyDisk } from '@fortawesome/free-solid-svg-icons'
import { fetchUlice } from "../../../Redux/Actions";
import { useDispatch } from 'react-redux';

const WspolnotyUliceLista = props => {

    const { ulice, setActiveWindow, wybranaUlica, setWybranaUlica, idw } = props;

    const dispatch = useDispatch();

    const [edytowanaUlica, setEdytowanaUlica] = useState(0);
    const [edytowanaNazwa, setEdytowanaNazwa] = useState("");
    const [edytowanyNumer, setEdytowanyNumer]= useState("");

    const handleWybranaUlica = e => {
        setWybranaUlica(e);
    }

    useEffect( () => {
        const ulica = ulice?.find( u => u?.id == edytowanaUlica);
        setEdytowanaNazwa( ulica?.nazwa );
        setEdytowanyNumer(ulica?.numer_budynku);
    },[ulice, edytowanaUlica])

    const zapiszUlice = async id => {

        await axios.post('/api/ulica/edytuj/', {
            id: id,
            nazwa: edytowanaNazwa,
            numer: edytowanyNumer
          }).then( (res) => {
            setEdytowanaUlica(0);
            dispatch(fetchUlice(idw)); 
          }).catch( err => {
            console.log(err);
          })
    }
    
    const listaUlice = ulice?.map ( (ulica, i) => (
        edytowanaUlica == ulica?.id ? (
            <li key={i} >
                <div>
                    <input
                        type="text"
                        value={edytowanaNazwa}
                        className="ulica"
                        onChange={(e)=>setEdytowanaNazwa(e.target.value)}
                    />
                </div>
                <div>
                    <input
                        type="text"
                        value={edytowanyNumer}
                        onChange={(e)=>setEdytowanyNumer(e.target.value)}
                    />
                </div>
                <div style={{"textAlign": "right"}}>
                    <FontAwesomeIcon 
                        icon={faFloppyDisk} 
                        className="pointer"
                        onClick={()=>zapiszUlice(ulica?.id)}
                        style={{margin: "0 5px"}}
                    />
                    <FontAwesomeIcon 
                        icon={faCircleLeft} 
                        className="pointer"
                        onClick={()=>setEdytowanaUlica(0)}
                        style={{margin: "0 5px"}}
                    />
                </div>
            </li>
        ) : (
            <li 
                key={i} 
                onClick={ ( ) => handleWybranaUlica(ulica.id) } 
                className="pointer"
                style={ wybranaUlica == ulica.id ? {"backgroundColor": "var(--podswietlenie)"} : {} }
            >
                <div>
                    { ulica.rodzaj + " " + ulica.nazwa + " " + ulica.numer_budynku }
                </div>
                <div 
                    style={{"textAlign": "right"}}
                    className="pointer"
                    onClick={()=>setEdytowanaUlica(ulica?.id)}
                >
                    <FontAwesomeIcon icon={faGear} />
                </div>
            </li>
        )
    ))

    const handleClick = () => setActiveWindow("dodaj");

    return (
        <div className="wspolnotyUliceLista">
            <div className="naglowek">
                <h3>
                    Lista ulic 
                </h3>
                <FontAwesomeIcon 
                    icon={faPlus}
                    className="pointer" 
                    onClick={handleClick}
                />
            </div>
            { listaUlice && listaUlice?.length > 0 ? (
                    <ul className="uliceLista">
                        {listaUlice}
                    </ul>
                ) : (<h4>Brak ulic</h4>) 
            }            
        </div>
    )
}

export default WspolnotyUliceLista;