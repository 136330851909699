import React, { useEffect, useState } from "react";
import { dataFormat, strefa } from "../../../functions/dataFormat";

const MieszkaniecGlosowanieListaKart = props => {

    const { glosowanieKarty, wybranaKarta, setKarta, glosowanieOdpowiedzi, mieszkaniec } = props;

    const [aktywneKarty, setAktywneKarty] = useState([]);
    const [archiwumKarty, setArchiwumKarty] = useState([]);
    
    const today = new Date();

    useEffect( () => {
        const aktywne = glosowanieKarty?.filter( g => strefa(g.data_od) <= today && strefa(g.data_do) >= today && !glosowanieOdpowiedzi.some(obj => obj.id_karty == g.id && obj.id_user == mieszkaniec?.id_user));
        setAktywneKarty(aktywne)
    },[glosowanieKarty, glosowanieOdpowiedzi]);

    useEffect( () => {
        const archiwalne = glosowanieKarty?.filter( g => today > strefa(g.data_do) || glosowanieOdpowiedzi.some(obj => obj.id_karty == g.id && obj.id_user == mieszkaniec?.id_user));
        setArchiwumKarty(archiwalne);
    },[glosowanieKarty, glosowanieOdpowiedzi]);
    
    const handleClick = e => setKarta( glosowanieKarty?.find( g => g?.id == e.currentTarget.id ) );

    const listaAktywne = (
        aktywneKarty?.map( (karta, i) => (
            <div 
                className={ wybranaKarta.id == karta.id ? "karta kartaNaglowek ramka ramka-border-shadow ramka-wnetrze-ramki pointer active" : "karta kartaNaglowek ramka ramka-border-shadow ramka-wnetrze-ramki pointer" }
                id={karta.id}
                onClick={handleClick}
                key={i}
            >
                {karta?.numer}
            </div>
        ) )
    )

    const listaArchiwum = (
        archiwumKarty?.map( (karta, i) => (
            <div 
                className={ wybranaKarta.id == karta.id ? "karta kartaNaglowek ramka ramka-border-shadow ramka-wnetrze-ramki pointer active" : "karta kartaNaglowek ramka ramka-border-shadow ramka-wnetrze-ramki pointer" }
                id={karta.id}
                onClick={handleClick}
                key={i}
            >
                {karta?.numer}
            </div>
        ) )
    )

    return (
        <div className="lista-kart ramka-br-light">
            <div className="lista-aktywne">
                <h4>Lista aktywnych głosowań:</h4>
                { listaAktywne }
            </div>
            <div className="lista-archiwum">
                <h4>Archiwum:</h4>
                { listaArchiwum }
            </div>

        </div>
    )
}

export default MieszkaniecGlosowanieListaKart;