import React, { useEffect, useState } from "react";


const RozliczenieWydrukA4THead = props => {

    const { stawki_w, stawkiJednorazowe_w, czynszeMsc, ind } = props;

    function containsJednorazowaKwota(czynszeMsc) {
        return czynszeMsc.some(czynszObj => 
          czynszObj.czynsz && 'jednorazowaInd_kwota' in czynszObj.czynsz
        );
      }

      const indCols = ind?.map( (e,i) => 
        (
          <th style={{border: "1px solid black", maxWidth: "150px"}}>{e}</th>
        )
      )

    return (
      <thead>
        <tr key={0}>
          <th style={{border: "1px solid black"}}>Mieszkaniec</th>
          <th style={{border: "1px solid black"}}>Lokal</th>
            { stawki_w?.czy_eksploatacja == 1 ? ( <th  style={{border: "1px solid black"}}>eks.</th> ) : ""   }
            { stawki_w?.czy_garaz == 1 ? ( <th  style={{border: "1px solid black"}}>gar.</th> ) : ""}
            { stawki_w?.czy_strych == 1 ? ( <th  style={{border: "1px solid black"}}>str.</th> ) : "" }
            { stawki_w?.czy_komorka ==  1 ? ( <th  style={{border: "1px solid black"}}>kom.</th> ) : "" }
            { stawki_w?.czy_utrzymanie_czystosci == 1 ? ( <th  style={{border: "1px solid black"}}>utr.</th> ) : ""} 
            { stawki_w?.czy_wywoz_smieci == 1 ? ( <th  style={{border: "1px solid black"}}>wyw.</th> ) : ""} 
            { stawki_w?.czy_fundusz_remontowy == 1 ? ( <th  style={{border: "1px solid black"}}>rem.</th> ) : ""} 
            { stawki_w?.czy_konserw_domofonu == 1 ? ( <th  style={{border: "1px solid black"}}>dom.</th> ) : ""} 
            { stawki_w?.czy_konserw_windy == 1 ? ( <th  style={{border: "1px solid black"}}>win.</th> ) : ""} 
            { stawki_w?.czy_opl_za_adm == 1 ? ( <th  style={{border: "1px solid black"}}>adm.</th> ) : ""} 
            { stawki_w?.czy_abonament_cw == 1 ? ( <th  style={{border: "1px solid black"}}>abo.</th> ) : ""} 
            { stawki_w?.czy_co == 1 ? ( <th  style={{border: "1px solid black"}}>c.o.</th> ) : ""} 
            { stawki_w?.czy_energia_el_lokalu == 1 ? ( <th  style={{border: "1px solid black"}}>ene.</th> ) : ""} 
            { stawki_w?.czy_ryczalt_gaz == 1 ? ( <th  style={{border: "1px solid black"}}>gaz </th>) : ""} 
            { stawki_w?.czy_zagosp_terenu == 1 ? ( <th  style={{border: "1px solid black"}}>zag.</th> ) : ""} 
            { stawki_w?.czy_antena == 1 ? ( <th  style={{border: "1px solid black"}}>ant.</th> ) : ""} 
            { stawki_w?.czy_ciepla_woda == 1 ? ( <th  style={{border: "1px solid black"}}>cie.</th> ) : ""} 
            { stawki_w?.czy_zimna_woda == 1 ? ( <th  style={{border: "1px solid black"}}>zim.</th> ) : ""} 
            { stawkiJednorazowe_w?.dod_1_czy == 1 ? ( <th  style={{border: "1px solid black"}}>{stawkiJednorazowe_w?.dod_1_opis}</th> ) : ""} 
            { stawkiJednorazowe_w?.dod_2_czy == 1 ? ( <th  style={{border: "1px solid black"}}>{stawkiJednorazowe_w?.dod_2_opis}</th> ) : ""} 
            { stawkiJednorazowe_w?.dod_3_czy == 1 ? ( <th  style={{border: "1px solid black"}}>{stawkiJednorazowe_w?.dod_3_opis}</th> ) : ""} 
            {/* { containsJednorazowaKwota(czynszeMsc) ? ( <th  style={{border: "1px solid black"}}>indywidualne<br/>naliczenie</th> ) : "" } */}
            {
              containsJednorazowaKwota(czynszeMsc) ? (
                <>
                  {indCols}
                </>
              ) : <></>
            } 
          <th  style={{border: "1px solid black"}}>Suma </th>
        </tr>
      </thead>
    )

}

export default RozliczenieWydrukA4THead;