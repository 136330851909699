import React from 'react';

const HistoriaTransakcjiTHead = () => {

    return (
        <thead>
            <tr>
                <th>
                    Nazwa pliku
                </th>
                <th>
                    ID
                </th>
                <th style={{ maxWidth: "120px" }}>
                    Operacja
                </th>
                <th style={{ minWidth: "80px" }}>
                    Data
                </th>
                <th>
                    Mieszkaniec / nadawca/ odbiorca
                </th>
                <th>
                    Kwota
                </th>
                <th style={{ maxWidth: "250px" }}>
                    Tytuł
                </th>
                <th>
                    ID user
                </th>
                <th style={{ textAlign: "center" }}>
                    Grupa numer
                </th>
                <th></th>
            </tr>
        </thead>
    )
}

export default HistoriaTransakcjiTHead;