import React, { useEffect, useState } from "react";
import { useDispatch } from 'react-redux';
import axios from "axios";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash, faFileArrowDown, faTrash, faPencil } from '@fortawesome/free-solid-svg-icons';
import DialogYesNo from "../DialogYesNo";
import { dataFormat2 } from "../../functions/dataFormat";
import { zl } from "../../functions/utilities";
import { fetchStawkiInd } from "../../Redux/Actions";
import { translate } from "../../functions/dictionary";


const MieszkaniecDaneStawkiIndList = props => {

    const { grupaUdzial, stawki_w, stawkiInd_g, stawkaId, setStawkaId } = props;
    const dispatch = useDispatch(); 
    let listaWplat = [];
    const [open, setOpen] = useState(false);
    const [trashId, setTrashId] = useState(0);

    const [wybranaStawka, setWybranaStawka] = useState(undefined);
    
    const [edytowanaStawka, setEdytowanaStawka] = useState({id: undefined, nazwa: undefined, kwota: undefined});
    const [addNew, setAddNew] = useState(false)

    const handleEdytowana = stawka => {
        const id = stawkaId;
        const nazwa = stawka[0];
        const kwota = stawka[1];
        setEdytowanaStawka({id, nazwa, kwota});
    }

    const handleClick = (id) => {
        setTrashId(id)
        setOpen(true);   
    }


    useEffect( () => {
        setWybranaStawka(undefined);
        setStawkaId(undefined);
        setEdytowanaStawka({id: undefined, nazwa: undefined, kwota: undefined})
    },[grupaUdzial])

    const usunStawki = async (id) => {
        try {
            const response = await axios.post('/api/stawki_ind/usun', { id: id });
            if (response.data.success === true) {
                setStawkaId(undefined);
                setWybranaStawka(undefined)
                dispatch(fetchStawkiInd(grupaUdzial?.id_wspolnoty)); 
            }
        } catch (error) {
            console.error('Error sending ID to backend:', error);
        }
    }

    const loadInd = stawka => {
        setStawkaId(stawka?.id)
        const temp = Object.entries(stawka).filter(
            ([key, value]) => typeof value === "number" && !isNaN(value) && !key.includes("data_od") && !key.includes("data_utworzenia") && !key.includes("id") && !key.includes("usuniety") && !key.includes("grupa_numer")
        );
        setWybranaStawka(temp)
    }
    
    const listaDat = stawkiInd_g?.map( (stawka, i) => {

        return (
            <li 
                key={i}
                className={ stawka?.id == stawkaId ? "active pointer" : "pointer" }
            >
                <div
                    // className={ stawkiIndAkt?.id == stawka.id ? "pointer b" : "pointer" }
                    onClick={()=>loadInd(stawka)}
                >
                    {dataFormat2(stawka?.data_od)}
                </div>
                <div
                    style={{flex: "initial"}}    
                >
                    <FontAwesomeIcon
                        icon={faTrash} 
                        className="pointer"
                        onClick={()=>handleClick(stawka.id)}
                    />
                </div>
            </li>
        )
    })

    const handleEditCancel = () => setEdytowanaStawka({id: undefined, nazwa: undefined, kwota: undefined});
    // const handleEditSave => () => 


    /*const handleUpdate = async () => {
        const obj = {
            id_wspolnoty: grupaUdzial?.id_wspolnoty,
            id_user: grupaUdzial?.id_user,
            grupa_numer: grupaUdzial?.grupa_numer,
            data_od: dataOd,
            eksploatacja: null,
            garaz: null,
            strych: null,
            utrzymanie_czystosci: null,
            wywoz_smieci: null,
            fundusz_remontowy: null,
            konserw_domofonu: null,
            konserw_windy: null,
            opl_za_adm: null,
            ciepla_woda: null,
            zimna_woda: null,
            abonament_cw: null,
            co: null,
            energia_el_lokalu: null,
            ryczalt_gaz: null,
            zagosp_terenu: null,
            antena: null,
            komorka: null,
        };

        for (let key in stawkiCzy) {
            if (Object.prototype.hasOwnProperty.call(stawkiCzy, key) && Object.prototype.hasOwnProperty.call(wybraneStawki, key)) {
              if (stawkiCzy[key] !== wybraneStawki[key]) {
                obj[key] = wybraneStawki[key]; // Zapisz wartości z obu obiektów
              }
            }
        }

        try {
            await axios.post('/api/stawki_ind/dodaj', { obj }).then( response => {
                if (response.status === 200) 
                    dispatch(fetchStawkiInd())
                    setEditGrupe(0);    
            })
        } catch (error) {
            console.error('Błąd podczas dodawania danych:', error);
        }
        
    }*/

    const listaStawek = wybranaStawka?.map( (stawka, i) => (
        <li
            key={i}
        >
            <div>
                {translate(stawka[0])}
            </div>
            <div>
                { zl(stawka[1]) }
            </div>
            {/*
                edytowanaStawka?.id && stawka[0] == edytowanaStawka?.nazwa ? (
                    <div>
                        <input
                            type="number"
                            value={edytowanaStawka?.kwota}
                            onChange={(e)=>setEdytowanaStawka( prevState =>({...prevState, kwota: e.target.value} ))}
                        />
                        <FontAwesomeIcon 
                            icon={faChevronLeft} 
                            onClick={handleEditCancel}
                            className="pointer"
                        />
                        <FontAwesomeIcon 
                            icon={faCheck} 
                            onClick={handleUpdate}
                            className="pointer"
                        />
                    </div>
                ) : (
                    <div>
                        {stawka[1] === null ? "brak" : zl(stawka[1]) }
                        <FontAwesomeIcon 
                            icon={faPencil} 
                            onClick={()=>handleEdytowana(stawka)}    
                            className="pointer"
                        />
                    </div>
                )
            */}

        </li>
    ))




    // console.log(listaStawek)

    // const filteredEntries = 

    return (
        <div className="wsad ramka-inset-shadow">
            <div className="daty">
                <ul className="lista">
                    <li>
                        <div>
                            Data od
                        </div>
                    </li>
                    {listaDat}
                </ul>
            </div>
            <div className="stawki">
                <ul className="lista">
                    <li>
                        <div>
                            Stawka
                        </div>
                        <div>
                            Kwota
                        </div>
                    </li>
                    {listaStawek}
                </ul>
            </div>
            <DialogYesNo 
                open={open}
                setOpen={setOpen}
                message={`Czy na pewno chcesz usunąć te stawki?`}
                f={usunStawki}
                id={trashId}
            />
        </div>
    )
}

export default MieszkaniecDaneStawkiIndList;