import React from 'react';



const MieszkancyRodzajLokaluBox = props => {

    const { setMieszkaniec,setUser, setGrupaUdzial, setAddGrupe, rodzajLokalu, setRodzajLokalu, setWybranyOdczyt } = props;
    
    const rodzaje = [
        {
            rodzaj: "mieszkanie",
            opis: "Mieszkania"
        },
        {
            rodzaj: "garaż",
            opis: "Garaże"
        },
        {
            rodzaj: "komórka",
            opis: "Komórki"
        },
        {
            rodzaj: "strych",
            opis: "Strychy"
        },
    ]

    const lokale = rodzaje?.map( (r, i) => {

        return (
            <div 
                className= {r.rodzaj == rodzajLokalu ? 'ulicaBtn pointer active' : 'ulicaBtn pointer' } 
                onClick={()=>{
                    setMieszkaniec(undefined);
                    setGrupaUdzial(undefined);
                    setUser(undefined);
                    setAddGrupe(false);
                    setRodzajLokalu(r.rodzaj);
                    setWybranyOdczyt(undefined);
                }}
                key={i}
            >
                {`${r.opis}`}
            </div>
        )
    })
    
    return (
        <div className='mieszkancyUlice'>
            <div className='box'>
                {lokale}
            </div>
        </div>
    )
}

export default MieszkancyRodzajLokaluBox;