import { zera, isNumber } from "./utilities";
const czynszF = (kwota, liczbaDniMsc) => {
    if (isNaN(kwota))
        return 0
    else
        return (Math.round(kwota * 100000) / 100000) / liczbaDniMsc 
};

export const obliczCzynsz2 = ( liczbaDomownikow, lokal, stawki,liczbaDniMsc, planowane_zuzycie, jednorazowe, stawkiInd_na_dzien ) => {

    const czynsz = [];
  
    let calkowitaPu = lokal?.metraz;
    let sumaGaraze = 0;
    let sumaStrychy = 0;
    let sumaKomorki = 0;    
    let mieszkaniePu = 0;

    let czyGaraz = false;
    let czyStrych = false;
    let czyKomorka = false;
    
    switch(lokal?.rodzaj) {
        case "mieszkanie":
            mieszkaniePu = lokal?.metraz;
            break;
        case "garaż":
            sumaGaraze = lokal?.metraz;
            czyGaraz = true;
            break;
        case "strych":
            sumaStrychy = lokal?.metraz;
            czyStrych = true;
            break;
        case "komórka":
            sumaKomorki = lokal?.metraz;
            czyKomorka = true;
            break;
        default:
            break; 
    }

    

    if (stawki?.czy_eksploatacja == "1") {
        let temp = {};
        temp = {
            nazwa: "eksploatacja",
            rodzaj: stawki.eksploatacja_rodzaj,
        }
        const stawka = isNumber(stawkiInd_na_dzien?.eksploatacja) ? parseFloat(stawkiInd_na_dzien.eksploatacja) :  parseFloat(stawki.eksploatacja);
        switch(stawki.eksploatacja_rodzaj) {
            case "1":
                temp["czynsz"] = lokal?.rodzaj === "mieszkanie" ? czynszF( stawka, liczbaDniMsc) : 0;
                temp["podstawa"] = "-";
                temp["stawka"] = stawka;
                break;
            case "5":
                temp["czynsz"] = czynszF( stawka * parseFloat(mieszkaniePu), liczbaDniMsc);
                temp["podstawa"] = parseFloat(mieszkaniePu);
                temp["stawka"] = stawka;
                break;
            case "9":
                temp["czynsz"] = czynszF(stawka * parseFloat(calkowitaPu), liczbaDniMsc);
                temp["podstawa"] = calkowitaPu;
                temp["stawka"] = stawka;
                break;
            default:
                temp["czynsz"] = -1;
                temp["podstawa"] = "BŁĄD";
        }
        czynsz.push(temp);
        
    } 


    
    if (stawki?.czy_garaz == "1" && lokal?.rodzaj === "garaż") {
        let temp = {};

        

        temp = {
            nazwa: "garaż",
            rodzaj: stawki.garaz_rodzaj
        }
        const stawka = isNumber(stawkiInd_na_dzien?.garaz) ? parseFloat(stawkiInd_na_dzien.garaz) :  parseFloat(stawki.garaz);
        
        switch(stawki?.garaz_rodzaj) {
            case "1":
                temp["czynsz"] = czyGaraz ? czynszF(stawka, liczbaDniMsc) : 0;
                temp["podstawa"] = "-";
                temp["stawka"] = stawka;
                break;
            case "16":
                temp["czynsz"] = czynszF(stawka * parseFloat(sumaGaraze), liczbaDniMsc);
                temp["podstawa"] = parseFloat(sumaGaraze);
                temp["stawka"] = stawka;
                break
            default:
                temp["czynsz"] = -1;
                temp["podstawa"] = "BŁĄD";
        }       
        czynsz.push(temp);
    }

    if (stawki?.czy_strych == "1" && lokal?.rodzaj === "strych") {
        let temp = {};
        temp = {
            nazwa: "strych",
            rodzaj: stawki.strych_rodzaj
        }
        const stawka = isNumber(stawkiInd_na_dzien?.strych) ? parseFloat(stawkiInd_na_dzien.strych) :  parseFloat(stawki.strych);
        switch(stawki?.garaz_rodzaj) {
            case "1":
                temp["czynsz"] = czyStrych ?   czynszF(stawka, liczbaDniMsc) : 0;
                temp["podstawa"] = "-";
                temp["stawka"] = stawka;
                break;
            case "17":
                temp["czynsz"] = czynszF(stawka * parseFloat(sumaStrychy), liczbaDniMsc);
                temp["podstawa"] = parseFloat(sumaStrychy);
                temp["stawka"] = stawka;
                break
            default:
                temp["czynsz"] = -1;
                temp["podstawa"] = "BŁĄD";
        }  
        czynsz.push(temp);
    } 

    if (stawki?.czy_komorka == "1" && lokal?.rodzaj === "komórka") {
        let temp = {};
        temp = {
            nazwa: "komórka",
            rodzaj: stawki?.komorka_rodzaj
        }
        const stawka = isNumber(stawkiInd_na_dzien?.komorka) ? parseFloat(stawkiInd_na_dzien.komorka) :  parseFloat(stawki.komorka);
        switch(stawki?.komorka_rodzaj) {
            case "1":
                temp["czynsz"] = czyKomorka ?  czynszF(stawka, liczbaDniMsc) : 0;
                temp["podstawa"] = "-";
                temp["stawka"] = stawka;
                break;
            case "18":
                temp["czynsz"] = czynszF(stawka * parseFloat(sumaKomorki), liczbaDniMsc);
                temp["podstawa"] = parseFloat(sumaKomorki);
                temp["stawka"] = stawka;
                break
            default:
                temp["czynsz"] = -1;
                temp["podstawa"] = "BŁĄD";
        }  
        czynsz.push(temp);
    }

    if (stawki?.czy_utrzymanie_czystosci == "1" && lokal?.rodzaj === "mieszkanie" ) {
        let temp = {};
        temp = {
            nazwa: "utrzymanie_czystosci",
            rodzaj: stawki.utrzymanie_czystosci_rodzaj,
        }
        const stawka = isNumber(stawkiInd_na_dzien?.utrzymanie_czystosci) ? parseFloat(stawkiInd_na_dzien.utrzymanie_czystosci) :  parseFloat(stawki.utrzymanie_czystosci);
        switch(stawki.utrzymanie_czystosci_rodzaj) {
            case "1":
                temp["czynsz"] = czynszF(stawka, liczbaDniMsc);
                temp["podstawa"] = "-";
                temp["stawka"] = stawka;
                break;
            case "5":
                temp["czynsz"] = czynszF(stawka * parseFloat(mieszkaniePu), liczbaDniMsc);
                temp["podstawa"] = parseFloat(mieszkaniePu);
                temp["stawka"] = stawka;
                break;
            default:
                temp["czynsz"] = -1;
                temp["podstawa"] = "BŁĄD";
        }
        czynsz.push(temp);
    }

    if (stawki?.czy_wywoz_smieci == "1" && lokal?.rodzaj === "mieszkanie") {
        const stawka = isNumber(stawkiInd_na_dzien?.wywoz_smieci) ? parseFloat(stawkiInd_na_dzien.wywoz_smieci) :  parseFloat(stawki.wywoz_smieci);
        czynsz.push( {
            nazwa: "wywoz_smieci", 
            rodzaj: stawki.wywoz_smieci_rodzaj, 
            czynsz: czynszF(stawka * parseInt(liczbaDomownikow), liczbaDniMsc),
            podstawa: parseFloat(liczbaDomownikow),
            stawka: stawka
        });
    }

    if (stawki?.czy_fundusz_remontowy == "1" ) {
        let temp = {};
        temp = {
            nazwa: "fundusz_remontowy",
            rodzaj: stawki.fundusz_remontowy_rodzaj,
        }
        const stawka = isNumber(stawkiInd_na_dzien?.fundusz_remontowy) ? parseFloat(stawkiInd_na_dzien.fundusz_remontowy) :  parseFloat(stawki.fundusz_remontowy);
        switch(stawki?.fundusz_remontowy_rodzaj) {
            case "1":
                temp["czynsz"] = lokal?.rodzaj === "mieszkanie" ? czynszF(stawka, liczbaDniMsc) : 0;
                temp["podstawa"] = "-";
                temp["stawka"] = stawka;
                break;
            case "5":
                temp["czynsz"] = czynszF(stawka * parseFloat(mieszkaniePu), liczbaDniMsc);
                temp["podstawa"] = parseFloat(mieszkaniePu);
                temp["stawka"] = stawka;
                break;
            case "9":
                temp["czynsz"] = czynszF(stawka * parseFloat(calkowitaPu), liczbaDniMsc);
                temp["podstawa"] = parseFloat(calkowitaPu);
                temp["stawka"] = stawka;
                break;
            default:
                temp["czynsz"] = -1;
                temp["podstawa"] = "BŁĄD";
        }
        czynsz.push(temp);
    }

    if (stawki?.czy_konserw_domofonu == "1"&& lokal?.rodzaj === "mieszkanie") {
        const stawka = isNumber(stawkiInd_na_dzien?.konserw_domofonu) ? parseFloat(stawkiInd_na_dzien.konserw_domofonu) :  parseFloat(stawki.konserw_domofonu);
        czynsz.push({
            nazwa: "konserw_domofonu",
            rodzaj: stawki.konserw_domofonu_rodzaj,
            czynsz: czynszF(stawka, liczbaDniMsc),
            podstawa: "-",
            stawka:  stawka,
        });
    }

    if (stawki?.czy_konserw_windy == "1" && lokal?.rodzaj === "mieszkanie") {
        const stawka = isNumber(stawkiInd_na_dzien?.konserw_windy) ? parseFloat(stawkiInd_na_dzien.konserw_windy) :  parseFloat(stawki.konserw_windy);
        
        czynsz.push({
            nazwa: "konserw_windy",
            rodzaj: stawki.konserw_windy_rodzaj,
            czynsz: czynszF(stawka, liczbaDniMsc),
            podstawa: "-",
            stawka: stawka,
        });
    }

    if (stawki?.czy_opl_za_adm == "1") {
        let temp = {};
        temp = {
            nazwa: "opl_za_adm",
            rodzaj: stawki.opl_za_adm_rodzaj,
        }
        const stawka = isNumber(stawkiInd_na_dzien?.opl_za_adm) ? parseFloat(stawkiInd_na_dzien.opl_za_adm) :  parseFloat(stawki.opl_za_adm);
        switch(stawki.opl_za_adm_rodzaj) {
            case "1":
                temp["czynsz"] = lokal?.rodzaj === "mieszkanie" ? czynszF(stawka, liczbaDniMsc) : 0;
                temp["podstawa"] = "-";
                temp["stawka"] = stawka;
                break;
            case "5":
                temp["czynsz"] = czynszF(stawka * parseFloat(mieszkaniePu), liczbaDniMsc);
                temp["podstawa"] = parseFloat(mieszkaniePu);
                temp["stawka"] = stawka;
                break;
            case "9":
                temp["czynsz"] = czynszF(stawka * calkowitaPu, liczbaDniMsc);
                temp["podstawa"] = calkowitaPu;
                temp["stawka"] = stawka;
                break;
            default:
                temp["czynsz"] = -1;
                temp["podstawa"] = "BŁĄD";
        }
        czynsz.push(temp);
    }

    if (stawki?.czy_zimna_woda == "1" && lokal?.rodzaj === "mieszkanie") {
        const stawka = isNumber(stawkiInd_na_dzien?.zimna_woda) ? parseFloat(stawkiInd_na_dzien.zimna_woda) :  parseFloat(stawki.zimna_woda);
        czynsz.push({
            nazwa: "zimna_woda",
            rodzaj: stawki.zimna_woda_rodzaj,
            czynsz: czynszF(stawka * parseFloat(planowane_zuzycie?.woda_zimna), liczbaDniMsc),
            podstawa: parseFloat(planowane_zuzycie?.woda_zimna),
            stawka: stawka
        })
    }

    if (stawki?.czy_ciepla_woda == "1" && lokal?.rodzaj === "mieszkanie") {
        const stawka = isNumber(stawkiInd_na_dzien?.ciepla_woda) ? parseFloat(stawkiInd_na_dzien.ciepla_woda) :  parseFloat(stawki.ciepla_woda);
        czynsz.push({
            nazwa: "ciepla_woda",
            rodzaj: stawki.ciepla_woda_rodzaj,
            czynsz: czynszF(stawka * parseFloat(planowane_zuzycie?.woda_ciepla), liczbaDniMsc),
            podstawa: parseFloat(planowane_zuzycie?.woda_ciepla),
            stawka: stawka
        })
    }

    if (stawki?.czy_abonament_cw == "1" && lokal?.rodzaj === "mieszkanie") {
        const stawka = isNumber(stawkiInd_na_dzien?.abonament_cw) ? parseFloat(stawkiInd_na_dzien.abonament_cw) :  parseFloat(stawki.abonament_cw);
        czynsz.push({
            nazwa: "abonament_cw",
            rodzaj: stawki.abonament_cw_rodzaj,
            czynsz: czynszF(stawka * parseFloat(mieszkaniePu), liczbaDniMsc),
            podstawa: parseFloat(mieszkaniePu),
            stawka: stawka
        });
    }

    if (stawki?.czy_co == "1" && lokal?.rodzaj === "mieszkanie") {
        const stawka = isNumber(stawkiInd_na_dzien?.co) ? parseFloat(stawkiInd_na_dzien.co) :  parseFloat(stawki.co);
        czynsz.push({
            nazwa: "co",
            rodzaj: stawki.co_rodzaj,
            czynsz: czynszF(stawka * parseFloat(mieszkaniePu), liczbaDniMsc),
            podstawa: parseFloat(mieszkaniePu),
            stawka:  stawka
        });
    }

    if (stawki?.czy_energia_el_lokalu == "1") {
        let temp = {};
        temp = {
            nazwa: "energia_el_lokalu",
            rodzaj: stawki.energia_el_lokalu_rodzaj,
        }
        const stawka = isNumber(stawkiInd_na_dzien?.energia_el_lokalu) ? parseFloat(stawkiInd_na_dzien.energia_el_lokalu) :  parseFloat(stawki.energia_el_lokalu);
        switch(stawki.energia_el_lokalu_rodzaj) {
            case "1":
                temp["czynsz"] = lokal?.rodzaj === "mieszkanie" ? czynszF(stawka, liczbaDniMsc) : 0;
                temp["podstawa"] = "-";
                temp["stawka"] = stawka;
                break;
            case "5":
                temp["czynsz"] = czynszF(stawka * parseFloat(mieszkaniePu), liczbaDniMsc);
                temp["podstawa"] = parseFloat(mieszkaniePu);
                temp["stawka"] = stawka;
                break;
            default:
                temp["czynsz"] = -1;
                temp["podstawa"] = "BŁĄD";
        }
        czynsz.push(temp);
    }

    if (stawki?.czy_ryczalt_gaz == "1" && lokal?.rodzaj === "mieszkanie") {
        let temp = {};
        temp = {
            nazwa: "ryczalt_gaz",
            rodzaj: stawki.ryczalt_gaz_rodzaj,
        }
        const stawka = isNumber(stawkiInd_na_dzien?.ryczalt_gaz) ? parseFloat(stawkiInd_na_dzien.ryczalt_gaz) :  parseFloat(stawki.ryczalt_gaz);
        switch(stawki.ryczalt_gaz_rodzaj) {
            case "1":
                temp["czynsz"] = czynszF(stawka, liczbaDniMsc);
                temp["podstawa"] = "-";
                temp["stawka"] = stawka;
                break;
            case "3":
                temp["czynsz"] = czynszF(stawka * parseFloat(liczbaDomownikow), liczbaDniMsc);
                temp["podstawa"] = parseFloat(liczbaDomownikow);
                temp["stawka"] = stawka;
                break;
            default:
                temp["czynsz"] = -1;
                temp["podstawa"] = "BŁĄD";
        }
        czynsz.push(temp);
    }

    if (stawki?.czy_zagosp_terenu == "1" && lokal?.rodzaj === "mieszkanie") {
        let temp = {};
        temp = {
            nazwa: "zagosp_terenu",
            rodzaj: stawki.zagosp_terenu_rodzaj,
        }
        const stawka = isNumber(stawkiInd_na_dzien?.zagosp_terenu) ? parseFloat(stawkiInd_na_dzien.zagosp_terenu) :  parseFloat(stawki.zagosp_terenu);
        switch(stawki.zagosp_terenu_rodzaj) {
            case "1":
                temp["czynsz"] = czynszF(stawka, liczbaDniMsc);
                temp["podstawa"] = "-";
                temp["stawka"] = stawka;
                break;
            case "5":
                temp["czynsz"] = czynszF(stawka * parseFloat(mieszkaniePu), liczbaDniMsc);
                temp["podstawa"] = parseFloat(mieszkaniePu);
                temp["stawka"] = stawka;
                break;
            default:
                temp["czynsz"] = -1;
                temp["podstawa"] = "BŁĄD";
        }
        czynsz.push(temp);
    }

    if (stawki?.czy_antena == "1" && lokal?.rodzaj === "mieszkanie") {
        let temp = {};
        temp = {
            nazwa: "antena",
            rodzaj: stawki.antena_rodzaj,
        }
        const stawka = isNumber(stawkiInd_na_dzien?.antena) ? parseFloat(stawkiInd_na_dzien.antena) :  parseFloat(stawki.antena);
        switch(stawki.antena_rodzaj) {
            case "1":
                temp["czynsz"] = czynszF(stawka, liczbaDniMsc);
                temp["podstawa"] = "-";
                temp["stawka"] = stawka;
                break;
            case "5":
                temp["czynsz"] = czynszF(stawka * parseFloat(mieszkaniePu), liczbaDniMsc);
                temp["podstawa"] = parseFloat(mieszkaniePu);
                temp["stawka"] = stawka;
                break;
            default:
                temp["czynsz"] = -1;
                temp["podstawa"] = "BŁĄD";
        }
        czynsz.push(temp);
    }
    
    if (stawkiInd_na_dzien != undefined && stawkiInd_na_dzien?.id_user == 63) {
        console.log(stawki)
        console.log(jednorazowe)
     
    }    

    if (jednorazowe?.dod_1_czy == "1") {
        let temp = {};
        temp = {
            nazwa: "dod_1",
            rodzaj: jednorazowe?.dod_1_rodzaj,
            opis: jednorazowe?.dod_1_opis
        }
        const stawka = isNumber(stawkiInd_na_dzien?.dod_1_kwota) ? parseFloat(stawkiInd_na_dzien?.dod_1_kwota) :  parseFloat(jednorazowe?.dod_1_kwota);
        switch(jednorazowe.dod_1_rodzaj) {
            case "1":
                temp["czynsz"] = czynszF(stawka, liczbaDniMsc);
                temp["podstawa"] = "-";
                temp["stawka"] = stawka;
                break;
            case "3":
                temp["czynsz"] = czynszF(stawka * parseFloat(liczbaDomownikow), liczbaDniMsc);
                temp["podstawa"] = parseFloat(liczbaDomownikow);
                temp["stawka"] = stawka;
                break;
            case "5":
                temp["czynsz"] = czynszF(stawka * parseFloat(mieszkaniePu), liczbaDniMsc);
                temp["podstawa"] = parseFloat(mieszkaniePu);
                temp["stawka"] = stawka;
                break;
            case "9":
                temp["czynsz"] = czynszF(stawka * calkowitaPu, liczbaDniMsc);
                temp["podstawa"] = calkowitaPu;
                temp["stawka"] = stawka;
                break;
            default:
                temp["czynsz"] = -1;
                temp["podstawa"] = "BŁĄD";
        }
        czynsz.push(temp);
    }

    if (jednorazowe?.dod_2_czy == "1") {
        let temp = {};
        temp = {
            nazwa: "dod_2",
            rodzaj: jednorazowe?.dod_2_rodzaj,
            opis: jednorazowe?.dod_2_opis
        }
        const stawka = isNumber(stawkiInd_na_dzien?.dod_2_kwota) ? parseFloat(stawkiInd_na_dzien?.dod_2_kwota) :  parseFloat(stawki?.dod_2_kwota);
        switch(jednorazowe.dod_2_rodzaj) {
            case "1":
                temp["czynsz"] = czynszF(stawka, liczbaDniMsc);
                temp["podstawa"] = "-";
                temp["stawka"] = stawka;
                break;
            case "3":
                temp["czynsz"] = czynszF(stawka * parseFloat(liczbaDomownikow), liczbaDniMsc);
                temp["podstawa"] = parseFloat(liczbaDomownikow);
                temp["stawka"] = stawka;
                break;
            case "5":
                temp["czynsz"] = czynszF(stawka * parseFloat(mieszkaniePu), liczbaDniMsc);
                temp["podstawa"] = parseFloat(mieszkaniePu);
                temp["stawka"] = stawka;
                break;
            case "9":
                temp["czynsz"] = czynszF(stawka * calkowitaPu, liczbaDniMsc);
                temp["podstawa"] = calkowitaPu;
                temp["stawka"] = stawka;
                break;
            default:
                temp["czynsz"] = -1;
                temp["podstawa"] = "BŁĄD";
        }
        czynsz.push(temp);
    }

    if (jednorazowe?.dod_3_czy == "1") {
        let temp = {};
        temp = {
            nazwa: "dod_3",
            rodzaj: jednorazowe?.dod_3_rodzaj,
            opis: jednorazowe?.dod_3_opis
        }
        const stawka = isNumber(stawkiInd_na_dzien?.dod_3_kwota) ? parseFloat(stawkiInd_na_dzien?.dod_3_kwota) :  parseFloat(stawki?.dod_3_kwota);
        switch(jednorazowe.dod_3_rodzaj) {
            case "1":
                temp["czynsz"] = czynszF(stawka, liczbaDniMsc);
                temp["podstawa"] = "-";
                temp["stawka"] = stawka;
                break;
            case "3":
                temp["czynsz"] = czynszF(stawka * parseFloat(liczbaDomownikow), liczbaDniMsc);
                temp["podstawa"] = parseFloat(liczbaDomownikow);
                temp["stawka"] = stawka;
                break;
            case "5":
                temp["czynsz"] = czynszF(stawka * parseFloat(mieszkaniePu), liczbaDniMsc);
                temp["podstawa"] = parseFloat(mieszkaniePu);
                temp["stawka"] = stawka;
                break;
            case "9":
                temp["czynsz"] = czynszF(stawka * calkowitaPu, liczbaDniMsc);
                temp["podstawa"] = calkowitaPu;
                temp["stawka"] = stawka;
                break;
            default:
                temp["czynsz"] = -1;
                temp["podstawa"] = "BŁĄD";
        }
        czynsz.push(temp);
    }

    let suma_ = 0;
    let suma__ = 0;

    czynsz.forEach( el => {
        suma_ += zera(el.czynsz)
        suma__ += parseFloat(zera(el.czynsz).toFixed(2));
    })

    czynsz.push({
        stawka: 0,
        nazwa: "suma",
        podstawa: "-",
        rodzaj: 0,
        czynsz: suma_,
        czynsz_: suma__
    })
    
    return czynsz;

}

export const obliczWode = (cieplaZuzycie, zimnaZuzycie, cieplaWodaCena, zimnaWodaCena, rozliczanieWody) => {

    if (rozliczanieWody == 1) {
        return {
            ciepla: zera((cieplaZuzycie * zimnaWodaCena) + (cieplaZuzycie * cieplaWodaCena)),
            zimna: zera(zimnaZuzycie * zimnaWodaCena),
        }

    } 
}