import React, { useEffect, useState, useRef } from 'react';

const ImportBNP = (props) => {

  const { setWczytane,setPelneDane, przelewy, setCzyDubel } = props;
  const fileInputRef = useRef(null);

  function extractAccountNumber(text) {
    const match = text.match(/IDEN_ID:(\d+)\s/);
    return match ? match[1] : null;
  }

  const handleFileUpload = event => {
    const file = event.target.files[0]; // Pobranie pliku XML z input
    if (file) {

      const dubel = przelewy?.filter( p => p?.nazwa_pliku === file?.name && p?.nazwa_pliku !== "");

      setCzyDubel( dubel?.length > 0 ? true : false );

      const reader = new FileReader();
      reader.onload = function (e) {
        const xmlString = e.target.result;
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(xmlString, "text/xml");
        const ntryElements = xmlDoc.querySelectorAll('Stmt > Ntry');

        const dataOperacji = xmlDoc.querySelector('ValDt > Dt')?.textContent;
        const dataWczytania = new Date();
        
        const parsedData = Array.from(ntryElements).map((ntry) => {
          const operacja = ntry.querySelector('CdtDbtInd')?.textContent;
          
          let kwota = 0;
          let rodzajOperacji = "";
          let nadawcaNazwa = "";
          let nadawcaKonto = "";
          let odbiorcaNazwa = "";
          let odbiorcaKonto = "";
          let tytul = "";
          let przelewId = ntry.querySelector('InstrId')?.textContent;

          if (operacja === "CRDT" || operacja === "DBIT") {
            const ustrd = ntry.querySelector('Ustrd')?.textContent;

            if (!ustrd.includes("collect_")) {
              
              switch (operacja) {
                case "CRDT":
                  kwota = parseFloat(ntry.querySelector('Amt').textContent);
                  rodzajOperacji = "przelew przychodzący";
                  
                  if (ustrd.indexOf('IDEN_ID:') > -1) {
                    tytul = ustrd.split('IDEN_ID:')[1]?.substring(27).trim();
                    odbiorcaKonto = ustrd?.substring(8,34);
                    odbiorcaNazwa = "Wspólnota (konto grupy)";
                  } else {
                    tytul = ustrd;
                    odbiorcaKonto = "Konto główne wspólnoty"
                    odbiorcaNazwa = "Wspólnota (konto główne)";
                  }
                  
                  nadawcaNazwa = ntry.querySelector('Nm')?.textContent;
                  nadawcaKonto = ntry.querySelector('Othr > Id')?.textContent;
                  
                  
                  break;
                case "DBIT":
                  kwota = parseFloat(ntry.querySelector('Amt').textContent) * -1;
                  rodzajOperacji = "przelew wychodzący";

                  if (ustrd.indexOf('IDEN_ID:') > -1) {
                    tytul = ustrd.split('IDEN_ID:')[1]?.substring(27).trim();
                    nadawcaKonto = extractAccountNumber(ustrd);
                    nadawcaNazwa = "Konto grupy";
                  } else {
                    tytul = ustrd;  
                    nadawcaNazwa = "Wspólnota";
                    nadawcaKonto = "Konto główne wspólnoty"
                  }

                  odbiorcaNazwa = ntry.querySelector('Nm').textContent;
                  odbiorcaKonto = ntry.querySelector('Othr > Id').textContent
                  break;
                default:
              }
            } else {
              rodzajOperacji = "prowizja"
              kwota = parseFloat(ntry.querySelector('Amt').textContent) * -1;
            }
            
          } 
          return { przelewId, dataOperacji, dataWczytania, rodzajOperacji, tytul, kwota, nadawcaNazwa, nadawcaKonto, odbiorcaNazwa, odbiorcaKonto, plik: file?.name };
        });
        setWczytane(parsedData)
      }
      reader.readAsText(file);
    }
  }

  const handleClearFile = () => {
    fileInputRef.current.value = ''; 
    setWczytane([]);
    setPelneDane([]);
    setCzyDubel(false);
  }

  return (
    <div className='reader'>
      <input 
        type="file" 
        id="file-input" 
        accept=".xml" 
        multiple
        onChange={handleFileUpload} 
        ref={fileInputRef}
      />
      <button
        onClick={handleClearFile}
      >
        Wyczyść schowek
      </button>
    </div>
  );
};

export default ImportBNP;