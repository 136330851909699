import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { faTrash } from '@fortawesome/free-solid-svg-icons'

import { dataFormat, date_to_mm_rrrr } from "../../../functions/dataFormat.js";
import { zl } from "../../../functions/utilities.js";
import MieszkaniecDaneWodaPlanowaneZuzycie from "./MieszkaniecDaneWodaPlanowaneZuzycie.js";
import MieszkaniecDaneWodaStanLicznikow from "./MieszkaniecDaneWodaStanLicznikow.js";
import MieszkaniecDaneWodaRozliczenie from "./MieszkaniecDaneWodaRozliczenie.js";
import { AdresPro2 } from "../../../functions/ulice.js";

import { 
    fetchPlanowaneZuzycie,
    fetchOdczyty,
    fetchStawki,
    fetchUlice,
    fetchLokale
} from '../../../Redux/Actions.js'; 
import { znajdzNajblizszaStawke } from "../../../functions/stawki.js";

const MieszkaniecDaneWoda = props => {

    const { grupaUdzial, tablicaCzynsze_g, wybranyOdczyt, setWybranyOdczyt, mieszkaniec, grupy_g } = props;
    const dispatch = useDispatch();

    const [stawki_w, setStawki_w] = useState(undefined);
    const [stawkiAkt, setStawkiAkt] = useState(undefined);
    const [adres, setAdres] = useState("ładuję...");
    
    const [wybraneRozliczenie, setWybraneRozliczenie] = useState(undefined);

    const planowaneZuzycie = useSelector( state => state?.planowaneZuzycie?.data);
    const odczyty = useSelector( state => state?.odczyty?.data)
    const stawki = useSelector( state => state?.stawki?.data);
    const ulice = useSelector( state => state?.ulice?.data);
    const lokale = useSelector( state => state?.lokale?.data);

    useEffect( ()=> {
        dispatch(fetchPlanowaneZuzycie(grupaUdzial?.id_wspolnoty));
        dispatch(fetchOdczyty(grupaUdzial?.id_wspolnoty));
        dispatch(fetchStawki(grupaUdzial?.id_wspolnoty));
        dispatch(fetchUlice(grupaUdzial?.id_wspolnoty));     
        dispatch(fetchLokale(grupaUdzial?.id_wspolnoty)); 
    },[dispatch]);

    useEffect( () => {
        setAdres( AdresPro2( grupaUdzial, lokale, ulice, grupy_g ) )
    }, [grupaUdzial, lokale, ulice, grupy_g])

    useEffect( () => setStawki_w( stawki?.filter( s => s?.id_wspolnoty == grupaUdzial?.id_wspolnoty ) ),[stawki, grupaUdzial]);

    useEffect( () => {
        const temp = znajdzNajblizszaStawke(date_to_mm_rrrr(new Date()),stawki_w);
        setStawkiAkt(temp)
    },[stawki_w])

    return (
        <div className="ramka grupaWoda">
            <div className="naglowek">
                <h2>
                    Woda 
                </h2>
            </div>

            <MieszkaniecDaneWodaPlanowaneZuzycie
                grupaUdzial={grupaUdzial}
                planowaneZuzycie={planowaneZuzycie}
            />
            <MieszkaniecDaneWodaStanLicznikow
                grupaUdzial={grupaUdzial}
                odczyty={odczyty}
                stawkiAkt={stawkiAkt}
                wybraneRozliczenie={wybraneRozliczenie}
                setWybraneRozliczenie={setWybraneRozliczenie}
                wybranyOdczyt={wybranyOdczyt}
                setWybranyOdczyt={setWybranyOdczyt}
            />
            <MieszkaniecDaneWodaRozliczenie
                grupaUdzial={grupaUdzial}
                odczyty={odczyty}
                stawkiAkt={stawkiAkt}
                wybraneRozliczenie={wybraneRozliczenie}
                setWybraneRozliczenie={setWybraneRozliczenie}
                wybranyOdczyt={wybranyOdczyt}
                setWybranyOdczyt={setWybranyOdczyt}
                tablicaCzynsze_g={tablicaCzynsze_g}
                stawki_w={stawki_w}
                mieszkaniec={mieszkaniec}
                adres={adres}
            />
        </div>
    )
}

export default MieszkaniecDaneWoda;