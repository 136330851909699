import React from "react";

const WspolnotaRaportZbiorowyTHead = ( props ) => {

    const { listaMsc, visible, czyRok, rok, rokObecny } = props;

    const kolumnyMiesiace = listaMsc?.map( (e, i) => {

        if (!visible) {
            return (
                <>
                </>
            )
        } else {
            return (
                <>
                    <th>
                        należności
                    </th>
                    <th>
                        wpłaty
                    </th>
                    <th>
                        saldo
                    </th>
                </>
            )
        }
    })      

    const thDaty = listaMsc?.map( (e,i) => {
        if (!visible) {
            return (
                <></>
            )
        } else {
            return (
                <th colSpan={3} style={{textAlign: "center"}}>
                    {e}  
                </th>
            )
        }
    })

    const thRok = czyRok && (<th style={{backgroundColor: "var(--podswietlenie)"}}>{`Na koniec ${rok}`}</th>); 
    const thRokCols = czyRok && (<>
        <th style={{backgroundColor: "var(--podswietlenie)"}}>razem</th>
    </>)

    return (
        <thead>
            <tr key={-1}>
                <th rowSpan={2} style={{border: "1px solid black"}}>
                    Mieszkaniec
                </th>
                <th rowSpan={2} style={{border: "1px solid black"}}>
                    Adres
                </th>
                { thRok }
                {
                    visible ? (
                        <>
                            {thDaty}
                            <th
                                colSpan={3} 
                                style={{textAlign: "center", backgroundColor: "var(--podswietlenie)"}}>
                                Podsumowanie (wybrany zakres)
                            </th>
                        </>
                    ) : (
                        <>
                            <th
                                colSpan={3} 
                                style={{textAlign: "center", backgroundColor: "var(--podswietlenie)"}}>
                                Podsumowanie (wybrany zakres)
                            </th>
                        </>
                    )
                }
            </tr>

            {
                <tr key={0}>
                    {thRokCols}
                    {kolumnyMiesiace}
                    <th style={{backgroundColor: "var(--podswietlenie)"}}>
                        należności
                    </th>
                    <th style={{backgroundColor: "var(--podswietlenie)"}}>
                        wpłaty
                    </th>
                    <th style={{backgroundColor: "var(--podswietlenie)"}}>
                        saldo
                    </th>
                </tr>
            }
        </thead>
    ) 

}

export default WspolnotaRaportZbiorowyTHead;