export const dataCzas = (dateString) => {
    const date = new Date(dateString);
  
    // Wydobycie poszczególnych części daty
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Miesiące w JS zaczynają się od 0
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
  
    // Składanie wyniku w pożądanym formacie
    return `${year}-${month}-${day} ${hours}:${minutes}`;
}

export const strefa = (dateString) => {
    const date = new Date(dateString);
    return new Date(date.getFullYear(), date.getMonth(), date.getDate());
};

export const getMonthDifference = (date1, date2) => {
    
    const d1 = new Date(date1);
    const d2 = new Date(date2);
    
    const yearsDiff = d2.getFullYear() - d1.getFullYear();
    const monthsDiff = d2.getMonth() - d1.getMonth();
    
    return yearsDiff * 12 + monthsDiff;
};

export const dataFormat = (d) => {
    if (!d) {
        return "brak"
    } else {
        const data = new Date(Date.parse(d));
        const dzien = data.getDate() < 10 ? "0"+data.getDate() : data.getDate();
        const miesiac = (data.getMonth() + 1) < 10 ? "0"+(data.getMonth() + 1) : data.getMonth() + 1; // Dodajemy 1, ponieważ miesiące są indeksowane od 0
        const rok = data.getFullYear();
        const dataJakoTekst = `${dzien}-${miesiac}-${rok}`;
    
        return dataJakoTekst;
    }
}

export const rrrr_mm_dd_to_mm_rrrr = d => {
    const [year, month] = d.split("-");
    return `${month}-${year}`;
}

export const mm_rrrr_to_date = textDate => {
    const [month, year] = textDate.split('-');
    const formattedDate = `${year}.${month}.01`;
    return formattedDate;
}

export const rrrrmmdd_to_date = dateStr => {
    const year = parseInt(dateStr.substring(0, 4));
    const month = parseInt(dateStr.substring(4, 6));
    const day = parseInt(dateStr.substring(6, 8));
    return new Date(year, month - 1, day);
}

export const date_to_mm_rrrr = d => {
    if (!(d instanceof Date) || isNaN(d)) {
        return "Invalid date";
      }
    
      const month = String(d.getMonth() + 1).padStart(2, '0');
      const year = d.getFullYear();
    
      return `${month}-${year}`;
}

export const dataFormat2 = (d) => {

    const data = new Date(Date.parse(d));
    const dzien = data.getDate() < 10 ? "0"+data.getDate() : data.getDate();
    const miesiac = (data.getMonth() + 1) < 10 ? "0"+(data.getMonth() + 1) : data.getMonth() + 1; // Dodajemy 1, ponieważ miesiące są indeksowane od 0
    const rok = data.getFullYear();
    const dataJakoTekst = `${rok}-${miesiac}-${dzien}`;

    return dataJakoTekst;
}

export const dataFormatMsc = (d) => {
    const data = new Date(Date.parse(d));
    const miesiac = (data.getMonth() + 1) < 10 ? "0"+(data.getMonth() + 1) : data.getMonth() + 1; // Dodajemy 1, ponieważ miesiące są indeksowane od 0
    const rok = data.getFullYear();

    return `${miesiac}-${rok}`;
}

export const nextMonth = () => {
    const dzisiaj = new Date();
    const rok = dzisiaj.getFullYear();
    const aktualnyMiesiac = dzisiaj.getMonth();
  
    // Ustalanie daty pierwszego dnia kolejnego miesiąca
    return new Date(rok, aktualnyMiesiac + 1, 1);
}

export const addOneDay = d => {
    if (d === null)
        return d
    else {
        const data = new Date(d);
        data.setDate(data.getDate()+1);
        return data;
    }
}


export const minusOneDay = d => {
    if (d === null)
        return d
    else {
        const data = new Date(d);
        data.setDate(data.getDate()-1);
        return data;
    }
}

export const zamienTekstNaDate = (tekst) => {
    const [miesiac, rok] = tekst.split('-'); // Dzieli tekst na miesiąc i rok

    // Tworzy datę z pierwszego dnia miesiąca i podanego roku
    const data = new Date(`${rok}-${miesiac}-01`);
    
    return data;
}

export const rrrrmmdd_to_mm_rrrr = (dataTekst) => {

    const data = new Date(dataTekst);
    
    // Pobieranie miesiąca i roku z obiektu Date
    let miesiac = data.getMonth() + 1;  // getMonth zwraca miesiąc od 0 do 11, więc dodajemy 1
    const rok = data.getUTCFullYear();
    
    // Formatowanie miesiąca do postaci dwucyfrowej
    miesiac = miesiac < 10 ? '0' + miesiac : miesiac.toString();
  
    // Tworzenie nowego formatu daty

    return miesiac + '-' + rok;
}

export const dzien = (date = new Date()) => {
    const options = {
        weekday: 'long',  // Pełna nazwa dnia tygodnia
        day: '2-digit',   // Dzień miesiąca jako liczba zawsze dwucyfrowa
        month: 'long',    // Pełna nazwa miesiąca
        year: 'numeric'   // Rok jako liczba czterocyfrowa
    };

    // Formatowanie daty do języka polskiego z wykorzystaniem odpowiednich opcji
    return date.toLocaleDateString('pl-PL', options);
}

export const data_z_dniem = (date = new Date()) => {
    const days = ['niedziela', 'poniedziałek', 'wtorek', 'środa', 'czwartek', 'piątek', 'sobota'];
    const months = [
      'stycznia', 'lutego', 'marca', 'kwietnia', 'maja', 'czerwca', 
      'lipca', 'sierpnia', 'września', 'października', 'listopada', 'grudnia'
    ];
  
    const dayOfWeek = days[date.getDay()];
    const dayOfMonth = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();
  
    return `${dayOfWeek}, ${dayOfMonth} ${month} ${year} r.`;
}

export const dodajMiesiac = (d, i = 1) => {
    d.setMonth(d.getMonth() + i);
    return d;
}

export const pierwszyDzienMiesiaca = (date = new Date()) => {

    const rok = date.getFullYear();
    const miesiac = date.getMonth() ;
    const pierwszy = new Date(rok, miesiac, 1);
    return pierwszy
}

export const pierwszyDzienNastepnegoMiesiaca = (date = new Date()) => {
    const rok = date.getFullYear();
    const month = date.getMonth() + 1;
    return new Date(rok, month, 1);
} 

export const ostatniDzienMiesiaca = (date = new Date()) => {
    const year = date.getFullYear();
    const month = date.getMonth() + 1; // Dodajemy 1, ponieważ miesiące są indeksowane od 0

    // Tworzymy nową datę z następnym miesiącem, a dzień ustawiamy na 0, co daje nam ostatni dzień bieżącego miesiąca
    const lastDay = new Date(year, month, 0);
    return lastDay;
}

export const ostatniDzienNastepnegoMiesiaca = (dzisiaj = new Date()) => {

    const rok = dzisiaj.getFullYear();
    const miesiac = dzisiaj.getMonth();

    // Tworzymy datę pierwszego dnia miesiąca po następnym
    const pierwszyDzienPoNastMsc = new Date(rok, miesiac + 2, 1);

    // Odejmujemy 1 dzień, żeby dostać ostatni dzień następnego miesiąca
    const ostatniDzien = new Date(pierwszyDzienPoNastMsc - 1);

    return ostatniDzien;
}

export const monthsDiff = (startDateStr, endDateStr) => {
    const startDate = new Date(startDateStr);
    const endDate = new Date(endDateStr);

    // Pobieramy rok i miesiąc z obu dat
    const startYear = startDate.getFullYear();
    const startMonth = startDate.getMonth(); // 0-11 (styczeń = 0)
    const endYear = endDate.getFullYear();
    const endMonth = endDate.getMonth();

    // Obliczamy różnicę w miesiącach
    const monthDifference = (endYear - startYear) * 12 + (endMonth - startMonth) + 1;

    return monthDifference;
}

export function obliczDatyNoty() {
    const dzisiaj = new Date();
    const rok = dzisiaj.getFullYear();
    const miesiac = dzisiaj.getMonth();
    
    // Sprawdzenie czy dzień jest mniejszy niż 15
    const czyAktualnyMiesiac = dzisiaj.getDate() < 20;

    // Obliczenie odpowiedniego miesiąca i roku
    const uzywanyMiesiac = czyAktualnyMiesiac ? miesiac : miesiac + 1;
    const uzywanyRok = (uzywanyMiesiac > 11) ? rok + 1 : rok;
    const miesiacDoNaliczenia = (uzywanyMiesiac % 12) + 1;

    // Formatowanie datySprzedazy i terminZaplaty
    const dataSprzedazy = new Date(uzywanyRok, uzywanyMiesiac, 1);
    const terminZaplaty = new Date(uzywanyRok, uzywanyMiesiac, 11);
    
    // Formatowanie miesiacNaliczenia
    const miesiacNaliczenia = `${uzywanyRok}-${miesiacDoNaliczenia < 10 ? '0' : ''}${miesiacDoNaliczenia}`;
    const miesiacTytul = `${miesiacDoNaliczenia < 10 ? '0' : ''}${miesiacDoNaliczenia}/${uzywanyRok}`;

    // Funkcja pomocnicza do formatowania daty
    function formatujDate(date) {
        const dzien = date.getDate();
        const miesiac = date.getMonth() + 1;
        const rok = date.getFullYear();
        return `${dzien < 10 ? '0' : ''}${dzien}.${miesiac < 10 ? '0' : ''}${miesiac}.${rok}`;
    }

    return {
        dataSprzedazy: formatujDate(dataSprzedazy),
        terminZaplaty: formatujDate(terminZaplaty),
        miesiacNaliczenia: miesiacNaliczenia,
        miesiacTytul: miesiacTytul
    };
}