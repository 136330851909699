import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import WspolnotaRozliczenieSaldo from './WspolnotaRozliczenieSaldo'; 
import { dataFormatMsc, nextMonth } from '../../../functions/dataFormat';
import { znajdzNajblizszaStawke } from '../../../functions/stawki.js'
import { getTablicaPrzelewy2, getTablicaCzynsze2 } from "../../../functions/tablice";
import { wstepy } from '../../../functions/motions';
import { znajdzAktualny } from "../../../functions/stawki";
import Loader from "../../Loader";
import WspolnotaRozliczenieButtons from "./WspolnotaRozliczenieButtons.js";
import WspolnotaRozliczenieTable from "./WspolnotaRozliczenieTable.js";
import WspolnotaRozliczenieZaleglosci from "./WspolnotaRozliczenieZaleglosci.js";
import RozliczenieWydrukModal from './RozliczenieWydrukModal';
import WspolnotaRozliczenieRozliczeniaMieszkancow from "./WspolnotaRozliczenieRozliczeniaMieszkancow.js";
import MieszkaniecWydruk from "../../Mieszkaniec/Wydruk/MieszkaniecWydruk.js";
// import WspolnotaRozliczenieSMS from "./WspolnotaRozliczenieSMS.js";

import { 
  fetchWspolnota, 
  fetchMieszkancy, 
  fetchMieszkancyAll,
  fetchPrzelewy, 
  fetchLokale, 
  fetchUlice, 
  fetchStawki, 
  fetchStawkiInd,
  fetchStawkiJednorazowe,
  fetchGrupy, 
  fetchGrupyAll,
  fetchUdzialyKonta,
  fetchUdzialyKontaAkt,
  fetchUsers,
  fetchPlanowaneZuzycie,
  fetchStawkiJednorazoweInd
} from '../../../Redux/Actions.js'; 

const WspolnotaRozliczenie = () => {

  const queryParams = new URLSearchParams(window.location.search)
  const idw = queryParams.get("id");

  const dispatch = useDispatch();
  
  const [showPrint, setShowPrint] = useState(false)

  const wspolnotaArr = useSelector( state  => state?.wspolnota?.data);
  const mieszkancy = useSelector( state => state?.mieszkancy?.data);

  const lokale = useSelector( state => state?.lokale?.data);
  const ulice = useSelector( state => state?.ulice?.data);
  const grupy = useSelector( state => state?.grupy?.data);
  const udzialyKonta = useSelector( state => state?.udzialyKonta?.data);
  const udzialyKontaAkt = useSelector( state => state?.udzialyKontaAkt?.data);
  const przelewy = useSelector( state => state?.przelewy?.data);
  const stawki = useSelector( state => state?.stawki?.data);
  const stawkiJednorazowe = useSelector( state => state?.stawkiJednorazowe?.data);
  const planowaneZuzycie = useSelector( state => state?.planowaneZuzycie?.data);

  const [wspolnota, setWspolnota] = useState([]);
  const [miesiac, setMiesiac] = useState(dataFormatMsc(new Date()));
  const [listaMsc, setListaMsc] = useState(undefined); 

  const [stawkiAkt, setStawkiAkt] = useState(undefined);
  const [czynszeMsc, setCzynszeMsc] = useState(undefined);
  const stawkiInd = useSelector( state => state?.stawkiInd?.data );
  const stawkiJednorazoweInd = useSelector( state => state?.stawkiJednorazoweInd?.data);
  const [loaderOn, setLoaderOn] = useState(true);

  const [przelewyWspolnota, setPrzelewyWspolnota] = useState(undefined);
  const [czynszeWspolnota, setCzynszeWspolnota] = useState(undefined);

  const [czyRozliczenie, setCzyRozliczenie] = useState(true);
  const [rozliczeniaMieszkancow, setRozliczeniaMieszkancow] = useState(false);

  const [udzialyKonta_a, setUdzialyKonta_a] = useState([]);
  const [grupy_a, setGrupy_a] = useState([]);
  const [stawkiOst, setStawkiOst] = useState([]);
  const [mieszkaniec, setMieszkaniec] = useState(undefined);
  const [grupa_numer, setGrupa_numer] = useState(undefined);
  const [tablicaCzynszeAkt, setTablicaCzynszeAkt] = useState([])

  const zestaw = (id_user, grupa_numer) => {

    setUdzialyKonta_a( udzialyKontaAkt?.find(u => u.id_wspolnoty == idw && u.id_user == id_user && u.grupa_numer == grupa_numer) );
    setGrupy_a( grupy?.filter( g => g?.id_user == id_user && g?.grupa_numer == grupa_numer) );
    setMieszkaniec( mieszkancy?.find( m => m?.id_user == id_user));
    setGrupa_numer(grupa_numer);

    const czynsz1 = czynszeWspolnota?.find( c => c?.id_user == id_user).grupy_udzial_msc;
    const czynsz2 = czynsz1?.find( c => c?.grupa_numer == grupa_numer).temp_msc;
    setTablicaCzynszeAkt(czynsz2?.find( c => c?.msc == miesiac));
    

    handleShowPrintMieszkaniec();
  }


  useEffect( () => setStawkiOst(znajdzAktualny(stawki, miesiac)), [miesiac, stawki]);

  const [ind, setInd] = useState([]); 

  useEffect( ()=> {
    dispatch(fetchWspolnota(idw)); 
    dispatch(fetchMieszkancy(idw)); 
    dispatch(fetchUsers(idw));
    dispatch(fetchMieszkancyAll(idw));
    dispatch(fetchPrzelewy(idw)); 
    dispatch(fetchLokale(idw)); 
    dispatch(fetchUlice(idw)); 
    dispatch(fetchStawki(idw)); 
    dispatch(fetchStawkiInd(idw));
    dispatch(fetchStawkiJednorazowe(idw));
    dispatch(fetchStawkiJednorazoweInd(idw));
    dispatch(fetchGrupy(idw)); 
    dispatch(fetchGrupyAll(idw)); 
    dispatch(fetchUdzialyKonta(idw));
    dispatch(fetchUdzialyKontaAkt(idw));
    dispatch(fetchPlanowaneZuzycie(idw));
  },[dispatch, idw]);
  
  useEffect(() => setWspolnota( wspolnotaArr?.find( w => w.id == idw) ), [wspolnotaArr, idw])
  

  const dodajMiesiac = d => {
    d.setMonth(d.getMonth() + 1);
    return d;
  };

  useEffect( () =>{
    const temp = getTablicaPrzelewy2(wspolnotaArr, udzialyKontaAkt, przelewy)[0];
    if (temp?.length > 0) 
      setPrzelewyWspolnota(temp[0].grupy)
  },[wspolnotaArr, udzialyKontaAkt, przelewy])

  useEffect( () => setStawkiAkt( znajdzNajblizszaStawke(miesiac, stawki) ), [miesiac, stawki]);
  

  useEffect( () => {
    const temp = getTablicaCzynsze2(wspolnotaArr, mieszkancy, grupy, [],udzialyKonta, udzialyKontaAkt, lokale, stawki, planowaneZuzycie, stawkiJednorazowe, stawkiInd, stawkiJednorazoweInd /*, zaliczki */)[0];
    setCzynszeWspolnota(temp?.grupy);
  },[wspolnotaArr, mieszkancy, grupy, udzialyKonta, udzialyKontaAkt, lokale, stawki, planowaneZuzycie, stawkiJednorazowe, stawkiInd, stawkiJednorazoweInd /*, zaliczki */])

  useEffect( () => czynszeWspolnota && przelewyWspolnota ? setLoaderOn(false) : setLoaderOn(true), [czynszeWspolnota, przelewyWspolnota]);

  useEffect(()=> {
    if (stawki?.length > 0) {
      let minData = stawki[0].data_od
      for (let i = 1; i < stawki.length; i++) {
        if (stawki[i].data_od < minData) {
          minData = stawki[i].data_od;
        }
      }

      const dataObecna = new Date();
      const dataMiesiacPoObecnej = nextMonth(new Date(dataObecna));
      const tablicaMiesiecy = [];
      let aktualnaData = new Date(minData);
      while (aktualnaData <= dataMiesiacPoObecnej) {
        tablicaMiesiecy.push(dataFormatMsc(aktualnaData));
        aktualnaData = dodajMiesiac(aktualnaData);
      }
      setListaMsc(tablicaMiesiecy)
    }
  },[stawki]);

  useEffect(()=>{
    if (listaMsc) {
      setMiesiac(listaMsc[listaMsc?.length-1])
    }
  },[listaMsc])

  useEffect( () => { 
    const temp2  = [];
    czynszeWspolnota?.forEach( czynsz => {
      czynsz?.grupy_udzial_msc?.forEach( c => {
        const temp = c.temp_msc?.filter( t => t.msc == miesiac)
        if (temp && temp.length > 0) {
          const tempCzynszMsc = czynszeWspolnota?.find( cz => cz?.id_user == czynsz?.id_user && cz?.grupa_numer == czynsz?.grupa_numer)?.grupy_msc?.find( cc => cc?.grupa_numer == c.grupa_numer);
  
          temp2.push(     
            {
              id_user: czynsz.id_user,
              grupa_numer: c.grupa_numer,
              czynsz: temp[0],
              ind: tempCzynszMsc ? tempCzynszMsc?.temp_msc?.find( t => t?.msc == miesiac)?.jednorazoweIndTab : []
            }
          )
        }
      });
    });
    setCzynszeMsc(temp2)
  },[czynszeWspolnota, miesiac])

  useEffect( () => {
    const uniqueOpisSet = new Set();
    czynszeMsc?.forEach(obj => {
      if (Array.isArray(obj.ind)) {
          obj.ind.forEach(item => {
              if (item.opis) {
                  uniqueOpisSet.add(item.opis);
              }
          });
      }
    });
    setInd(Array.from(uniqueOpisSet))
  },[czynszeMsc])
    
  const handleShowPrint = () => setShowPrint( prevState => !prevState);
  const handleShowPrintMieszkaniec = () => setRozliczeniaMieszkancow( prevState => !prevState);
  
  return (
    <div 
      className="content wspolnotaRozliczenie"
      variants={wstepy}
      initial="initial"
      animate="animate"
    >
      <Loader stan={loaderOn} />
        <h1 className="nazwa">
          Należności wspólnoty 
        </h1>
        <h3>
          { wspolnota?.name }
        </h3>
        <div className="container">
          <div className="left">
            <WspolnotaRozliczenieButtons
              czyRozliczenie={czyRozliczenie}
              setCzyRozliczenie={setCzyRozliczenie}
              miesiac={miesiac}
              setMiesiac={setMiesiac}
              listaMsc={listaMsc}
              setShowPrint={setShowPrint}
              rozliczeniaMieszkancow={rozliczeniaMieszkancow}
              setRozliczeniaMieszkancow={setRozliczeniaMieszkancow}
            />
            {
              czyRozliczenie ? (
                <WspolnotaRozliczenieTable
                  stawki={stawkiAkt}
                  stawkiJednorazowe={stawkiJednorazowe}
                  czynszeMsc={czynszeMsc}
                  ind={ind}
                  idw={idw}
                  wspolnota={wspolnota}
                  lokale={lokale}
                  ulice={ulice}
                  grupy={grupy}
                  udzialyKontaAkt={udzialyKontaAkt}
                  przelewyWspolnota={przelewyWspolnota}
                  czynszeWspolnota={czynszeWspolnota}
                  mieszkancy={mieszkancy}
                  zestaw={zestaw}
                />
              ) : 
                <WspolnotaRozliczenieZaleglosci
                  czynszeMsc={czynszeMsc}
                  idw={idw}
                  udzialyKontaAkt={udzialyKontaAkt}
                  przelewyWspolnota={przelewyWspolnota}
                  czynszeWspolnota={czynszeWspolnota}
                  mieszkancy={mieszkancy}
                  ulice={ulice}
                  lokale={lokale}
                  grupy={grupy}
                />
            }

            <div style={{"height": "50px"}}></div>

            <WspolnotaRozliczenieSaldo 
              listaMsc={listaMsc} 
              przelewyWspolnota={przelewyWspolnota} 
              czynsze={czynszeWspolnota} 
              miesiac={miesiac}
              setMiesiac={setMiesiac}
            />
          </div>
          <div className="right">
            {rozliczeniaMieszkancow ? (
                <WspolnotaRozliczenieRozliczeniaMieszkancow
                  stawki={stawki}
                  miesiac={miesiac}
                  mieszkancy={mieszkancy}
                />
              ) : (<></>)
            }
          </div>

        </div>
        {
          rozliczeniaMieszkancow && (
            <MieszkaniecWydruk 
              udzialyKonta_a={udzialyKonta_a}
              showPrint={handleShowPrintMieszkaniec}
              mieszkaniec={mieszkaniec}
              tablicaCzynszeAkt={tablicaCzynszeAkt}
              stawkiOst={stawkiOst}
              stawkiJednorazowe={stawkiJednorazowe}
              stawkiJednorazoweInd={stawkiJednorazoweInd}
              lokale={lokale}
              ulice={ulice}
              grupy_a={grupy_a}
              miesiac={miesiac}
              grupa_numer={grupa_numer}
            />
          )
        }
        {
          showPrint && 
          <RozliczenieWydrukModal 
            showPrint={showPrint}
            handleShowPrint={handleShowPrint}
            wspolnota={wspolnota}
            miesiac={miesiac}
            stawki_w={stawkiAkt}
            stawkiJednorazowe={stawkiJednorazowe}
            czynszeMsc={czynszeMsc}
            idw={idw}
            lokale={lokale}
            ulice={ulice}
            grupy={grupy}
            udzialyKontaAkt={udzialyKontaAkt}
            przelewyWspolnota={przelewyWspolnota}
            czynszeWspolnota={czynszeWspolnota}
            mieszkancy={mieszkancy}
            ind={ind}
          />
        }
    </div>
  )
}

export default WspolnotaRozliczenie;