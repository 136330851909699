import React from "react";

import { dataFormat } from "../../functions/dataFormat";
import { zl } from "../../functions/utilities";

const MieszkaniecDaneHistoriaWplat = props => {

    const { tablicaPrzelewy_g } = props;

    let listaWplat = [];
    
    if (tablicaPrzelewy_g?.przelewy?.length > 0) {
        listaWplat = tablicaPrzelewy_g?.przelewy?.map( (p,i) => {
            return (
                <li key={i}>
                    <div style={{width: "100px"}}>
                        {dataFormat(p.data_operacji)}
                    </div>
                    <div>
                        {p.operacja}
                    </div>
                    <div>
                        {zl(p.kwota)}
                    </div>
                </li>
            )
        });
    }

    const wplaty = (
        <div 
            className="zmiana"
            style={{width: "385px", maxHeight: "230px", overflow: "auto"}}    

        >
            <ul className="historiaWplat ramka-inset-shadow" >
                {listaWplat?.length > 0 ? listaWplat : <span className="brakWplat">brak wpłat w tej grupie</span>}
            </ul>
        </div>
    )

    return (
        <div className="historia-wplat ramka">
            <h2>
                Historia wpłat 
            </h2>
            { wplaty}
        </div>
    )
}

export default MieszkaniecDaneHistoriaWplat;