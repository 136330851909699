import React, {useEffect, useState} from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { dataFormat } from '../../../../functions/dataFormat.js';
import { zl } from "../../../../functions/utilities.js";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPencil } from '@fortawesome/free-solid-svg-icons'
import FiltrWspolnoty from '../../FiltrWspolnoty.js';
import FiltrMieszkancy from '../../FiltrMieszkancy.js';
import HistoriaTransakcjiListaPlikow from './HistoriaTransakcjiListaPlikow.js';
import HistoriaTransakcjiTHead from './HistoriaTransakcjiTHead.js';
import HistoriaTransakcjiTBody from './HistoriaTransakcjiTBody.js';
import { fetchPrzelewy, fetchMieszkancy, fetchUdzialyKontaAkt } from '../../../../Redux/Actions.js'; 
import DialogYesNo from "../../../DialogYesNo.js";

const HistoriaTransakcji = (props) => {

    const {wspolnoty} = props;

    const dispatch = useDispatch();

    const [wybranaWspolnotaId, setWybranaWpolnotaId] = useState(0);
    const [wybranyMieszkaniecId, setWybranyMieszkaniecId] = useState(0);
    const [open, setOpen] = useState(false);
    const [trashId, setTrashId] = useState(0);
    const [przelewy_a, setPrzelewy_a] = useState([]);

    useEffect( ()=> {
        dispatch(fetchMieszkancy(wybranaWspolnotaId)); 
        dispatch(fetchPrzelewy(wybranaWspolnotaId)); 
        dispatch(fetchUdzialyKontaAkt(wybranaWspolnotaId));
    },[dispatch, wybranaWspolnotaId]);

    const mieszkancy = useSelector( state => state?.mieszkancy?.data);
    const przelewy = useSelector( state => state?.przelewy?.data);
    const udzialyKontaAkt = useSelector( state => state?.udzialyKontaAkt?.data);

    useEffect( ( ) => {
        if (wybranyMieszkaniecId == 0)
            setPrzelewy_a( przelewy?.filter( p => p.id_wspolnoty == wybranaWspolnotaId && p?.operacja !== "prowizja") )
        else if (wybranyMieszkaniecId  > 0)
            setPrzelewy_a( przelewy?.filter( p => p?.id_user == wybranyMieszkaniecId && p?.operacja !== "prowizja") )
    },[wybranaWspolnotaId, wybranyMieszkaniecId, przelewy]);



    async function usunPrzelew(id) {
        try {
          const response = await axios.post('/api/przelewy/usun', { id: id });
          if (response.data.success === true)  {
            dispatch(fetchPrzelewy(wybranaWspolnotaId)); 
          }

          console.log('Response from backend:', response.data);
        } catch (error) {
          console.error('Error sending ID to backend:', error);
        }
    }

    useEffect( () => {
        if (wspolnoty && wspolnoty?.length > 0)
            setWybranaWpolnotaId( wspolnoty[0]?.id )
    },[wspolnoty])

    return (
        <div className="przelewy">
            <DialogYesNo 
                open={open}
                setOpen={setOpen}
                message={`Czy na pewno chcesz usunąć ten przelew?`}
                f={usunPrzelew}
                id={trashId}
            />
            <h4 className="nazwa">
                Historia przelewów/transakcji
            </h4>

            <div className='historia'>
                <div className='filtry'>
                    <FiltrWspolnoty 
                        wspolnoty={wspolnoty}
                        wybranaWspolnotaId={wybranaWspolnotaId}
                        setWybranaWpolnotaId={setWybranaWpolnotaId}
                        setWybranyMieszkaniecId={setWybranyMieszkaniecId}

                    />
                    <FiltrMieszkancy 
                        mieszkancy_w={mieszkancy}
                        wybranyMieszkaniecId={wybranyMieszkaniecId}
                        setWybranyMieszkaniecId={setWybranyMieszkaniecId}
                    />
                </div> 
                <div className='tabela'>
                    <table>
                        <HistoriaTransakcjiTHead />
                        <HistoriaTransakcjiTBody
                            wybranyMieszkaniecId={wybranyMieszkaniecId}
                            wybranaWspolnotaId={wybranaWspolnotaId}
                            przelewy={przelewy}
                            mieszkancy={mieszkancy}
                            udzialyKontaAkt={udzialyKontaAkt}
                            setTrashId={setTrashId}
                            setOpen={setOpen}
                        />
                    </table>
                </div>

            </div>
            <HistoriaTransakcjiListaPlikow
                przelewy={przelewy}
                wspolnoty={wspolnoty}
                wybranaWspolnotaId={wybranaWspolnotaId}
            />
        </div>
    )
}

export default HistoriaTransakcji;