import React, { useState, useEffect, useContext } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { dataFormatMsc, getMonthDifference, dataFormat2, nextMonth, dodajMiesiac, mm_rrrr_to_date, ostatniDzienMiesiaca, 
    pierwszyDzienMiesiaca, pierwszyDzienNastepnegoMiesiaca, monthsDiff, date_to_mm_rrrr } from '../../../functions/dataFormat';
import { wstepy } from '../../../functions/motions';
import { getTablicaPrzelewy2, getTablicaCzynsze2 } from "../../../functions/tablice";
import { zaokr } from "../../../functions/utilities.js";
import Loader from "../../Loader";
import { AuthContext } from "../../../Context/AuthProvider";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPrint, faEye, faEyeSlash, faCalendarPlus, faCalendarMinus } from '@fortawesome/free-solid-svg-icons'

import WspolnotaRaportZbiorowyTHead from "./WspolnotaRaportZbiorowyTHead";
import WspolnotaRaportZbiorowyTFoot from "./WspolnotaRaportZbiorowyTFoot";
import WspolnotyRaportZbiorowyTBody from "./WspolnotaRaportZbiorowyTBody";
import ZbiorowyWydrukModal from "./ZbiorowyWydrukModal";
import GoBackBtn from "../../GoBackBtn.js";

import { 
    fetchWspolnota, 
    fetchMieszkancy, 
    fetchPrzelewy, 
    fetchLokale, 
    fetchUlice,
    fetchStawki, 
    fetchStawkiInd,
    fetchStawkiJednorazowe,
    fetchGrupy, 
    fetchGrupyAll,
    fetchUdzialyKonta,
    fetchUdzialyKontaAkt,
    fetchUsers,
    fetchPlanowaneZuzycie,
    fetchStawkiJednorazoweInd
  } from '../../../Redux/Actions.js'; 
import { usun_duplikaty } from "../../../functions/grupy.js";

const WspolnotaRaportZbiorowy = () => {

    const queryParams = new URLSearchParams(window.location.search)
    const idw = queryParams.get("id");

    const { auth, user, setAuth, role, setRole, userId, cred } = useContext(AuthContext);

    const dispatch = useDispatch();

    const udzialyKonta = useSelector( state => state?.udzialyKonta?.data);
    const udzialyKontaAkt = useSelector( state => state?.udzialyKontaAkt?.data);

    const stawki = useSelector( state => state?.stawki?.data);
    const stawkiInd = useSelector(state => state?.stawkiInd?.data);
    const stawkiJednorazowe = useSelector( state => state?.stawkiJednorazowe?.data);
    const stawkiJednorazoweInd = useSelector(state => state?.stawkiJednorazoweInd?.data);
    const mieszkancy = useSelector( state => state?.mieszkancy?.data);
    const wspolnotaArr = useSelector( state => state?.wspolnota?.data);
    const wspolnoty = [];
    const przelewy = useSelector(state => state?.przelewy?.data);
    const lokale = useSelector(state => state?.lokale?.data);
    const grupy = useSelector(state => state?.grupy?.data);
    const planowaneZuzycie = useSelector(state => state?.planowaneZuzycie?.data);
   
    const [idwZarzad, setIdwZarzad] = useState(null);
    const [showPrint, setShowPrint] = useState(false);

    const [id_wspolnoty, setId_wspolnoty] = useState(undefined);

    useEffect( () => setId_wspolnoty( idw === null ? idwZarzad : idw ),[idwZarzad, idw])

    const [czynszeWspolnota, setCzynszeWspolnota] = useState(undefined);
    const [czynszeWspolnota2, setCzynszeWspolnota2] = useState(undefined);
    const [przelewyWspolnota, setPrzelewyWspolnota] = useState(undefined);

    const [czyRok, setCzyRok] = useState(true);
    const [rok, setRok] = useState(undefined);
    const [przelewyRok, setPrzelewyRok] = useState(undefined);
    const [czynszeRok, setCzynszeRok] = useState(undefined);
    const [przelewyTotal, setPrzelewyTotal] = useState(undefined);
    const [czynszeTotal, setCzynszeTotal] = useState(undefined);
    const [przelewyRokObecny, setPrzelewyRokObecny] = useState(undefined);
    const [czynszeRokObecny, setCzynszeRokObecny] = useState(undefined);
    const [rokObecny, setRokObecny] = useState(undefined); 
    const [przelewyWidoczne, setPrzelewyWidoczne] = useState(undefined);
    const [czynszeWidoczne, setCzynszeWidoczne] = useState(undefined);
    const [ileMscDodac, setIleMscDodac] = useState(0);
 
    useEffect(() => {
        const mieszkaniec = mieszkancy?.find( m => m?.id_user == userId);
        setIdwZarzad( idw == null && mieszkaniec && mieszkaniec?.czy_zarzad == 1 ? wspolnota?.id : 0);
    },[mieszkancy, wspolnoty, idw])

    const [wspolnota, setWspolnota] = useState(undefined);
    const [zakresOd, setZakresOd] = useState(dataFormat2( new Date(new Date().getFullYear(), 0, 1) ));
    const [zakresDo, setZakresDo] = useState(dataFormat2(ostatniDzienMiesiaca()));
    const [listaMsc, setListaMsc] = useState(undefined);
    const [visible, setVisible] = useState(false);
    const [loaderOn, setLoaderOn] = useState(true);

    useEffect( ()=> {
        dispatch(fetchWspolnota(idw));
        dispatch(fetchMieszkancy(idw)); 
        dispatch(fetchUsers(idw));
        dispatch(fetchPrzelewy(idw)); 
        dispatch(fetchLokale(idw)); 
        dispatch(fetchUlice(idw));
        dispatch(fetchStawki(idw)); 
        dispatch(fetchStawkiInd(idw));
        dispatch(fetchStawkiJednorazowe(idw));
        dispatch(fetchStawkiJednorazoweInd(idw));
        dispatch(fetchGrupy(idw)); 
        dispatch(fetchGrupyAll()); 
        dispatch(fetchUdzialyKonta(idw));
        dispatch(fetchUdzialyKontaAkt(idw));
        dispatch(fetchPlanowaneZuzycie(idw));
    },[dispatch, idw]);

    useEffect( () => wspolnotaArr && wspolnotaArr?.length > 0 ? setWspolnota( wspolnotaArr[0]) : setWspolnota({}) , [wspolnotaArr]);

    useEffect( () => czynszeWspolnota2 && listaMsc && przelewyWspolnota ? setLoaderOn(false) : setLoaderOn(true), [czynszeWspolnota2, listaMsc, przelewyWspolnota])

    useEffect( () =>{
        const temp = getTablicaPrzelewy2(wspolnotaArr, udzialyKontaAkt, przelewy)[0];
        if (temp?.length > 0) 
            setPrzelewyWspolnota(temp[0].grupy)
    },[wspolnotaArr, udzialyKontaAkt, przelewy, id_wspolnoty])

    useEffect( () => { 
        setCzynszeWspolnota(getTablicaCzynsze2(wspolnotaArr, mieszkancy, grupy, [],udzialyKonta, udzialyKontaAkt, lokale, stawki, planowaneZuzycie, stawkiJednorazowe, stawkiInd, stawkiJednorazoweInd  /*, zaliczki */)[0]?.grupy)
    },[wspolnotaArr, idw, mieszkancy, grupy, udzialyKonta, udzialyKontaAkt, lokale, stawki, planowaneZuzycie, stawkiJednorazowe, stawkiInd, stawkiJednorazoweInd  /*, zaliczki */, id_wspolnoty])

    useEffect( () => setListaMsc(generujZakres(zakresOd, zakresDo)), [zakresOd, zakresDo, stawki, udzialyKontaAkt]);
    
    const handleZakresOd = e => {
        setZakresOd(dataFormat2(pierwszyDzienMiesiaca(new Date(e.target.value))));
        setListaMsc(generujZakres(e.target.value, zakresDo));
    }
    const handleZakresDo = e => {
        setZakresDo(dataFormat2(ostatniDzienMiesiaca(new Date(e.target.value))));
        setListaMsc(generujZakres(zakresOd, e.target.value));

        const diff = monthsDiff( pierwszyDzienNastepnegoMiesiaca(), e.target.value );
        setIleMscDodac( diff > 1 ? diff - 1: 0 );
    };

    useEffect( () => setCzynszeWspolnota2(czynszeWspolnota),[czynszeWspolnota]);

    useEffect( () => {

        const czynszeWspolnota_temp = structuredClone(czynszeWspolnota);

        if (czynszeWspolnota_temp && czynszeWspolnota_temp?.length > 0 && ileMscDodac > 0) {
            czynszeWspolnota_temp.forEach( czynsz => {
                czynsz.grupy_udzial_msc.forEach( grupa => {
                    const size = grupa.temp_msc.length;
                 
                    const ost_czynsz = grupa.temp_msc[size-1];
                    
                    for (let j = 1; j < ileMscDodac+1; j++) {

                        grupa.temp_msc.push({
                            ...ost_czynsz,
                            msc: date_to_mm_rrrr(dodajMiesiac(pierwszyDzienNastepnegoMiesiaca(), j))
                        })
                    }
                    grupa.temp_msc = usun_duplikaty(grupa.temp_msc);
                })
            }) 
            setCzynszeWspolnota2(czynszeWspolnota_temp)
        }
        
    },[ileMscDodac])

    const generujZakres = (zakresOd, zakresDo) => {
        let result = [];
        let currentDate = new Date(zakresOd);     
        currentDate.setDate(1);
        currentDate.setMonth(currentDate.getMonth());
        const l_msc = getMonthDifference(zakresOd, zakresDo) + 1;
        for (let i = 0; i < l_msc; i++) {
            result.push(dataFormatMsc(currentDate));
            currentDate.setMonth(currentDate.getMonth() + 1);   
        }
        return result;
    }

    useEffect( () => {
        const rok = new Date().getFullYear();
        setRokObecny(rok)
        const data_od = new Date(rok, 0, 1);
        const tablicaPrzelewy = przelewyWspolnota ? przelewyWspolnota?.map( item => {
            const sumaKwot = item.przelewy
                ?.filter( p => new Date(p.data_operacji) >= data_od && !isNaN(p.kwota))
                .reduce((acc,p) => acc + Number(p.kwota), 0);
            return {
                id_user: item.id_user,
                grupa_numer: item.nr_grupyUdzial,
                kwota: zaokr(sumaKwot, 2)
            };
        }) : [];
        setPrzelewyRokObecny(tablicaPrzelewy);

        const tablicaCzynsze = udzialyKontaAkt ? udzialyKontaAkt?.map( udzial => {
            const czynsze = czynszeWspolnota2 && czynszeWspolnota2?.find( c => c?.id_user == udzial?.id_user);
            const czynszeGrupa  = czynsze?.grupy_udzial_msc?.find( g => g?.grupa_numer == udzial.grupa_numer)?.temp_msc;
            const czynszeGrupaUwzglednione = czynszeGrupa?.filter( c => new Date(mm_rrrr_to_date(c?.msc)) >= data_od && new Date(mm_rrrr_to_date(c?.msc)) <= new Date());
            const sumaKwot = czynszeGrupaUwzglednione?.length > 0 ? czynszeGrupaUwzglednione.reduce((suma, item) => {
                return suma + (Number(item.czynsz_na_msc) || 0);
            }, 0) : 0;
            return {
                id_user: udzial.id_user,
                grupa_numer: udzial.grupa_numer,
                kwota: sumaKwot
            }
        }) : [];
        setCzynszeRokObecny(tablicaCzynsze)
    },[listaMsc, przelewyWspolnota, udzialyKontaAkt, czynszeWspolnota2]);

    useEffect( () => {
        const dataOd = new Date(zakresOd).setHours(0, 0, 0, 0);
        const dataDo = new Date(zakresDo).setHours(0, 0, 0, 0);

        const tablicaPrzelewy = przelewyWspolnota ? przelewyWspolnota?.map( item => {
            const sumaKwot = item.przelewy
                ?.filter( p => !isNaN(p.kwota) && new Date(p.data_operacji).setHours(0, 0, 0, 0) >= dataOd && new Date(p.data_operacji).setHours(0, 0, 0, 0) <= dataDo)
                .reduce((acc,p) => acc + Number(p.kwota), 0);
            return {
                id_user: item.id_user,
                grupa_numer: item.nr_grupyUdzial,
                kwota: zaokr(sumaKwot, 2)
            };
        }) : [];
        setPrzelewyWidoczne(tablicaPrzelewy);

        const tablicaCzynsze = udzialyKontaAkt ? udzialyKontaAkt?.map( udzial => {
            const czynsze = czynszeWspolnota2 && czynszeWspolnota2?.find( c => c?.id_user == udzial?.id_user);
            const czynszeGrupa  = czynsze?.grupy_udzial_msc?.find( g => g?.grupa_numer == udzial.grupa_numer).temp_msc;

            const czynszeGrupaUwzglednione = czynszeGrupa?.filter( c => new Date(mm_rrrr_to_date(c?.msc)).setHours(0, 0, 0, 0) >= dataOd && new Date(mm_rrrr_to_date(c?.msc)).setHours(0, 0, 0, 0) <= dataDo);
            const sumaKwot = czynszeGrupaUwzglednione?.length > 0 ? czynszeGrupaUwzglednione.reduce((suma, item) => {
                return suma + (Number(item.czynsz_na_msc) || 0);
            }, 0) : 0;
            return {
                id_user: udzial.id_user,
                grupa_numer: udzial.grupa_numer,
                kwota: sumaKwot
            }
        }) : [];
        setCzynszeWidoczne(tablicaCzynsze)
    },[listaMsc, przelewyWspolnota, zakresOd, zakresDo])

    useEffect( () => {
        if (czyRok) {
            const rok = listaMsc?.length > 0 ? listaMsc[0].split("-")[1]-1 : 0;
            setRok(rok);
            const data_do = rok > 0 ? new Date(rok, 11, 31) : undefined;
            const tablicaPrzelewy = rok > 0 && przelewyWspolnota ? przelewyWspolnota.map(item => {
                const sumaKwot = item.przelewy
                  ?.filter(p => new Date(p.data_operacji) <= data_do && !isNaN(p.kwota))
                  .reduce((acc, p) => acc + Number(p.kwota), 0);
                return {
                  id_user: item.id_user,
                  grupa_numer: item.nr_grupyUdzial,
                  kwota: zaokr(sumaKwot, 2)
                };
            }) : []
            setPrzelewyRok(tablicaPrzelewy);

            const tablicaCzynsze = rok > 0 ? udzialyKontaAkt?.map( udzial => {
                const czynsze = czynszeWspolnota2 && czynszeWspolnota2?.find( c => c?.id_user == udzial?.id_user);
                const czynszeGrupa  = czynsze?.grupy_udzial_msc?.find( g => g?.grupa_numer == udzial.grupa_numer)?.temp_msc;
                const czynszeGrupaUwzglednione = czynszeGrupa?.filter( c => new Date(mm_rrrr_to_date(c?.msc)) <= data_do );
                const sumaKwot = czynszeGrupaUwzglednione?.length > 0 ? czynszeGrupaUwzglednione.reduce((suma, item) => {
                    return suma + (Number(item.czynsz_na_msc) || 0);
                }, 0) : 0;
                return {
                    id_user: udzial.id_user,
                    grupa_numer: udzial.grupa_numer,
                    kwota: sumaKwot
                }
            }) : [];
            setCzynszeRok(tablicaCzynsze);            
        }
    }, [przelewyWspolnota, czynszeWspolnota2, czyRok, listaMsc, udzialyKontaAkt])

    useEffect( () => {
        const data_do = ostatniDzienMiesiaca(new Date());
        const tablicaPrzelewy = przelewyWspolnota && przelewyWspolnota.map(item => {
            const sumaKwot = item.przelewy
                ?.filter(p => new Date(p.data_operacji) <= data_do && !isNaN(p.kwota))
                .reduce((acc, p) => acc + Number(p.kwota), 0);
            return {
                id_user: item.id_user,
                grupa_numer: item.nr_grupyUdzial,
                kwota: zaokr(sumaKwot, 2)
            };
        });
        setPrzelewyTotal(tablicaPrzelewy);

        const tablicaCzynsze = udzialyKontaAkt.map( udzial => {
            const czynsze = czynszeWspolnota2 && czynszeWspolnota2?.find( c => c?.id_user == udzial?.id_user);
            const czynszeGrupa  = czynsze?.grupy_udzial_msc?.find( g => g?.grupa_numer == udzial.grupa_numer)?.temp_msc;
            const czynszeGrupaUwzglednione = czynszeGrupa?.filter( c => new Date(mm_rrrr_to_date(c?.msc)) <= data_do );
            const sumaKwot = czynszeGrupaUwzglednione?.length > 0 ? czynszeGrupaUwzglednione.reduce((suma, item) => {
                return suma + (Number(item.czynsz_na_msc) || 0);
            }, 0) : 0;

            return {
                id_user: udzial.id_user,
                grupa_numer: udzial.grupa_numer,
                kwota: sumaKwot
            }
        });
        setCzynszeTotal(tablicaCzynsze);            

    }, [przelewyWspolnota, czynszeWspolnota2, udzialyKontaAkt])
    
    const handleVisible = () => setVisible( prevState => !prevState);
    const handleShowPrint = () => setShowPrint( prevState => !prevState);

    return (
        <div 
            className="content wspolnotaRozliczenie zbiorowka"
            variants={wstepy}
            initial="initial"
            animate="animate"
        >
            <Loader stan={loaderOn} />
            <h1 className="nazwa">
                Raport zbiorowy wspólnoty
            </h1>
            <h3>
                { wspolnota?.name }
            </h3>
            <div className="buttonsZbiorowka">

                { idw !== null && <GoBackBtn /> }
                <input type="date" value={zakresOd} onChange={handleZakresOd}/>
                <input type="date" value={zakresDo} onChange={handleZakresDo}/>
                <FontAwesomeIcon 
                    icon={faPrint} 
                    className='pointer'
                    onClick={handleShowPrint}
                />
                {
                    visible ? (
                        <FontAwesomeIcon 
                            icon={faEyeSlash}
                            className="pointer"
                            onClick={handleVisible} 
                        />
                    ) : (
                        <FontAwesomeIcon 
                            icon={faEye}
                            className="pointer"
                            onClick={handleVisible} 
                        />
                    )
                }
                {
                    czyRok ? (
                        <FontAwesomeIcon
                            icon={faCalendarMinus}
                            className="pointer"
                            onClick={()=>setCzyRok(false)}
                        />
                    ) : (
                        <FontAwesomeIcon
                            icon={faCalendarPlus}
                            className="pointer"
                            onClick={()=>setCzyRok(true)}
                        />
                    )
                }
            </div>

            <div style={{"height": "20px"}}></div>
            <div className="table-container zbiorowka">
                <table className="lista tabela zbiorowka" >
                    <WspolnotaRaportZbiorowyTHead 
                        listaMsc={listaMsc}
                        visible={visible}
                        czyRok={czyRok}
                        rok={rok}
                        rokObecny={rokObecny}
                    />
                    <WspolnotyRaportZbiorowyTBody
                        listaMsc={listaMsc}
                        idw={idw !== null ? idw : idwZarzad}
                        idw_temp={idw}
                        czynszeWspolnota={czynszeWspolnota2}
                        przelewyWspolnota={przelewyWspolnota}
                        visible={visible}
                        czyRok={czyRok}
                        przelewyRok={przelewyRok}
                        czynszeRok={czynszeRok}
                        przelewyTotal={przelewyTotal}
                        czynszeTotal={czynszeTotal}
                        przelewyRokObecny={przelewyRokObecny}
                        czynszeRokObecny={czynszeRokObecny}
                        przelewyWidoczne={przelewyWidoczne}
                        czynszeWidoczne={czynszeWidoczne}
                    />
                    <WspolnotaRaportZbiorowyTFoot
                        listaMsc={listaMsc}
                        czynszeWspolnota={czynszeWspolnota2}
                        przelewyWspolnota={przelewyWspolnota}
                        visible={visible}
                        czyRok={czyRok}
                        przelewyRok={przelewyRok}
                        czynszeRok={czynszeRok}
                        przelewyTotal={przelewyTotal}
                        czynszeTotal={czynszeTotal}
                        przelewyRokObecny={przelewyRokObecny}
                        czynszeRokObecny={czynszeRokObecny}
                        przelewyWidoczne={przelewyWidoczne}
                        czynszeWidoczne={czynszeWidoczne}
                    />
                </table>
            </div>

            <div style={{"height": "50px"}}></div>
            {
                showPrint &&             
                <ZbiorowyWydrukModal 
                    handleShowPrint={handleShowPrint}
                    listaMsc={listaMsc}
                    wspolnota={wspolnota}
                    idw={idw}
                    setShowPrint={setShowPrint}
                    visible={visible}
                    czynszeWspolnota={czynszeWspolnota2}
                    przelewyWspolnota={przelewyWspolnota}
                />
            }
        </div>
    ) 

}

export default WspolnotaRaportZbiorowy;