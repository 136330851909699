import React, {useEffect, useState } from 'react';
import { zl } from '../../../functions/utilities.js';
import { arrayHasElement, usunKluczNaN, filterKeysWithNumbers } from '../../../functions/tablice.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPrint } from '@fortawesome/free-solid-svg-icons'
import Tooltip from '@mui/material/Tooltip';

const MieszkancyRozliczenieListTBody = (props) => {

    const { grupa_numer, czynszeMieszkaniec, listaMsc, wybierzMiesiac, stawki } = props;

    const [czyInd, setCzyInd] = useState(false);
    const [grupaUdzialMsc, setGrupaUdzialMsc] = useState([]);

    useEffect( () => {
        if (czynszeMieszkaniec?.grupy_msc?.length > 0) {
            const grupySprawdzane = czynszeMieszkaniec?.grupy_msc?.filter( c => c?.grupa_numer == grupa_numer);
            grupySprawdzane?.forEach( grupa => {
                grupa?.temp_msc?.forEach( g1 => {
                    if (g1?.jednorazoweIndTab?.length > 0)
                        setCzyInd(true);
                })
            })
        }
    },[czynszeMieszkaniec]);

    useEffect( () => {
        setGrupaUdzialMsc(czynszeMieszkaniec?.grupy_udzial_msc?.find( g => g?.grupa_numer == grupa_numer)?.temp_msc);
    },[czynszeMieszkaniec, grupa_numer]);

    const kwotyInd = (msc) => {
        const tab = [];
        const przeszukiwane = czynszeMieszkaniec?.grupy_msc?.filter( g => g?.grupa_numer == grupa_numer);
        przeszukiwane?.forEach( p => {
            const przeszukiwanyMsc = p?.temp_msc?.find( t => t?.msc == msc);
            if (przeszukiwanyMsc?.jednorazoweIndTab?.length > 0) {
                const arr = przeszukiwanyMsc?.jednorazoweIndTab;
                tab.push(...arr);
            }

        })
        const td = tab?.length > 0 ?
            tab.map( (c, i) => {        
                return (
                    <Tooltip
                        title={c.opis}
                        placement='top'
                        key={i}
                    >
                        <p className='u'>{
                            zl(c.kwota)}
                        </p>
                    </Tooltip>
                ) 
            }) : []
        return (
            <td>{td}</td>
        )
    }

    const tab = listaMsc?.map( (msc,i) => {
        const czynszMiesiac = grupaUdzialMsc?.find( czynsz => czynsz.msc == msc);
    
        return (   
            <tr key={i}>
                <td style={{"fontWeight": 800}} >{ msc }</td>
                { stawki?.czy_eksploatacja == "1" ? (<td>{ zl(czynszMiesiac?.eksploatacja) }</td> ) : ""}
                { stawki?.czy_garaz == "1" ? ( <td>{ zl(czynszMiesiac?.garaz) }</td> ) : ""}
                { stawki?.czy_strych == "1" ? (<td>{ zl(czynszMiesiac?.strych) }</td> ) : ""}
                { stawki?.czy_komorka == "1" ? (<td>{ zl(czynszMiesiac?.komorka) }</td> ) : ""}
                { stawki?.czy_utrzymanie_czystosci == "1" ? (<td>{ zl(czynszMiesiac?.utrzymanie_czystosci) }</td> ) : ""}
                { stawki?.czy_wywoz_smieci == "1" ?  (<td>{ zl(czynszMiesiac?.wywoz_smieci) }</td> ) : "" }
                { stawki?.czy_fundusz_remontowy == "1" ? (<td>{ zl(czynszMiesiac?.fundusz_remontowy) }</td> ) : ""}
                { stawki?.czy_konserw_domofonu == "1" ? (<td>{ zl(czynszMiesiac?.konserw_domofonu) }</td> ) : ""}
                { stawki?.czy_konserw_windy == "1" ? (<td>{ zl(czynszMiesiac?.konserw_windy) }</td> ) : ""}
                { stawki?.czy_opl_za_adm == "1" ? (<td>{ zl(czynszMiesiac?.opl_za_adm) }</td> ) : ""}
                { stawki?.czy_abonament_cw == "1" ? ( <td>{ zl(czynszMiesiac?.abonament_cw)}</td> ) : ""}
                { stawki?.czy_co == "1" ? (<td>{ zl(czynszMiesiac?.co) }</td> ) : ""}
                { stawki?.czy_energia_el_lokalu == "1" ? (<td>{ zl(czynszMiesiac?.energia_el_lokalu) }</td> ) : ""}
                { stawki?.czy_ryczalt_gaz == "1" ? (<td>{ zl(czynszMiesiac?.ryczalt_gaz) }</td> ) : ""}
                { stawki?.czy_zagosp_terenu == "1" ? (<td>{ zl(czynszMiesiac?.zagosp_terenu) }</td> ) : ""}
                { stawki?.czy_antena == "1" ? (<td>{ zl(czynszMiesiac?.antena) }</td> ) : ""}
                { stawki?.czy_ciepla_woda == "1" ? (<td>{ zl(czynszMiesiac?.ciepla_woda) }</td> ) : ""}
                { stawki?.czy_zimna_woda == "1" ? (<td>{ zl(czynszMiesiac?.zimna_woda) }</td> ) : ""}
                { stawki?.czy_dod_1 == "1" ? (<td>{ zl(czynszMiesiac?.dod_1) }</td> ) : ""}
                { stawki?.czy_dod_2 == "1" ? (<td>{ zl(czynszMiesiac?.dod_2) }</td> ) : ""}
                { stawki?.czy_dod_3 == "1" ? (<td>{ zl(czynszMiesiac?.dod_3) }</td> ) : ""}
                {
                    czyInd ? (
                        kwotyInd(msc) 
                    ) : (
                        ""
                    )
                }
                { <td>{ zl(czynszMiesiac?.czynsz_na_msc) }</td> }
                {   
                    <td
                        style={{textAlign: "center"}}
                    >
                        <FontAwesomeIcon 
                            icon={faPrint} 
                            className='pointer'
                            onClick={()=> {wybierzMiesiac(msc)}}
                        />
                    </td>
                }
            </tr>
        )
    })
    
    return (
        <tbody>
            {tab}
        </tbody>
    )
}

export default MieszkancyRozliczenieListTBody;