import React, {useContext, useState, useEffect} from "react";
import { NavLink, Link, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { AnimatePresence, motion, useCycle } from 'framer-motion';
import axios from "axios";
import { AuthContext } from '../Context/AuthProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faKey } from '@fortawesome/free-solid-svg-icons'
import { czyAktywnySerwis } from "../functions/utilities.js";

import { 
    fetchMieszkancy, 
    fetchSerwis
  } from '../Redux/Actions.js'; 

const NavigationMobile = () => {
    const navigate = useNavigate();
    const { auth, role } = useContext(AuthContext);
    const [ isFocus, setFocus ] = useState(false);
    const [inputValue, setInputValue] = useState("");
    const [czyAktywny, setCzyAktywny] = useState(false);
    const dispatch = useDispatch();


    useEffect( ()=> {
        dispatch(fetchMieszkancy()); 
        dispatch(fetchSerwis());
      },[dispatch]);

    const mieszkancy = useSelector( state => state?.mieszkancy?.data);
    const serwisy = useSelector( state => state?.serwis?.data);

    useEffect( () => {
        const odp = czyAktywnySerwis(serwisy);
        setCzyAktywny( odp );
    },[serwisy]);

    const [sidebar, setSidebar] = useState("sidebar visible")

    const logout = () => {
        axios.get("/api/logout")
            .then( res => {
                if (res.data.Status === "Success") {
                    // navigate('/');
                    window.location.reload(false);
                } else {
                    alert("error");
                }
            }).catch(err => console.log(err));
    }
    
    const uniqueUsersMap = new Map();
    mieszkancy?.forEach(user => {
      if (!uniqueUsersMap.has(user.id_user) || new Date(user.data_od) > new Date(uniqueUsersMap.get(user.id_user).data_od)) {
          uniqueUsersMap.set(user.id_user, user);
      }
    });
    const uniqueUsers = Array.from(uniqueUsersMap.values());

    const openHint = ( (id, idw) => {
        setFocus(false);
        navigate(`/mieszkancy/rozliczenia?id=${id}&idw=${idw}`);
    });

    const itemVariants = {
        closed: {
          opacity: 0
        },
        open: { opacity: 1 }
    };

    const sideVariants = {
        closed: {
          transition: {
            staggerChildren: 0.2,
            staggerDirection: -1
          }
        },
        open: {
          transition: {
            staggerChildren: 0.2,
            staggerDirection: 1
          }
        }
    };

    const [open, cycleOpen] = useCycle(false, true);

    return (
        <div className="mobile-menu" >
            <div className="bar">
                {/* <div className="x">
                    <FontAwesomeIcon icon={faXmark} className="x" style={x}/>
                </div> */}
                <div className="bar-logo">
                    <div className="logo">

                    </div>
                </div>

                <div className="h" onClick={cycleOpen}> 
                    <FontAwesomeIcon icon={faBars} className="h" />
                </div>
            </div>
            
            <AnimatePresence>
            {
                open && (
                    <motion.aside className={sidebar}
                        initial={{ width: 0 }} 
                        animate={{ width: "100%" }}
                        exit={{
                            width: 0,
                            transition: { delay: 0, duration: 0.3 }
                          }}
                    >
                        <motion.nav
                            initial="closed"
                            animate="open"
                            exit="closed"
                            variants={sideVariants}
                        >
                            <div className="bar-logo">
                                <div className="logo">
        
                                </div>
                            </div>
                            <motion.div className="positions"
                                          animate="open"
                                          exit="closed"
                                          variants={sideVariants}
                            >
                                <motion.ul>
                                    <motion.li onClick={cycleOpen}
                                        whileHover={{ scale: 1.1 }}
                                        variants={itemVariants}   
                                    >
                                        <Link to="https://bonadministrator.pl" target={"_blank"}> {"Strona główna"} </Link> 
                                    </motion.li>
                                    <motion.li onClick={cycleOpen}
                                        whileHover={{ scale: 1.1 }}
                                        variants={itemVariants}   
                                    >
                                        <Link to="https://bonadministrator.pl/o-nas" target={"_blank"}> {"O Nas"} </Link> 
                                    </motion.li>
                                    <motion.li onClick={cycleOpen}
                                        whileHover={{ scale: 1.1 }}
                                        variants={itemVariants}   
                                    >
                                        <Link to="https://bonadministrator.pl/oferta" target={"_blank"}> {"Oferta"} </Link> 
                                    </motion.li>
                                    <motion.li onClick={cycleOpen}
                                        whileHover={{ scale: 1.1 }}
                                        variants={itemVariants}   
                                    >
                                        <Link to="https://bonadministrator.pl/kontakt" target={"_blank"}> {"Kontakt"} </Link> 
                                    </motion.li>
                                    <motion.li onClick={cycleOpen}
                                        whileHover={{ scale: 1.1 }}
                                        variants={itemVariants}   
                                    >
                                        <Link to="https://bonadministrator.pl/ogloszenia" target={"_blank"}> {"Ogłoszenia"} </Link> 
                                    </motion.li>
                                    <motion.li onClick={cycleOpen}
                                        whileHover={{ scale: 1.1 }}
                                        variants={itemVariants}   
                                    >
                                        <Link to="/komunikaty"> {"Komunikaty"} </Link> 
                                    </motion.li>
                                    {role === "mieszkaniec" && !czyAktywny ? (
                                        <motion.li onClick={cycleOpen}
                                            whileHover={{ scale: 1.1 }}
                                            variants={itemVariants}   
                                        >
                                            <NavLink to="/mieszkaniec"> {"Moje dane"}  </NavLink> 

                                        </motion.li> ) : ""
                                    }
                                    {role === "admin" ? (
                                        <>                        
                                            <motion.li onClick={cycleOpen}
                                                whileHover={{ scale: 1.1 }}
                                                variants={itemVariants}   
                                            >
                                                <NavLink to="/wspolnoty"> {"Wspólnoty"} </NavLink> 
                                            </motion.li>
                                            <motion.li onClick={cycleOpen}
                                                whileHover={{ scale: 1.1 }}
                                                variants={itemVariants}   
                                            >
                                                <NavLink to="/woda"> {"Woda"} </NavLink> 
                                            </motion.li>
                                            <motion.li onClick={cycleOpen}
                                                whileHover={{ scale: 1.1 }}
                                                variants={itemVariants}   
                                            >
                                                <NavLink to="/ksiegowosc"> {"Księgowość"} </NavLink> 
                                            </motion.li>
                                            <motion.li 
                                                onClick={cycleOpen}
                                                whileHover={{ scale: 1.1 }}
                                                variants={itemVariants}   
                                            >
                                                <NavLink to="/logi">                                     
                                                    <FontAwesomeIcon 
                                                        icon={faKey} 
                                                        className="pointer"
                                                        style={{padding: '5px 10px'}}
                                                    />   
                                                </NavLink> 
                                            </motion.li>
                                            <motion.li onClick={cycleOpen}
                                                whileHover={{ scale: 1.1 }}
                                                variants={itemVariants}   
                                            >
                                                {/* <input 
                                                    className="searchMieszkaniec" 
                                                    placeholder="Znajdź mieszkańca"
                                                    onClick={()=>{setFocus(!isFocus)}}
                                                    value={inputValue.toLowerCase()}
                                                    onChange={(e)=>setInputValue(e.target.value.toLowerCase())}
                                                /> */}
                                                {
                                                    isFocus && (
                                                        <div className="hintList">
                                                        {uniqueUsers?.map((mieszkaniec, i) => {
                                                            const input = mieszkaniec?.imie_nazwisko?.toLowerCase();
                                                            const isMatch = input.indexOf(inputValue) > -1
                                                            return (
                                                                isMatch && (
                                                                <div key={i} className="hint" onClick={()=>openHint(mieszkaniec?.id_user, mieszkaniec?.id_wspolnoty)}>
                                                                    <div>
                                                                        {mieszkaniec?.imie_nazwisko}
                                                                    </div>
                                                                </div>)
                                                            )
                                                        })}
                                                    </div>
                                                    ) 
                                                }
                                            </motion.li>
                                        </>
                                    ) : "" }
                                    { auth == false || role==undefined? (
                                        <motion.li onClick={cycleOpen}
                                            hileHover={{ scale: 1.1 }}
                                            variants={itemVariants}  
                                        >
                                            <NavLink to="/login" > {"Logowanie"} </NavLink> 
                                        </motion.li>
                                    ): 
                                        ""
                                    }   
                                    
                                    <motion.li onClick={cycleOpen}
                                        whileHover={{ scale: 1.1 }}
                                        variants={itemVariants}   
                                    >
                                        <a>
                                            Zamknij
                                        </a>
                                    </motion.li>
                                    { auth && role!=undefined? (
                                        <motion.li onClick={logout}
                                            whileHover={{ scale: 1.1 }}
                                            variants={itemVariants}   
                                        >
                                            <a>
                                                Wyloguj
                                            </a>
                                        </motion.li>
                                    ) : "" }
                                </motion.ul>
                            </motion.div>
        
                        </motion.nav>
                    </motion.aside>
                )
            }
            </AnimatePresence>


    
        </div>

    )
}

export default NavigationMobile;