import React from "react";

const WspolnotaRozliczenieTHead = (props) => {

    const { stawki, stawkiJednorazowe, czynszeMsc, ind } = props;

    function containsJednorazowaKwota(czynszeMsc) {
      return czynszeMsc?.some(czynszObj => 
        czynszObj.czynsz && 'jednorazowaInd_kwota' in czynszObj.czynsz
      );
    }
    const indCols = ind?.map( (e,i) => 
      (
        <th 
          style={{maxWidth: "150px"}}
          key={i}
        >
            {e}
        </th>
      )
    )

    return (
      <thead>
        <tr key={0}>
          <th>Mieszkaniec</th>
          <th>Lokal</th>
            { stawki?.czy_eksploatacja == 1 ? ( <th>eks.</th> ) : ""   }
            { stawki?.czy_garaz == 1 ? ( <th>gar.</th> ) : ""}
            { stawki?.czy_strych == 1 ? ( <th>str.</th> ) : "" }
            { stawki?.czy_komorka ==  1 ? ( <th>kom.</th> ) : "" }
            { stawki?.czy_utrzymanie_czystosci == 1 ? ( <th>utr.</th> ) : ""}

            { stawki?.czy_wywoz_smieci == 1 ? ( <th>wyw.</th> ) : ""}
             
            { stawki?.czy_fundusz_remontowy == 1 ? ( <th>rem.</th> ) : ""} 
            { stawki?.czy_konserw_domofonu == 1 ? ( <th>dom.</th> ) : ""} 
            { stawki?.czy_konserw_windy == 1 ? ( <th>win.</th> ) : ""} 
            { stawki?.czy_opl_za_adm == 1 ? ( <th>adm.</th> ) : ""} 
            { stawki?.czy_abonament_cw == 1 ? ( <th>abo.</th> ) : ""} 
            { stawki?.czy_co == 1 ? ( <th>c.o.</th> ) : ""} 
            { stawki?.czy_energia_el_lokalu == 1 ? ( <th>ene.</th> ) : ""} 
            { stawki?.czy_ryczalt_gaz == 1 ? ( <th>gaz </th>) : ""} 
            { stawki?.czy_zagosp_terenu == 1 ? ( <th>zag.</th> ) : ""} 
            { stawki?.czy_antena == 1 ? ( <th>ant.</th> ) : ""} 
            { stawki?.czy_ciepla_woda == 1 ? ( <th>cie.</th> ) : ""} 
            { stawki?.czy_zimna_woda == 1 ? ( <th>zim.</th> ) : ""} 
            { stawkiJednorazowe?.dod_1_czy == 1 ? ( <th>{stawkiJednorazowe?.dod_1_opis}</th> ) : ""} 
            { stawkiJednorazowe?.dod_2_czy == 1 ? ( <th>{stawkiJednorazowe?.dod_2_opis}</th> ) : ""} 
            { stawkiJednorazowe?.dod_3_czy == 1 ? ( <th>{stawkiJednorazowe?.dod_3_opis}</th> ) : ""} 
            {
              containsJednorazowaKwota(czynszeMsc) ? (
                <>
                  {indCols}
                </>
              ) : <></>
            } 
          <th>Suma </th>
          <th>Drukuj</th>
        </tr>
      </thead>
    )
}

export default WspolnotaRozliczenieTHead;