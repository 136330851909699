import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { zl } from '../../../functions/utilities';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPrint } from '@fortawesome/free-solid-svg-icons'
import MieszkaniecWydruk from '../Wydruk/MieszkaniecWydruk';
import MieszkaniecStawkiStawki from './MieszkaniecStawkiStawki';
import MieszkaniecStawkiTHead from './MieszkaniecStawkiTHead';
import MieszkaniecStawkiJednorazowe from './MieszkaniecStawkiJednorazowe';
import MieszkaniecStawkiJednorazoweInd from './MieszkaniecStawkiJednorazoweInd';
import { rrrr_mm_dd_to_mm_rrrr } from '../../../functions/dataFormat';


const MieszkaniecStawki = props => {

    const { stawkiOst, tablicaCzynsze, mieszkaniec, udzialyKonta_a, lokale, ulice, grupy_a} = props;

    const [tablicaCzynszeAkt, setTablicaCzynszeAkt] = useState([]);
    const [czyWydruk, setCzyWydruk] = useState(false);
    
    const stawkiJednorazowe = useSelector( state => state?.stawkiJednorazowe?.data);
    const stawkiJednorazoweInd = useSelector( state => state?.stawkiJednorazoweInd?.data);

    useEffect( () => {
        let len = tablicaCzynsze?.temp_msc?.length;
        if (len && len > 1)
            setTablicaCzynszeAkt( tablicaCzynsze?.temp_msc[len - 2] );
        else if (len && len === 1)
        setTablicaCzynszeAkt( tablicaCzynsze?.temp_msc[0] );
    },[tablicaCzynsze])

    const showPrint = () => setCzyWydruk( prevState => !prevState );
    
    const [stawkiJednorazowe_msc, setStawkiJednorazowe_msc] = useState([]);
    const [stawkiJednorazoweInd_msc, setStawkiJednorazoweInd_msc] = useState([])

    useEffect( () => setStawkiJednorazowe_msc( stawkiJednorazowe?.find( stawki => stawki?.msc == tablicaCzynszeAkt?.msc && stawki?.id_wspolnoty == udzialyKonta_a?.id_wspolnoty )), [tablicaCzynszeAkt, udzialyKonta_a]);

    useEffect( () => setStawkiJednorazoweInd_msc( 
        stawkiJednorazoweInd?.filter( stawki => rrrr_mm_dd_to_mm_rrrr(stawki.msc ) == tablicaCzynszeAkt?.msc && stawki?.id_wspolnoty == udzialyKonta_a?.id_wspolnoty && stawki.id_user == mieszkaniec?.id_user)),
        [stawkiJednorazoweInd, tablicaCzynszeAkt]
    )

    return (
        <div className='stawkiAkt'>
            <div className='naglowek'>
                <h4>
                    Aktualne stawki:
                </h4>
                    <FontAwesomeIcon 
                        icon={faPrint} 
                        className='pointer'
                        onClick={showPrint}
                    />
            </div>
            <ul>
                <MieszkaniecStawkiTHead/>
                <MieszkaniecStawkiStawki
                    stawkiOst={stawkiOst}
                    tablicaCzynsze={tablicaCzynsze}
                />
                <MieszkaniecStawkiJednorazowe
                    stawkiJednorazowe_msc={stawkiJednorazowe_msc}
                />
                <MieszkaniecStawkiJednorazoweInd
                    stawkiJednorazoweInd_msc={stawkiJednorazoweInd_msc}
                />
                <li className='lastRow'>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div>
                        Razem:
                    </div>
                    <div>
                        {zl(tablicaCzynszeAkt?.czynsz_na_msc)}
                    </div>
                </li>
            </ul>
            
                { czyWydruk ? (
                    <MieszkaniecWydruk
                        udzialyKonta_a={udzialyKonta_a}
                        showPrint={showPrint}
                        mieszkaniec={mieszkaniec}
                        tablicaCzynszeAkt={tablicaCzynszeAkt}
                        stawkiOst={stawkiOst}
                        stawkiJednorazowe={stawkiJednorazowe}
                        stawkiJednorazoweInd={stawkiJednorazoweInd}
                        lokale={lokale}
                        ulice={ulice}
                        grupy_a={grupy_a}
                        miesiac={tablicaCzynszeAkt?.msc}
                        grupa_numer={udzialyKonta_a?.grupa_numer}
                    />
                ) : ""}
            

        </div>
    )

}

export default MieszkaniecStawki;