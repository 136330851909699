import React, { useContext, useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import Usterka from "./Usterka/Usterka.js";
import Loader from "../Loader.js";
import MieszkaniecMojeDane from "./MieszkaniecMojeDane.js";
import MieszkaniecGlosowanie from "./Glosowanie/MieszkaniecGlosowanie.js";
import Zarzad from "./Zarzad.js";
import WspolnotaRaportZbiorowy from "../Wspolnoty/RaportZbiorowy/WspolnotaRaportZbiorowy.js";
import { AuthContext } from "../../Context/AuthProvider";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBullhorn, faFileInvoiceDollar, faCheckToSlot } from '@fortawesome/free-solid-svg-icons'

import { 
    fetchWspolnota,
    fetchMieszkaniec,
    fetchPrzelewyU, 
    fetchUlice, 
    fetchLokale,
    fetchGrupyU, 
    fetchPlanowaneZuzycieU,
    fetchStawkiJednorazoweIndU,
    fetchUdzialyKontaU,
    fetchStawki, 
    fetchStawkiInd,
    fetchStawkiJednorazowe,
    fetchUdzialyKontaAktU
} from '../../Redux/Actions.js'; 

// import { motion } from 'framer-motion';
import { wstepy } from '../../functions/motions';

import { getTablicaCzynsze2, getTablicaPrzelewy2 } from "../../functions/tablice.js";

const Mieszkaniec = () => {

    const { userId } = useContext(AuthContext);

    const dispatch = useDispatch();   
    
    const [wspolnota, setWspolnota] = useState(undefined);
    const [tablicaCzynsze_u, setTablicaCzynsze_u] = useState(undefined);
    const [tablicaCzynsze_g, setTablicaCzynsze_g] = useState(undefined);
    const [tablicaPrzelewy_g, setTablicaPrzelewy_g] = useState(undefined);
    const [udzialyKonta_a, setUdzialyKonta_a] = useState(undefined);
    const [grupy_a, setGrupy_a] = useState(undefined);
    const [loaderOn, setLoaderOn] = useState(true);
    const [czyZarzad, setCzyZarzad] = useState(false);
    const [activeWindow, setActiveWindow] = useState("Moje dane");
    const [stawkiOst, setStawkiOst] = useState([]);
    // const [mieszkaniec, setMieszkaniec] = useState(undefined);
    
    const wspolnotaArr = useSelector( state => state?.wspolnota?.data);
    const mieszkancy = useSelector(state => state?.mieszkaniec?.data);
    const przelewy_u = useSelector( state => state?.przelewyU?.data);
    const grupyU = useSelector( state => state?.grupyU?.data);
    const planowaneZuzycie = useSelector( state => state?.planowaneZuzycieU?.data);
    const lokale = useSelector( state => state?.lokale?.data);
    const ulice = useSelector( state => state?.ulice?.data);

    const stawki = useSelector( state => state?.stawki?.data);
    const stawkiInd = useSelector( state => state?.stawkiInd?.data );
    const stawkiJednorazowe = useSelector( state => state?.stawkiJednorazowe?.data);
    const stawkiJednorazoweInd_u = useSelector( state => state?.stawkiJednorazoweIndU?.data);
        
    const udzialyKontaAktU = useSelector( state => state?.udzialyKontaAktU?.data);
    const udzialyKontaU = useSelector( state => state?.udzialyKontaU?.data);

    const [activeGroup, setActiveGroup] = useState({idw: 0, grupa_numer: 1});
    const [mieszkaniec, setMieszkaniec] = useState(undefined);

    useEffect( () => setWspolnota( wspolnotaArr && wspolnotaArr?.length > 0 ? wspolnotaArr[0] : [] ), [wspolnotaArr]);

    useEffect( () => {
        if (stawki && stawki?.length > 0)
            setStawkiOst(stawki[0])
    },[stawki]);

    useEffect( ( )=> {
        if (udzialyKontaAktU?.length > 0)  {
            setActiveGroup( { idw: udzialyKontaAktU[0].id_wspolnoty, grupa_numer: udzialyKontaAktU[0].grupa_numer } );
            setUdzialyKonta_a( udzialyKontaAktU[0] )
        }
    },[udzialyKontaAktU]);

    useEffect( () => setGrupy_a( grupyU?.filter( g => g.grupa_numer == activeGroup?.grupa_numer )) ,[grupyU, activeGroup]);

    useEffect( () => setMieszkaniec( mieszkancy?.length > 0 ? mieszkancy[0] : {} ), [mieszkancy]);

    useEffect( ()=> {
      dispatch(fetchWspolnota());
      dispatch(fetchMieszkaniec());
      dispatch(fetchPrzelewyU());
      dispatch(fetchGrupyU()); 
      dispatch(fetchLokale()); 
      dispatch(fetchUlice()); 
      dispatch(fetchPlanowaneZuzycieU());
      dispatch(fetchUdzialyKontaU());
      dispatch(fetchStawki()); 
      dispatch(fetchStawkiInd());
      dispatch(fetchStawkiJednorazowe());
      dispatch(fetchStawkiJednorazoweIndU());
      dispatch(fetchUdzialyKontaAktU());
      dispatch(fetchUdzialyKontaU());
    },[dispatch]);
    
    useEffect( () => setCzyZarzad( mieszkaniec?.czy_zarzad == 1 ? true : false), [mieszkaniec]);
        
    useEffect( () => {
        const przelewyTemp = getTablicaPrzelewy2(wspolnotaArr, udzialyKontaAktU, przelewy_u)[0][0];
        setTablicaPrzelewy_g( przelewyTemp?.grupy?.length > 0 ? przelewyTemp?.grupy?.find( g => g?.nr_grupyUdzial == activeGroup.grupa_numer)?.przelewy : [])
    },[wspolnotaArr, udzialyKontaAktU, przelewy_u, activeGroup]);
 
    useEffect( () => { 
        const tablicaTemp = getTablicaCzynsze2(wspolnotaArr, mieszkancy, grupyU, [],udzialyKontaU, udzialyKontaAktU, lokale, stawki, planowaneZuzycie, stawkiJednorazowe, stawkiInd, stawkiJednorazoweInd_u  /*, zaliczki */)[0];
        
        setTablicaCzynsze_u(tablicaTemp?.grupy?.find( g => g?.id_user == userId))
    },[wspolnotaArr, mieszkancy, grupyU,udzialyKontaU, udzialyKontaAktU, lokale, stawki, planowaneZuzycie, stawkiJednorazowe, stawkiInd, stawkiJednorazoweInd_u  /*, zaliczki */])

    useEffect( () => {
        setTablicaCzynsze_g( tablicaCzynsze_u?.grupy_udzial_msc?.find( g => g?.grupa_numer == activeGroup.grupa_numer )  );
    },[tablicaCzynsze_u, activeGroup]);

    const handleMojeDane = () => setActiveWindow("Moje dane");
    const handleRozliczenie = () => setActiveWindow("Saldo wspólnoty");
    const handleRaportZbiorczy = () => setActiveWindow("Raport zbiorczy");
    const handleUsterka = () => setActiveWindow("Usterka");
    const handleGlosowanie = () => setActiveWindow("Głosowanie");

    const window = () => {
        switch(activeWindow) {
            case "Usterka": 
                return <Usterka
                    mieszkaniec={mieszkaniec}
                    wspolnota={wspolnota}
                />
            case "Moje dane":
                return <MieszkaniecMojeDane
                    mieszkaniec={mieszkaniec}
                    ulice={ulice}
                    lokale={lokale}
                    grupyU={grupyU}
                    tablicaCzynsze_g={tablicaCzynsze_g}
                    tablicaPrzelewy_g={tablicaPrzelewy_g}
                    stawkiOst={stawkiOst}
                    stawkiJednorazowe_w={stawkiJednorazowe}
                    stawkiJednorazoweInd_u={stawkiJednorazoweInd_u}
                    grupy_a={grupy_a}
                    udzialyKonta_a={udzialyKonta_a}
                    setUdzialyKonta_a={setUdzialyKonta_a}
                    activeGroup={activeGroup}
                    setActiveGroup={setActiveGroup}
                    udzialyKontaAktU={udzialyKontaAktU}
                />
            case "Saldo wspólnoty":
                return <Zarzad />
            case "Raport zbiorczy":
                return <WspolnotaRaportZbiorowy 
                    />
            case "Głosowanie":
                return <MieszkaniecGlosowanie
                    wspolnota={wspolnota}
                    mieszkaniec={mieszkaniec}
                />
        }
    }

    useEffect( () => {
        if ( tablicaCzynsze_u && tablicaPrzelewy_g && lokale && ulice )
            setLoaderOn(false)
    },[ tablicaCzynsze_u, tablicaPrzelewy_g, lokale, ulice]);

    return (
        <div className="mieszkaniec">
            <Loader
                stan={loaderOn}
            />
            <div className="buttons">
                <button onClick={handleMojeDane}>
                    <FontAwesomeIcon icon={faFileInvoiceDollar} />
                    {`  `}
                    Moje dane
                </button>
                {
                    czyZarzad ? (
                        <>
                            <button className="raport" onClick={handleRozliczenie}>Saldo Wspólnoty</button>
                            <button className="raport" onClick={handleRaportZbiorczy}>Raport zbiorczy</button>
                        </>
                    ) : <></>
                }
                <button onClick={handleUsterka}>
                    <FontAwesomeIcon icon={faBullhorn} />
                    {`  `}
                    Zgłoś usterkę
                </button>
                <button onClick={handleGlosowanie}>
                    <FontAwesomeIcon icon={faCheckToSlot} />
                    {`  `}
                    Głosowanie
                </button>
            </div>
            { window() }
        </div>
    )
}

export default Mieszkaniec;