import React, { useContext, useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPersonDigging} from '@fortawesome/free-solid-svg-icons'
import { useSelector, useDispatch } from 'react-redux';
import { fetchSerwis } from '../../Redux/Actions';

const Serwis = () => {

    const dispatch = useDispatch();

    const [tresc, setTresc] = useState("");
    const [czyAktywny, setCzyAktywny] = useState(false);

    useEffect( () => {
        dispatch(fetchSerwis())
    },[dispatch]);

    const serwisy = useSelector( state => state?.serwis?.data);

    useEffect( () => {
        if (serwisy && serwisy?.length > 0 ) {
            let temp = false;
            serwisy.forEach( s => {
                if (s.data_do === null)
                    temp = true;
            });
            setCzyAktywny(temp);
            setTresc("");
        }
    },[serwisy]);

    useEffect( () => {
        if (czyAktywny) {
            const temp = serwisy?.find( s => s?.data_do === null)?.tresc;
            setTresc( temp && temp?.length > 0 ? temp : "Trwają prace serwisowe");
        }
    }, [czyAktywny, serwisy]);

    return (
        <div className="mieszkaniec">
     
            <div className="serwis">
                <p>
                    <FontAwesomeIcon icon={faPersonDigging} size="2xl" />
                </p>
                <p>
                    {tresc}
                </p>            
            </div>
        </div>
    )
}

export default Serwis;