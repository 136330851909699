import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint } from '@fortawesome/free-solid-svg-icons'
import axios from "axios";
import { dataFormat2, mm_rrrr_to_date, date_to_mm_rrrr } from "../../../functions/dataFormat.js";
import { zl } from "../../../functions/utilities.js";
import { znajdzNajblizszaStawke } from "../../../functions/stawki.js";
import { 
    fetchOdczyty
} from '../../../Redux/Actions.js';
import MieszkaniecDaneWodaRozliczenieZaliczki from "./MieszkaniecDaneWodaRozliczenieZaliczki.js";
import MieszkaniecDaneWodaRozliczeniePobrana from "./MieszkaniecDaneWodaRozliczeniePobrana.js";
import MieszkaniecDaneWodaRozliczenieKoszt from "./MieszkaniecDaneWodaRozliczenieKoszt.js";
import MieszkaniecDaneWodaRozliczeniePodsumowanie from "./MieszkaniecDaneWodaRozliczeniePodsumowanie.js";
import MieszkaniecDaneWodaRozliczenieSuma from "./MieszkaniecDaneWodaRozliczenieSuma.js";
import MieszkaniecDaneWodaRozliczenieWydrukModal from "./Wydruk/MieszkaniecDaneWodaRozliczenieWydrukModal.js";


const MieszkaniecDaneWodaRozliczenie = props => {
    
    const { grupaUdzial, odczyty, stawkiAkt, wybranyOdczyt, tablicaCzynsze_g, stawki_w, mieszkaniec, adres } = props;

    const [czynszeOkres, setCzynszeOkres] = useState(undefined);
    const [sumaZaliczek, setSumaZaliczek] = useState(undefined);
    const [zuzycie, setZuzycie] = useState({zimna: "obliczam...", ciepla: "obliczam..."});
    const [stawkaRozliczenie, setStawkaRozliczenie] = useState(undefined);
    const [podsumowanie, setPodsumowanie] = useState({
        zimnaPodsumowanie: "obliczam...",
        zimnaOpis: "",
        cieplaPodsumowanie: "obliczam...",
        cieplaOpis: "",
        sumaOpis: ""
    });

    const [showPrint, setShowPrint] = useState(false);

    useEffect( () => {
        const temp = [];
        if (wybranyOdczyt && wybranyOdczyt.okres_koniec && tablicaCzynsze_g) {
            tablicaCzynsze_g?.forEach( (t, i) => {
                if ( new Date(mm_rrrr_to_date(t.msc)) >= new Date(wybranyOdczyt.okres_poczatek) && new Date(mm_rrrr_to_date(t.msc)) <= new Date(wybranyOdczyt.okres_koniec) ) {
                    temp.push(t)
                }
            })
            setCzynszeOkres(temp)
        }
    },[wybranyOdczyt, tablicaCzynsze_g, grupaUdzial]);

    useEffect( () => {
        if (wybranyOdczyt?.okres_koniec) {
            const temp = czynszeOkres?.reduce(
                (acc, obj) => {
                  acc.zimna_woda += obj.zimna_woda ?? 0;
                  acc.ciepla_woda += obj.ciepla_woda ?? 0;
                  return acc;
                },
                { zimna_woda: 0, ciepla_woda: 0 });
    
            setSumaZaliczek(temp);
        }

    },[czynszeOkres, grupaUdzial]);

    useEffect( () => {
        setZuzycie(
            {
                zimna: wybranyOdczyt?.zimna_stan_koniec - wybranyOdczyt?.zimna_stan_poczatek,
                ciepla: wybranyOdczyt?.ciepla_stan_koniec - wybranyOdczyt?.ciepla_stan_poczatek,
                zimnaKoszt: ((wybranyOdczyt?.zimna_stan_koniec - wybranyOdczyt?.zimna_stan_poczatek) + (wybranyOdczyt?.ciepla_stan_koniec - wybranyOdczyt?.ciepla_stan_poczatek)) * stawkaRozliczenie?.zimna_woda,
                cieplaKoszt: ((wybranyOdczyt?.ciepla_stan_koniec - wybranyOdczyt?.ciepla_stan_poczatek)) * stawkaRozliczenie?.ciepla_woda
            }
        )
    },[wybranyOdczyt, stawkaRozliczenie, grupaUdzial]);

        
    useEffect( () => {
        if (wybranyOdczyt) {
            const temp = znajdzNajblizszaStawke(date_to_mm_rrrr(new Date()),stawki_w);
            setStawkaRozliczenie(temp);
        } 
    },[stawki_w, wybranyOdczyt, grupaUdzial]);



    useEffect( () => {
        let cieplaOpisTemp = "";
        if (sumaZaliczek?.ciepla_woda - zuzycie?.cieplaKoszt > 0)
            cieplaOpisTemp = "nadpłata";
        else if (sumaZaliczek?.ciepla_woda - zuzycie?.cieplaKoszt < 0)
            cieplaOpisTemp = "niedopłata"
        else if (sumaZaliczek?.ciepla_woda - zuzycie?.cieplaKoszt == 0)
            cieplaOpisTemp = "";

        let zimnaOpisTemp = "";
        if (sumaZaliczek?.zimna_woda - zuzycie?.zimnaKoszt > 0)
            zimnaOpisTemp = "nadpłata";
        else if (sumaZaliczek?.zimna_woda - zuzycie?.zimnaKoszt < 0)
            zimnaOpisTemp = "niedopłata"
        else if (sumaZaliczek?.zimna_woda - zuzycie?.zimnaKoszt == 0)
            zimnaOpisTemp = "";

        const sumaTotalTemp = (sumaZaliczek?.zimna_woda - zuzycie?.zimnaKoszt) + (sumaZaliczek?.ciepla_woda - zuzycie?.cieplaKoszt)
        let sumaOpisTemp = "";
        if (sumaTotalTemp > 0)
            sumaOpisTemp = "Nadpłata";
        else if (sumaTotalTemp < 0)
            sumaOpisTemp = "Niedopłata";
        else if (sumaTotalTemp == 0)
            sumaOpisTemp= "";

        setPodsumowanie({
            zimnaPodsumowanie: sumaZaliczek?.zimna_woda - zuzycie?.zimnaKoszt,
            zimnaOpis: zimnaOpisTemp,
            cieplaPodsumowanie: sumaZaliczek?.ciepla_woda - zuzycie?.cieplaKoszt,
            cieplaOpis: cieplaOpisTemp,
            suma: (sumaZaliczek?.zimna_woda - zuzycie?.zimnaKoszt) ,
            sumaTotal: sumaTotalTemp,
            sumaTotalOpis: sumaOpisTemp
        })
    },[sumaZaliczek, zuzycie ])

    const handlePrint = () => {
        setShowPrint( prevState => !prevState)
    }

    const handleShowPrint = () => setShowPrint( prevState => !prevState);

    return (
        <div className="planpowaneZuzycie rozliczenie">
            <div className="naglowek">
                <h5>
                    Rozliczenia (zamknięte okresy)
                </h5>
            </div>
            {
                wybranyOdczyt?.okres_koniec ? (
                    <div className="dane ramka-inset-shadow">
                        <div className="naglowek">
                            <p>
                                {`Wybrany okres: ${dataFormat2(wybranyOdczyt.okres_poczatek)} - ${dataFormat2(wybranyOdczyt.okres_koniec)}`}    
                            </p>
                            <FontAwesomeIcon 
                                icon={faPrint} 
                                className='pointer'
                                onClick={handleShowPrint}
                            />                        
                        </div>
                        <div className="tabele">
                            <MieszkaniecDaneWodaRozliczenieZaliczki
                                czynszeOkres={czynszeOkres}
                                sumaZaliczek={sumaZaliczek}
                            />
                            <MieszkaniecDaneWodaRozliczeniePobrana
                                wybranyOdczyt={wybranyOdczyt}
                            />        
                        </div>
                        {
                            wybranyOdczyt ? (
                                <>
                                    <MieszkaniecDaneWodaRozliczenieKoszt
                                        zuzycie={zuzycie}
                                        stawkaRozliczenie={stawkaRozliczenie}
                                    />
                                    <MieszkaniecDaneWodaRozliczeniePodsumowanie
                                        zuzycie={zuzycie}
                                        sumaZaliczek={sumaZaliczek}
                                        podsumowanie={podsumowanie}
                                    />
                                    <MieszkaniecDaneWodaRozliczenieSuma
                                        podsumowanie={podsumowanie}
                                    />
                                </>

                            ) : ""
                        }
                        {
                            showPrint ? (
                                <MieszkaniecDaneWodaRozliczenieWydrukModal
                                    setShowPrint={setShowPrint}
                                    czynszeOkres={czynszeOkres}
                                    sumaZaliczek={sumaZaliczek}
                                    wybranyOdczyt={wybranyOdczyt}
                                    zuzycie={zuzycie}
                                    stawkaRozliczenie={stawkaRozliczenie}
                                    podsumowanie={podsumowanie}
                                    mieszkaniec={mieszkaniec}
                                    adres={adres}
                                />
                            ) : ""
                        }

                    </div>
                ) : (
                    ""
                )
            }   
        </div>
    )
}

export default MieszkaniecDaneWodaRozliczenie;