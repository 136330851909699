import React from 'react';

const NotaNadawca = (props) => {

    const { wspolnota } = props;

    const nrDomu = wspolnota?.nr_domu ? wspolnota?.nr_domu : "";

    return (
        <section
            style={{
                display: 'flex',
                justifyContent: 'space-between',
                fontSize: '13px'
            }}
        >
            <div>
                <span>
                    <b>Wspólnota</b><br/>
                    {wspolnota?.name}<br/>
                    {`${wspolnota?.ulica} ${nrDomu}`}<br/>
                    {`${wspolnota?.kod_pocztowy} ${wspolnota?.miejscowosc}`}<br/>
                    {`NIP: ${wspolnota?.nip}`}
                </span>
            </div>
            <div>
                <span>
                    ORYGINAŁ
                </span>
            </div>
        </section>            
    )
}

export default NotaNadawca;