import React, { useContext, useEffect, useState } from 'react';
import MieszkaniecStawki from './MieszkaniecStawki.js';
import MieszkaniecRozliczenieList from './MieszkaniecRozliczenieList.js';
import MieszkaniecWplaty from './MieszkaniecWplaty.js';
import MieszkaniecSaldo from './MieszkaniecSaldo.js';
import { motion } from 'framer-motion';
import { wstepy } from '../../../functions/motions.js';
import { adresPro, findMieszkanieId } from '../../../functions/ulice.js';

const MieszkaniecRozliczenie = props => {

    const { activeGroup, tablicaCzynsze, tablicaPrzelewy_g, stawkiOst, stawkiJednorazowe_w, mieszkaniec, udzialyKonta_a, lokale, ulice, grupy_a, stawkiJednorazoweInd_u } = props;

    const i = findMieszkanieId(udzialyKonta_a, grupy_a, lokale );
    const adres = adresPro(i, lokale, ulice)
    return (
        <motion.div 
            className="rozliczeniaContainer ramka-br ramka-border-shadow"
            variants={wstepy}
            initial="initial"
            animate="animate"
        >
            <h3>
                {`Rozliczenia miesięczne ${adres}`}
            </h3>
            <MieszkaniecStawki 
                stawkiOst={stawkiOst}
                tablicaCzynsze={tablicaCzynsze}
                mieszkaniec={mieszkaniec}
                udzialyKonta_a={udzialyKonta_a}
                ulice={ulice}
                lokale={lokale}
                grupy_a={grupy_a}
            />
            <MieszkaniecSaldo 
                tablicaCzynsze={tablicaCzynsze}
                tablicaPrzelewy_g={tablicaPrzelewy_g}
            />
            <div className='tabele'>
                <MieszkaniecRozliczenieList 
                    activeGroup={activeGroup} 
                    tablicaCzynsze={tablicaCzynsze}
                    stawkiJednorazowe_w={stawkiJednorazowe_w}
                    stawkiJednorazoweInd_u={stawkiJednorazoweInd_u}
                />  
                <MieszkaniecWplaty 
                    tablicaPrzelewy_g={tablicaPrzelewy_g}
                />
            </div>
        </motion.div>
    )
}

export default MieszkaniecRozliczenie;