import React, {useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import NotyMasowa from './NotyMasowa';
import Loader from '../Loader';
import NotyTHead from './NotyTHead';
import NotyTBody from './NotyTBody';
import NotyHistoria from './NotyHistoria/NotyHistoria';
import { obliczDatyNoty } from '../../functions/dataFormat'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowsRotate } from '@fortawesome/free-solid-svg-icons';
import { getTablicaCzynsze2 } from '../../functions/tablice';
import { znajdzNajblizszaStawke } from '../../functions/stawki';
import { dataFormatMsc } from '../../functions/dataFormat';
import {
    fetchStawki,
    fetchWspolnota,
    fetchMieszkancy,
    fetchLokale,
    fetchGrupy,
    fetchUdzialyKonta,
    fetchUdzialyKontaAkt,
    fetchNoty,
    fetchPlanowaneZuzycie,
    fetchStawkiJednorazowe,
    fetchStawkiJednorazoweInd,
    fetchStawkiInd,
    fetchUlice
} from "../../Redux/Actions";

const Noty = () => {
    const queryParams = new URLSearchParams(window.location.search)
    const id_wspolnoty = queryParams.get("idw");
    const dispatch = useDispatch();
    const stawki = useSelector( state => state?.stawki?.data);
    const wspolnotaArr = useSelector( state  => state?.wspolnota?.data);
    const mieszkancy = useSelector( state => state?.mieszkancy?.data);
    const grupy = useSelector( state => state?.grupy?.data);
    const udzialyKonta = useSelector( state => state?.udzialyKonta?.data);
    const udzialyKontaAkt = useSelector( state => state?.udzialyKontaAkt?.data);
    const planowaneZuzycie = useSelector( state => state?.planowaneZuzycie?.data);
    const stawkiJednorazowe = useSelector( state => state?.stawkiJednorazowe?.data);
    const stawkiJednorazoweInd = useSelector( state => state?.stawkiJednorazoweInd?.data);
    const stawkiInd = useSelector( state => state?.stawkiInd?.data);
    const lokale = useSelector(state => state?.lokale?.data);
    const ulice = useSelector(state => state?.ulice?.data);
    const noty = useSelector( state  => state?.noty?.data);

    useEffect( ()=> {
        dispatch(fetchStawki(id_wspolnoty));
        dispatch(fetchWspolnota(id_wspolnoty)); 
        dispatch(fetchMieszkancy(id_wspolnoty)); 
        dispatch(fetchGrupy(id_wspolnoty)); 
        dispatch(fetchLokale(id_wspolnoty)); 
        dispatch(fetchUlice(id_wspolnoty)); 
        dispatch(fetchUdzialyKontaAkt(id_wspolnoty));
        dispatch(fetchPlanowaneZuzycie(id_wspolnoty));
        dispatch(fetchStawkiJednorazowe(id_wspolnoty));
        dispatch(fetchStawkiJednorazoweInd(id_wspolnoty));
        dispatch(fetchStawkiInd(id_wspolnoty));
        dispatch(fetchUdzialyKonta(id_wspolnoty));
        dispatch(fetchNoty(id_wspolnoty));
    },[dispatch, id_wspolnoty]);

    const [tablicaCzynsze, setTablicaCzynsze] = useState(false);

    const [activeWindow, setActiveWindow] = useState("nowe")
    const [tablica, setTablica] = useState([]);

    const [wspolnota, setWspolnota] = useState([]);

    const [loaderOn, setLoaderOn] = useState(true);

    const [activeDock, setActiveDock] = useState("");
    const [wysylkaStan, setWysylkaStan] = useState([]);
    const [stawkiAkt, setStawkiAkt] = useState(undefined);

    const datyNoty = obliczDatyNoty();

    useEffect(() => {
        const timer = setTimeout(() => {
          setLoaderOn(false)
        }, 4000);
    
        return () => clearTimeout(timer);
    }, []);

    useEffect( () => setWspolnota( wspolnotaArr && wspolnotaArr?.length > 0 ? wspolnotaArr[0] : [] ), [wspolnotaArr]);

    useEffect( () => {
        setTablicaCzynsze(getTablicaCzynsze2(wspolnotaArr, mieszkancy, grupy, [],udzialyKonta, udzialyKontaAkt, lokale, stawki, planowaneZuzycie, stawkiJednorazowe, stawkiInd, stawkiJednorazoweInd /*, zaliczki */))
    },[ wspolnotaArr, mieszkancy, grupy, udzialyKonta, udzialyKontaAkt, lokale, stawki, planowaneZuzycie, stawkiJednorazowe, stawkiInd, stawkiJednorazoweInd /*, zaliczki */])


    useEffect( () => setStawkiAkt( znajdzNajblizszaStawke( dataFormatMsc(new Date()), stawki ) ),  [stawki]);

    useEffect( () => {
        const temp = udzialyKontaAkt?.filter(udzial => {
            const mieszkaniec = mieszkancy?.find(m => m?.id_user == udzial?.id_user);
    
            if (mieszkaniec?.czy_nota == 1) {
              return true; // Warunek spełniony, zwracamy ten obiekt
            }
            return false; // Obiekt nie spełnia warunku
          }).map(udzial => {
            const mieszkaniec = mieszkancy?.find(m => m?.id_user == udzial?.id_user);
            const grupyNoty = tablicaCzynsze[0]?.grupyNoty?.find( g => g?.grupa_numer == udzial?.grupa_numer && g?.id_user == udzial?.id_user)
            return {
              mieszkaniec,
              nota: udzial?.id,
              udzialyKontaAkt_u: udzial,
              grupyNoty
            };
        });
        setTablica(temp);
    },[udzialyKontaAkt, mieszkancy, tablicaCzynsze ]);


    const handleSite = site => setActiveWindow(site);
    const handleRefresh = () => dispatch(fetchNoty(id_wspolnoty));
    
    const strona = () => {
        switch(activeWindow) {
            case "nowe":
                return (
                    <>
                        <div className='lista'>
                            <h4>
                                Noty w miesiącu {datyNoty.miesiacNaliczenia} 
                                <FontAwesomeIcon 
                                    icon={faArrowsRotate}
                                    className='pointer'
                                    style={{margin: "0 10px"}} 
                                    onClick={handleRefresh}
                                />
                            </h4>
                            
                            <table className="noty" >
                                <NotyTHead />
                                <NotyTBody
                                    grupy={grupy}
                                    lokale={lokale}
                                    ulice={ulice}
                                    wysylkaStan={wysylkaStan}
                                    tablica={tablica}
                                    setActiveDock={setActiveDock}
                                    noty={noty}
                                    grupyNoty={tablicaCzynsze?.grupyNoty}
                                    id_wspolnoty={id_wspolnoty}
                                />
                            </table>
                        </div>
                        <div className='preview'>
                            <NotyMasowa
                                tablicaObiektow={tablica}
                                setWysylkaStan={setWysylkaStan} 
                                wspolnota={wspolnota}   
                                tablicaCzynsze={tablicaCzynsze}
                                lokale={lokale}
                                setLoaderOn={setLoaderOn}
                                setActiveDock={setActiveDock}
                                activeDock={activeDock}
                                mieszkancy={mieszkancy}
                                noty={noty}
                                stawki={stawkiAkt}
                            />
                        </div>
                    </>

                )   
            case "historia":
                return (
                    <NotyHistoria
                        grupy={grupy}
                        udzialyKontaAkt={udzialyKontaAkt}
                        lokale={lokale}
                        ulice={ulice}
                        tablica={tablica}
                        noty={noty}
                        tablicaCzynsze={tablicaCzynsze}
                        mieszkancy={mieszkancy}
                    />
                )   
        }
    }

    return (
        <div className='noty'>
            <h1 className="nazwa">
                Noty obciążeniowe 
            </h1>
            <h3>
                { wspolnota?.name }
            </h3>
            <div className='buttons'>   
                <NavLink to="/wspolnoty"> 
                    <button>
                        Wróć do listy WM
                    </button>
                </NavLink>
                <button
                    onClick={()=>handleSite("nowe")}
                >
                    Generowanie i wysyłka
                </button>
                <button
                    onClick={()=>handleSite("historia")}
                >
                    Historia
                </button>
            </div>
            <div className='notyContainer'>
                {strona()}
            </div>

            <Loader
                stan={loaderOn}
            />
        </div>
    )
}

export default Noty;