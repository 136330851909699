import React, { useEffect, useState } from 'react';

import { motion } from 'framer-motion';
import { wstepy } from '../../functions/motions.js';
import { getTablicaPrzelewy2, getTablicaCzynsze2 } from '../../functions/tablice.js';

import MieszkaniecDaneInformacje from './MieszkaniecDaneInformacje.js';
import MieszkaniecDaneHistoriaWplat from './MieszkaniecDaneHistoriaWplat.js';
import MieszkaniecDaneGrupy from './MieszkaniecDaneGrupy.js';
import MieszkaniecDaneWoda from './Woda/MieszkaniecDaneWoda.js';
import MieszkaniecDaneDodajGrupe from './MieszkaniecDaneDodajGrupe.js';
import MieszkaniecDaneNaleznosci from './MieszkaniecDaneNaleznosci.js';
import MieszkaniecDaneSaldo from './MieszkaniecDaneSaldo.js';
import MieszkaniecDaneStawkiInd from './MieszkaniecDaneStawkiInd.js';

import Loader from '../Loader.js';

const MieszkaniecDane = (props) => {

    const { wspolnotaArr, przelewy, mieszkancy, mieszkaniec, mieszkaniecAll, grupy, udzialyKonta, udzialyKontaAkt, lokale, planowaneZuzycie, stawkiJednorazowe, 
        stawkiJednorazoweInd, stawkiInd, wspolnota, 
        editDataOd, setEditDataOd, editDataDo, setEditDataDo, 
        user, grupaUdzial, grupy_g, taGrupaUdzial, ulicaId, addGrupe, setAddGrupe, imie, setImie, email, setEmail, telefon, 
        setTelefon, stawki, nota, setNota, zarzad, setZarzad, notaDane, setNotaDane, wybranyOdczyt, setWybranyOdczyt
    } = props; 
    
    const [zmianyMieszkancaPokaz, setZmianyMieszkancaPokaz] = useState( false );

    const [tablicaCzynsze, setTablicaCzynsze] = useState(undefined);
    const [tablicaCzynsze_g, setTablicaCzynsze_g] = useState(undefined);
    const [tablicaPrzelewy, setTablicaPrzelewy] = useState(undefined);
    const [tablicaPrzelewy_g, setTablicaPrzelewy_g] = useState(undefined);
    const [mieszkaniecArr, setMieszkaniecArr] = useState([]);
    const [grupy_u, setGrupy_u] = useState([]);
    const [udzialyKonta_u, setUdzialyKonta_u] = useState([]);
    const [udzialyKontaAkt_u, setUdzialyKontaAkt_u] = useState([]);
    const [planowaneZuzycie_u, setPlanowaneZuzycie_u] = useState([]);
    const [stawkiInd_g, setStawkiInd_g] = useState([]);
    const [stawkiJednorazoweInd_u, setStawkiJednorazoweInd_u] = useState([]);

    const [loaderOn, setLoaderOn] = useState(false);

    useEffect( () => setMieszkaniecArr(mieszkancy?.filter( m => m?.id_user == mieszkaniec?.id_user)), [mieszkancy, mieszkaniec]);
    useEffect( () => setGrupy_u( grupy?.filter( g => g?.id_user == mieszkaniec?.id_user) ),[grupy, mieszkaniec]);
    useEffect( () => setUdzialyKonta_u( udzialyKonta?.filter( u => u?.id_user == mieszkaniec?.id_user)), [udzialyKonta, mieszkaniec]);
    useEffect( () => setUdzialyKontaAkt_u( udzialyKontaAkt?.filter( u => u?.id_user == mieszkaniec?.id_user)), [udzialyKontaAkt, mieszkaniec]);
    useEffect( () => setStawkiJednorazoweInd_u( stawkiJednorazoweInd?.filter( s => s?.id_user == mieszkaniec?.id_user)), [stawkiJednorazoweInd, mieszkaniec]);
    useEffect( () => setPlanowaneZuzycie_u( planowaneZuzycie?.filter( p => p?.id_user == mieszkaniec?.id_user )), [planowaneZuzycie, mieszkaniec]);
    useEffect( () => setStawkiInd_g(stawkiInd?.filter( s => s?.id_user == grupaUdzial?.id_user && s?.grupa_numer == grupaUdzial?.grupa_numer)),[stawkiInd, grupaUdzial]);

    useEffect( () => {
        setTablicaCzynsze(getTablicaCzynsze2(wspolnotaArr, mieszkaniecArr, grupy_u, [], udzialyKonta_u, udzialyKontaAkt_u, lokale, stawki, planowaneZuzycie_u, stawkiJednorazowe, stawkiInd_g, stawkiJednorazoweInd_u /*, zaliczki */))
    },[wspolnotaArr, mieszkaniecArr, grupy_u, udzialyKonta_u, udzialyKontaAkt_u, lokale, stawki, planowaneZuzycie_u, stawkiJednorazowe, stawkiInd_g, stawkiJednorazoweInd_u /*, zaliczki */])

    useEffect( () => {
        if (tablicaCzynsze?.length > 0) {
            const u = tablicaCzynsze[0]?.grupy?.find( t => t?.id_user == grupaUdzial?.id_user)?.grupy_udzial_msc;            
            const g = u?.find( u => u?.grupa_numer == grupaUdzial?.grupa_numer)
            setTablicaCzynsze_g( g?.temp_msc )
        }
    },[tablicaCzynsze, grupaUdzial]);

    useEffect( () => {
        const temp = getTablicaPrzelewy2(wspolnotaArr, udzialyKontaAkt_u, przelewy)[0];
        if (temp?.length > 0)
            setTablicaPrzelewy( temp[0].grupy );
    },[ wspolnotaArr, udzialyKontaAkt_u, przelewy]);

    useEffect( () => setTablicaPrzelewy_g( tablicaPrzelewy?.find( t => t?.nr_grupyUdzial == grupaUdzial?.grupa_numer && t?.id_user == grupaUdzial?.id_user) ), [tablicaPrzelewy, grupaUdzial]);

    // useEffect( () => setLoaderOn( tablicaCzynsze && tablicaPrzelewy_g ? false : true ), [tablicaCzynsze, tablicaPrzelewy_g]);

    return(
        <motion.div 
            className="content mieszkancyDane ramka-inset-shadow"
            variants={wstepy}
            initial="initial"
            animate="animate"
        >
            <div className='lewa'>
                <MieszkaniecDaneInformacje
                    mieszkaniec={mieszkaniec}
                    user={user}
                    mieszkaniecAll={mieszkaniecAll}
                    zmianyMieszkancaPokaz={zmianyMieszkancaPokaz}
                    setZmianyMieszkancaPokaz={setZmianyMieszkancaPokaz}
                    setAddGrupe={setAddGrupe}
                    imie={imie}
                    setImie={setImie}
                    telefon={telefon}
                    setTelefon={setTelefon}
                    email={email}
                    setEmail={setEmail}
                    nota={nota}
                    setNota={setNota}
                    zarzad={zarzad}
                    setZarzad={setZarzad}
                    notaDane={notaDane}
                    setNotaDane={setNotaDane}
                />
                {
                    ulicaId !== -1 ? (
                        <>
                            <MieszkaniecDaneSaldo
                                grupaUdzial={grupaUdzial}
                                tablicaPrzelewy_g={tablicaPrzelewy_g}
                                tablicaCzynsze_g={tablicaCzynsze_g}
                            />
                            <MieszkaniecDaneHistoriaWplat
                                grupaUdzial={grupaUdzial}
                                tablicaPrzelewy_g={tablicaPrzelewy_g}
                            />
                            <MieszkaniecDaneNaleznosci
                                grupaUdzial={grupaUdzial}
                                tablicaCzynsze_g={tablicaCzynsze_g}
                            /> 
                        </>
                    ) : (<></>)
                }
            </div>
            {
                <div className='prawa'>
                    {   
                        addGrupe ? (
                            <MieszkaniecDaneDodajGrupe
                                mieszkaniec={mieszkaniec}
                                id_wspolnoty={wspolnota?.id}
                                setAddGrupe={setAddGrupe}
                                ktoraGrupa={udzialyKontaAkt?.filter( u => u?.id_user == mieszkaniec?.id_user)?.length + 1}
                            />
                        ) : (
                            ulicaId !== -1 && grupaUdzial? (
                                <> 
                                    <MieszkaniecDaneGrupy
                                        mieszkaniec={mieszkaniec}
                                        grupy_g={grupy_g}
                                        grupaUdzial={grupaUdzial}
                                        wspolnota={wspolnota}
                                        editDataOd={editDataOd}
                                        setEditDataOd={setEditDataOd}
                                        editDataDo={editDataDo}
                                        setEditDataDo={setEditDataDo}
                                        taGrupaUdzial={taGrupaUdzial}
                                    />  
                                    <MieszkaniecDaneStawkiInd
                                        grupaUdzial={grupaUdzial}
                                        stawki={stawki}
                                        stawkiInd_g={stawkiInd_g}
                                    />
                                  <MieszkaniecDaneWoda
                                        grupaUdzial={grupaUdzial}
                                        tablicaCzynsze_g={tablicaCzynsze_g}
                                        wybranyOdczyt={wybranyOdczyt}
                                        setWybranyOdczyt={setWybranyOdczyt}
                                        mieszkaniec={mieszkaniec}
                                        grupy_g={grupy_g}
                                    />
                                </>
                            ) : (<></>)
                        )
                    }
                </div>
            }
            <Loader
                stan={loaderOn}
            />
        </motion.div>
    )
}

export default MieszkaniecDane;