import React from "react";
import { zl, isNumber } from "../../../functions/utilities";
import { mm_rrrr_to_date } from "../../../functions/dataFormat";

const WspolnotaRaportZbiorowyTFoot = ( props ) => {

    const { listaMsc, czynszeWspolnota, przelewyWspolnota, visible, czyRok, przelewyRok, czynszeRok, przelewyTotal, czynszeTotal, 
        przelewyRokObecny, czynszeRokObecny, przelewyWidoczne, czynszeWidoczne } = props;

    const sumujNaleznosciMsc = (msc) => {
        let suma = 0;
        czynszeWspolnota?.forEach( czynsz => {
            czynsz?.grupy_udzial_msc?.forEach( grupa => {
                const mscTemp = grupa?.temp_msc?.find( g => g?.msc === msc)
                const lastTemp = grupa?.temp_msc[grupa?.temp_msc?.length - 1]
                const czynszTemp = mscTemp === undefined && mm_rrrr_to_date(msc) >= mm_rrrr_to_date(grupa?.temp_msc[grupa?.temp_msc?.length - 1].msc) ? lastTemp : mscTemp;
                
                    suma += isNumber(czynszTemp?.czynsz_na_msc) && (czynszTemp?.czynsz_na_msc > 0 || czynszTemp?.czynsz_na_msc == 0 || czynszTemp?.czynsz_na_msc < 0 ) ? czynszTemp?.czynsz_na_msc : 0;

            })
        })
        return suma;
    }

    const sumujWplatyMsc = (msc) => {
        let suma = 0;
        przelewyWspolnota?.forEach(przelew => {
            przelew?.przelewy?.forEach( p => {
                if (p?.msc === msc)
                    suma += p?.kwota;
            })
        })
        return suma;
    }

    const podsumowanieMiesiac = listaMsc?.map( (msc, i) => {

        if (!visible) {

            return (
                <>
                </>
            )
        } else {
            return (
                <>
                    <td style={{ whiteSpace: "nowrap" }}>
                        {zl(sumujNaleznosciMsc(msc))}
                    </td>
                    <td style={{whiteSpace: "nowrap" }}>
                        {zl(sumujWplatyMsc(msc))}
                    </td>
                    <td style={{fontWeight: "800", whiteSpace: "nowrap" }}>
                        {zl( sumujWplatyMsc(msc) - sumujNaleznosciMsc(msc))}
                    </td>
                </>
            )

        }
    })

    const podsumowanieRokTotal = () => {
        
        const sumaPrzelewy = przelewyTotal?.reduce((suma, item) => {
            return suma + (Number(item.kwota) || 0);
        }, 0);

        const sumaCzynsze = czynszeTotal?.reduce((suma, item) => {
            return suma + (Number(item.kwota) || 0);
        }, 0);

        const sumaPrzelewyWidoczne = przelewyWidoczne?.reduce((suma, item) => {
            return suma + (Number(item.kwota) || 0);
        }, 0);

        const sumaCzynszeWidoczne = czynszeWidoczne?.reduce((suma, item) => {
            return suma + (Number(item.kwota) || 0);
        }, 0);

        return (
            <>
                <td style={{whiteSpace: "nowrap", backgroundColor: "var(--podswietlenie)" }}>
                    {zl(sumaCzynszeWidoczne)}
                </td>
                <td style={{whiteSpace: "nowrap", backgroundColor: "var(--podswietlenie)" }}>
                    {zl(sumaPrzelewyWidoczne)}
                </td>
                <td style={{fontWeight: "600", whiteSpace: "nowrap", backgroundColor: "var(--podswietlenie)"}}>
                    {zl( sumaPrzelewyWidoczne - sumaCzynszeWidoczne)}                    
                </td>
            </>
        )
    }

    const sumaPrzelewyRok = czyRok ? 
        przelewyRok?.reduce((suma, item ) => {
            return suma + (Number(item.kwota) || 0);
        },0) : 0;
    const sumaCzynszeRok = czyRok ? 
        czynszeRok?.reduce((suma, item) => {
            return suma + (Number(item.kwota) || 0);
        },0) : 0;

    const rok = czyRok ? (
        <td style={{fontWeight: "600", whiteSpace: "nowrap", backgroundColor: "var(--podswietlenie)"}}>
            { zl( sumaPrzelewyRok - sumaCzynszeRok )}
        </td>
    ) : <></>;

    const podsumowanieFooter = (
        <tr>
            <>
                <td colSpan={2}>

                </td>
                {rok}
                {podsumowanieMiesiac}
                {podsumowanieRokTotal()}
            </> 
        </tr>
    )

    return (
        <tfoot>
            {podsumowanieFooter}
        </tfoot>
    ) 

}

export default WspolnotaRaportZbiorowyTFoot;