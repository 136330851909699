import React, { useEffect, useState } from "react";
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPlus, faChevronLeft, faCheck } from '@fortawesome/free-solid-svg-icons'
import axios from "axios";
import { dataFormat } from "../../../functions/dataFormat.js";

import { 
    fetchPlanowaneZuzycie
} from '../../../Redux/Actions.js';
import DialogYesNo from "../../DialogYesNo.js";

const MieszkaniecDaneWodaPlanowaneZuzycie = props => {

    const dispatch = useDispatch();
    const { grupaUdzial, planowaneZuzycie } = props;

    const [planowaneZuzycie_g, setPlanowaneZuzycie_g] = useState([]);
    const [open, setOpen] = useState(false);
    const [trashId, setTrashId] = useState(0);

    const handleOpen = (id) => {
        setTrashId(id);
        setOpen(true);
    }

    useEffect( () => setPlanowaneZuzycie_g( planowaneZuzycie?.filter( p => p?.id_user == grupaUdzial?.id_user && p?.grupa_numer == grupaUdzial?.grupa_numer ) ), [grupaUdzial, planowaneZuzycie])

    const handleDelete = async id => {
        const listaNowa = planowaneZuzycie_g?.filter( el =>  el.id !== id );
        setPlanowaneZuzycie_g(listaNowa);
        axios.post("/api/planowane_zuzycie/usun", { id })
            .then(response => {
                dispatch(fetchPlanowaneZuzycie(grupaUdzial?.id_wspolnoty));
            });
        setOpen( false );
    }

    const [wc, setWc] = useState(0);
    const [wz, setWz] = useState(0);
    const [pzd, setPzd] = useState("");

    const [addNew, setAddNew] = useState(false);

    const planowaneZuzycieLista = planowaneZuzycie_g?.map( (p, i) => (
        <li key={i}>
            <div className="w100">{`${dataFormat(p.data_od)}`}</div>
            <div className="w100">{`${p.woda_zimna}`}</div>
            <div className="w100">{`${p.woda_ciepla}`}</div>
            <div className="w50">
                <FontAwesomeIcon 
                    icon={faTrash} 
                    className="pointer" 
                    onClick={()=>handleOpen(p.id)}
                />
            </div>
        </li>
    ))

    const handlePzd = e => setPzd(e.target.value);
    const handleWc = e => setWc(e.target.value);
    const handleWz = e => setWz(e.target.value);

    const handleAnuluj = () => {
        setWc(0);
        setWz(0);
        setPzd("");
        setAddNew(false);
    }

    const handleSave = () => {
        console.log(grupaUdzial?.id_wspolnoty)   
        try {
            axios.post('/api/planowane_zuzycie/dodaj', {
              id_wspolnota: grupaUdzial?.id_wspolnoty,
              id_user: grupaUdzial?.id_user,
              ciepla_woda: wc,
              zimna_woda: wz,
              data_od: pzd,
              grupa_numer: grupaUdzial?.grupa_numer
            }).then(response => {
              dispatch(fetchPlanowaneZuzycie(grupaUdzial?.id_wspolnoty)); 
              setWc(0);
              setWz(0);
              setPzd("");
              setAddNew(false);
            })
          } catch (error) {
            console.error('Błąd podczas dodawania danych:', error);
          }
    }


    const add = (
        <div className="form">    
            <div className="">
                <label htmlFor="pzd">
                    Początek:
                </label>
                <input 
                    type="date"    
                    id="pzd"
                    value={pzd}
                    onChange={handlePzd}
                />
            </div>
            <div className="">
                <label htmlFor="wz">
                    W. zimna:
                </label>
                <input 
                    type="number" 
                    id="wz" 
                    className="w50"
                    value={wz}
                    onChange={handleWz}
                />
            </div>
            <div className="">
                <label htmlFor="wc">
                    W. ciepła:
                </label>
                <input 
                    type="number" 
                    id="wc" 
                    className="w50"
                    value={wc}
                    onChange={handleWc}
                />
            </div>
            <div>

            </div>
        </div>
    )

    return (
        <div className="planpowaneZuzycie">
            <div className="naglowek">
                <h5>
                    Planowane zużycie
                </h5>
                { !addNew ? (
                        <div>
                            <FontAwesomeIcon 
                                icon={faPlus} 
                                className="pointer" 
                                onClick={()=>setAddNew(true)}
                            />
                        </div>
                    ) : (
                        <div>
                            <FontAwesomeIcon 
                                icon={faChevronLeft} 
                                onClick={handleAnuluj}
                                className="pointer"
                            />
                            <FontAwesomeIcon 
                                icon={faCheck} 
                                onClick={handleSave}
                                className="pointer"
                            />
                        </div>
                    )}
            </div>
            
            { addNew ? add : <></>}


            <ul className="ramka-inset-shadow">
                <li>
                    <div style={{fontWeight: "500"}}>Data od</div>
                    <div className="w100" style={{fontWeight: "500"}}>z. woda (m³)</div>
                    <div className="w100" style={{fontWeight: "500"}}>c. woda (m³)</div>
                    <div className="w50" style={{fontWeight: "500"}}></div>
                </li>
                {planowaneZuzycieLista}
                
            </ul>
            <DialogYesNo
                open={open}
                setOpen={setOpen}
                message={"Czy na pewno chcesz usunąć ten wpis?"}
                f={handleDelete}
                id={trashId}
            />
        </div>
    )
}

export default MieszkaniecDaneWodaPlanowaneZuzycie;