import React from "react";
import { zl } from "../../../functions/utilities.js";

const MieszkaniecDaneWodaRozliczenieZaliczki = props => {
    
    const { czynszeOkres, sumaZaliczek } = props;

    const naliczoneZaliczki = czynszeOkres?.map( (zaliczka, i) => (
        <tr key={i}>
            <td>{zaliczka.msc}</td>
            <td>{ zl(zaliczka.zimna_woda) }</td>
            <td>{ zl(zaliczka.ciepla_woda) }</td>
        </tr>
    ));

    return (
        <div className="lewa">
            <div className="naglowek">Naliczone zaliczki</div>
            <table>
                <thead>
                    <tr>
                        <th>
                            Miesiąc
                        </th>
                        <th>
                            Zimna woda
                        </th>
                        <th>
                            Ciepła woda
                        </th>
                    </tr>
                </thead>
                <tbody>
                    { naliczoneZaliczki }
                </tbody>
                <tfoot>
                    <tr>
                        <td>Razem:</td>
                        <td>
                            { zl(sumaZaliczek?.zimna_woda) }
                        </td>
                        <td>
                            { zl(sumaZaliczek?.ciepla_woda) }
                        </td>
                    </tr>
                </tfoot>
            </table>
        </div>
    )
}

export default MieszkaniecDaneWodaRozliczenieZaliczki;