import React, { useState } from "react";
import { useDispatch } from 'react-redux';

import axios from "axios";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash, faTrash } from '@fortawesome/free-solid-svg-icons';
import { dataFormat } from "../../functions/dataFormat";
import { fetchGrupy, fetchGrupyAll, fetchGrupyAkt, fetchLokale, fetchUdzialyKonta, fetchUdzialyKontaAkt, fetchUdzialyKontaAll } from "../../Redux/Actions";
import DialogYesNo from "../DialogYesNo";
import { format } from 'date-fns';


const MieszkaniecDaneGrupyHistoriaZmian = props => {

    const { zmianyGrupyPokaz, setZmianyGrupyPokaz, taGrupaUdzial } = props;

    const dispatch = useDispatch();

    const onClickHandle = () => {
        if (zmianyGrupyPokaz.show && zmianyGrupyPokaz.id == taGrupaUdzial?.id) {
            setZmianyGrupyPokaz({id: 0, show: false})
        } else if (zmianyGrupyPokaz.show && zmianyGrupyPokaz.id != taGrupaUdzial?.id)
            setZmianyGrupyPokaz({id: taGrupaUdzial.id, show: true})
        else
        setZmianyGrupyPokaz({id: taGrupaUdzial?.id, show: true});
    }

    const [open, setOpen] = useState(false);

    async function usunGrupe(id) {
        try {
            const response = await axios.post('/api/grupy_konta_udzialy/usun', { id: id });
            if (response.data.success === true) {
                dispatch(fetchUdzialyKonta(taGrupaUdzial.id_wspolnoty)); 
                dispatch(fetchUdzialyKontaAll(taGrupaUdzial.id_wspolnoty));
                dispatch(fetchUdzialyKontaAkt(taGrupaUdzial.id_wspolnoty));
                dispatch(fetchGrupy(taGrupaUdzial.id_wspolnoty));
                dispatch(fetchGrupyAkt(taGrupaUdzial.id_wspolnoty));
                dispatch(fetchGrupyAll(taGrupaUdzial.id_wspolnoty));
                dispatch(fetchLokale(taGrupaUdzial.id_wspolnoty));
            }
        } catch (error) {
            console.error('Error sending ID to backend:', error);
        }
    }

    const [trashId, setTrashId] = useState(0);

    const handleClick = (id) => {
        setTrashId(id)
        setOpen(true);   
    }
    
    const listaZmian = taGrupaUdzial?.map( (u,i) => {
        return (
            <li key={i}>
                <div className="kosz-con">
                    <div className="kosz" onClick={()=>handleClick(u.id)}>
                        <FontAwesomeIcon icon={faTrash} />
                    </div>
                </div>
                <div className="dane">
                    <div className="daty">
                        <div className="opis">
                            Data utworzenia:
                        </div> 
                        <div>
                            {format(u.data_utworzenia,'yyyy-MM-dd HH:mm:ss')}
                        </div>
                    </div>
                    <div className="daty">
                        <div className="opis">
                            Data od:
                        </div>
                        <div
                            style={{fontWeight: "600"}}
                        >
                            {dataFormat(u.data_od)}
                        </div>
                    </div>
                    <div>
                        Konto: {u.konto_bankowe}
                    </div>
                    <div className="daty">
                        <div className="opis">
                            Udział: 
                        </div>
                        <div>
                            {u.udzial}
                        </div>
                    </div>
                    <div className="daty">
                        <div className="opis">
                            Liczba mieszańców:
                        </div>
                        <div>
                            {u.liczba_domownikow}
                        </div>
                    </div>
                    <div className="daty">
                        <div className="opis">
                            Id wpisu:
                        </div>
                        <div>
                            {u.id}
                        </div>
                    </div>
                </div>                
            </li>
        )
    })

    const zmiany = (
        <div className="zmiana">
            <ul className="historiaZmian">
                {listaZmian}
            </ul>
        </div>
    )

    return (
        <div className="historia">
            <h2>
                <span 
                    onClick={()=>{onClickHandle()}}
                >
                    Historia zmian { zmianyGrupyPokaz.show && zmianyGrupyPokaz.id == taGrupaUdzial?.id ? <FontAwesomeIcon icon={faEyeSlash} className="pointer"/> : <FontAwesomeIcon icon={faEye} className="pointer"/> }
                </span>
            </h2>
            <DialogYesNo 
                open={open}
                setOpen={setOpen}
                message={`Czy na pewno chcesz usunąć ten wpis? ${trashId}`}
                f={usunGrupe}
                id={trashId}
            />
            { zmianyGrupyPokaz.show && zmianyGrupyPokaz.id == taGrupaUdzial?.id && zmiany}
        </div>
    )
}

export default MieszkaniecDaneGrupyHistoriaZmian;