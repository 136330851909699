import React, {useContext} from 'react';
import CookieConsent from './Components/Cookie';
import { useCookies } from 'react-cookie';
import './scss/style.css';
import './scss/cookie.css';
import './scss/dialog.css';
import './scss/dodajGrupe.css';
import './scss/footer.css';
import './scss/form.css';
import './scss/glosowanie.css';
import './scss/glowna.css';
import './scss/grupy.css';
import './scss/komunikaty.css';
import './scss/kontakt.css';
import './scss/ksiegowosc.css';
import './scss/listy.css';
import './scss/logi.css';
import './scss/login.css';
import './scss/mieszkancy.css';
import './scss/mieszkancyRozliczenie.css';
import './scss/navigation.css';
import './scss/noty.css';
import './scss/onas.css';
import './scss/polityka.css';
import './scss/serwis.css';
import './scss/stawki.css';
import './scss/usterki.css';
import './scss/woda.css';
import './scss/wspolnoty.css';
import './scss/wspolnotyDane.css';
import './scss/wspolnotaRozliczenie.css';
import './scss/wydruk.css';
import './scss/zbiorowka.css'
import Main from './Components/Main';
import Footer from './Components/Footer';
import Navigation from './Components/Navigation';
import NavigationMobile from './Components/NavigationMobile';
import { AuthContext } from './Context/AuthProvider';

const App = () => {

  const { role } = useContext(AuthContext);

  const [cookies] = useCookies(["cookieConsent"]);
  return (
    <div className="app">
      {<NavigationMobile />}
      <header
        className= { role === "admin" ? "admin" : "" }
      >
        {
          <Navigation />
        }
      </header>
      <Main />
      { role !== "admin" ? (<footer> <Footer /> </footer>) : "" }
      {!cookies.cookieConsent && <CookieConsent />}
    </div>
  )
} 

export default App;