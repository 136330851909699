import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import FiltrWspolnoty from "../FiltrWspolnoty"
import FiltrMieszkancy from "../FiltrMieszkancy";
import PrzeksiegowaniaForm from "./PrzeksiegowaniaForm";
import { zl, sortByNum } from "../../../functions/utilities";
import { getTablicaCzynsze2, getTablicaPrzelewy2 } from "../../../functions/tablice.js"
import { 
    fetchMieszkancy, 
    fetchPrzelewy, 
    fetchLokale, 
    fetchStawki, 
    fetchStawkiInd,
    fetchStawkiJednorazowe,
    fetchPlanowaneZuzycie,
    fetchUdzialyKontaAkt,
    fetchStawkiJednorazoweInd
  } from '../../../Redux/Actions.js'; 
  import Loader from "../../Loader.js";

const Przeksiegowania = props => {

    const {wspolnoty, banki} = props;

    const dispatch = useDispatch();

    const [tablicaPrzelewy, setTablicaPrzelewy] = useState(undefined);
    const [loaderOn, setLoaderOn] = useState(true);

    const [wybranaWspolnotaId, setWybranaWpolnotaId] = useState({id: 0});
    const [wybranyMieszkaniecId, setWybranyMieszkaniecId] = useState(0)
    const [przelewy_u, setPrzelewy_u] = useState([]);
    const [przeksiegowania, setPrzeksiegowania] = useState(undefined);
    const [udzialyKontaAkt_u, setUdzialyKontaAkt_u] = useState([]);   
    const [tablicaCzynsze, setTablicaCzynsze] = useState(undefined);
    const [kontoGlowneSaldo_w, setKontoGlowneSaldo_w] = useState(0);
    
    const mieszkancy = useSelector( state => state?.mieszkancy?.data);
    const przelewy = useSelector( state => state?.przelewy?.data);
    const lokale = useSelector( state => state?.lokale?.data);
    const stawki = useSelector( state => state?.stawki?.data);
    const stawkiInd = useSelector( state => state?.stawkiInd?.data );
    const stawkiJednorazowe = useSelector( state => state?.stawkiJednorazowe?.data);
    const stawkiJednorazoweInd = useSelector( state => state?.stawkiJednorazoweInd?.data);
    const planowaneZuzycie = useSelector( state => state?.planowaneZuzycie?.data);
    const grupy = useSelector( state => state?.grupy?.data);
    const udzialyKontaAkt = useSelector( state => state?.udzialyKontaAkt?.data);

    useEffect( ()=> {
        dispatch(fetchMieszkancy(wybranaWspolnotaId)); 
        dispatch(fetchPrzelewy(wybranaWspolnotaId)); 
        dispatch(fetchLokale(wybranaWspolnotaId)); 
        dispatch(fetchStawki(wybranaWspolnotaId)); 
        dispatch(fetchStawkiInd(wybranaWspolnotaId));
        dispatch(fetchStawkiJednorazowe(wybranaWspolnotaId));
        dispatch(fetchStawkiJednorazoweInd(wybranaWspolnotaId));
        dispatch(fetchUdzialyKontaAkt(wybranaWspolnotaId));
        dispatch(fetchPlanowaneZuzycie(wybranaWspolnotaId));
    },[dispatch]);

    useEffect( () => {
        if (tablicaPrzelewy && tablicaCzynsze)
            setLoaderOn(false);
    },[tablicaPrzelewy, tablicaCzynsze]);

    useEffect( () => {
        setTablicaPrzelewy( getTablicaPrzelewy2(wspolnoty, udzialyKontaAkt, przelewy)[0] )
    },[wspolnoty, udzialyKontaAkt, przelewy])

    useEffect( () => {
        const przelewy_w = przelewy?.filter( p => p?.id_wspolnoty == wybranaWspolnotaId && (
            p?.odbiorca_konto === "Konto główne wspólnoty" && p.operacja === "przelew przychodzący" 
                || p?.nadawca_konto === "Konto główne wspólnoty" && p.operacja === "przeksięgowanie z"
        )  );
        let saldo = 0;
        if (przelewy_w.length > 0) {
            saldo = przelewy_w?.reduce( (suma, obiekt) => suma + obiekt.kwota, 0 );
            setKontoGlowneSaldo_w(saldo);
        } else {
            setKontoGlowneSaldo_w(0)
        }
    },[przelewy, wybranaWspolnotaId]);

    useEffect( () => {
        if (wspolnoty && wspolnoty?.length > 0)  
            setWybranaWpolnotaId( wspolnoty[0]?.id);
    },[wspolnoty, mieszkancy, przelewy]);

    useEffect( () => {
        const w = wspolnoty?.filter( w => w?.id == wybranaWspolnotaId);
        setTablicaCzynsze(getTablicaCzynsze2(w, mieszkancy, grupy, [],udzialyKontaAkt, [], lokale, stawki, planowaneZuzycie, stawkiJednorazowe, stawkiInd, stawkiJednorazoweInd /*, zaliczki */))
    },[wybranaWspolnotaId, wspolnoty, mieszkancy, grupy, udzialyKontaAkt, lokale, stawki, planowaneZuzycie, stawkiJednorazowe, stawkiInd, stawkiJednorazoweInd /*, zaliczki */])

    useEffect( () => setPrzelewy_u( przelewy?.filter( p => p.id_user ==  wybranyMieszkaniecId)), [przelewy, wybranyMieszkaniecId]);
    useEffect( () => setUdzialyKontaAkt_u( sortByNum(udzialyKontaAkt?.filter( u => u?.id_user == wybranyMieszkaniecId ), "grupa_numer")), [udzialyKontaAkt, wybranyMieszkaniecId]);

    return (
        <div className="przelewy">
            <h4 className="nazwa">
                Przeksięgowania
            </h4>
            <div className='historia'>
                <div className='filtry'>
                    <FiltrWspolnoty 
                        wspolnoty={wspolnoty}
                        wybranaWspolnotaId={wybranaWspolnotaId}
                        setWybranaWpolnotaId={setWybranaWpolnotaId}
                        setWybranyMieszkaniecId={setWybranyMieszkaniecId}
                    />
                    <FiltrMieszkancy 
                        mieszkancy_w={mieszkancy}
                        wybranyMieszkaniecId={wybranyMieszkaniecId}
                        setWybranyMieszkaniecId={setWybranyMieszkaniecId}
                    />
                </div>
            </div>
            <p className="saldo_w">
                {`Stan konta głównego wspólnoty: ${zl(kontoGlowneSaldo_w)}`}
            </p>
            {
                wybranyMieszkaniecId ? (
                    <PrzeksiegowaniaForm
                        przelewy_u={przelewy_u}
                        udzialyKontaAkt_u={udzialyKontaAkt_u}
                        tablicaCzynsze={tablicaCzynsze}
                        tablicaPrzelewy={tablicaPrzelewy}
                        wybranaWspolnotaId={wybranaWspolnotaId}
                        wybranyMieszkaniecId={wybranyMieszkaniecId}
                        setWybranyMieszkaniecId={setWybranyMieszkaniecId}
                        kontoGlowneSaldo_w={kontoGlowneSaldo_w}
                    />
                ) : ""
            }
            <Loader
                stan={loaderOn}
            />
        </div>
    )
}

export default Przeksiegowania;