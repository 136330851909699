import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import GoBackBtn from '../GoBackBtn';
import FakturyLista from "./Faktury/FakturyLista.js";
import KontrahenciLista from "./Kontrahent/KontrahenciLista.js";
import KontaLista from "./Konta/KontaLista.js";
import ImportTransakcji from "./Bank/ImportTransakcji/ImportTransakcji.js";
import HistoriaTransakcji from './Bank/HistoriaTransakcji/HistoriaTransakcji.js';
import Przeksiegowania from "./Przeksiegowania/Przeksiegowania.js";
import { motion } from 'framer-motion';
import { wstepy } from '../../functions/motions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileImport, faListOl, faArrowRightArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { 
    fetchWspolnoty, 
    fetchBanki
  } from '../../Redux/Actions.js'; 

const Ksiegowosc = () => {

    const [activeWindow, setActiveWindow] = useState("przelewy import");

    const dispatch = useDispatch();

    const wspolnoty = useSelector( state  => state?.wspolnoty?.data);
    const mieszkancy = useSelector( state => state?.mieszkancy?.data);
    const przelewy = useSelector( state => state?.przelewy?.data);
    const banki = useSelector( state => state?.banki?.data);

    useEffect( ()=> {
        dispatch(fetchWspolnoty()); 
        dispatch(fetchBanki());
    },[dispatch]);

    const renderComponent = () => {
        switch (activeWindow) {
            case "faktury":
                return <FakturyLista />
            case "kontrahenciLista":
                return <KontrahenciLista />
            case "konta":
                return <KontaLista />
            case "przelewy import":
                return <ImportTransakcji 
                    wspolnoty={wspolnoty}
                    banki={banki}
                />
            case "przelewy historia":
                return <HistoriaTransakcji 
                    wspolnoty={wspolnoty}
                    przelewy={przelewy}
                    mieszkancy={mieszkancy}
                />
            case "przeksiegowania":
                return <Przeksiegowania 
                    wspolnoty={wspolnoty}
                    banki={banki}
                />
            default:
                return <></>
        }
    }

    return (
        <motion.div 
            className="content ksiegowosc"
            variants={wstepy}
            initial="initial"
            animate="animate"
        >
            <h1 className="nazwa">
                Księgowość
            </h1>
            <div className="buttons">
                <GoBackBtn />
                <button disabled={true} onClick={()=>{setActiveWindow("faktury")}}>Faktury</button>
                <button disabled={true} onClick={()=>{setActiveWindow("kontrahenciLista")}}>Lista Kontrahentów</button>
                <button disabled={true} onClick={()=>{setActiveWindow("konta")}}>Konta</button>
                <button onClick={()=>{setActiveWindow("przelewy import")}}><FontAwesomeIcon icon={faFileImport} />   Wczytaj przelewy</button>
                <button onClick={()=>{setActiveWindow("przelewy historia")}}><FontAwesomeIcon icon={faListOl} />   Historia transakcji</button>
                <button onClick={()=>{setActiveWindow("przeksiegowania")}}><FontAwesomeIcon icon={faArrowRightArrowLeft} />   Przeksięgowania</button>
            </div>
            <div className="window">
                { renderComponent() }
            </div>
        </motion.div>
    )
}

export default Ksiegowosc;