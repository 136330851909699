import React from "react";
import { zl } from "../../../functions/utilities.js";

const MieszkaniecDaneWodaRozliczeniePodsumowanie = props => {
    
    const {  zuzycie, sumaZaliczek, podsumowanie } = props;

    return (
        <div className="koszt">
            <div className="naglowek">Rozliczenie</div>
            
            <div className="podsumowanie">
                <div>
                    <table>
                        <tbody>
                            <tr>
                                <td style={{textDecoration: "underline"}}>
                                    zimna woda:
                                </td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>
                                    {zl(sumaZaliczek?.zimna_woda)}
                                </td>
                                <td>
                                    zaliczki na zimna wodę
                                </td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>
                                    {zl(zuzycie?.zimnaKoszt)}
                                </td>
                                <td>
                                    koszt zimnej wody
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    {podsumowanie?.zimnaOpis}
                                </td>
                                <td>
                                    {zl(podsumowanie?.zimnaPodsumowanie)}
                                </td>
                                <td>
                                    rozliczenie zimnej wody
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div>
                    <table>
                        <tbody>
                            <tr>
                                <td style={{textDecoration: "underline"}}>
                                    ciepła woda:
                                </td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>
                                    {zl(sumaZaliczek?.ciepla_woda)}
                                </td>
                                <td>
                                    zaliczki na ciepłą wodę
                                </td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>
                                    {zl(zuzycie?.cieplaKoszt)}
                                </td>
                                <td>
                                    koszt ciepłej wody
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    {podsumowanie?.cieplaOpis}
                                </td>
                                <td>
                                    {zl(podsumowanie?.cieplaPodsumowanie)}
                                </td>
                                <td>
                                    rozliczenie ciepłej wody
                                </td>
                            </tr>
                        </tbody>
                </table>
                </div>
                
            </div>
        </div>
    )
}

export default MieszkaniecDaneWodaRozliczeniePodsumowanie;