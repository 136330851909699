import React, {useState} from "react";
import axios from "axios";
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faPencil, faSave } from '@fortawesome/free-solid-svg-icons';
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { dataFormat, dataFormat2, strefa } from "../../../functions/dataFormat.js";
import { null_brak } from "../../../functions/utilities.js";
import { fetchOdczyty } from "../../../Redux/Actions.js";
import DialogYesNo from "../../DialogYesNo.js";

const MieszkaniecDaneWodaStanLicznikowLista = props => {

    const dispatch = useDispatch();

    const { odczyty_g, setOdczyty_g, edit, setEdit, editStan, setEditStan, wybraneRozliczenie, setWybraneRozliczenie, wybranyOdczyt, setWybranyOdczyt, idw } = props;

    const [open, setOpen] = useState(false);
    const [trashId, setTrashId] = useState(0);

    const handleOpen = (id) => {
        setTrashId(id);
        setOpen(true);
    }

    const handleDelete = id => {
        const listaNowa = odczyty_g?.filter( el =>  el.id !== id );
        setOdczyty_g(listaNowa);
        axios.post("/api/odczyty/usun", { id })
            .then(response => {
                dispatch(fetchOdczyty(idw));
            })
        setOpen( false );
    }

    const handleAnuluj = () => {
        setEditStan({
            cieplaPoczatek: undefined,
            cieplaKoniec: undefined,
            zimnaPoczatek: undefined,
            zimnaKoniec: undefined,
            okresPoczatek: undefined,
            okresKoniec: undefined
        });
        setEdit({id: null, visible: false});
    }

    const handleSaveEdit = async () => {
        try {
            axios.post("/api/odczyt/edytuj", { 
                okres_poczatek: editStan.okresPoczatek,
                okres_koniec: editStan.okresKoniec,
                ciepla_stan_poczatek: editStan.cieplaPoczatek,
                ciepla_stan_koniec: editStan.cieplaKoniec,
                zimna_stan_poczatek: editStan.zimnaPoczatek,
                zimna_stan_koniec: editStan.zimnaKoniec,
                id: edit.id
            }).then(response => {
              dispatch(fetchOdczyty(idw)); 
              handleAnuluj();
            })
        } catch (error) {
        console.error('Błąd podczas dodawania danych:', error);
        }
    }
    
    const handlePencil = (event, id) => {
        console.log('handlePencil');
        event.stopPropagation();
        setEdit({id: id, visible: true});
        setWybranyOdczyt(undefined);
    }

    const odczytyLista = odczyty_g?.map( (p, i) => {
        
        let klasaLi = "wiersz"
        if ( p?.okres_koniec )
            klasaLi += " pointer";
        if ( p?.id == wybranyOdczyt?.id )   
            klasaLi += " active";

        return edit.id && edit.id == p.id ? (
            <li key={i} >
                <div style={{width: "150px"}}>
                    <input
                        type="date"
                        value={editStan.okresPoczatek}
                        onChange={(e)=>setEditStan( prevState => ({...prevState, okresPoczatek: e.target.value}) )}
                    />
                    <input
                        type="date"
                        value={editStan.okresKoniec}
                        onChange={(e)=>setEditStan( prevState => ({...prevState, okresKoniec: e.target.value}) )}
                    />
                </div>
                <div className="w100">
                    <input
                        type="number"
                        className="w100"
                        value={editStan.cieplaPoczatek}
                        onChange={(e)=>setEditStan( prevState => ({...prevState, cieplaPoczatek: e.target.value}) )}
                    />
                    <input
                        type="number"
                        className="w100"
                        value={editStan.cieplaKoniec}
                        onChange={(e)=>setEditStan( prevState => ({...prevState, cieplaKoniec: e.target.value}))}
                    />
                </div>
                <div className="w100">
                    <input
                        type="number"
                        className="w100"
                        value={editStan.zimnaPoczatek}
                        onChange={(e)=>setEditStan( prevState => ({...prevState,zimnaPoczatek: e.target.value}) )}
                    />
                    <input
                        type="number"
                        className="w100"
                        value={editStan.zimnaKoniec}
                        onChange={(e)=>setEditStan( prevState => ({...prevState, zimnaKoniec: e.target.value}))}
                    />
                </div>

                <div className="w50">
                    <FontAwesomeIcon 
                        icon={faArrowLeft} 
                        className="pointer" 
                        onClick={handleAnuluj}
                    />
                    <FontAwesomeIcon 
                        icon={faSave} 
                        className="pointer" 
                        onClick={handleSaveEdit}
                    />
                </div>
            </li>
        ) : (        
            <li 
                key={i}
                className= {klasaLi}
                onClick={ p.okres_koniec ? ()=>setWybranyOdczyt(p) : ()=>setWybranyOdczyt(undefined)}
            >
                <div style={{width: "150px"}}>{`${dataFormat(p.okres_poczatek)} / ${dataFormat(p.okres_koniec)}`}</div>
                <div className="w100">{`${p.ciepla_stan_poczatek} / ${ null_brak(p.ciepla_stan_koniec)}`}</div>
                <div className="w100">{`${p.zimna_stan_poczatek} / ${ null_brak(p.zimna_stan_koniec)}`}</div>
                <div className="w50">
                    <FontAwesomeIcon 
                        icon={faPencil} 
                        className="pointer" 
                        onClick={(event)=>handlePencil(event, p.id)}
                    />
                    <FontAwesomeIcon 
                        icon={faTrash} 
                        className="pointer" 
                        onClick={()=>handleOpen(p.id)}
                    />
                </div>
            </li>
        )
    })

    return (
        <ul className="odczyty lista ramka-inset-shadow">
            <li>
                <div style={{fontWeight: "500", width: "150px"}}>Data od / do</div>
                <div className="w100" style={{fontWeight: "500"}}>zimna woda (m³)</div>
                <div className="w100" style={{fontWeight: "500"}}>ciepła woda (m³)</div>
                <div className="w50" style={{fontWeight: "500"}}></div>
            </li>
            {odczytyLista}
            <DialogYesNo
                open={open}
                setOpen={setOpen}
                message={"Czy na pewno chcesz usunąć ten wpis?"}
                f={handleDelete}
                id={trashId}
            />
        </ul>
    )
}

export default MieszkaniecDaneWodaStanLicznikowLista;