import React, { useEffect, useState } from "react";
import axios from "axios";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faCheck, faXmark, faGear, faFloppyDisk, faCircleLeft } from '@fortawesome/free-solid-svg-icons'
import { ikona } from "../../../functions/ikony";
import { fetchLokale } from "../../../Redux/Actions";
import { useDispatch } from 'react-redux';
import Tooltip from '@mui/material/Tooltip';

const WspolnotyLokaleLista = props => {    

    const { lokaleUlica, setActiveWindow, wspolnota } = props;

    const _MIESZKANIE_ = "mieszkanie";
    const _GARAZ_ = "garaż";
    const _KOMORKA_ = "komórka";
    const _STRYCH_ = "strych";

    const dispatch = useDispatch();

    const [mieszkania, setMieszkania] = useState([]);
    const [garaze, setGaraze] = useState([]);
    const [komorki, setKomorki] = useState([]);
    const [strychy, setStrychy] = useState([]);
    const [edytowany, setEdytowany] = useState(0);
    const [edytowanyKlatka, setEdytowanyKlatka]=  useState("");
    const [edytowanyNrLokalu, setEdytowanyNrLokalu] = useState("");
    const [edytowanyMetraz, setEdytowanyMetraz] = useState(0);
    const [edytowanyUdzial, setEdytowanyUdzial] = useState(0);

    useEffect(() => {
        setMieszkania( lokaleUlica?.filter( l => l.rodzaj === _MIESZKANIE_) );
        setGaraze(lokaleUlica?.filter( l => l.rodzaj === _GARAZ_) );
        setKomorki(lokaleUlica?.filter( l => l.rodzaj === _KOMORKA_ ));
        setStrychy(lokaleUlica?.filter(l => l.rodzaj === _STRYCH_));
    },[lokaleUlica])

    
    useEffect( () => {
        const lokal = lokaleUlica?.find( l => l?.id == edytowany );
        setEdytowanyKlatka(lokal?.klatka);
        setEdytowanyNrLokalu(lokal?.numer);
        setEdytowanyMetraz(lokal?.metraz);
        setEdytowanyUdzial(lokal?.udzial);
    },[lokaleUlica, edytowany]);

    const zapiszLokal = async id => {

        await axios.post('/api/lokal/edytuj/', {
            id: id,
            klatka: edytowanyKlatka,
            numer: edytowanyNrLokalu,
            metraz: edytowanyMetraz, 
            udzial: edytowanyUdzial
          }).then( (res) => {
            setEdytowany(0);
            dispatch(fetchLokale(wspolnota?.id)); 
          }).catch( err => {
            console.log(err);
          })

    }

    const listaLokale = (lokale) => {

        return (
            <>
                <li className="naglowek">
                    <div 
                        style={{
                                "textAlign": "center",
                                "min-width": "61px"            
                        }}>
                            Id
                    </div>
                    <div style={{"textAlign": "center"}}>Klatka</div>
                    <div style={{"textAlign": "center"}}>Nr lok.</div>
                    <div style={{"textAlign": "center"}}>Pow.</div>
                    <div style={{"textAlign": "center"}}>Udział</div>
                    <div style={{"textAlign": "center"}}>Zajęty</div> 
                    <div style={{"textAlign": "center"}}>Edytuj</div>
                </li>
                
                {lokale?.map( (l, i) => {
                    return (
                        
                        edytowany == l?.id ? (
                            <li key={i}>
                                <div>
                                    {l.id}
                                </div>
                                <div>
                                    <input
                                        type="text"
                                        value={edytowanyKlatka}
                                        onChange={(e)=>setEdytowanyKlatka(e.target.value)}
                                    />
                                </div>
                                <div>
                                    <input
                                        type="text"
                                        value={edytowanyNrLokalu}
                                        onChange={(e)=>setEdytowanyNrLokalu(e.target.value)}
                                    />
                                </div>
                                <div>
                                    <input
                                        type="number"
                                        className="pow"
                                        value={edytowanyMetraz}
                                        onChange={(e)=>setEdytowanyMetraz(e.target.value)}
                                    />
                                </div>
                                <div>
                                    <input
                                        type="number"
                                        value={edytowanyUdzial}
                                        onChange={(e)=>setEdytowanyUdzial(e.target.value)}
                                    />
                                </div>
                                <div style={{"textAlign": "center"}}>
                                    {l.czy_zajety !== null ? <FontAwesomeIcon icon={faCheck} /> : <FontAwesomeIcon icon={faXmark} />}
                                </div>
                                <div 
                                    style={{"textAlign": "center"}}
                                >                    
                                    <Tooltip
                                        title="Zapisz"
                                        placement='top'
                                    >
                                        <FontAwesomeIcon 
                                            icon={faFloppyDisk} 
                                            className="pointer"
                                            onClick={()=>zapiszLokal(l?.id)}
                                        />
                                    </Tooltip>
                                    <Tooltip
                                        title="Anuluj"
                                        placement='top'
                                    >
                                        <FontAwesomeIcon 
                                            icon={faCircleLeft} 
                                            className="pointer"
                                            onClick={()=>setEdytowany(0)}
                                        />
                                    </Tooltip>
                                </div>
                            </li>
                        ) : 
                        (
                            <li key={i}>
                                <div style={{"textAlign": "center"}}>
                                    {l.id}
                                </div>
                                <div
                                    style={{"textAlign": "center"}}
                                >
                                    {l.klatka === null || l.klatka === "" ? "-" : l.klatka }
                                </div>
                                <div style={{"textAlign": "center"}}>{l.numer ? l.numer : '-'}</div>
                                <div style={{"textAlign": "center"}}>{l.metraz ? `${l.metraz} m²` : '-'}</div>
                                <div style={{"textAlign": "center"}}>{`${l.udzial ? l.udzial : "-"}`}</div>
                                <div style={{"textAlign": "center"}}>
                                    {l.czy_zajety !== null ? <FontAwesomeIcon icon={faCheck} /> : <FontAwesomeIcon icon={faXmark} />}
                                </div>
                                <div 
                                    style={{"textAlign": "center"}}
                                    className="pointer"
                                    onClick={()=>setEdytowany(l?.id)}
                                >
                                    <FontAwesomeIcon icon={faGear} />
                                </div>
                            </li>
                        )
                    )
                })}
            </>
        )
    };

    const handleClick = () => setActiveWindow("dodaj");

    return (
        <div className="wspolnotyLokaleLista">
            <div className="naglowek">
                <h3>
                    Lista lokali 
                </h3>
                <FontAwesomeIcon 
                    icon={faPlus}
                    className="pointer" 
                    onClick={handleClick}
                />
            </div>
            <div className="grupaLokali">
                <div className="naglowek">
                    { ikona (_MIESZKANIE_)}
                    <h4>
                        Mieszkania
                    </h4>
                </div>
                <ul className="uliceLista">
                    {listaLokale(mieszkania, _MIESZKANIE_)}
                </ul>
            </div>
            <div className="grupaLokali">
                <div className="naglowek">
                    { ikona (_GARAZ_)}
                    <h4>
                        Garaże
                    </h4>
                </div>
                <ul className="uliceLista">
                    {listaLokale(garaze, _GARAZ_)}
                </ul>
            </div>
            <div className="grupaLokali">
                <div className="naglowek">
                    { ikona (_KOMORKA_)}
                    <h4>
                        Komórki
                    </h4>
                </div>
                <ul className="uliceLista">
                    {listaLokale(komorki, _KOMORKA_)}
                </ul>
            </div>
            <div className="grupaLokali">
                <div className="naglowek">
                    { ikona (_STRYCH_)}
                    <h4>
                        Strychy
                    </h4>
                </div>
                <ul className="uliceLista">
                    {listaLokale(strychy, _STRYCH_)}
                </ul>
            </div>

            
        </div>
    )
}

export default WspolnotyLokaleLista;