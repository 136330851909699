import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import MieszkaniecDaneGrupy from "./MieszkaniecDaneGrupy.js";
import MieszkaniecInformacje from "./MieszkaniecInformacje.js";
import MieszkaniecRozliczenie from "./Rozliczenie/MieszkaniecRozliczenie.js";



import { motion } from 'framer-motion';
import { wstepy } from '../../functions/motions.js';

const MieszkaniecMojeDane = (props) => {

    const {activeGroup, udzialyKontaAktU, setActiveGroup,mieszkaniec, ulice, lokale, grupyU, tablicaCzynsze_g, tablicaPrzelewy_g, stawkiOst, 
        stawkiJednorazowe_w, grupy_a, udzialyKonta_a,setUdzialyKonta_a, stawkiJednorazoweInd_u } = props;

    useEffect( ( )=> {
        if (udzialyKontaAktU?.length > 0) {
            setActiveGroup( { idw: udzialyKontaAktU[0].id_wspolnoty, grupa_numer: udzialyKontaAktU[0].grupa_numer } );
        }
    },[udzialyKontaAktU]);

    return (
            <div className="content">

                <div className="mieszkaniecDaneContainer">
                    <motion.div 
                        className="lista ramka-br ramka-border-shadow"
                        variants={wstepy}
                        initial="initial"
                        animate="animate"
                    >
                        <h3>
                            Informacje podstawowe
                        </h3>
                        <MieszkaniecInformacje
                            mieszkaniec={mieszkaniec}
                        />
                    </motion.div>
                    <div className='grupy'>
                        <MieszkaniecDaneGrupy 
                            mieszkaniec={mieszkaniec}
                            activeGroup={activeGroup}
                            setActiveGroup={setActiveGroup}
                            ulice={ulice}
                            lokale={lokale}
                            grupyU={grupyU}
                            udzialyKontaAktU={udzialyKontaAktU}
                            setUdzialyKonta_a={setUdzialyKonta_a}
                        />
                    </div>
                </div>

                <MieszkaniecRozliczenie
                    activeGroup={activeGroup}
                    tablicaCzynsze={tablicaCzynsze_g}
                    tablicaPrzelewy_g={tablicaPrzelewy_g}
                    udzialyKonta_a={udzialyKonta_a}
                    stawkiOst={stawkiOst}
                    stawkiJednorazowe_w={stawkiJednorazowe_w}
                    mieszkaniec={mieszkaniec}
                    ulice={ulice}
                    lokale={lokale}
                    grupy_a={grupy_a}
                    stawkiJednorazoweInd_u={stawkiJednorazoweInd_u}
                />
            </div>
    )
}

export default MieszkaniecMojeDane;