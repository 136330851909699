import { mm_rrrr_to_date } from "./dataFormat";

export const stawki = [
    ["eksploatacja","zł / m2 p.u."],
    ["garaz", "zł / m2 p.u."],
    ["strych", "zł / m2 p.u."],
    ["utrzymanie_czystosci", "zł"],
    ["wywoz_smieci", "zł / os"],
    ["fundusz_remontowy", "zł / m2 p.u."],
    ["konserw_domofonu", "zł"],
    ["konserw_windy", "zł"],
    ["opl_za_adm", "zł / m2 p.u."],
    ["zimna_woda", "zł / m3"],
    ["ciepla_woda", "zł / m3"],
    ["abonament_cw", "zł / m2 p.u."],
    ["co", "zł / m2 p.u."],
    ["energia_el_lokalu", "zł"],
    ["ryczalt_gaz", "zł"],
    ["zagosp_terenu", "zł"],
    ["antena", "zł"],
    ["data_od", "Data obowiązywania"]
  ];

export const podajStawki = (word) => { 
    let result = stawki.filter((a)=>a[0]===word);
    return result.length > 0 ? result[0][1] : word;
}

export const znajdzAktualny = (dane, miesiac) => {
    // Zamień "10-2024" na Date — pierwszy dzień miesiąca
    if (stawki?.length > 0 && miesiac !== undefined) {

        const [m, y] = miesiac.split("-").map(Number);
        const granica = new Date(y, m - 1); // np. 2024-10-01T00:00:00.000Z
    
        // Filtrowanie tylko tych obiektów, które są przed datą "miesiac"

        const przed = dane.filter(obj => new Date(obj.data_od) <= granica);

        // Zwracamy ten z najnowszą datą
        if (przed.length === 0) return null;
    
        return przed.reduce((naj, curr) =>
            new Date(curr.data_od) >= new Date(naj.data_od) ? curr : naj
        );
    }
    else {
        return {}
    }
  }

export const skladnikNull = (skladnik, rodzaj) => {
  if (rodzaj === 0) 
      return skladnik == null ? 0 : parseFloat(skladnik).toFixed(2);
  else 
      return skladnik == null ? "n/d" : parseFloat(skladnik).toFixed(2);
}  

export const skladnikNullDod = (s1, s2) => {

  if ((s1 == null || s1 === "n/d" || s1 === undefined)  && (s2 == null || s2 === "n/d" || s2===undefined))
    return null
  let w1 = s1 == null || s1 === "n/d" || s1 === undefined ? 0 : s1;
  let w2 = s2 == null || s2 === "n/d" || s2 === undefined ? 0 : s2;

  return (parseFloat(w1) + parseFloat(w2))
}

export const sumujLiczby = (obiekt) => {
  let suma = 0;

  for (const klucz in obiekt) {
    const lista = ["dod", "dod_ind", "wspolnota_id", "id_user"]
      if (!isNaN(obiekt[klucz]) && typeof obiekt[klucz] === 'number' && !klucz.includes(lista)) {
          suma += obiekt[klucz];
      }
  }

  return suma;
};

export const fixed = (liczba, zamiennik) => {
  if (!isNaN(liczba) && typeof(liczba) === "number") {

    const roundedAmount = Number(liczba).toFixed(2);

    // Rozdzielenie liczby na część całkowitą i część dziesiętną
    const [integerPart, decimalPart] = roundedAmount.split('.');
  
    // Dodanie spacji jako separatora tysięcy
    const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  
    // Dodanie części dziesiętnej (o ile istnieje)
    const formattedAmount = decimalPart ? `${formattedIntegerPart}.${decimalPart}` : formattedIntegerPart;

    return formattedAmount;
  } 
  else return zamiennik === undefined ? "n/d" : zamiennik;
}

export const sumujKwotyTabela = (tablica, klucz) => {
  return tablica.reduce((suma, obiekt) => {
    if (typeof obiekt[klucz] === 'number') {
      return suma + obiekt[klucz];
    }
    return suma;
  }, 0);
};

export const usunDuplikaty = (tablica) => {
  return Array.from(new Set(tablica));
}

export const znajdzNajblizszaStawke = (miesiac, stawki_w) => {
    const miesiacDate = new Date(mm_rrrr_to_date(miesiac));
    if (stawki_w && stawki_w?.length > 0 ) {
      return stawki_w
        .filter(stawka => new Date(stawka.data_od) <= miesiacDate)
        .sort((a, b) => new Date(b.data_od) - new Date(a.data_od))[0] || null;
    } else {
      return [];
    }
    
};