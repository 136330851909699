import React, { useState } from "react";
import { useDispatch } from 'react-redux';
import { dataFormatMsc } from '../../../functions/dataFormat';
import { zl } from "../../../functions/utilities";
import { rodzaj_rozliczenia } from "../../../functions/dictionary";
import axios from "axios";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import DialogYesNo from "../../DialogYesNo";
import { fetchStawkiJednorazowe } from "../../../Redux/Actions";

const WspolnotyStawkiJednorazoweListaWszystkich = props => {

    const { stawkiJednorazowe, idw } = props;
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [trashId, setTrashId] = useState(0);

    const lista = stawkiJednorazowe?.map( (stawki, i) => {
        return (
            <li
                key={i}
            >
                <div>
                    {dataFormatMsc(stawki?.miesiac)}
                </div>
                <div>
                    {
                        stawki?.dod_1_czy == 1 ? (
                            `${stawki?.dod_1_opis}: ${zl(stawki?.dod_1_kwota)} (${rodzaj_rozliczenia(stawki?.dod_1_rodzaj)})`
                        ) : ""
                    }
                </div>
                <div>
                    {
                        stawki?.dod_2_czy == 1 ? (
                            `${stawki?.dod_2_opis}: ${zl(stawki?.dod_2_kwota)} (${rodzaj_rozliczenia(stawki?.dod_2_rodzaj)})`
                        ) : ""
                    }
                </div>
                <div>
                    {
                        stawki?.dod_3_czy == 1 ? (
                            `${stawki?.dod_3_opis}: ${zl(stawki?.dod_3_kwota)} (${rodzaj_rozliczenia(stawki?.dod_3_rodzaj)})`
                        ) : ""
                    }
                </div>
                <div 
                    className="kosz pointer" 
                    onClick={()=>handleClick(stawki.id)}
                    style={{textAlign: "center"}}
                >
                    <FontAwesomeIcon icon={faTrash} />
                </div>
            </li>
        )
    })
    
    async function usunStawki(id) {
        try {
            const response = await axios.post('/api/wspolnoty_stawki_jednorazowe/usun', { id: id });
            if (response.data.success === true) {
                dispatch(fetchStawkiJednorazowe(idw)); 
                console.log('Response from backend:', response.data);
            }
        } catch (error) {
            console.error('Error sending ID to backend:', error);
        }
    }

    const handleClick = (id) => {
        setTrashId(id)
        setOpen(true);   
    }

    return (
        <ul className="lista jednorazoweLista">
            <DialogYesNo 
                open={open}
                setOpen={setOpen}
                message={`Czy na pewno chcesz usunąć te stawki? (id ${trashId})`}
                f={usunStawki}
                id={trashId}
            />
            <li
                key={0}
                className="firstRow"
            >
                <div>
                    Miesiąc
                </div>
                <div>
                    Koszt 1
                </div>
                <div>
                    Koszt 2
                </div>
                <div>
                    Koszt 3
                </div>
                <div></div>
            </li>
            {lista}
        </ul>
    )
}

export default WspolnotyStawkiJednorazoweListaWszystkich;