import React, { useRef } from "react";
import { useReactToPrint } from 'react-to-print';
import MieszkaniecDaneWodaRozliczenieWydrukA4 from "./MieszkaniecDaneWodaRozliczenieWydrukA4.js";

const MieszkaniecDaneWodaRozliczenieWydrukModal = props => {
    
    const { setShowPrint, czynszeOkres, sumaZaliczek, wybranyOdczyt, zuzycie, stawkaRozliczenie, podsumowanie, mieszkaniec, adres } = props;

    const componentRef = useRef();

    const handleShowPrint = () => setShowPrint(prevState => !prevState);
    
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: 'Visitor Pass',
        onAfterPrint: () => console.log('Printed PDF successfully!'),
    });

    return (
        <div 
            className='rozliczenieWydruk'
            onClick={handleShowPrint}
        >
            <div className='modal'>
                <div>
                    <button
                        onClick={handlePrint}
                    >
                        Drukuj
                    </button>
                </div>
                <MieszkaniecDaneWodaRozliczenieWydrukA4
                    componentRef={componentRef} 
                    setShowPrint={setShowPrint}
                    czynszeOkres={czynszeOkres}
                    sumaZaliczek={sumaZaliczek}
                    wybranyOdczyt={wybranyOdczyt}
                    zuzycie={zuzycie}
                    stawkaRozliczenie={stawkaRozliczenie}
                    podsumowanie={podsumowanie}
                    mieszkaniec={mieszkaniec}
                    adres={adres}
                />
            </div>
        </div>
    )
}

export default MieszkaniecDaneWodaRozliczenieWydrukModal;