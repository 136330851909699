import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import axios from "axios";
import { dataFormat } from "../../../functions/dataFormat.js";
import DialogYesNo from "../../DialogYesNo.js";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'

import { 
  fetchStawki, 
} from '../../../Redux/Actions.js'; 

const WspolnotyStawkiListaWszystkich = ( props ) => {

  const { setStawkiAkt, idw, stawkiArch } = props;

  const dispatch = useDispatch();

  const [open, setOpen] = useState(false)
  const [removeId, setRemoveId] = useState(0);

  const handleClick = (id) => {
    setRemoveId(id)
    setOpen(true);
  }

  const onClickHandle = (item) => {
    setStawkiAkt(item);
  }

  const remove = (id) => {
      try {
        axios.post('/api/stawki_wspolnoty_arch/usun', {
            id: id
        }).then( response => {
          dispatch(fetchStawki(idw));  
        })
      } catch (error) {
        console.log(error)
    }      
  }

  const datyArch = stawkiArch?.length > 0 ? 
    stawkiArch.map((item) => (
      <li key={item.id}>
          <div className="pointer" onClick={()=>onClickHandle(item)}>{dataFormat(item.data_od)} </div>
          <div className="pointer icon" onClick={()=>handleClick(item.id)}><FontAwesomeIcon icon={faTrash} /></div>
      </li>
    )) : <h5>Brak wcześniejszych stawek dla tej wspólnoty</h5>;

  return (
    <div className="arch">
      <DialogYesNo 
        open={open}
        setOpen={setOpen}
        message={"Czy na pewno chcesz usunąć te stawki?"}
        f={remove}
        id={removeId}
      />
      <p>Lista wszystkich okresów:</p>
      <ul className="listaArch">
        {datyArch}
      </ul>
    </div>
  )
}

export default WspolnotyStawkiListaWszystkich;