import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import WspolnotyStawkiJednorazoweAddForm from "./WspolnotyStawkiJednorazoweAddForm";
import WspolnotyStawkiJednorazoweListaWszystkich from "./WspolnotyStawkiJednorazoweListaWszystkich";



const WspolnotyStawkiJednorazowe = props => {

    const { wspolnota, stawkiJednorazowe } = props;

    return (
        <div className="stawkiJednorazowe">
            <WspolnotyStawkiJednorazoweAddForm
                wspolnota={wspolnota}
            />
            <WspolnotyStawkiJednorazoweListaWszystkich
                stawkiJednorazowe={stawkiJednorazowe}
                idw={wspolnota?.id}
            />  
        </div>
    )
}

export default WspolnotyStawkiJednorazowe;