import React, { useContext, useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import WspolnotaRozliczenieSaldo from '../Wspolnoty/Rozliczenie/WspolnotaRozliczenieSaldo'; 
import { dataFormatMsc, nextMonth } from '../../functions/dataFormat';
import { wstepy } from '../../functions/motions';
import { znajdzNajblizszaStawke } from "../../functions/stawki.js";
import { getTablicaCzynsze2, getTablicaPrzelewy2 } from "../../functions/tablice";
import Loader from "../Loader";
import WspolnotaRozliczenieTHead from "../Wspolnoty/Rozliczenie/WspolnotaRozliczenieTHead";
import WspolnotaRozliczenieTBody from "../Wspolnoty/Rozliczenie/WspolnotaRozliczenieTBody";
import WspolnotyRozliczenieTFooter from "../Wspolnoty/Rozliczenie/WspolnotaRozliczenieTFooter";
import { AuthContext } from "../../Context/AuthProvider";
import { 
    fetchWspolnota, 
    fetchMieszkancy, 
    fetchMieszkancyAll,
    fetchPrzelewy, 
    fetchLokale, 
    fetchUlice, 
    fetchStawki, 
    fetchStawkiInd,
    fetchStawkiJednorazowe,
    fetchStawkiJednorazoweInd,
    fetchGrupy, 
    fetchUdzialyKonta,
    fetchUdzialyKontaAkt,
    fetchUsers,
    fetchPlanowaneZuzycie  
  } from '../../Redux/Actions.js'; 

const WspolnotaRozliczenie = () => {

  const { user } = useContext(AuthContext);

  const dispatch = useDispatch();

  const wspolnotaArr = useSelector( state  => state?.wspolnota?.data);
  const mieszkancy = useSelector( state => state?.mieszkancy?.data);
  const [thisUser, setThisUser] = useState(0);   

  const lokale = useSelector( state => state?.lokale?.data);
  const ulice = useSelector( state => state?.ulice?.data);
  const grupy = useSelector( state => state?.grupy?.data);
  const udzialyKonta = useSelector( state => state?.udzialyKonta?.data);
  const udzialyKontaAkt = useSelector( state => state?.udzialyKontaAkt?.data); 
  const przelewy = useSelector( state => state?.przelewy?.data);
  const stawki = useSelector( state => state?.stawki?.data);
  const stawkiInd = useSelector( state => state?.stawkiInd?.data );
  const stawkiJednorazowe = useSelector( state => state?.stawkiJednorazowe?.data);
  const stawkiJednorazoweInd = useSelector( state => state?.stawkiJednorazoweInd?.data);
  const users = useSelector(state => state?.users?.data);
  const planowaneZuzycie = useSelector( state => state?.planowaneZuzycie?.data);

  const [tablicaCzynsze, setTablicaCzynsze] = useState([]);
  const [tablicaPrzelewy, setTablicaPrzelewy] = useState([]);
  const [czynszeWspolnota, setCzynszeWspolnota] = useState([]);
  const [przelewyWspolnota, setPrzelewyWspolnota] = useState([]);

  const [wspolnota, setWspolnota] = useState([]);
  const [mieszkaniec, setMieszkaniec] = useState({});
  const [miesiac, setMiesiac] = useState(dataFormatMsc(new Date()));
  const [listaMsc, setListaMsc] = useState([]);
  const [ind, setInd] = useState([]);
  const [czynszeMsc, setCzynszeMsc] = useState([]);
  const [stawkiAkt, setStawkiAkt] = useState(undefined);

  useEffect( () => {
    setTablicaPrzelewy( getTablicaPrzelewy2(wspolnotaArr, udzialyKontaAkt, przelewy)[0]) 
  }, [wspolnotaArr, udzialyKontaAkt, przelewy])

  useEffect( () => {
      setTablicaCzynsze(getTablicaCzynsze2(wspolnotaArr, mieszkancy, grupy, [],udzialyKonta, udzialyKontaAkt, lokale, stawki, planowaneZuzycie, stawkiJednorazowe, stawkiInd, stawkiJednorazoweInd /*, zaliczki */))
  },[wspolnotaArr, wspolnota, mieszkancy, grupy, udzialyKonta, lokale, stawki, planowaneZuzycie, stawkiJednorazowe, stawkiInd, stawkiJednorazoweInd /*, zaliczki */])

  useEffect( ()=> {
      dispatch(fetchWspolnota()); 
      dispatch(fetchMieszkancy()); 
      dispatch(fetchMieszkancyAll());
      dispatch(fetchUsers());
      dispatch(fetchPrzelewy()); 
      dispatch(fetchLokale()); 
      dispatch(fetchUlice()); 
      dispatch(fetchStawki()); 
      dispatch(fetchStawkiInd());
      dispatch(fetchStawkiJednorazowe());
      dispatch(fetchStawkiJednorazoweInd());
      dispatch(fetchGrupy()); 
      dispatch(fetchUdzialyKonta());
      dispatch(fetchUdzialyKontaAkt());
      dispatch(fetchPlanowaneZuzycie());
    },[dispatch]); 

  const [loaderOn, setLoaderOn] = useState(true);

  useEffect( () => {
    if (wspolnota && lokale?.length > 0 && ulice?.length > 0 && grupy?.length > 0 && udzialyKontaAkt?.length > 0 
          && stawki && przelewyWspolnota?.length > 0 && czynszeWspolnota?.length > 0 
          && czynszeMsc?.length > 0 && mieszkancy?.length > 0)
      setLoaderOn(false)
  },[wspolnota, lokale, ulice, grupy, udzialyKontaAkt, stawki, stawkiJednorazowe, przelewyWspolnota, czynszeWspolnota, czynszeMsc, mieszkancy])

  useEffect(() => setThisUser( users?.find( u => u?.user == user ) ),[user, users]);
  useEffect( () => setCzynszeWspolnota( tablicaCzynsze?.find( t => t?.id_wspolnoty == wspolnota?.id)?.grupy ), [wspolnota, tablicaCzynsze]);
  useEffect( () => setPrzelewyWspolnota( tablicaPrzelewy?.find( t => t?.id_wspolnoty == wspolnota?.id)?.grupy), [wspolnota, tablicaPrzelewy] );
  useEffect( () => setMieszkaniec( mieszkancy?.find( m => m.id_user == thisUser?.id) ), [mieszkancy, thisUser])
  useEffect( () => setWspolnota( wspolnotaArr && wspolnotaArr?.length > 0 ? wspolnotaArr[0] : [] ), [wspolnotaArr]);

  useEffect( () => {
    const temp = znajdzNajblizszaStawke(miesiac, stawki);
    setStawkiAkt( temp );
  },[miesiac, stawki])

  useEffect( () => {
    const uniqueOpisSet = new Set();

    czynszeMsc?.forEach(obj => {
      if (Array.isArray(obj.ind)) {
          obj.ind.forEach(item => {
              if (item.opis) {
                  uniqueOpisSet.add(item.opis);
              }
          });
      }
    });
    setInd(Array.from(uniqueOpisSet))
  },[czynszeMsc])

    const dodajMiesiac = d => {
      d.setMonth(d.getMonth() + 1);
      return d;
    }

    useEffect(()=> {
      if (stawki?.length > 0) {
        let minData = stawki[0].data_od
        for (let i = 1; i < stawki.length; i++) {
          if (stawki[i].data_od < minData) {
            minData = stawki[i].data_od;
          }
        }

        const dataObecna = new Date();
        const dataMiesiacPoObecnej = nextMonth(new Date(dataObecna));
        const tablicaMiesiecy = [];
        let aktualnaData = new Date(minData);
        while (aktualnaData <= dataMiesiacPoObecnej) {
          tablicaMiesiecy.push(dataFormatMsc(aktualnaData));
          aktualnaData = dodajMiesiac(aktualnaData);
      }

        setListaMsc(tablicaMiesiecy)
      }
    },[stawki]);

    const select = listaMsc?.map( (e,i) => (
      <option key={i} value={e}>
        {e} 
      </option>
    ));

    const onChangeHandle = (e) => setMiesiac(e.target.value);

    useEffect( () => { 
      const temp2  = [];
      czynszeWspolnota?.forEach( czynsz => {
        czynsz?.grupy_udzial_msc?.forEach( c => {
          const temp = c.temp_msc?.filter( t => t.msc == miesiac)
          if (temp && temp.length > 0) {
            temp2.push(
              {
                id_user: czynsz.id_user,
                grupa_numer: c.grupa_numer,
                czynsz: temp[0],
              }
            )
          }
        });
      });
        
      setCzynszeMsc(temp2)
    },[czynszeWspolnota, miesiac])

    return (
        <div 
          className="content wspolnotaRozliczenie zarzad"
          variants={wstepy}
          initial="initial"
          animate="animate"
        >
          <Loader stan={loaderOn} />
            <h1 className="nazwa">
              Należności wspólnoty 
            </h1>
            <h3>
              { wspolnota?.name }
            </h3>

            <select onChange={onChangeHandle} value={miesiac}>
              <option value="0">
                Wybierz okres:
              </option>
              {select}
            </select>
            <div style={{"height": "20px"}}></div>
            <div className="table-container">
              <table className="lista tabela naleznosci">
                <WspolnotaRozliczenieTHead 
                  stawki={stawkiAkt}
                  stawkiJednorazowe={stawkiJednorazowe}
                  czynszeMsc={czynszeMsc}
                  ind={ind}
                />
                <WspolnotaRozliczenieTBody
                  idw={wspolnota?.id} 
                  wspolnota={wspolnota}
                  lokale={lokale}
                  ulice={ulice}
                  grupy={grupy}
                  udzialyKontaAkt={udzialyKontaAkt}
                  stawki={stawkiAkt}
                  stawkiJednorazowe={stawkiJednorazowe}
                  przelewyWspolnota={[przelewyWspolnota]}
                  czynszeWspolnota={czynszeWspolnota}
                  czynszeMsc={czynszeMsc}
                  mieszkancy={mieszkancy}
                  ind={ind}
                />
                <WspolnotyRozliczenieTFooter
                  udzialyKontaAkt={udzialyKontaAkt}
                  stawki={stawkiAkt}
                  stawkiJednorazowe={stawkiJednorazowe}
                  czynszeMsc={czynszeMsc}
                  ind={ind}
                />
              </table>
            </div>

            <div style={{"height": "50px"}}></div>

            <WspolnotaRozliczenieSaldo 
              listaMsc={listaMsc} 
              przelewyWspolnota={przelewyWspolnota} 
              czynsze={czynszeWspolnota} 
              miesiac={miesiac}
              setMiesiac={setMiesiac}
            />

        </div>
    )
}

export default WspolnotaRozliczenie;