import React from "react";

const WspolnotyListaTHead = () => {

    return (
      <li style={{display: "flex", justifyContent: "space-between", fontSize: "13px"}}>
        <div style={{flex: "initial", width: "50px"}}>
            ID
        </div>
        <div style={{flex: "initial", width: "400px"}}>
            Nazwa
        </div>
        <div style={{padding: "0 0 0 10px", flex: "initial", width: "120px"}}>   
            NIP
        </div>
        <div style={{flex: "initial", width: "250px"}}>
            Adres
        </div>
        <div className="central" style={{flex: "initial", width: "100px"}}>
            Dane
        </div>
        <div className="central" style={{flex: "initial", width: "100px"}}>
            Stawki
        </div>
        <div className="central" style={{flex: "initial", width: "100px"}}>
            Należności
        </div>
        <div className="central" style={{flex: "initial", width: "100px"}}>
            Raport zbiorowy
        </div>
        <div className="central" style={{flex: "initial", width: "100px"}}>
            Mieszkańcy
        </div>
        <div className="central" style={{flex: "initial", width: "100px"}}>
            Grupy
        </div>
        <div className="central" style={{flex: "initial", width: "100px"}}>
            Noty - wysyłka
        </div>
        <div className="central" style={{flex: "initial", width: "100px"}}>
            Udziały
        </div>

      </li>
    )
}

export default WspolnotyListaTHead;

